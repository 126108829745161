import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { AuthContext } from '../../context/AuthContext';
import { useOnlineStatus } from '../../context/OnlineStatusContext';
import AddFriend from './AddFriend';
import FriendRequests from './FriendRequests';
import { useNavigate } from 'react-router-dom';
import useWindowSize from '../../hooks/useWindowSize';
import BackLink from "../BackLink";
import FriendSuggestions from "./FriendSuggestions";
import { motion, AnimatePresence } from 'framer-motion';
import { UserPlus, Trash2, ChevronRight } from 'lucide-react';
import BottomNav from "../BottomNav";
import UserAvatar from '../UserAvatar';
import OnlineStatusIndicator from '../OnlineStatusIndicator';
import Aside from "../Aside";

function FriendList() {
  const [friends, setFriends] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [friendToDelete, setFriendToDelete] = useState(null);
  const { isLoggedIn } = useContext(AuthContext);
  const { loading: statusLoading } = useOnlineStatus();
  const navigate = useNavigate();
  const { width } = useWindowSize();

  const fetchFriends = async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/friends`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setFriends(response.data);
    } catch (error) {
      console.error('Ошибка при загрузке друзей!', error);
    }
  };

  useEffect(() => {
    if (isLoggedIn) {
      fetchFriends();
    }
  }, [isLoggedIn]);

  const openModal = (friendId) => {
    setFriendToDelete(friendId);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setFriendToDelete(null);
  };

  const handleDeleteFriend = async () => {
    const token = localStorage.getItem('token');
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/friends/${friendToDelete}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setFriends(friends.filter(friend => friend.friend.id !== friendToDelete));
      closeModal();
    } catch (error) {
      console.error('Ошибка при удалении друга!', error);
    }
  };

  const handleViewProfile = (friendId) => {
    navigate(`/profile/${friendId}`);
  };

  return (
      <div className="flex container mx-auto p-2">
        <Aside />

        <div className="flex-1 container mx-auto dark:bg-neutral-900 min-h-screen pt-16 pb-20">
          <div className="mx-auto">
            <BackLink/>
            <h2 className="text-3xl font-extrabold mb-6 dark:text-white">Друзья</h2>
            <div className="relative space-y-4">
              <AddFriend fetchFriends={fetchFriends}/>
              <FriendSuggestions className="relative z-0"/>
            </div>
            <FriendRequests/>
            <div className="lg:col-span-2">
              {friends.length === 0 ? (
                  <div className="text-center p-8 rounded-xl bg-white dark:bg-neutral-800/50 mt-4">
                    <div className="inline-block p-4 bg-gray-100 dark:bg-neutral-700 rounded-full mb-4">
                      <UserPlus className="w-8 h-8 text-gray-400"/>
                    </div>
                    <h3 className="text-xl font-medium dark:text-white">Друзей пока нет</h3>
                    <p className="text-gray-500 dark:text-neutral-400 mt-2">
                      Начните добавлять друзей, используя кнопку выше
                    </p>
                  </div>
              ) : (
                  <div className="space-y-4 mt-4">
                    <h2 className="text-2xl font-bold dark:text-white">Ваши друзья</h2>
                    <motion.ul className="space-y-2">
                      {friends.map(friend => (
                          <motion.li
                              key={friend.id}
                              initial={{opacity: 0, y: 10}}
                              animate={{opacity: 1, y: 0}}
                              className="group relative bg-white dark:bg-neutral-800/50 hover:bg-gray-50 dark:hover:bg-neutral-700/50 rounded-xl p-2 transition-all"
                          >
                            <div className="flex items-center justify-between">
                              <div className="flex items-center space-x-3 flex-1 min-w-0">
                                <UserAvatar user={friend.friend} size="md" showStatus={true} />

                                <div className="flex-1 min-w-0">
                                  <h3 className="font-semibold truncate dark:text-white">
                                    {friend.friend.name}
                                    <span className="text-gray-500 dark:text-neutral-400 font-normal ml-2">
                                @{friend.friend.id}
                              </span>
                                  </h3>
                                  <OnlineStatusIndicator userId={friend.friend.id} size="sm" />
                                </div>
                              </div>

                              <div className="flex items-center space-x-2 opacity-0 group-hover:opacity-100 transition-opacity">
                                <button
                                    onClick={() => handleViewProfile(friend.friend.id)}
                                    className="p-2 hover:bg-gray-100 dark:hover:bg-neutral-600 rounded-lg text-gray-600 dark:text-neutral-300"
                                >
                                  <ChevronRight className="w-5 h-5"/>
                                </button>

                                <button
                                    onClick={() => openModal(friend.friend.id)}
                                    className="p-2 hover:bg-red-50 dark:hover:bg-red-900/20 rounded-lg text-red-500"
                                >
                                  <Trash2 className="w-5 h-5"/>
                                </button>
                              </div>
                            </div>
                          </motion.li>
                      ))}
                    </motion.ul>
                  </div>
              )}
            </div>
          </div>

          <AnimatePresence>
            {modalIsOpen && (
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center p-4 z-50"
                >
                  <div className="bg-white dark:bg-neutral-800 rounded-2xl p-6 max-w-md w-full shadow-xl">
                    <div className="text-center mb-4">
                      <div className="mx-auto flex items-center justify-center w-12 h-12 bg-red-100 dark:bg-red-900/20 rounded-full">
                        <Trash2 className="w-6 h-6 text-red-600 dark:text-red-400" />
                      </div>
                    </div>
                    <h3 className="text-xl font-semibold text-center mb-2 dark:text-white">Удалить друга?</h3>
                    <p className="text-gray-600 dark:text-neutral-300 text-center mb-6">
                      Все совместные данные и история будут сохранены
                    </p>
                    <div className="flex justify-end gap-3">
                      <button
                          onClick={closeModal}
                          className="px-5 py-2.5 text-gray-500 dark:text-neutral-300 hover:bg-gray-100 dark:hover:bg-neutral-700 rounded-lg"
                      >
                        Отмена
                      </button>
                      <button
                          onClick={handleDeleteFriend}
                          className="px-5 py-2.5 bg-red-500 text-white hover:bg-red-600 rounded-lg transition-colors"
                      >
                        Удалить
                      </button>
                    </div>
                  </div>
                </motion.div>
            )}
          </AnimatePresence>

          {width < 768 && <BottomNav />}
        </div>
      </div>
  );
}

export default FriendList;