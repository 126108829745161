import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { AuthContext } from '../../context/AuthContext';
import { Link, useNavigate } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { UserIcon } from '@heroicons/react/24/outline';

function FriendListForProfile() {
  const [friends, setFriends] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { isLoggedIn } = useContext(AuthContext);
  const navigate = useNavigate();

  const fetchFriends = async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/friends`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setFriends(response.data);
      setError(null);
    } catch (error) {
      setError('Не удалось загрузить список друзей');
      console.error('Error fetching friends:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleViewProfile = (friendId) => {
    navigate(`/profile/${friendId}`);
  };

  useEffect(() => {
    if (isLoggedIn) fetchFriends();
  }, [isLoggedIn]);

  return (
      <div className="mx-auto w-full max-w-3xl px-4 py-4">
        <div className="mb-4 flex items-center justify-between">
          <Link
              to="/friends"
              className="group flex items-center space-x-2 transition-opacity hover:opacity-80"
          >
            <h2 className="text-xl font-semibold text-neutral-800 dark:text-neutral-200">
              Друзья
            </h2>
            <span className="rounded-lg bg-neutral-100 px-2 py-1 text-sm font-medium text-neutral-600 transition-colors group-hover:bg-neutral-200 dark:bg-neutral-800 dark:text-neutral-400 group-hover:dark:bg-neutral-700">
            {friends.length}
          </span>
          </Link>
          <Link
              to="/friends"
              className="text-sm font-medium text-indigo-600 hover:text-indigo-500 dark:text-indigo-400 dark:hover:text-indigo-300"
          >
            Посмотреть всех
          </Link>
        </div>

        {loading ? (
            <div className="flex justify-center py-6">
              <ClipLoader className="h-8 w-8 text-indigo-600" />
            </div>
        ) : error ? (
            <div className="rounded-lg bg-red-50 p-4 dark:bg-red-900/20">
              <p className="text-center text-red-600 dark:text-red-400">{error}</p>
            </div>
        ) : (
            <ul className="relative flex gap-4 overflow-x-auto pb-4 scrollbar-thin scrollbar-track-transparent scrollbar-thumb-neutral-300 hover:scrollbar-thumb-neutral-400 dark:scrollbar-thumb-neutral-700 dark:hover:scrollbar-thumb-neutral-600">
              {friends.length === 0 ? (
                  <div className="flex w-full flex-col items-center py-6">
                    <UserIcon className="h-12 w-12 text-neutral-400 dark:text-neutral-600" />
                    <p className="mt-2 text-neutral-500 dark:text-neutral-600">
                      Пока нет друзей
                    </p>
                  </div>
              ) : (
                  friends.map((friend) => (
                      <li
                          key={friend.id}
                          className="group flex shrink-0 flex-col items-center"
                      >
                        <button
                            onClick={() => handleViewProfile(friend.friend.id)}
                            className="relative transition-transform hover:scale-105"
                        >
                          <div className="relative h-16 w-16 overflow-hidden rounded-full border-2 border-white shadow-lg dark:border-neutral-900">
                            {friend.friend.avatar ? (
                                <img
                                    src={`${process.env.REACT_APP_BASE_URL}/storage/${friend.friend.avatar}`}
                                    alt={friend.friend.name}
                                    loading="lazy"
                                    className="h-full w-full object-cover"
                                />
                            ) : (
                                <div className="flex h-full w-full items-center justify-center bg-neutral-100 dark:bg-neutral-800">
                        <span className="text-xl font-medium text-neutral-500 dark:text-neutral-400">
                          {friend.friend.name?.[0]?.toUpperCase() || '?'}
                        </span>
                                </div>
                            )}
                          </div>
                          {friend.is_online && (
                              <div className="absolute bottom-0 right-0 h-3 w-3 rounded-full border-2 border-white bg-green-500 dark:border-neutral-900" />
                          )}
                        </button>
                        <div className="mt-2 max-w-[90px] text-center">
                          <p className="truncate text-sm font-medium text-neutral-700 group-hover:text-neutral-900 dark:text-neutral-300 dark:group-hover:text-neutral-100">
                            {friend.friend.name || 'Без имени'}
                          </p>
                          <p className="truncate text-xs text-neutral-500 dark:text-neutral-500">
                            {friend.friend.city || ''}
                          </p>
                        </div>
                      </li>
                  ))
              )}
            </ul>
        )}
      </div>
  );
}

export default FriendListForProfile;