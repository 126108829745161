import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { usePlayer } from '../context/PlayerContext';
import { FiPlay, FiHeart, FiMoreVertical, FiMusic, FiPause, FiTrash, FiEdit, FiPlus, FiCheck, FiX } from 'react-icons/fi';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import toast from 'react-hot-toast';
import {formatArtists, formatDuration, getTrackWordForm} from "./utils/helpers";
import BackLink from "./BackLink";
import { ClipLoader } from "react-spinners";
import useWindowSize from '../hooks/useWindowSize';
import BottomNav from "./BottomNav"; // Import the hook

const PlaylistPage = () => {
    const { id } = useParams();
    const [playlist, setPlaylist] = useState(null);
    const [tracks, setTracks] = useState([]);
    const [isEditing, setIsEditing] = useState(false);
    const [editName, setEditName] = useState('');
    const [editDescription, setEditDescription] = useState('');
    const [showConfirmDelete, setShowConfirmDelete] = useState(false);
    const [draggedTrack, setDraggedTrack] = useState(null);
    const [isAddingTrack, setIsAddingTrack] = useState(false);
    const [availableTracks, setAvailableTracks] = useState([]);
    const [selectedTracks, setSelectedTracks] = useState([]);
    const { playTrack, isPlaying, currentTrack, pauseTrack } = usePlayer();
    const { width } = useWindowSize(); // Use the window size hook
    const isMobile = width < 640; // Define mobile breakpoint

    // Загрузка данных о плейлисте
    useEffect(() => {
        const fetchPlaylist = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/playlists/${id}`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                    },
                });
                if (!response.ok) throw new Error('Ошибка загрузки плейлиста');
                const data = await response.json();
                setPlaylist(data);
                setTracks(data.tracks);
                setEditName(data.name);
                setEditDescription(data.description || '');
            } catch (error) {
                console.error('Ошибка:', error);
                toast.error('Не удалось загрузить плейлист');
            }
        };
        fetchPlaylist();
    }, [id]);

    // Загрузка доступных треков для добавления
    useEffect(() => {
        if (isAddingTrack) {
            const fetchAvailableTracks = async () => {
                try {
                    const response = await fetch(`${process.env.REACT_APP_API_URL}/tracks`, {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem('token')}`,
                        },
                    });
                    if (!response.ok) throw new Error('Ошибка загрузки треков');
                    const data = await response.json();
                    // Фильтруем треки, которых нет в плейлисте
                    const playlistTrackIds = tracks.map(track => track.id);
                    setAvailableTracks(data.filter(track => !playlistTrackIds.includes(track.id)));
                } catch (error) {
                    console.error('Ошибка:', error);
                    toast.error('Не удалось загрузить доступные треки');
                }
            };
            fetchAvailableTracks();
        }
    }, [isAddingTrack, tracks]);

    // Обработчики воспроизведения
    const handlePlayPlaylist = () => {
        if (tracks.length > 0) {
            if (currentTrack && tracks.some(t => t.id === currentTrack.id) && isPlaying) {
                pauseTrack();
            } else if (currentTrack && tracks.some(t => t.id === currentTrack.id) && !isPlaying) {
                playTrack(currentTrack, tracks);
            } else {
                playTrack(tracks[0], tracks);
            }
        }
    };

    const handlePlayTrack = (track) => {
        if (currentTrack?.id === track.id) {
            isPlaying ? pauseTrack() : playTrack(track, tracks);
        } else {
            playTrack(track, tracks);
        }
    };

    // Управление плейлистом
    const handleSavePlaylistInfo = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/playlists/${id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
                body: JSON.stringify({
                    name: editName,
                    description: editDescription
                }),
            });

            if (!response.ok) throw new Error('Ошибка обновления плейлиста');

            const updatedPlaylist = await response.json();
            setPlaylist({
                ...playlist,
                name: updatedPlaylist.name,
                description: updatedPlaylist.description
            });
            setIsEditing(false);
            toast.success('Плейлист обновлен');
        } catch (error) {
            console.error('Ошибка:', error);
            toast.error('Не удалось обновить плейлист');
        }
    };

    const handleDeletePlaylist = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/playlists/${id}`, {
                method: 'DELETE',
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            });

            if (!response.ok) throw new Error('Ошибка удаления плейлиста');

            toast.success('Плейлист удален');
            // Перенаправление на страницу с плейлистами
            window.location.href = '/music';
        } catch (error) {
            console.error('Ошибка:', error);
            toast.error('Не удалось удалить плейлист');
        }
    };

    // Управление треками в плейлисте
    const handleRemoveTrack = async (trackId) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/playlists/${id}/tracks/${trackId}`, {
                method: 'DELETE',
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            });

            if (!response.ok) throw new Error('Ошибка удаления трека');

            setTracks(tracks.filter(track => track.id !== trackId));
            toast.success('Трек удален из плейлиста');
        } catch (error) {
            console.error('Ошибка:', error);
            toast.error('Не удалось удалить трек из плейлиста');
        }
    };

    const handleDragStart = (track) => {
        if (isMobile) return; // Disable drag on mobile
        setDraggedTrack(track);
    };

    const handleDragOver = (e, targetIndex) => {
        e.preventDefault();
    };

    const handleDrop = async (e, targetIndex) => {
        e.preventDefault();
        if (!draggedTrack || isMobile) return; // Prevent drop on mobile

        const sourceIndex = tracks.findIndex(track => track.id === draggedTrack.id);
        if (sourceIndex === targetIndex) return;

        // Обновляем порядок треков локально
        const newTracks = [...tracks];
        const [movedTrack] = newTracks.splice(sourceIndex, 1);
        newTracks.splice(targetIndex, 0, movedTrack);
        setTracks(newTracks);

        // Отправляем обновление на сервер
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/playlists/${id}/reorder`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
                body: JSON.stringify({
                    tracks: newTracks.map((track, index) => ({
                        id: track.id,
                        position: index
                    }))
                }),
            });

            if (!response.ok) throw new Error('Ошибка обновления порядка треков');

            toast.success('Порядок треков обновлен');
        } catch (error) {
            console.error('Ошибка:', error);
            toast.error('Не удалось обновить порядок треков');
        }

        setDraggedTrack(null);
    };

    const handleAddTracksToPlaylist = async () => {
        if (selectedTracks.length === 0) {
            setIsAddingTrack(false);
            return;
        }

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/playlists/${id}/tracks`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
                body: JSON.stringify({
                    track_ids: selectedTracks.map(track => track.id)
                }),
            });

            if (!response.ok) throw new Error('Ошибка добавления треков');

            // Обновляем список треков
            const updatedPlaylist = await response.json();
            setTracks(updatedPlaylist.tracks);
            setIsAddingTrack(false);
            setSelectedTracks([]);
            toast.success('Треки добавлены в плейлист');
        } catch (error) {
            console.error('Ошибка:', error);
            toast.error('Не удалось добавить треки в плейлист');
        }
    };

    const toggleTrackSelection = (track) => {
        if (selectedTracks.some(t => t.id === track.id)) {
            setSelectedTracks(selectedTracks.filter(t => t.id !== track.id));
        } else {
            setSelectedTracks([...selectedTracks, track]);
        }
    };

    if (!playlist) {
        return (
            <div className="min-h-screen pt-12 pb-24">
                <div className="container mx-auto px-3 md:px-8">
                    <div className="flex items-center justify-center my-auto h-full">
                        <ClipLoader color="#6B46C1" size={75} />
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="min-h-screen pt-16 pb-28">
            <div className="container mx-auto px-3 md:px-8">
                {/* Заголовок и описание плейлиста */}
                <BackLink />

                {width < 768 && <BottomNav />}

                <div className={`flex flex-col ${isMobile ? 'items-center' : 'sm:flex-row items-start'} gap-4 sm:gap-8 w-full mt-4`}>
                    {/* Обложка плейлиста */}
                    <div className={`relative ${isMobile ? 'w-48 h-48' : 'w-30 h-30 sm:w-60 sm:h-60'} rounded-2xl overflow-hidden shadow-2xl group`}>
                        <LazyLoadImage
                            src={playlist.cover_image ? `${process.env.REACT_APP_BASE_URL}/storage/${playlist.cover_image}` : '/placeholder.png'}
                            alt={playlist.name}
                            className="w-full h-full object-cover transition-transform duration-700"
                            effect="blur"
                            placeholderSrc="/placeholder.png"
                        />
                    </div>

                    {/* Информация о плейлисте */}
                    <div className="flex-1 max-w-full sm:max-w-2xl mt-4 sm:mt-0 w-full">
                        {isEditing ? (
                            <div className="space-y-3">
                                <input
                                    type="text"
                                    value={editName}
                                    onChange={(e) => setEditName(e.target.value)}
                                    className="w-full p-2 bg-white/10 rounded-xl border border-neutral-600 text-neutral-800 dark:text-white text-base sm:text-xl focus:ring-2 focus:ring-purple-500 focus:border-transparent outline-none"
                                    placeholder="Название плейлиста"
                                />
                                <textarea
                                    value={editDescription}
                                    onChange={(e) => setEditDescription(e.target.value)}
                                    className="w-full p-2 bg-white/10 rounded-xl border border-neutral-600 dark:text-white text-neutral-800 focus:ring-2 focus:ring-purple-500 focus:border-transparent outline-none"
                                    placeholder="Описание плейлиста"
                                    rows={3}
                                />
                                <div className="flex gap-3 pt-2 flex-wrap">
                                    <button
                                        onClick={handleSavePlaylistInfo}
                                        className="bg-purple-600 text-white px-3 sm:px-6 py-2 rounded-xl flex items-center gap-2 hover:bg-purple-700 transition-all shadow-lg hover:shadow-purple-500/30"
                                    >
                                        <FiCheck className="text-lg"/> Сохранить
                                    </button>
                                    <button
                                        onClick={() => {
                                            setIsEditing(false);
                                            setEditName(playlist.name);
                                            setEditDescription(playlist.description || '');
                                        }}
                                        className="bg-neutral-600 text-white px-3 sm:px-6 py-2 rounded-xl flex items-center gap-2 hover:bg-neutral-700 transition-all"
                                    >
                                        <FiX className="text-lg"/> Отмена
                                    </button>
                                </div>
                            </div>
                        ) : (
                            <>
                                <h1 className={`${isMobile ? 'text-xl text-center sm:text-left' : 'text-2xl sm:text-4xl'} font-bold text-neutral-800 dark:text-white mb-2`}>
                                    {playlist.name}
                                </h1>
                                {playlist.description && (
                                    <p className={`text-neutral-800/70 dark:text-white/70 text-sm sm:text-lg mt-2 ${isMobile ? 'text-center sm:text-left' : ''}`}>
                                        {playlist.description}
                                    </p>
                                )}
                                <div className={`mt-3 sm:mt-6 flex items-center gap-3 sm:gap-4 flex-wrap ${isMobile ? 'justify-center sm:justify-start' : ''}`}>
                                    <button
                                        onClick={handlePlayPlaylist}
                                        className="bg-purple-600 text-white px-5 sm:px-8 py-2 rounded-xl flex items-center gap-2 sm:gap-3 hover:bg-purple-700 transition-all shadow-lg hover:shadow-purple-500/30"
                                    >
                                        {currentTrack && tracks.some(t => t.id === currentTrack.id) && isPlaying ? (
                                            <><FiPause className="text-base sm:text-xl"/><span
                                                className="font-medium">Пауза</span></>
                                        ) : (
                                            <><FiPlay className="text-base sm:text-xl"/><span
                                                className="font-medium">Слушать</span></>
                                        )}
                                    </button>
                                    <button
                                        onClick={() => setIsEditing(true)}
                                        className="p-2 sm:p-4 rounded-xl bg-neutral-200 dark:bg-neutral-700 hover:bg-neutral-300 dark:hover:bg-neutral-600 transition-colors"
                                        title="Редактировать"
                                    >
                                        <FiEdit className="text-base sm:text-xl text-neutral-800 dark:text-white"/>
                                    </button>
                                    <button
                                        onClick={() => setShowConfirmDelete(!showConfirmDelete)}
                                        className="p-2 sm:p-4 rounded-xl bg-red-500/20 dark:bg-red-700/40 hover:bg-red-500/30 dark:hover:bg-red-600/50 transition-colors"
                                        title="Удалить"
                                    >
                                        <FiTrash className="text-base sm:text-xl text-red-700 dark:text-red-300"/>
                                    </button>
                                </div>

                                {showConfirmDelete && (
                                    <div
                                        className={`mt-3 sm:mt-6 p-3 sm:p-6 bg-red-500/10 backdrop-blur-sm rounded-xl border border-red-500 shadow-lg ${isMobile ? 'mx-2' : ''}`}>
                                        <p className="text-neutral-800 dark:text-white text-sm sm:text-lg mb-3">Вы уверены, что хотите
                                            удалить этот плейлист?</p>
                                        <div className="flex gap-3 flex-wrap">
                                            <button
                                                onClick={handleDeletePlaylist}
                                                className="bg-red-600 text-white px-4 sm:px-6 py-2 rounded-xl hover:bg-red-700 transition-colors shadow-lg"
                                            >
                                                Да, удалить
                                            </button>
                                            <button
                                                onClick={() => setShowConfirmDelete(false)}
                                                className="bg-neutral-600 text-white px-4 sm:px-6 py-2 rounded-xl hover:bg-neutral-700 transition-colors"
                                            >
                                                Отмена
                                            </button>
                                        </div>
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                </div>


                {/* Список треков */}
                <div className="mt-6 mb-6">
                    <div className="flex justify-between items-center mb-4 flex-wrap gap-2">
                        <h2 className="text-xl sm:text-2xl font-bold text-white">Треки</h2>
                        {!isAddingTrack ? (
                            <button
                                onClick={() => setIsAddingTrack(true)}
                                className="bg-purple-600/90 text-white px-4 py-2 rounded-xl flex items-center gap-2 hover:bg-purple-700 transition-all shadow-lg hover:shadow-purple-500/20 text-sm sm:text-base"
                            >
                                <FiPlus className="text-lg"/> {isMobile ? 'Добавить' : 'Добавить треки'}
                            </button>
                        ) : (
                            <div className="flex gap-2 flex-wrap">
                                <button
                                    onClick={handleAddTracksToPlaylist}
                                    className="bg-purple-600 text-white px-3 sm:px-6 py-2 rounded-xl flex items-center gap-2 hover:bg-purple-700 transition-all shadow-lg text-sm sm:text-base"
                                    disabled={selectedTracks.length === 0}
                                >
                                    <FiCheck className="text-lg"/> {isMobile ? `Добавить (${selectedTracks.length})` : `Добавить выбранные (${selectedTracks.length})`}
                                </button>
                                <button
                                    onClick={() => {
                                        setIsAddingTrack(false);
                                        setSelectedTracks([]);
                                    }}
                                    className="bg-neutral-600 text-white px-3 sm:px-6 py-2 rounded-xl flex items-center gap-2 hover:bg-neutral-700 transition-all text-sm sm:text-base"
                                >
                                    <FiX className="text-lg"/> Отмена
                                </button>
                            </div>
                        )}
                    </div>

                    {isAddingTrack ? (
                        <div
                            className="space-y-2 max-h-80 sm:max-h-96 overflow-y-auto bg-white/5 backdrop-blur-sm p-2 sm:p-3 rounded-xl border border-white/10 shadow-xl">
                            {availableTracks.length === 0 ? (
                                <div className="flex flex-col items-center justify-center py-8 sm:py-12 text-center">
                                    <FiMusic className="text-3xl sm:text-4xl text-neutral-400 mb-3"/>
                                    <p className="text-neutral-400 text-base sm:text-lg">Нет доступных треков для добавления</p>
                                </div>
                            ) : (
                                availableTracks.map(track => (
                                    <div
                                        key={track.id}
                                        className={`flex items-center p-2 rounded-xl ${
                                            selectedTracks.some(t => t.id === track.id)
                                                ? 'bg-purple-500/20 border-l-4 border-purple-500'
                                                : 'bg-white/5 hover:bg-white/10'
                                        } transition-all cursor-pointer`}
                                        onClick={() => toggleTrackSelection(track)}
                                    >
                                        {/* Чекбокс */}
                                        <div className="w-8 flex justify-center">
                                            <div
                                                className={`w-5 h-5 border-2 rounded-md flex items-center justify-center ${
                                                    selectedTracks.some(t => t.id === track.id)
                                                        ? 'bg-purple-500 border-purple-500'
                                                        : 'border-neutral-400'
                                                } transition-colors`}>
                                                {selectedTracks.some(t => t.id === track.id) &&
                                                    <FiCheck className="text-white text-xs"/>}
                                            </div>
                                        </div>

                                        {/* Обложка трека */}
                                        <div className="w-10 h-10 sm:w-12 sm:h-12 rounded-lg overflow-hidden mx-2 sm:mx-4 shadow-md">
                                            <LazyLoadImage
                                                src={track.album_art ? `${process.env.REACT_APP_BASE_URL}/storage/${track.album_art}` : '/placeholder.png'}
                                                alt={track.title}
                                                className="w-full h-full object-cover"
                                                effect="blur"
                                                placeholderSrc="/placeholder.png"
                                            />
                                        </div>

                                        {/* Информация о треке */}
                                        <div className="flex-1 min-w-0">
                                            <h3 className="font-medium text-neutral-900 dark:text-white text-sm sm:text-lg truncate">
                                                {track.title}
                                            </h3>
                                            <p className="text-xs sm:text-sm text-neutral-500 dark:text-neutral-400 truncate">
                                                {formatArtists(track)}
                                            </p>
                                        </div>

                                        {/* Длительность трека */}
                                        <span className="text-xs sm:text-sm font-medium text-neutral-500 dark:text-neutral-400 mr-1 sm:mr-2 ml-2">
                                        {formatDuration(track.duration)}
                                    </span>
                                    </div>
                                ))
                            )}
                        </div>
                    ) : (
                        <div
                            className="space-y-2 bg-white/5 backdrop-blur-sm p-2 rounded-xl border border-white/10 shadow-xl">
                            {tracks.length === 0 ? (
                                <div className="flex flex-col items-center justify-center py-10 sm:py-16 text-center">
                                    <FiMusic className="text-4xl sm:text-5xl text-neutral-400 mb-3"/>
                                    <p className="text-neutral-400 text-lg sm:text-xl mb-2">В этом плейлисте пока нет треков</p>
                                    <p className="text-neutral-500 text-sm sm:text-base">Нажмите «Добавить треки», чтобы начать</p>
                                </div>
                            ) : (
                                tracks.map((track, index) => (
                                    <div
                                        key={track.id}
                                        className={`flex items-center p-1 rounded-xl ${
                                            currentTrack?.id === track.id
                                                ? 'bg-purple-500/15 border-l-4 border-purple-600'
                                                : 'bg-white/5 hover:bg-white/10'
                                        } transition-all cursor-pointer mb-2`}
                                        onClick={() => handlePlayTrack(track)}
                                        draggable={!isMobile}
                                        onDragStart={() => handleDragStart(track)}
                                        onDragOver={(e) => handleDragOver(e, index)}
                                        onDrop={(e) => handleDrop(e, index)}
                                    >
                                        {/* Обложка трека */}
                                        <div
                                            className="w-12 h-12 sm:w-14 sm:h-14 rounded-lg overflow-hidden mx-2 sm:mx-4 shadow-lg group-hover:shadow-xl transition-shadow">
                                            <LazyLoadImage
                                                src={track.album_art ? `${process.env.REACT_APP_BASE_URL}/storage/${track.album_art}` : '/placeholder.png'}
                                                alt={track.title}
                                                className="w-full h-full object-cover transition-transform duration-500"
                                                effect="blur"
                                                placeholderSrc="/placeholder.png"
                                            />
                                        </div>

                                        {/* Информация о треке */}
                                        <div className="flex-1 min-w-0">
                                            <h3 className={`font-medium text-sm sm:text-lg truncate ${
                                                currentTrack?.id === track.id
                                                    ? 'text-purple-500'
                                                    : 'text-neutral-900 dark:text-white'
                                            }`}>
                                                {track.title}
                                            </h3>
                                            <p className="text-xs sm:text-sm text-neutral-500 dark:text-neutral-400 truncate">
                                                {formatArtists(track)}
                                            </p>
                                        </div>

                                        {/* Длительность трека - скрываем на очень маленьких экранах */}
                                        {width > 400 && (
                                            <span className="text-xs sm:text-sm font-medium text-neutral-500 dark:text-neutral-400 mx-2 sm:mx-4 hidden sm:block">
                                                {formatDuration(track.duration)}
                                            </span>
                                        )}

                                        {/* Кнопки управления */}
                                        <div className="flex items-center ml-1">
                                            {/* Кнопка воспроизведения */}
                                            <button
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    handlePlayTrack(track);
                                                }}
                                                className="p-2 rounded-xl hover:bg-white/15 transition-colors"
                                            >
                                                {currentTrack?.id === track.id && isPlaying ? (
                                                    <FiPause className="text-base sm:text-xl text-purple-500"/>
                                                ) : (
                                                    <FiPlay className="text-base sm:text-xl text-purple-500"/>
                                                )}
                                            </button>

                                            {/* Кнопка удаления */}
                                            <button
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleRemoveTrack(track.id);
                                                }}
                                                className="p-2 rounded-xl hover:bg-white/15 transition-colors ml-1"
                                                title="Удалить из плейлиста"
                                            >
                                                <FiTrash
                                                    className="text-base sm:text-xl text-neutral-400 hover:text-red-500 transition-colors"/>
                                            </button>
                                        </div>
                                    </div>
                                ))
                            )}
                        </div>
                    )}
                </div>

                {/* Информация о количестве треков */}
                <div className="text-sm sm:text-base text-neutral-500 dark:text-neutral-400 font-medium">
                    {tracks.length} {getTrackWordForm(tracks.length)}
                </div>
            </div>
        </div>
    );
}

export default PlaylistPage;