import React, { useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { AuthContext } from "../context/AuthContext";
import BackLink from "./BackLink";
import {FiEdit2, FiFileText, FiUser, FiType, FiAlertCircle, FiAlignLeft, FiUserCheck, FiImage} from 'react-icons/fi';
import {ToastContext} from "./ToastProvider";

const NewsForm = ({ editMode = false }) => {
    const { id } = useParams();
    const [errors, setErrors] = useState({});
    const navigate = useNavigate();
    const { user } = useContext(AuthContext);
    const { addToast } = useContext(ToastContext);
    const [formData, setFormData] = useState({
        title: '',
        content: '',
        author: user?.name || '',
        image: null
    });
    const [previewImage, setPreviewImage] = useState(null);
    const [currentImage, setCurrentImage] = useState(null);

    useEffect(() => {
        if (editMode && id) {
            const fetchNews = async () => {
                try {
                    setErrors({});
                    const response = await axios.get(
                        `${process.env.REACT_APP_API_URL}/news/${id}`
                    );
                    setFormData({
                        title: response.data.title,
                        content: response.data.content,
                        author: response.data.author,
                        image: response.data.image
                    });
                    setCurrentImage(response.data.image);
                    if(response.data.image) {
                        // Используем единый подход к URL изображений
                        setPreviewImage(`${process.env.REACT_APP_BASE_URL}/storage/${response.data.image}`);
                    }
                } catch (error) {
                    addToast('Не удалось загрузить данные новости', 'error');
                    navigate('/news');
                }
            };
            fetchNews();
        }
    }, [id, editMode, navigate]);

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if(file) {
            setFormData({ ...formData, image: file });
            setPreviewImage(URL.createObjectURL(file));
        } else {
            setFormData({ ...formData, image: currentImage });
            setPreviewImage(currentImage ? `${process.env.REACT_APP_BASE_URL}/storage/${currentImage}` : null);
        }
    };

    // Функция для удаления изображения
    const handleRemoveImage = () => {
        setFormData({ ...formData, image: null });
        setPreviewImage(null);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const data = new FormData();
        data.append('title', formData.title);
        data.append('content', formData.content);
        data.append('author', formData.author || user.name);

        // Для метода PUT в Laravel нужно добавить _method
        if (editMode) {
            data.append('_method', 'PUT');
        }

        // Обработка изображения
        if (formData.image instanceof File) {
            // Если новый файл загружен
            data.append('image', formData.image);
        } else if (formData.image === null) {
            // Если изображение удалено
            data.append('image', null);
        } else if (formData.image) {
            // Если используется существующее изображение (строка с путем)
            // Не добавляем в FormData, так как бэкенд сохранит текущее изображение
        }

        try {
            const url = editMode
                ? `${process.env.REACT_APP_API_URL}/news/${id}`
                : `${process.env.REACT_APP_API_URL}/news`;

            const config = {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'multipart/form-data'
                }
            };

            // Всегда используем POST для multipart/form-data, метод эмулируется через _method
            const response = await axios.post(url, data, config);

            // Показываем уведомление об успехе через toast
            const successMessage = editMode
                ? 'Новость успешно обновлена!'
                : 'Новость успешно создана!';
            addToast(successMessage, 'success');

            navigate(editMode ? `/news/${id}` : '/news');
        } catch (err) {
            console.error('Submission error:', err.response);
            if (err.response?.status === 403) {
                addToast('Недостаточно прав!', 'error');
            } else if (err.response?.data?.errors) {
                setErrors(err.response.data.errors);
                addToast('Пожалуйста, исправьте ошибки в форме', 'warning');
            } else {
                addToast('Произошла ошибка при сохранении', 'error');
            }
        }
    };

    if (!user?.is_admin) return null;

    return (
        <div className="container mx-auto px-4 py-8 pt-16">
            <div className="mb-6">
                <BackLink/>
                <h2 className="text-3xl font-extrabold mb-8 dark:text-white">
                    {editMode ? 'Редактирование новости' : 'Новая публикация'}
                </h2>
            </div>

            <div className="bg-white dark:bg-neutral-900 rounded-2xl p-2 shadow-xl dark:shadow-neutral-800/10 transition-all duration-300">
                <form onSubmit={handleSubmit} className="space-y-7">
                    {/* Поле изображения */}
                    <div className="group relative">
                        <div className="flex items-center gap-3 mb-3">
                            <FiImage className="w-6 h-6 text-purple-600 dark:text-purple-400" />
                            <label className="block text-lg font-semibold text-gray-800 dark:text-gray-200">
                                Изображение
                            </label>
                        </div>
                        <div className="relative">
                            <input
                                type="file"
                                onChange={handleImageChange}
                                accept="image/*"
                                className="hidden"
                                id="imageInput"
                            />
                            <div className="relative">
                                <label
                                    htmlFor="imageInput"
                                    className="cursor-pointer block w-full p-4 border-2 border-dashed rounded-xl
                                    border-gray-200/80 hover:border-gray-300 dark:border-neutral-700
                                    text-gray-400 dark:text-neutral-500 hover:text-purple-500 transition-all"
                                >
                                    {previewImage ? (
                                        <div className="relative">
                                            <img
                                                src={previewImage}
                                                alt="Preview"
                                                className="w-full h-48 object-cover rounded-lg mb-4"
                                            />
                                            <div className="absolute inset-0 bg-black/30 opacity-0 hover:opacity-100 transition-opacity flex items-center justify-center rounded-lg">
                                                <span className="text-white font-medium">Изменить изображение</span>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="text-center py-8">
                                            <svg className="mx-auto h-12 w-12 text-gray-300 dark:text-neutral-600" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
                                                <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                            <p className="mt-2">Нажмите для загрузки изображения</p>
                                            <p className="text-xs text-gray-400 dark:text-neutral-500 mt-1">PNG, JPG, GIF до 2MB</p>
                                        </div>
                                    )}
                                </label>
                                {previewImage && (
                                    <button
                                        type="button"
                                        onClick={handleRemoveImage}
                                        className="absolute top-2 right-2 bg-red-500 hover:bg-red-600 text-white rounded-full p-1.5 shadow-lg transition-all"
                                        title="Удалить изображение"
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                            <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                                        </svg>
                                    </button>
                                )}
                            </div>
                        </div>
                        {errors.image && (
                            <div className="mt-2 flex items-center gap-2 text-red-500 animate-fadeIn">
                                <FiAlertCircle className="flex-shrink-0" />
                                <span className="text-sm">{errors.image[0]}</span>
                            </div>
                        )}
                        {editMode && formData.image && !previewImage && (
                            <div className="mt-2 text-sm text-gray-500 dark:text-neutral-400">
                                Текущее изображение: {formData.image}
                            </div>
                        )}
                    </div>                    {/* Поле заголовка */}
                    <div className="group relative">
                        <div className="flex items-center gap-3 mb-3">
                            <FiType className="w-6 h-6 text-purple-600 dark:text-purple-400" />
                            <label className="block text-lg font-semibold text-gray-800 dark:text-gray-200">
                                Заголовок
                            </label>
                        </div>
                        <div className="relative">
                            <input
                                value={formData.title}
                                onChange={(e) => setFormData({ ...formData, title: e.target.value })}
                                className={`w-full pl-12 pr-4 py-3.5 text-lg rounded-xl border-2 bg-transparent transition-all
                                    ${errors.title
                                    ? 'border-red-400/50 hover:border-red-400 focus:border-red-500'
                                    : 'border-gray-200/80 hover:border-gray-300 focus:border-purple-500 dark:border-neutral-700'}
                                    focus:ring-4 focus:ring-purple-500/20 dark:focus:ring-purple-400/30
                                    dark:text-gray-100 placeholder-gray-400/70 dark:placeholder-neutral-500`}
                                placeholder="Введите заголовок..."
                            />
                            <FiEdit2 className="absolute left-4 top-1/2 -translate-y-1/2 w-5 h-5 text-gray-400 dark:text-neutral-500" />
                        </div>
                        {errors.title && (
                            <div className="mt-2 flex items-center gap-2 text-red-500 animate-fadeIn">
                                <FiAlertCircle className="flex-shrink-0" />
                                <span className="text-sm">{errors.title[0]}</span>
                            </div>
                        )}
                    </div>

                    {/* Поле содержания */}
                    <div className="group relative">
                        <div className="flex items-center gap-3 mb-3">
                            <FiFileText className="w-6 h-6 text-purple-600 dark:text-purple-400" />
                            <label className="block text-lg font-semibold text-gray-800 dark:text-gray-200">
                                Содержание
                            </label>
                        </div>
                        <div className="relative">
                            <textarea
                                value={formData.content}
                                onChange={(e) => setFormData({ ...formData, content: e.target.value })}
                                rows="6"
                                className={`w-full pl-12 pr-4 py-3.5 text-lg rounded-xl border-2 bg-transparent transition-all
                                    ${errors.content
                                    ? 'border-red-400/50 hover:border-red-400 focus:border-red-500'
                                    : 'border-gray-200/80 hover:border-gray-300 focus:border-purple-500 dark:border-neutral-700'}
                                    focus:ring-4 focus:ring-purple-500/20 dark:focus:ring-purple-400/30
                                    dark:text-gray-100 placeholder-gray-400/70 dark:placeholder-neutral-500 resize-y min-h-[180px]`}
                                placeholder="Напишите содержание новости..."
                            />
                            <FiAlignLeft className="absolute left-4 top-4 w-5 h-5 text-gray-400 dark:text-neutral-500" />
                        </div>
                        {errors.content && (
                            <div className="mt-2 flex items-center gap-2 text-red-500 animate-fadeIn">
                                <FiAlertCircle className="flex-shrink-0" />
                                <span className="text-sm">{errors.content[0]}</span>
                            </div>
                        )}
                    </div>

                    {/* Поле автора */}
                    <div className="group relative">
                        <div className="flex items-center gap-3 mb-3">
                            <FiUser className="w-6 h-6 text-purple-600 dark:text-purple-400" />
                            <label className="block text-lg font-semibold text-gray-800 dark:text-gray-200">
                                Автор
                            </label>
                        </div>
                        <div className="relative">
                            <input
                                value={formData.author || user?.name || ''}
                                readOnly
                                className="w-full pl-12 pr-4 py-3.5 text-lg rounded-xl border-2 bg-gray-50/50 dark:bg-neutral-800/30
                                    border-gray-200/80 dark:border-neutral-700 text-gray-600 dark:text-gray-300
                                    cursor-not-allowed transition-all"
                            />
                            <FiUserCheck className="absolute left-4 top-1/2 -translate-y-1/2 w-5 h-5 text-gray-400 dark:text-neutral-500" />
                        </div>
                    </div>

                    {/* Кнопка отправки */}
                    <div className="pt-6">
                        <button
                            type="submit"
                            className="group relative inline-flex items-center justify-center w-full md:w-auto px-8 py-4
                                text-lg font-semibold text-white transition-all duration-300 rounded-xl
                                bg-gradient-to-r from-purple-600 to-blue-500 hover:to-blue-600
                                shadow-lg hover:shadow-xl hover:scale-[1.02]
                                active:scale-95 active:shadow-lg"
                        >
                            <span className="relative z-10">
                                {editMode ? 'Сохранить изменения' : 'Опубликовать новость'}
                            </span>
                            <div className="absolute inset-0 rounded-xl bg-gradient-to-r from-white/10 to-transparent
                                opacity-0 group-hover:opacity-100 transition-opacity" />
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default NewsForm;
