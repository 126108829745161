import React from 'react';
import { useOnlineStatus } from '../context/OnlineStatusContext';

const UserAvatar = ({ user, size = 'md', showStatus = true }) => {
    const { getUserStatus } = useOnlineStatus();
    const status = getUserStatus(user.id);

    // Определяем размеры на основе пропса size
    const sizeClasses = {
        xs: 'w-8 h-8',
        sm: 'w-10 h-10',
        md: 'w-12 h-12',
        lg: 'w-16 h-16',
        xl: 'w-24 h-24'
    };

    const statusSizeClasses = {
        xs: 'w-2 h-2 -bottom-0.5 -right-0.5',
        sm: 'w-2.5 h-2.5 -bottom-0.5 -right-0.5',
        md: 'w-3 h-3 -bottom-1 -right-1',
        lg: 'w-4 h-4 -bottom-1 -right-1',
        xl: 'w-5 h-5 -bottom-1.5 -right-1.5'
    };

    const avatarSize = sizeClasses[size] || sizeClasses.md;
    const statusSize = statusSizeClasses[size] || statusSizeClasses.md;

    const avatarUrl = user.avatar
        ? `${process.env.REACT_APP_BASE_URL}/storage/${user.avatar}`
        : `https://ui-avatars.com/api/?name=${encodeURIComponent(user.name)}&background=random`;

    return (
        <div className="relative">
            <img
                src={avatarUrl}
                alt={user.name}
                className={`${avatarSize} rounded-xl object-cover shadow-sm`}
            />
            {showStatus && (
                <div
                    className={`absolute ${statusSize} rounded-full border-2 border-white dark:border-neutral-800 
            ${status.is_online ? 'bg-green-500' : 'bg-gray-300'}`}
                />
            )}
        </div>
    );
};

export default UserAvatar;