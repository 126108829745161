export const formatDuration = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
};

export function getTrackWordForm(count) {
    if (count % 10 === 1 && count % 100 !== 11) {
        return "трек";
    } else if ([2, 3, 4].includes(count % 10) && ![12, 13, 14].includes(count % 100)) {
        return "трека";
    } else {
        return "треков";
    }
}

export const formatArtists = (track) => {
    // Вариант 1: Массив artists
    if (track.artists && Array.isArray(track.artists) && track.artists.length > 0) {
        return track.artists.map(artist => artist.name).join(', ');
    }

    // Вариант 2: Объект artists с вложенным массивом data
    if (track.artists && typeof track.artists === 'object' && track.artists.data && Array.isArray(track.artists.data)) {
        return track.artists.data.map(artist => artist.name).join(', ');
    }

    // Вариант 3: Массив artist_names (некоторые API могут возвращать уже готовый массив имен)
    if (track.artist_names && Array.isArray(track.artist_names) && track.artist_names.length > 0) {
        return track.artist_names.join(', ');
    }

    // Вариант 4: Строка all_artists (некоторые API могут возвращать уже готовую строку)
    if (track.all_artists && typeof track.all_artists === 'string') {
        return track.all_artists;
    }

    // Запасной вариант: Используем основного исполнителя
    return track.artist?.name || 'Unknown Artist';
};