import Pusher from 'pusher-js';
import axios from 'axios';

const setupPusher = () => {
    const token = localStorage.getItem('token');

    if (!token) {
        console.error('No authentication token found');
        return;
    }

    const getCsrfToken = () => {
        return document.cookie
            .split('; ')
            .find(row => row.startsWith('XSRF-TOKEN='))
            ?.split('=')[1];
    };
    // Сначала получаем CSRF токен, если используем Laravel Sanctum
    return axios.get(`${process.env.REACT_APP_BASE_URL}/sanctum/csrf-cookie`, {
        withCredentials: true
    })
        .then(() => {
            console.log('CSRF cookies:', document.cookie);
            // Настройка Pusher
            const pusher = new Pusher(process.env.REACT_APP_PUSHER_APP_KEY, {
                cluster: process.env.REACT_APP_PUSHER_CLUSTER || 'eu',
                forceTLS: true,
                // Важно: кастомный авторизатор для отладки процесса авторизации
                authorizer: (channel) => ({
                    authorize: (socketId, callback) => {
                        axios.post(`${process.env.REACT_APP_API_URL}/broadcasting/auth`, {
                            socket_id: socketId,
                            channel_name: channel.name
                        }, {
                            headers: {
                                'Authorization': `Bearer ${token}`,
                                'X-CSRF-TOKEN': getCsrfToken()
                            },
                            withCredentials: true
                        })
                            .then(response => {
                                callback(false, response.data);
                            })
                            .catch(error => {
                                console.error('Auth error details:', error.response?.data);
                                callback(true);
                            });
                    }
                })
            });

            // Логирование состояния подключения
            pusher.connection.bind('connected', () => {
                console.log('Connected to Pusher successfully!');
            });

            pusher.connection.bind('error', (err) => {
                console.error('Pusher connection error:', err);
            });

            return pusher;
        })
        .catch(error => {
            console.error('Failed to get CSRF token:', error);
            throw error;
        });
};

export default setupPusher;