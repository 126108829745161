// Исправленный контекст OnlineStatusContext.jsx
import React, {createContext, useCallback, useContext, useEffect, useState} from 'react';
import axios from 'axios';
import {AuthContext} from './AuthContext';

export const OnlineStatusContext = createContext();

export const OnlineStatusProvider = ({ children }) => {
    const [onlineUsers, setOnlineUsers] = useState({});
    const [loading, setLoading] = useState(true);
    const { isLoggedIn } = useContext(AuthContext);
    const [pendingUpdates, setPendingUpdates] = useState({});

    // Получаем статусы всех пользователей при загрузке
    useEffect(() => {
        if (!isLoggedIn) {
            setLoading(false);
            return;
        }

        const fetchOnlineStatuses = async () => {
            const token = localStorage.getItem('token');
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/users/status`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });

                const statusMap = {};
                response.data.forEach(user => {
                    // Ensure boolean conversion for is_online
                    statusMap[user.id] = {
                        is_online: Boolean(user.is_online),
                        last_activity: user.last_activity
                    };
                });

                setOnlineUsers(statusMap);
                setLoading(false);
            } catch (error) {
                console.error('Ошибка при загрузке статусов пользователей:', error);
                setLoading(false);

                // Повторная попытка через 5 секунд при ошибке
                setTimeout(() => fetchOnlineStatuses(), 5000);
            }
        };

        fetchOnlineStatuses();
    }, [isLoggedIn]);

    // Обработка отложенных обновлений
    useEffect(() => {
        if (Object.keys(pendingUpdates).length === 0) return;

        const timerId = setTimeout(() => {
            setOnlineUsers(prev => ({
                ...prev,
                ...pendingUpdates
            }));
            setPendingUpdates({});
        }, 300);

        return () => clearTimeout(timerId);
    }, [pendingUpdates]);

    // Подписываемся на события изменения статуса - ИСПРАВЛЕНО: удалена глобальная подписка
    useEffect(() => {
        if (!isLoggedIn || !window.Echo) return;

        if (process.env.NODE_ENV === 'development') {
            console.log('Setting up Echo subscription to users.status channel');
        }

        // Подписываемся на канал статусов пользователей
        const channel = window.Echo.channel('users.status');

        channel.listen('.UserStatusChanged', (data) => {
            if (process.env.NODE_ENV === 'development') {
                console.log('Received event:', data);
            }

            setPendingUpdates(prev => ({
                ...prev,
                [data.id]: {
                    is_online: Boolean(data.is_online),
                    last_activity: data.last_activity
                }
            }));
        });

        return () => {
            if (process.env.NODE_ENV === 'development') {
                console.log('Cleaning up Echo subscription');
            }
            channel.stopListening('.UserStatusChanged');
        };
    }, [isLoggedIn]);

    // Обновляем статус текущего пользователя при необходимости
    const updateMyStatus = useCallback(async (status = true) => {
        const token = localStorage.getItem('token');
        try {
            if (process.env.NODE_ENV === 'development') {
                console.log('Updating my status to:', status);
            }

            await axios.post(`${process.env.REACT_APP_API_URL}/users/status`,
                { status },
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }
            );
        } catch (error) {
            console.error('Ошибка при обновлении статуса:', error);
        }
    }, []);

    // Получаем статус указанного пользователя
    const getUserStatus = useCallback((userId) => {
        // Логирование только в режиме разработки
        // if (process.env.NODE_ENV === 'development') {
        //     console.log(`Getting status for user ${userId}:`, status);
        // }

        return onlineUsers[userId] || {is_online: false, last_activity: null};
    }, [onlineUsers]);

    // Форматируем время последней активности
    const formatLastActivity = useCallback((timestamp) => {
        if (!timestamp) return 'Неизвестно';

        const date = new Date(timestamp);
        const now = new Date();
        const diffMinutes = Math.floor((now - date) / (60 * 1000));

        if (diffMinutes < 1) return 'Только что';
        if (diffMinutes < 60) return `${diffMinutes} мин. назад`;

        const diffHours = Math.floor(diffMinutes / 60);
        if (diffHours < 24) return `${diffHours} ч. назад`;

        const options = { day: 'numeric', month: 'short', hour: '2-digit', minute: '2-digit' };
        return date.toLocaleDateString('ru-RU', options);
    }, []);

    // Устанавливаем обработчики для событий страницы
    // ИСПРАВЛЕНО: удален вызов функции в зависимостях
    useEffect(() => {
        if (!isLoggedIn) return;

        const handleVisibilityChange = () => {
            const isVisible = document.visibilityState === 'visible';
            if (process.env.NODE_ENV === 'development') {
                console.log('Visibility changed, updating status to:', isVisible);
            }
            updateMyStatus(isVisible);
        };

        const handleBeforeUnload = () => {
            if (process.env.NODE_ENV === 'development') {
                console.log('Page unloading, updating status to offline');
            }
            navigator.sendBeacon(
                `${process.env.REACT_APP_API_URL}/users/status`,
                JSON.stringify({
                    status: false,
                    _token: localStorage.getItem('token')
                })
            );
        };

        // Устанавливаем обработчики
        document.addEventListener('visibilitychange', handleVisibilityChange);
        window.addEventListener('beforeunload', handleBeforeUnload);

        // Обновляем статус при монтировании
        updateMyStatus(true);

        // Очистка при размонтировании
        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
            window.removeEventListener('beforeunload', handleBeforeUnload);
            updateMyStatus(false);
        };
    }, [isLoggedIn, updateMyStatus]); // ИСПРАВЛЕНО: передаем ссылку на функцию, а не вызов функции

    return (
        <OnlineStatusContext.Provider value={{
            onlineUsers,
            loading,
            getUserStatus,
            formatLastActivity,
            updateMyStatus
        }}>
            {children}
        </OnlineStatusContext.Provider>
    );
};

export const useOnlineStatus = () => useContext(OnlineStatusContext);
