import Echo from 'laravel-echo';
import Pusher from 'pusher-js';

const echoConfig = {
    broadcaster: 'pusher',
    key: process.env.REACT_APP_PUSHER_APP_KEY,
    cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,
    wsHost: process.env.REACT_APP_PUSHER_HOST || `ws-${process.env.REACT_APP_PUSHER_APP_CLUSTER}.pusher.com`,
    wsPort: process.env.REACT_APP_PUSHER_PORT || 80,
    wssPort: process.env.REACT_APP_PUSHER_PORT || 443,
    forceTLS: true,
    encrypted: true,
    disableStats: true,
    enabledTransports: ['ws', 'wss'],
    authEndpoint: `${process.env.REACT_APP_API_URL}/broadcasting/auth`,
    auth: {
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token') || ''}`,
            Accept: 'application/json',
        }
    }
};

window.Pusher = Pusher;
const echoInstance = new Echo(echoConfig);

console.log('Echo initialized:', !!window.Echo);

// Debugging
echoInstance.connector.pusher.connection.bind('connected', () => {
    console.log('Pusher connected!');
});

echoInstance.connector.pusher.connection.bind('disconnected', () => {
    console.log('Pusher disconnected!');
});

echoInstance.connector.pusher.connection.bind('failed', (err) => {
    console.error('Pusher connection failed:', err);
});

export default echoInstance;