import React, { useRef } from 'react';
import axios from 'axios';
import { FiUpload } from 'react-icons/fi';

const ArtistAvatarUpload = ({ artistId, className }) => {
    const fileInput = useRef(null);
    const theme = localStorage.getItem('theme');

    const handleUpload = async (e) => {
        const file = e.target.files[0];
        if (!file) return;

        const formData = new FormData();
        formData.append('avatar', file);

        try {
            await axios.post(
                `${process.env.REACT_APP_API_URL}/api/artists/${artistId}/avatar`,
                formData,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                        'Content-Type': 'multipart/form-data'
                    }
                }
            );
            window.location.reload(); // Обновляем данные
        } catch (error) {
            console.error('Ошибка загрузки:', error);
            alert('Не удалось обновить аватар');
        }
    };

    return (
        <div className={`${className} group`}>
            <input
                type="file"
                ref={fileInput}
                onChange={handleUpload}
                className="hidden"
                accept="image/*"
            />
            <button
                onClick={() => fileInput.current.click()}
                className={`p-2 rounded-full transition-all ${
                    theme === 'dark'
                        ? 'bg-purple-600/90 hover:bg-purple-700 text-white'
                        : 'bg-purple-500/90 hover:bg-purple-600 text-white'
                } shadow-lg hover:shadow-xl`}
            >
                <FiUpload className="text-lg" />
            </button>
        </div>
    );
};

export default ArtistAvatarUpload;