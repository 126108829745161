import React, {useEffect, useRef, useState} from 'react';
import { FiPlus, FiX, FiMusic } from 'react-icons/fi';
import CreatePlaylistModal from "./CreatePlaylistModal";

const TrackContextMenu = ({ track, playlists, onAddToPlaylist, onCreatePlaylist, onClose }) => {
    const [showCreateModal, setShowCreateModal] = useState(false)
    const menuRef = useRef(null);

    // Закрытие меню при клике вне его области
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                onClose();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [onClose]);

    const handleCreatePlaylist = async (formData) => {
        await onCreatePlaylist(formData);
        setShowCreateModal(false);
        onClose(); // Закрываем контекстное меню после создания
    };

    return (
        <div
            ref={menuRef}
            className="absolute right-0 z-50 bg-white dark:bg-neutral-800 rounded-xl shadow-2xl p-3 min-w-[240px] border dark:border-neutral-700"
        >
            {/* Заголовок и кнопка закрытия */}
            <div className="flex justify-between items-center mb-3 p-2">
                <h3 className="font-semibold text-lg dark:text-white">Добавить в плейлист</h3>
            </div>

            {/* Список плейлистов */}
            <div className="space-y-1">
                {playlists.map((playlist) => (
                    <button
                        key={playlist.id}
                        onClick={() => {
                            onAddToPlaylist(playlist.id, track.id);
                            onClose(); // Закрываем меню после выбора
                        }}
                        className="w-full text-left p-2.5 hover:bg-gray-100 dark:hover:bg-neutral-700 rounded-lg flex items-center gap-3 transition-colors"
                    >
                        {/* Обложка плейлиста */}
                        <div
                            className="w-8 h-8 rounded-lg overflow-hidden flex items-center justify-center bg-gray-100 dark:bg-neutral-700">
                            {playlist.cover_image ? (
                                <img
                                    src={`${process.env.REACT_APP_BASE_URL}/storage/${playlist.cover_image}`}
                                    alt={playlist.name}
                                    className="w-full h-full object-cover"
                                />
                            ) : (
                                <FiMusic className="w-4 h-4 text-gray-500 dark:text-gray-400"/>
                            )}
                        </div>

                        {/* Название плейлиста */}
                        <span className="dark:text-white text-sm font-medium">
                            {playlist.name}
                        </span>
                    </button>
                ))}
            </div>

            {/* Кнопка создания нового плейлиста */}
            <button
                onClick={() => setShowCreateModal(true)}
                className="w-full mt-3 p-2.5 rounded-lg bg-purple-500/10 hover:bg-purple-500/20 text-purple-500 dark:text-purple-400 flex items-center justify-center gap-2 transition-colors"
            >
                <FiPlus className="w-4 h-4"/>
                <span className="text-sm font-medium">Создать плейлист</span>
            </button>

            <CreatePlaylistModal
                isOpen={showCreateModal}
                onClose={() => setShowCreateModal(false)}
                onCreate={handleCreatePlaylist}
            />
        </div>
    );
};

export default TrackContextMenu;