import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {Link, useLocation} from "react-router-dom";
import axios from 'axios';
import {Bell, Home, Menu, MessageCircle, Search} from "lucide-react";

// Хук для непрочитанных сообщений
const useUnreadMessages = () => {
    const [unreadMessagesCount, setUnreadMessagesCount] = useState(0);
    const token = useRef(localStorage.getItem('token'));

    const fetchUnreadMessages = useCallback(async () => {
        try {
            if (!token.current) return;

            const { data } = await axios.get(
                `${process.env.REACT_APP_API_URL}/messages/unread_count`,
                {
                    headers: { Authorization: `Bearer ${token.current}` },
                    params: { _: performance.now() }
                }
            );

            setUnreadMessagesCount(prev =>
                data?.unread_count !== prev ? data?.unread_count || 0 : prev
            );
        } catch (error) {
            console.error('Unread messages fetch error:', error);
            setUnreadMessagesCount(0);
        }
    }, []);

    useEffect(() => {
        const updateToken = () => token.current = localStorage.getItem('token');
        window.addEventListener('storage', updateToken);
        return () => window.removeEventListener('storage', updateToken);
    }, []);

    useEffect(() => {
        if (!token.current) return;
        fetchUnreadMessages();
        const interval = setInterval(fetchUnreadMessages, 60000);
        return () => clearInterval(interval);
    }, [fetchUnreadMessages]);

    return unreadMessagesCount;
};

// Хук для непрочитанных уведомлений
const useUnreadNotifications = () => {
    const [unreadNotificationsCount, setUnreadNotificationsCount] = useState(0);
    const token = useRef(localStorage.getItem('token'));

    const fetchUnreadNotifications = useCallback(async () => {
        try {
            if (!token.current) return;

            const { data } = await axios.get(
                `${process.env.REACT_APP_API_URL}/notifications/unread_count`,
                {
                    headers: { Authorization: `Bearer ${token.current}` },
                    params: { _: performance.now() }
                }
            );

            setUnreadNotificationsCount(prev =>
                data?.unread_count !== prev ? data?.unread_count || 0 : prev
            );
        } catch (error) {
            console.error('Unread notifications fetch error:', error);
            setUnreadNotificationsCount(0);
        }
    }, []);

    useEffect(() => {
        const updateToken = () => token.current = localStorage.getItem('token');
        window.addEventListener('storage', updateToken);
        return () => window.removeEventListener('storage', updateToken);
    }, []);

    useEffect(() => {
        if (!token.current) return;
        fetchUnreadNotifications();
        const interval = setInterval(fetchUnreadNotifications, 60000);
        return () => clearInterval(interval);
    }, [fetchUnreadNotifications]);

    return unreadNotificationsCount;
};

const BottomNav = () => {
    const location = useLocation();
    const unreadMessagesCount = useUnreadMessages();
    const unreadNotificationsCount = useUnreadNotifications();
    const [activePath, setActivePath] = useState(location.pathname);
    
    // Состояния для анимации
    const [isMessagesCounting, setIsMessagesCounting] = useState(false);
    const [isNotifCounting, setIsNotifCounting] = useState(false);
    const prevMessagesCountRef = useRef(unreadMessagesCount);
    const prevNotifCountRef = useRef(unreadNotificationsCount);

    // Эффекты анимации
    useEffect(() => {
        if (prevMessagesCountRef.current !== unreadMessagesCount) {
            setIsMessagesCounting(true);
            const timer = setTimeout(() => setIsMessagesCounting(false), 300);
            return () => clearTimeout(timer);
        }
        prevMessagesCountRef.current = unreadMessagesCount;
    }, [unreadMessagesCount]);

    useEffect(() => {
        if (prevNotifCountRef.current !== unreadNotificationsCount) {
            setIsNotifCounting(true);
            const timer = setTimeout(() => setIsNotifCounting(false), 300);
            return () => clearTimeout(timer);
        }
        prevNotifCountRef.current = unreadNotificationsCount;
    }, [unreadNotificationsCount]);

    // Стили анимации
    const messagesCounterStyle = {
        transform: isMessagesCounting ? 'scale(1.2)' : 'scale(1)',
        transition: 'transform 0.3s ease-in-out'
    };

    const notifCounterStyle = {
        transform: isNotifCounting ? 'scale(1.2)' : 'scale(1)',
        transition: 'transform 0.3s ease-in-out'
    };

    const items = useMemo(() => [
        {icon: Home, to: "/", name: "home"},
        {icon: Search, to: "/search", name: "search"},
        {
            icon: MessageCircle,
            to: "/messages",
            name: "messages",
            count: unreadMessagesCount
        },
        {
            icon: Bell,
            to: "/notifications",
            name: "notifications",
            count: unreadNotificationsCount
        },
        {icon: Menu, to: "/menu", name: "menu"},
    ], [unreadMessagesCount, unreadNotificationsCount]);

    useEffect(() => {
        setActivePath(location.pathname);
    }, [location]);

    const getNavItemClasses = useCallback((path) => 
        `relative flex items-center justify-center w-14 h-10 rounded-full transition-all 
        duration-300 cursor-pointer hover:bg-gray-100 dark:hover:bg-neutral-700
        ${activePath === path
            ? 'bg-gray-200 dark:bg-neutral-700 text-violet-500'
            : 'bg-transparent text-gray-500 dark:text-gray-400'}`
    , [activePath]);

    return (
        <nav className="bg-white p-2 rounded-t-2xl shadow-lg fixed bottom-0 left-0 right-0
                        flex justify-center dark:bg-neutral-800 z-50">
            <div className="flex space-x-4">
                {items.map((item) => {
                    const IconComponent = item.icon;
                    const isMessages = item.name === 'messages';
                    const isNotifications = item.name === 'notifications';

                    return (
                        <div
                            key={item.name}
                            className={getNavItemClasses(item.to)}
                        >
                            <Link
                                to={item.to}
                                className="flex items-center justify-center relative"
                                aria-label={item.name}
                            >
                                <IconComponent
                                    className="w-6 h-6 stroke-current"
                                    strokeWidth={1.5}
                                />
                                {(item.count > 0) && (
                                    <span
                                        className={`absolute -top-2 -right-2 transform translate-x-1/2 -translate-y-1/2
                                            text-white text-xs font-bold w-5 h-5 flex items-center
                                            justify-center rounded-full ${
                                                isMessages ? 'bg-red-500' : 
                                                isNotifications ? 'bg-violet-500' : ''
                                            }`}
                                        style={
                                            isMessages ? messagesCounterStyle : 
                                            isNotifications ? notifCounterStyle : {}
                                        }
                                    >
                                        {item.count}
                                    </span>
                                )}
                            </Link>
                        </div>
                    );
                })}
            </div>
        </nav>
    );
};

export default BottomNav;