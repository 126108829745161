import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { AuthContext } from '../context/AuthContext';
import toast from 'react-hot-toast';
import { motion, AnimatePresence } from 'framer-motion';
import { Search, X, MessageSquarePlus, Check, Plus } from 'lucide-react';

const notify = () => toast.error('У вас уже есть чат с этим пользователем!');
const notifySuccess = () => toast.success('Чат создан успешно!');

function CreateChat() {
    const [friends, setFriends] = useState([]);
    const [filteredFriends, setFilteredFriends] = useState([]);
    const [selectedFriendId, setSelectedFriendId] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const { isLoggedIn } = useContext(AuthContext);

    useEffect(() => {
        const fetchFriends = async () => {
            const token = localStorage.getItem('token');
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/friends`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setFriends(response.data);
                setFilteredFriends(response.data);
            } catch (error) {
                console.error('There was an error fetching the friends!', error);
            }
        };

        if (isLoggedIn) {
            fetchFriends();
        }
    }, [isLoggedIn]);

    const handleCreateChat = async () => {
        const token = localStorage.getItem('token');
        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/chats`, {
                user2_id: selectedFriendId,
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            setSelectedFriendId('');
            setIsModalOpen(false);
            notifySuccess();
        } catch (error) {
            if (error.response && error.response.data.error) {
                console.error('Error:', error.response.data.error);
                alert(error.response.data.error);
            } else {
                console.error('There was an error creating the chat!', error);
                notify();
            }
        }
    };

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
        setFilteredFriends(friends.filter(friend => friend.friend.name.toLowerCase().includes(e.target.value.toLowerCase())));
    };

    if (!isLoggedIn) {
        return <p>Пожалуйста, войдите в свою учетную запись.</p>;
    }

    return (
        <div className="flex justify-end">
            {/* Кнопка создания чата с улучшенным дизайном */}
            <motion.button
                onClick={() => setIsModalOpen(true)}
                aria-label="Создать новый чат"
                className="relative flex items-center gap-2 px-3 py-2 bg-purple-600 hover:bg-purple-700 active:bg-purple-800 text-white rounded-full shadow-lg shadow-purple-200 dark:shadow-purple-900/30 transition-all duration-300"
                whileHover={{scale: 1.03}}
                whileTap={{scale: 0.97}}
            >
                <Plus className="w-5 h-5"/>
                <span className="font-medium text-sm pr-1">Новый чат</span>
                <div
                    className="absolute -top-1 -right-1 flex h-5 w-5 items-center justify-center rounded-full bg-emerald-500 text-[10px] font-bold text-white ring-2 ring-white dark:ring-gray-900">
                    {friends.length}
                </div>
            </motion.button>

            <AnimatePresence>
                {isModalOpen && (
                    <motion.div
                        className="fixed inset-0 bg-black/60 flex justify-center items-center z-50 backdrop-blur-md p-4"
                        initial={{opacity: 0}}
                        animate={{opacity: 1}}
                        exit={{opacity: 0}}
                        onClick={(e) => {
                            if (e.target === e.currentTarget) setIsModalOpen(false);
                        }}
                    >
                        <motion.div
                            className="bg-white/90 dark:bg-neutral-800/90 backdrop-filter backdrop-blur-lg p-6 rounded-2xl shadow-2xl max-w-lg w-full relative border border-white/20 dark:border-neutral-700/30"
                            initial={{scale: 0.9, y: 20, opacity: 0}}
                            animate={{scale: 1, y: 0, opacity: 1}}
                            exit={{scale: 0.9, y: 20, opacity: 0}}
                            transition={{type: 'spring', stiffness: 300, damping: 25}}
                        >
                            {/* Кнопка закрытия */}
                            <motion.button
                                onClick={() => setIsModalOpen(false)}
                                className="absolute top-4 right-4 p-2 rounded-full bg-gray-100/80 dark:bg-neutral-700/50 text-gray-500 hover:text-gray-700 dark:text-gray-300 dark:hover:text-white transition-colors"
                                whileHover={{scale: 1.1, backgroundColor: "rgba(239, 68, 68, 0.1)"}}
                                whileTap={{scale: 0.95}}
                            >
                                <X className="w-5 h-5"/>
                            </motion.button>

                            {/* Заголовок */}
                            <motion.h2
                                className="text-2xl font-bold text-gray-800 mb-6 dark:text-white flex items-center gap-2"
                                initial={{opacity: 0, y: -10}}
                                animate={{opacity: 1, y: 0}}
                                transition={{delay: 0.1}}
                            >
                                <MessageSquarePlus className="w-6 h-6 text-violet-500 dark:text-violet-400"/>
                                Создание чата
                            </motion.h2>

                            {/* Поле поиска */}
                            <motion.div
                                className="relative mb-6"
                                initial={{opacity: 0, y: -10}}
                                animate={{opacity: 1, y: 0}}
                                transition={{delay: 0.2}}
                            >
                                <div className="absolute inset-y-0 left-3 flex items-center pointer-events-none">
                                    <Search className="w-5 h-5 text-gray-400"/>
                                </div>
                                <input
                                    type="text"
                                    value={searchTerm}
                                    onChange={handleSearchChange}
                                    placeholder="Поиск друзей..."
                                    className="w-full pl-10 pr-4 py-3 bg-white/50 dark:bg-neutral-700/50 border border-gray-200 dark:border-neutral-600/30 rounded-xl focus:outline-none focus:ring-2 focus:ring-violet-500/50 dark:text-white placeholder-gray-400 dark:placeholder-gray-500 transition-all"
                                />
                                {searchTerm && (
                                    <button
                                        onClick={() => {
                                            setSearchTerm('');
                                            setFilteredFriends(friends);
                                        }}
                                        className="absolute inset-y-0 right-3 flex items-center"
                                    >
                                        <X className="w-4 h-4 text-gray-400 hover:text-gray-600 dark:hover:text-gray-200"/>
                                    </button>
                                )}
                            </motion.div>

                            {/* Список друзей */}
                            <motion.div
                                className="grid grid-cols-1 sm:grid-cols-2 gap-3 max-h-60 overflow-y-auto pr-1 custom-scrollbar"
                                initial={{opacity: 0}}
                                animate={{opacity: 1}}
                                transition={{delay: 0.3}}
                            >
                                {filteredFriends.length === 0 ? (
                                    <motion.div
                                        className="text-center text-gray-500 dark:text-gray-400 col-span-2 py-8"
                                        initial={{opacity: 0}}
                                        animate={{opacity: 1}}
                                    >
                                        <div className="flex flex-col items-center gap-2">
                                            <Search className="w-8 h-8 text-gray-300 dark:text-gray-600"/>
                                            <p>Нет результатов</p>
                                        </div>
                                    </motion.div>
                                ) : (
                                    filteredFriends.map((friend, index) => (
                                        <motion.div
                                            key={friend.id}
                                            className={`flex items-center p-3 rounded-xl cursor-pointer transition-all ${
                                                selectedFriendId === friend.friend.id
                                                    ? 'bg-violet-100/80 dark:bg-violet-900/30 border-violet-300 dark:border-violet-700'
                                                    : 'bg-white/50 dark:bg-neutral-700/30 border-transparent hover:bg-gray-50 dark:hover:bg-neutral-700/50'
                                            } border`}
                                            onClick={() => setSelectedFriendId(friend.friend.id)}
                                            initial={{opacity: 0, y: 10}}
                                            animate={{opacity: 1, y: 0}}
                                            transition={{delay: 0.1 + index * 0.05}}
                                            whileHover={{scale: 1.02}}
                                            whileTap={{scale: 0.98}}
                                        >
                                            <div className="relative">
                                                <img
                                                    src={friend.friend.avatar ? `${process.env.REACT_APP_BASE_URL}/storage/${friend.friend.avatar}` : `https://ui-avatars.com/api/?name=${friend.friend.name}&background=random`}
                                                    alt="Avatar"
                                                    className="w-12 h-12 rounded-full object-cover ring-2 ring-white dark:ring-neutral-800"
                                                />
                                                {selectedFriendId === friend.friend.id && (
                                                    <motion.div
                                                        className="absolute -bottom-1 -right-1 bg-green-500 text-white p-1 rounded-full"
                                                        initial={{scale: 0}}
                                                        animate={{scale: 1}}
                                                        transition={{type: "spring", stiffness: 500, damping: 15}}
                                                    >
                                                        <Check className="w-3 h-3"/>
                                                    </motion.div>
                                                )}
                                            </div>
                                            <div className="ml-3 flex-1 min-w-0">
                                                <p className="font-medium text-gray-800 dark:text-white truncate">{friend.friend.name}</p>
                                                <p className="text-xs text-gray-500 dark:text-gray-400 truncate">Начать
                                                    новый чат</p>
                                            </div>
                                        </motion.div>
                                    ))
                                )}
                            </motion.div>

                            {/* Кнопки действий */}
                            <motion.div
                                className="flex justify-end mt-6 gap-3"
                                initial={{opacity: 0, y: 10}}
                                animate={{opacity: 1, y: 0}}
                                transition={{delay: 0.4}}
                            >
                                <motion.button
                                    onClick={() => setIsModalOpen(false)}
                                    className="px-5 py-2.5 rounded-xl bg-gray-100 dark:bg-neutral-700 text-gray-700 dark:text-gray-300 font-medium transition-colors hover:bg-gray-200 dark:hover:bg-neutral-600"
                                    whileHover={{scale: 1.02}}
                                    whileTap={{scale: 0.98}}
                                >
                                    Отмена
                                </motion.button>
                                <motion.button
                                    onClick={handleCreateChat}
                                    className={`px-5 py-2.5 rounded-xl bg-gradient-to-r from-violet-500 to-violet-600 text-white font-medium shadow-lg shadow-violet-200/50 dark:shadow-none transition-all ${
                                        !selectedFriendId ? 'opacity-50 cursor-not-allowed' : 'hover:shadow-violet-300/50 hover:from-violet-600 hover:to-violet-700'
                                    }`}
                                    disabled={!selectedFriendId}
                                    whileHover={selectedFriendId ? {scale: 1.02} : {}}
                                    whileTap={selectedFriendId ? {scale: 0.98} : {}}
                                >
                                    Создать чат
                                </motion.button>
                            </motion.div>
                        </motion.div>
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
}

export default CreateChat;
