import React, { useEffect, useState, useRef, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { motion, AnimatePresence } from 'framer-motion';
import BackLink from "./BackLink";
import { Clock, User, Edit, Trash } from "lucide-react";
import { ClipLoader } from "react-spinners";
import { AuthContext } from "../context/AuthContext";
import { MoreVert } from "@mui/icons-material";
import SocialShare from './SocialShare';

const NewsDetail = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [news, setNews] = useState(null);
    const [loading, setLoading] = useState(true);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const { user } = useContext(AuthContext);
    const menuRef = useRef(null);

    const menuVariants = {
        hidden: { opacity: 0, y: -10 },
        visible: { opacity: 1, y: 0 },
        exit: { opacity: 0, y: -10 }
    };

    const modalVariants = {
        hidden: { opacity: 0, scale: 0.95 },
        visible: { opacity: 1, scale: 1 },
        exit: { opacity: 0, scale: 0.95 }
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setIsMenuOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/news/${id}`)
            .then(response => {
                setNews(response.data);
                setLoading(false);
            })
            .catch(() => setLoading(false));
    }, [id]);

    const handleDelete = async () => {
        try {
            await axios.delete(`${process.env.REACT_APP_API_URL}/news/${id}`, {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
            });
            navigate('/news');
        } catch (error) {
            alert('Ошибка при удалении новости');
            setShowDeleteConfirm(false);
        }
    };

    const handleEdit = () => navigate(`/news/edit/${id}`);

    if (loading) {
        return (
            <div className="flex justify-center items-center h-[calc(100vh)]">
                <ClipLoader color="#6B46C1" size={75} />
            </div>
        );
    }

    if (!news) return <div className="text-center py-16 text-red-500">Новость не найдена</div>;

    return (
        <div className="container mx-auto px-4 pt-16 relative max-w-3xl">
            <BackLink />
            <article className="bg-white dark:bg-neutral-800 mt-4 rounded-xl shadow-lg overflow-hidden">
                {user?.is_admin ? (
                    <div className="absolute right-4 z-50 p-2" ref={menuRef}>
                        <motion.button
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.95 }}
                            onClick={() => setIsMenuOpen(!isMenuOpen)}
                            className="flex items-center justify-center w-8 h-8 rounded-full bg-gray-200 dark:bg-neutral-700 hover:bg-gray-300 dark:hover:bg-neutral-600 transition-colors"
                            aria-label="Меню действий"
                        >
                            <MoreVert className="w-6 h-6 text-gray-500 dark:text-gray-400" />
                        </motion.button>

                        <AnimatePresence>
                            {isMenuOpen && (
                                <motion.div
                                    key="admin-menu"
                                    initial="hidden"
                                    animate="visible"
                                    exit="exit"
                                    variants={menuVariants}
                                    transition={{ duration: 0.15 }}
                                    className="absolute right-0 mt-2 w-48 bg-white dark:bg-neutral-700 shadow-xl rounded-lg py-2 z-50"
                                >
                                    <motion.button
                                        onClick={handleEdit}
                                        className="w-full px-4 py-2 flex items-center gap-2 text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-neutral-600"
                                        whileTap={{ scale: 0.98 }}
                                    >
                                        <Edit className="w-4 h-4" />
                                        Редактировать
                                    </motion.button>
                                    <motion.button
                                        onClick={() => setShowDeleteConfirm(true)}
                                        className="w-full px-4 py-2 flex items-center gap-2 text-red-600 dark:text-red-400 hover:bg-gray-100 dark:hover:bg-neutral-600"
                                        whileTap={{ scale: 0.98 }}
                                    >
                                        <Trash className="w-4 h-4" />
                                        Удалить
                                    </motion.button>
                                </motion.div>
                            )}
                        </AnimatePresence>
                    </div>
                ) : null}

                {news.image && (
                    <motion.img
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        src={`${process.env.REACT_APP_BASE_URL}/storage/${news.image}`}
                        alt={news.title}
                        className="w-full h-64 object-cover mb-6"
                        loading="lazy"
                    />
                )}

                <div className="px-6 pb-8">
                    <h1 className="text-4xl font-bold text-gray-900 dark:text-white mb-6 leading-tight">
                        {news.title}
                    </h1>

                    <div className="flex flex-wrap items-center text-sm gap-4 mb-8 text-gray-500 dark:text-gray-400">
                        <div className="flex items-center bg-gray-100 dark:bg-neutral-700 px-3 py-1.5 rounded-full">
                            <User className="w-4 h-4 mr-2" />
                            <span className="font-medium">{news.author}</span>
                        </div>
                        <div className="flex items-center bg-gray-100 dark:bg-neutral-700 px-3 py-1.5 rounded-full">
                            <Clock className="w-4 h-4 mr-2" />
                            <time>{new Date(news.created_at).toLocaleDateString()}</time>
                        </div>
                    </div>

                    <div className="prose dark:prose-invert max-w-none">
                        {news.content.split('\n').map((paragraph, i) => (
                            <p key={i} className="text-lg text-gray-700 dark:text-gray-300 mb-6">
                                {paragraph}
                            </p>
                        ))}
                    </div>

                    <SocialShare
                        url={window.location.href}
                        title={news.title}
                    />
                </div>
            </article>

            <AnimatePresence>
                {showDeleteConfirm && (
                    <div className="fixed inset-0 bg-black/50 dark:bg-black/70 flex items-center justify-center z-50">
                        <motion.div
                            initial="hidden"
                            animate="visible"
                            exit="exit"
                            variants={modalVariants}
                            className="bg-white dark:bg-neutral-800 p-6 rounded-xl max-w-md w-full mx-4"
                        >
                            <h3 className="text-xl font-semibold mb-4 dark:text-white">
                                Удалить новость?
                            </h3>
                            <p className="text-gray-600 dark:text-gray-300 mb-6">
                                Вы уверены что хотите удалить эту новость? Это действие нельзя отменить.
                            </p>
                            <div className="flex justify-end gap-4">
                                <button
                                    onClick={() => setShowDeleteConfirm(false)}
                                    className="px-4 py-2 text-gray-600 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-neutral-700 rounded-lg"
                                >
                                    Отмена
                                </button>
                                <button
                                    onClick={handleDelete}
                                    className="px-4 py-2 bg-red-600 text-white hover:bg-red-700 rounded-lg"
                                >
                                    Удалить
                                </button>
                            </div>
                        </motion.div>
                    </div>
                )}
            </AnimatePresence>
        </div>
    );
};

export default NewsDetail;
