import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import BackLink from "../BackLink";
import { ArrowUpRightIcon, SunIcon, CurrencyDollarIcon, SparklesIcon } from "@heroicons/react/24/solid";
import { motion } from "framer-motion";
import Aside from "../Aside";

function ServiceCard({ title, description, icon, link, extraContent }) {
    return (
        <motion.div
            whileHover={{ y: -5 }}
            transition={{ type: "spring", stiffness: 300 }}
            className="h-full"
        >
            <Link
                to={link}
                className="group relative block h-full rounded-2xl bg-white/80 backdrop-blur-lg dark:bg-neutral-800/70 hover:bg-white/90 dark:hover:bg-neutral-800/90 transition-all duration-300 shadow-xl hover:shadow-2xl border border-white/20 dark:border-neutral-700/50"
            >
                <div className="p-6 pt-8">
                    <div className="absolute top-0 right-0 p-4 opacity-80 group-hover:opacity-100 transition-opacity">
                        <ArrowUpRightIcon className="w-5 h-5 text-purple-600 dark:text-purple-400" />
                    </div>

                    <div className="mb-6 flex items-center justify-center w-14 h-14 rounded-xl bg-gradient-to-br from-purple-500 to-indigo-600 shadow-lg">
                        {icon}
                    </div>

                    <h2 className="text-2xl font-bold bg-gradient-to-r from-purple-600 to-indigo-500 bg-clip-text text-transparent dark:from-purple-400 dark:to-indigo-300 mb-3">
                        {title}
                    </h2>

                    <p className="text-neutral-600 dark:text-neutral-300 text-lg mb-6 leading-relaxed">
                        {description}
                    </p>

                    <div className="space-y-2 text-sm font-medium text-purple-600 dark:text-purple-400">
                        {extraContent}
                    </div>
                </div>
            </Link>
        </motion.div>
    );
}

export default function Services() {
    const [weather, setWeather] = useState(null);
    const [isWeatherLoading, setIsWeatherLoading] = useState(true);
    const [exchangeRates, setExchangeRates] = useState(null);
    const [isRatesLoading, setIsRatesLoading] = useState(true);

    useEffect(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    fetchWeather(latitude, longitude);
                },
                () => setWeather({ city: "Недоступно", temperature: "-", description: "Ошибка геолокации" })
            );
        }
        fetchExchangeRates();
    }, []);

    const fetchWeather = async (lat, lon) => {
        try {
            const apiKey = "f271c589dc595252feddf5cae33f1718";
            const response = await fetch(
                `https://api.openweathermap.org/data/2.5/weather?lat=${lat}&lon=${lon}&units=metric&lang=ru&appid=${apiKey}`
            );
            const data = await response.json();
            setWeather({
                city: data.name,
                temperature: Math.round(data.main.temp),
                description: data.weather[0].description,
            });
        } catch {
            setWeather({ city: "Ошибка", temperature: "-", description: "Невозможно получить данные" });
        } finally {
            setIsWeatherLoading(false);
        }
    };

    const fetchExchangeRates = async () => {
        try {
            const response = await fetch("https://api.exchangerate-api.com/v4/latest/RUB");
            const data = await response.json();
            setExchangeRates({
                USD: (1 / data.rates.USD).toFixed(2),
                EUR: (1 / data.rates.EUR).toFixed(2),
            });
        } catch {
            setExchangeRates({ USD: "-", EUR: "-" });
        } finally {
            setIsRatesLoading(false);
        }
    };

    const services = [
        {
            title: "Погода",
            description: "Актуальные метеорологические данные для вашего местоположения",
            icon: <SunIcon className="w-7 h-7 text-white" />,
            link: "/services/weather",
            extraContent: isWeatherLoading ? (
                <div className="animate-pulse space-y-2">
                    <div className="h-4 bg-neutral-200/50 dark:bg-neutral-700/30 rounded w-full" />
                    <div className="h-4 bg-neutral-200/50 dark:bg-neutral-700/30 rounded w-3/4" />
                </div>
            ) : (
                weather && (
                    <div className="flex flex-col gap-1">
                        <span className="font-semibold">{weather.city}</span>
                        <span className="text-neutral-500 dark:text-neutral-400">
                            {weather.temperature}°C • {weather.description}
                        </span>
                    </div>
                )
            ),
        },
        {
            title: "Курсы валют",
            description: "Реальные времени курсы основных мировых валют",
            icon: <CurrencyDollarIcon className="w-7 h-7 text-white" />,
            link: "/services/currency",
            extraContent: isRatesLoading ? (
                <div className="animate-pulse space-y-2">
                    <div className="h-4 bg-neutral-200/50 dark:bg-neutral-700/30 rounded w-full" />
                    <div className="h-4 bg-neutral-200/50 dark:bg-neutral-700/30 rounded w-3/4" />
                </div>
            ) : (
                exchangeRates && (
                    <div className="flex flex-col gap-1">
                        <div className="flex justify-between">
                            <span>USD</span>
                            <span className="font-mono">{exchangeRates.USD} ₽</span>
                        </div>
                        <div className="flex justify-between">
                            <span>EUR</span>
                            <span className="font-mono">{exchangeRates.EUR} ₽</span>
                        </div>
                    </div>
                )
            ),
        },
        {
            title: "Тренировки",
            description: "Персонализированные программы упражнений и тренировок",
            icon: <SparklesIcon className="w-7 h-7 text-white" />,
            link: "/services/training",
            extraContent: (
                <div className="flex items-center gap-2 text-sm">
                    <span className="px-2 py-1 bg-purple-100 dark:bg-purple-900/30 text-purple-600 dark:text-purple-300 rounded-full">
                        Новая программа
                    </span>
                </div>
            ),
        }
    ];

    return (
        <div className="flex container mx-auto p-2">
            <Aside/>
            <div className="min-h-screen bg-neutral-50/50 dark:bg-neutral-900">
                <div className="container mx-auto  py-16">
                    <BackLink/>

                    <motion.h1
                        initial={{opacity: 0, y: 20}}
                        animate={{opacity: 1, y: 0}}
                        className="text-3xl font-extrabold text-neutral-800 dark:text-white mb-4"
                    >
                        Сервисы
                    </motion.h1>

                    <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
                        {services.map((service, index) => (
                            <ServiceCard
                                key={index}
                                title={service.title}
                                description={service.description}
                                icon={service.icon}
                                link={service.link}
                                extraContent={service.extraContent}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}