import React from 'react';
import { motion, AnimatePresence } from "framer-motion";

function ConfirmationModalOpenLink({ isOpen, message, onConfirm, onCancel }) {
    const modalVariants = {
        hidden: { opacity: 0, scale: 0.9 },
        visible: { opacity: 1, scale: 1 },
        exit: { opacity: 0, scale: 0.9 }
    };


    return (
        <AnimatePresence>
            {isOpen && (
                <div
                    className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 backdrop-blur-sm">
                    <motion.div
                        className="bg-white dark:bg-neutral-800 rounded-lg p-6 w-[90%] max-w-md shadow-lg"
                        variants={modalVariants}
                        initial="hidden"
                        animate="visible"
                        exit="exit"
                        transition={{type: 'spring', stiffness: 300, damping: 20}}
                    >
                        <h2 className="text-2xl font-semibold text-center text-gray-800 dark:text-gray-200 mb-4">
                            Подтвердите действие
                        </h2>
                        <p className="text-center text-gray-600 dark:text-gray-300 mb-6">
                            {message}
                        </p>
                        <div className="flex justify-center space-x-4">
                            <button
                                onClick={onCancel}
                                className="w-full px-4 py-2 text-gray-500 dark:text-gray-300 border border-transparent hover:bg-gray-100 dark:hover:bg-neutral-600/10 rounded-lg transition-colors"
                            >
                                Отмена
                            </button>
                            <button
                                onClick={onConfirm}
                                className="ml-3 w-full px-4 py-2 text-purple-500 border border-transparent hover:bg-purple-100 dark:hover:bg-purple-600/10 rounded-lg transition-all duration-300 ease-in-out"
                            >
                                Перейти
                            </button>
                        </div>
                    </motion.div>
                </div>
            )}
        </AnimatePresence>
    );
}

export default ConfirmationModalOpenLink;