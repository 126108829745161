import React, { useState, useCallback, useEffect, useRef } from "react";
import { useDropzone } from "react-dropzone";
import { toast } from "react-hot-toast";
import { ClipLoader } from "react-spinners";
import { motion, AnimatePresence } from "framer-motion";
import { Link } from "react-router-dom";
import BackLink from "./BackLink";
import useWindowSize from "../hooks/useWindowSize";
import BottomNav from "./BottomNav";
import { FiMusic, FiX, FiUpload, FiSearch, FiPlusCircle, FiImage } from "react-icons/fi";

const UploadTrack = () => {
    const [file, setFile] = useState(null);
    const [title, setTitle] = useState("");
    const [artistId, setArtistId] = useState(null);
    const [artistQuery, setArtistQuery] = useState("");
    const [genre, setGenre] = useState("");
    const [albumArt, setAlbumArt] = useState(null);
    const [albumArtPreview, setAlbumArtPreview] = useState(null);
    const [isUploading, setIsUploading] = useState(false);
    const [artists, setArtists] = useState([]);
    const [artistsLoading, setArtistsLoading] = useState(false);
    const [showArtistDropdown, setShowArtistDropdown] = useState(false);
    const { width } = useWindowSize();
    const artistDropdownRef = useRef(null);

    // Загрузка списка артистов
    useEffect(() => {
        const fetchArtists = async () => {
            setArtistsLoading(true);
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/artists`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`
                    }
                });
                const data = await response.json();
                setArtists(data.data || []);
            } catch (error) {
                toast.error('Не удалось загрузить список исполнителей');
            } finally {
                setArtistsLoading(false);
            }
        };
        fetchArtists();
    }, []);

    // Обработчик выбора артиста
    const handleSelectArtist = (artist) => {
        setArtistId(artist.id);
        setArtistQuery(artist.name);
        setShowArtistDropdown(false);
    };

    // Обработчик загрузки обложки
    const handleAlbumArtChange = (e) => {
        const file = e.target.files[0];
        if (!file) return;
        setAlbumArt(file);
        setAlbumArtPreview(URL.createObjectURL(file));
    };

    // Drag & Drop для аудио
    const onDrop = useCallback((acceptedFiles) => {
        setFile(acceptedFiles[0]);
        toast.success("Аудиофайл выбран!");
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: "audio/*",
    });

    // Валидация и отправка формы
    const handleUpload = async () => {
        if (!file || !title || !artistId || !albumArt) {
            toast.error("Заполните все обязательные поля");
            return;
        }

        const formData = new FormData();
        formData.append("file", file);
        formData.append("title", title);
        formData.append("artist_id", artistId);
        formData.append("album_art", albumArt);
        formData.append("genre", genre);

        setIsUploading(true);

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/tracks`, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
                body: formData,
            });

            if (!response.ok) throw new Error("Ошибка загрузки трека");

            toast.success("Трек успешно опубликован!");
            setTimeout(() => window.location.href = "/music", 2000);
        } catch (error) {
            toast.error(error.message || "Ошибка при загрузке");
        } finally {
            setIsUploading(false);
        }
    };

    useEffect(() => {
        const handleClickOutside = (e) => {
            if (artistDropdownRef.current && !artistDropdownRef.current.contains(e.target)) {
                setShowArtistDropdown(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    const filteredArtists = artists.filter(artist =>
        artist.name.toLowerCase().includes(artistQuery.trim().toLowerCase())
    );

    return (
        <div className="container mx-auto p-4 pt-16 pb-28">
            {width < 768 && <BottomNav />}
            <BackLink />

            <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                className=""
            >
                <h2 className="text-3xl font-extrabold text-neutral-800 dark:text-white mb-6 ">
                    Загрузить новый трек
                    <span className="block mt-2 text-lg font-normal text-purple-500">
                        Поддерживаемые форматы: MP3, WAV, FLAC
                    </span>
                </h2>

                <div className="bg-white dark:bg-neutral-900 rounded-2xl shadow-xl p-6 space-y-8 max-w-2xl mx-auto">
                    {/* Название трека */}
                    <div className="space-y-3">
                        <label className="text-sm font-medium text-neutral-600 dark:text-neutral-300 flex items-center gap-2">
                            Название трека
                            <span className="text-red-500">*</span>
                        </label>
                        <input
                            type="text"
                            placeholder="Введите название трека"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            className="w-full p-4 bg-neutral-50 dark:bg-neutral-800 rounded-xl border-2 border-transparent focus:border-purple-500 focus:ring-0 transition-all"
                        />
                    </div>

                    {/* Выбор исполнителя */}
                    <div className="space-y-3 relative">
                        <label className="text-sm font-medium text-neutral-600 dark:text-neutral-300 flex items-center gap-2">
                            Исполнитель
                            <span className="text-red-500">*</span>
                        </label>
                        <div className="relative">
                            <div className="flex items-center bg-neutral-50 dark:bg-neutral-800 rounded-xl pr-4 border-2 border-transparent focus-within:border-purple-500 transition-all">
                                <input
                                    type="text"
                                    placeholder="Начните вводить имя..."
                                    value={artistQuery}
                                    onChange={(e) => {
                                        setArtistQuery(e.target.value);
                                        setShowArtistDropdown(true);
                                    }}
                                    onFocus={() => setShowArtistDropdown(true)}
                                    className="flex-1 p-4 bg-transparent outline-none"
                                />
                                <FiSearch className="text-neutral-400 text-xl" />
                            </div>

                            <AnimatePresence>
                                {showArtistDropdown && (
                                    <motion.div
                                        initial={{ opacity: 0, y: -10 }}
                                        animate={{ opacity: 1, y: 0 }}
                                        exit={{ opacity: 0 }}
                                        ref={artistDropdownRef}
                                        className="absolute z-20 w-full mt-2 bg-white dark:bg-neutral-800 rounded-xl shadow-2xl border dark:border-neutral-700 overflow-hidden"
                                    >
                                        {artistsLoading ? (
                                            <div className="p-4 flex justify-center">
                                                <ClipLoader size={24} color="#9333ea" />
                                            </div>
                                        ) : (
                                            <>
                                                {filteredArtists.map(artist => (
                                                    <button
                                                        key={artist.id}
                                                        type="button"
                                                        onClick={() => handleSelectArtist(artist)}
                                                        className="w-full px-5 py-4 text-left hover:bg-purple-50 dark:hover:bg-purple-900/20 transition-colors flex items-center justify-between"
                                                    >
                                                        <span className="truncate">{artist.name}</span>
                                                        {artistId === artist.id && (
                                                            <FiX className="text-purple-500" />
                                                        )}
                                                    </button>
                                                ))}
                                                <div className="border-t dark:border-neutral-700">
                                                    <Link
                                                        to="/artists/new"
                                                        className="w-full px-5 py-4 flex items-center gap-3 text-purple-500 hover:bg-neutral-50 dark:hover:bg-neutral-700 transition-colors"
                                                    >
                                                        <FiPlusCircle className="flex-shrink-0" />
                                                        Создать нового исполнителя
                                                    </Link>
                                                </div>
                                            </>
                                        )}
                                    </motion.div>
                                )}
                            </AnimatePresence>
                        </div>
                    </div>

                    {/* Обложка и жанр */}
                    <div className="grid md:grid-cols-2 gap-6">
                        <div className="space-y-3">
                            <label className="text-sm font-medium text-neutral-600 dark:text-neutral-300 flex items-center gap-2">
                                Обложка
                                <span className="text-red-500">*</span>
                            </label>
                            <label className="group relative aspect-square bg-neutral-50 dark:bg-neutral-800 rounded-2xl border-2 border-dashed border-neutral-200 dark:border-neutral-700 hover:border-purple-500 transition-colors cursor-pointer block">
                                <input
                                    type="file"
                                    accept="image/*"
                                    onChange={handleAlbumArtChange}
                                    className="hidden"
                                />
                                {albumArtPreview ? (
                                    <div className="relative w-full h-full">
                                        <img
                                            src={albumArtPreview}
                                            alt="Превью обложки"
                                            className="w-full h-full object-cover rounded-xl"
                                        />
                                        <button
                                            type="button"
                                            onClick={() => {
                                                setAlbumArt(null);
                                                setAlbumArtPreview(null);
                                            }}
                                            className="absolute top-2 right-2 p-2 bg-white/80 dark:bg-neutral-700/80 rounded-full hover:bg-white transition-colors"
                                        >
                                            <FiX className="text-red-500" />
                                        </button>
                                    </div>
                                ) : (
                                    <div className="absolute inset-0 flex flex-col items-center justify-center p-4 text-center">
                                        <FiImage className="text-3xl text-neutral-400 mb-3 group-hover:text-purple-500 transition-colors" />
                                        <p className="text-neutral-500 dark:text-neutral-400 text-sm">
                                            Перетащите или выберите изображение
                                        </p>
                                    </div>
                                )}
                            </label>
                        </div>

                        <div className="space-y-3">
                            <label className="text-sm font-medium text-neutral-600 dark:text-neutral-300">
                                Жанр (через запятую)
                            </label>
                            <input
                                type="text"
                                placeholder="Например: Поп, Рок"
                                value={genre}
                                onChange={(e) => setGenre(e.target.value)}
                                className="w-full p-4 bg-neutral-50 dark:bg-neutral-800 rounded-xl border-2 border-transparent focus:border-purple-500 focus:ring-0 transition-all"
                            />
                        </div>
                    </div>

                    {/* Загрузка аудио */}
                    <div className="space-y-3">
                        <label className="text-sm font-medium text-neutral-600 dark:text-neutral-300 flex items-center gap-2">
                            Аудиофайл
                            <span className="text-red-500">*</span>
                        </label>
                        <div
                            {...getRootProps()}
                            className={`p-8 rounded-2xl border-2 border-dashed transition-all cursor-pointer ${
                                isDragActive
                                    ? "border-purple-500 bg-purple-50/50 dark:bg-purple-900/20"
                                    : "border-neutral-200 dark:border-neutral-700 hover:border-purple-500"
                            }`}
                        >
                            <div className="flex flex-col items-center text-center gap-4">
                                <div className="p-3 bg-purple-500/10 rounded-full text-purple-500">
                                    <FiUpload className="text-2xl" />
                                </div>
                                <div>
                                    <p className="font-medium dark:text-white">
                                        {file?.name || "Перетащите аудиофайл сюда"}
                                    </p>
                                    <p className="text-sm text-neutral-500 dark:text-neutral-400 mt-1">
                                        {file ? "Файл готов к загрузке" : "Или нажмите для выбора"}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <motion.button
                        whileHover={{ scale: 1.02 }}
                        whileTap={{ scale: 0.98 }}
                        onClick={handleUpload}
                        disabled={isUploading}
                        className={`w-full py-5 rounded-xl font-bold text-white transition-colors ${
                            isUploading
                                ? "bg-neutral-400 cursor-not-allowed"
                                : "bg-purple-500 hover:bg-purple-600"
                        }`}
                    >
                        {isUploading ? (
                            <div className="flex items-center justify-center gap-3">
                                <ClipLoader size={20} color="#fff" />
                                <span>Загрузка...</span>
                            </div>
                        ) : (
                            "Опубликовать трек"
                        )}
                    </motion.button>
                </div>

                {/*<p className="text-center text-neutral-500 dark:text-neutral-400 text-sm mt-6">*/}
                {/*    Нажимая «Опубликовать», вы соглашаетесь с нашими<br />*/}
                {/*    <a href="#" className="text-purple-500 hover:underline">Условиями использования</a>*/}
                {/*</p>*/}
            </motion.div>
        </div>
    );
};

export default UploadTrack;