import React from 'react';
import PropTypes from 'prop-types';

const EmptyState = ({ title, description, icon, className }) => {
    return (
        <div className={`flex flex-col items-center text-center p-6 md:p-8 ${className}`}>
            <div className="mb-4 text-gray-400 dark:text-neutral-600">
                {React.cloneElement(icon, {
                    className: 'w-12 h-12 md:w-14 md:h-14 mx-auto',
                })}
            </div>
            <h3 className="text-lg md:text-xl font-semibold text-gray-900 dark:text-white mb-2">
                {title}
            </h3>
            <p className="text-sm md:text-base text-gray-500 dark:text-neutral-400 max-w-md mx-auto">
                {description}
            </p>
        </div>
    );
};

EmptyState.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    icon: PropTypes.element.isRequired,
    className: PropTypes.string,
};

export default EmptyState;