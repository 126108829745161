import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import {
    FiMusic,
    FiHeadphones,
    FiClock,
    FiPause,
    FiPlay,
    FiMoreVertical,
    FiHeart,
    FiDownload,
    FiShare2,
    FiChevronLeft,
    FiShuffle,
    FiList,
    FiGrid,
    FiInfo
} from 'react-icons/fi';
import { motion, AnimatePresence } from 'framer-motion';
import { ClipLoader } from 'react-spinners';
import Modal from 'react-modal';
import {formatArtists, formatDuration} from "./utils/helpers";
import { usePlayer } from "../context/PlayerContext";
import TrackContextMenu from "./TrackContextMenu";
import toast from "react-hot-toast";
import useWindowSize from "../hooks/useWindowSize";
import Header from "./Header";
import BottomNav from "./BottomNav";

const ArtistPage = () => {
    const { id } = useParams();
    const [artist, setArtist] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [activeTab, setActiveTab] = useState('tracks');
    const [isPhotoModalOpen, setIsPhotoModalOpen] = useState(false);
    const theme = localStorage.getItem('theme') || 'dark';
    const [showContextMenu, setShowContextMenu] = useState(null);
    const [selectedTrack, setSelectedTrack] = useState(null);
    const [playlists, setPlaylists] = useState([]);
    const { width } = useWindowSize();
    const { playTrack, currentTrack, isPlaying, togglePlayPause } = usePlayer();
    const navigate = useNavigate();
    const [viewMode, setViewMode] = useState('list'); // 'list' или 'grid'
    const isMobile = width < 768;
    const mainRef = useRef(null);

    // Прокрутка страницы вверх при загрузке
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    // Загрузка данных исполнителя
    useEffect(() => {
        const fetchArtist = async () => {
            try {
                setLoading(true);
                const response = await fetch(`${process.env.REACT_APP_API_URL}/artists/${id}`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                });
                if (!response.ok) throw new Error("Ошибка загрузки данных исполнителя");
                const data = await response.json();
                setArtist(data);

                // Загружаем треки и альбомы исполнителя сразу после получения данных
                await Promise.all([
                    fetchArtistTracks(data.id),
                    fetchArtistAlbums(data.id)
                ]);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchArtist();
    }, [id]);

    // Функция для загрузки треков исполнителя
    const fetchArtistTracks = async (artistId) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/artists/${artistId}/tracks`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            });
            if (!response.ok) throw new Error("Ошибка загрузки треков исполнителя");
            const data = await response.json();
            setArtist(prevArtist => ({
                ...prevArtist,
                tracks: data
            }));
        } catch (err) {
            console.error("Ошибка загрузки треков:", err);
        }
    };

    // Функция для загрузки альбомов исполнителя
    const fetchArtistAlbums = async (artistId) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/artists/${artistId}/albums`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            });
            if (!response.ok) throw new Error("Ошибка загрузки альбомов исполнителя");
            const data = await response.json();
            setArtist(prevArtist => ({
                ...prevArtist,
                albums: data
            }));
        } catch (err) {
            console.error("Ошибка загрузки альбомов:", err);
        }
    };

    // Загрузка плейлистов
    useEffect(() => {
        const fetchPlaylists = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/playlists`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                });
                if (!response.ok) throw new Error("Ошибка загрузки плейлистов");
                const data = await response.json();
                setPlaylists(data);
            } catch (err) {
                console.error("Ошибка загрузки плейлистов:", err);
            }
        };

        fetchPlaylists();
    }, []);

    const handleCreatePlaylist = async (formData) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/playlists`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
                body: formData,
            });
            const newPlaylist = await response.json();
            setPlaylists([...playlists, newPlaylist]);
            toast.success('Плейлист успешно создан!');
        } catch (err) {
            console.error('Ошибка создания плейлиста:', err);
            toast.error('Не удалось создать плейлист');
        }
    };

    const handleAddToPlaylist = async (playlistId, trackId) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/playlists/${playlistId}/tracks`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ track_ids: [trackId] }),
            });

            if (!response.ok) {
                const errorData = await response.json().catch(() => null);
                console.error('Ошибка сервера:', errorData);
                throw new Error('Ошибка добавления трека в плейлист');
            }

            toast.success('Трек успешно добавлен в плейлист!');
        } catch (err) {
            console.error('Ошибка добавления трека в плейлист:', err);
            toast.error('Не удалось добавить трек в плейлист');
        }
    };

    // Функция для добавления/удаления трека из избранного
    const toggleFavorite = async (trackId) => {
        try {
            const track = artist.tracks.find((t) => t.id === trackId);
            const method = track.is_favorite ? "DELETE" : "POST";

            const response = await fetch(
                `${process.env.REACT_APP_API_URL}/tracks/${trackId}/favorite`,
                {
                    method,
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                }
            );

            if (!response.ok) throw new Error('Ошибка обновления избранного');

            // Обновляем состояние треков в artist
            setArtist(prevArtist => ({
                ...prevArtist,
                tracks: prevArtist.tracks.map((t) =>
                    t.id === trackId ? {...t, is_favorite: !t.is_favorite} : t
                )
            }));

            toast.success(track.is_favorite ? 'Удалено из избранного' : 'Добавлено в избранное');
        } catch (err) {
            console.error(err);
            toast.error('Ошибка при обновлении избранного');
        }
    };

    // Функция для воспроизведения трека с добавлением информации об исполнителе
    const handlePlayTrack = (track) => {
        if (!track) return;

        // Добавляем информацию об исполнителе в объект трека
        // Это решает проблему с currentTrack.artist is undefined
        const trackWithArtist = {
            ...track,
            artist: artist ? {
                id: artist.id,
                name: artist.name
            } : null
        };

        playTrack(trackWithArtist);
    };


    // Функция для отображения треков в режиме списка
    const renderTracksList = () => {
        if (!artist.tracks || artist.tracks.length === 0) {
            return (
                <div className="flex flex-col items-center justify-center py-16 text-center">
                    <FiMusic className={`text-5xl mb-4 ${theme === 'dark' ? 'text-neutral-600' : 'text-neutral-300'}`} />
                    <p className={`text-lg ${theme === 'dark' ? 'text-neutral-400' : 'text-neutral-500'}`}>
                        У этого исполнителя пока нет треков
                    </p>
                </div>
            );
        }

        return (
            <div className="space-y-2">
                <div className="flex justify-between items-center mb-6">
                    <div className="flex items-center gap-2">
                        <h2 className={`text-xl font-bold text-neutral-800 dark:text-white`}>
                            Треки
                        </h2>
                        <span className={`text-sm text-neutral-500 dark:text-neutral-400`}>
                            ({artist.tracks.length})
                        </span>
                    </div>
                    <div className="flex gap-2">
                        <button
                            className={`p-2 rounded-md transition-colors ${
                                viewMode === 'list'
                                    ? 'bg-neutral-100 dark:bg-neutral-800'
                                    : 'hover:bg-neutral-100 dark:hover:bg-neutral-800'
                            }`}
                            onClick={() => setViewMode('list')}
                            aria-label="Список"
                        >
                            <FiList className="text-neutral-700 dark:text-white"/>
                        </button>
                        <button
                            className={`p-2 rounded-md transition-colors ${
                                viewMode === 'grid'
                                    ? 'bg-neutral-100 dark:bg-neutral-800'
                                    : 'hover:bg-neutral-100 dark:hover:bg-neutral-800'
                            }`}
                            onClick={() => setViewMode('grid')}
                            aria-label="Сетка"
                        >
                            <FiGrid className="text-neutral-700 dark:text-white"/>
                        </button>
                        <button
                            className={`p-2 rounded-md bg-purple-100 hover:bg-purple-200 dark:bg-purple-900 transition-colors ml-2`}
                            onClick={() => {
                                if (artist.tracks && artist.tracks.length > 0) {
                                    const randomIndex = Math.floor(Math.random() * artist.tracks.length);
                                    const randomTrack = artist.tracks[randomIndex];
                                    const trackWithArtist = {
                                        ...randomTrack,
                                        artist: {id: artist.id, name: artist.name}
                                    };
                                    playTrack(trackWithArtist);
                                    toast.success('Случайное воспроизведение');
                                }
                            }}
                            aria-label="Случайное воспроизведение"
                        >
                            <FiShuffle className={`text-purple-700 dark:text-white`}/>
                        </button>
                    </div>
                </div>

                <div className="space-y-2">
                    {artist.tracks.map((track, index) => (
                        <motion.div
                            key={track.id}
                            initial={{opacity: 0, y: 10}}
                            animate={{opacity: 1, y: 0}}
                            transition={{delay: index * 0.03}}
                            className={`group flex items-center p-3 ${
                                currentTrack?.id === track.id
                                    ? 'bg-purple-50 dark:bg-purple-900/20 border-l-4 border-purple-500'
                                    : 'bg-white hover:bg-neutral-50 dark:bg-neutral-800/70 dark:hover:bg-neutral-700 border-l-4 border-transparent'
                            } rounded-xl cursor-pointer transition-colors`}
                            onClick={() => handlePlayTrack(track)}
                        >
                            {/* Номер трека и кнопка воспроизведения */}
                            {/*<div className="relative flex items-center justify-center w-10 h-10">*/}
                            {/*    <span className={`group-hover:opacity-0 ${*/}
                            {/*        currentTrack?.id === track.id ? 'opacity-0' : ''*/}
                            {/*    } text-neutral-500 dark:text-neutral-400`}>*/}
                            {/*        {index + 1}*/}
                            {/*    </span>*/}
                            {/*    <div className={`absolute inset-0 flex items-center justify-center ${*/}
                            {/*        currentTrack?.id === track.id && isPlaying ? 'opacity-100' : 'opacity-0 group-hover:opacity-100'*/}
                            {/*    }`}>*/}
                            {/*        {currentTrack?.id === track.id && isPlaying ? (*/}
                            {/*            <FiPause className="text-purple-400"/>*/}
                            {/*        ) : (*/}
                            {/*            <FiPlay className="text-purple-400"/>*/}
                            {/*        )}*/}
                            {/*    </div>*/}
                            {/*</div>*/}

                            {/* Обложка альбома */}
                            <div className="w-12 h-12 mr-4 rounded-lg overflow-hidden">
                                <LazyLoadImage
                                    src={track.album_art ? `${process.env.REACT_APP_BASE_URL}/storage/${track.album_art}` : '/placeholder.png'}
                                    alt={track.title}
                                    className="w-full h-full object-cover"
                                    effect="blur"
                                    placeholderSrc="/placeholder.png"
                                />
                            </div>

                            {/* Информация о треке */}
                            <div className="flex-1 min-w-0 mr-4">
                                <p className={`font-medium truncate ${
                                    currentTrack?.id === track.id
                                        ? 'text-purple-400'
                                        : 'text-neutral-800 dark:text-white'
                                }`}>
                                    {track.title}
                                </p>
                                <div className="flex items-center">
                                    <p className="text-sm truncate text-neutral-500 dark:text-neutral-400">
                                        {formatArtists(track)}
                                    </p>
                                    {track.album && (
                                        <>
                                            <span
                                                className="mx-2 text-xs text-neutral-300 dark:text-neutral-600">•</span>
                                            <p className="text-sm truncate text-neutral-500 dark:text-neutral-400">
                                                {track.album.title}
                                            </p>
                                        </>
                                    )}
                                </div>
                            </div>

                            {/* Длительность трека */}
                            <span className="mr-4 text-sm text-neutral-500 dark:text-neutral-400">
                {formatDuration(track.duration)}
            </span>

                            {/* Кнопки действий */}
                            <div className="flex items-center">
                                <button
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        toggleFavorite(track.id);
                                    }}
                                    className={`p-2 rounded-full hover:bg-neutral-100 dark:hover:bg-neutral-700 transition-colors opacity-0 group-hover:opacity-100 ${
                                        track.is_favorite ? 'opacity-100' : ''
                                    }`}
                                >
                                    <FiHeart
                                        className={
                                            track.is_favorite
                                                ? 'text-red-500 fill-red-500'
                                                : 'text-neutral-500 dark:text-neutral-400'
                                        }
                                    />
                                </button>
                                <button
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setSelectedTrack(track);
                                        setShowContextMenu(track.id);
                                    }}
                                    className="p-2 rounded-full hover:bg-neutral-100 dark:hover:bg-neutral-700 transition-colors"
                                >
                                    <FiMoreVertical className="text-neutral-500 dark:text-neutral-400"/>
                                </button>
                            </div>

                            {showContextMenu === track.id && (
                                <TrackContextMenu
                                    track={selectedTrack}
                                    playlists={playlists}
                                    onAddToPlaylist={handleAddToPlaylist}
                                    onCreatePlaylist={handleCreatePlaylist}
                                    onClose={() => setShowContextMenu(null)}
                                />
                            )}
                        </motion.div>
                    ))}
                </div>
            </div>
        );
    };

    // Функция для отображения треков в режиме сетки
    const renderTracksGrid = () => {
        if (!artist.tracks || artist.tracks.length === 0) {
            return (
                <div className="flex flex-col items-center justify-center py-16 text-center">
                    <FiMusic className={`text-5xl mb-4 text-neutral-300 dark:text-neutral-600`}/>
                    <p className={`text-lg text-neutral-500 dark:text-neutral-400`}>
                        У этого исполнителя пока нет треков
                    </p>
                </div>
            );
        }

        return (
            <div>
                <div className="flex justify-between items-center mb-6">
                    <div className="flex items-center gap-2">
                        <h2 className={`text-xl font-bold text-neutral-800 dark:text-white`}>
                            Треки
                        </h2>
                        <span className={`text-sm text-neutral-500 dark:text-neutral-400`}>
                            ({artist.tracks.length})
                        </span>
                    </div>
                    <div className="flex gap-2">
                        <button
                            className={`p-2 rounded-md hover:bg-neutral-100 dark:hover:bg-neutral-800 transition-colors bg-neutral-100 dark:bg-neutral-800`}
                            onClick={() => setViewMode('list')}
                            aria-label="Список"
                        >
                            <FiList className={`text-neutral-700 dark:text-white`}/>
                        </button>
                        <button
                            className={`p-2 rounded-md hover:bg-neutral-100 dark:hover:bg-neutral-800 transition-colors bg-neutral-100 dark:bg-neutral-800`}
                            onClick={() => setViewMode('grid')}
                            aria-label="Сетка"
                        >
                            <FiGrid className={`text-neutral-700 dark:text-white`}/>
                        </button>
                        <button
                            className={`p-2 rounded-md bg-purple-100 hover:bg-purple-200 dark:bg-purple-900 transition-colors ml-2`}
                            onClick={() => {
                                if (artist.tracks && artist.tracks.length > 0) {
                                    const randomIndex = Math.floor(Math.random() * artist.tracks.length);
                                    const randomTrack = artist.tracks[randomIndex];
                                    const trackWithArtist = {
                                        ...randomTrack,
                                        artist: {id: artist.id, name: artist.name}
                                    };
                                    playTrack(trackWithArtist);
                                    toast.success('Случайное воспроизведение');
                                }
                            }}
                            aria-label="Случайное воспроизведение"
                        >
                            <FiShuffle className={`text-purple-700 dark:text-white`}/>
                        </button>
                    </div>
                </div>

                <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4 pb-28">
                    {artist.tracks.map((track, index) => (
                        <motion.div
                            key={track.id}
                            initial={{opacity: 0, y: 10}}
                            animate={{opacity: 1, y: 0}}
                            transition={{delay: index * 0.03}}
                            className={`group cursor-pointer hover:bg-neutral-50 dark:hover:bg-neutral-800 rounded-lg p-3 transition-colors`}
                            onClick={() => handlePlayTrack(track)}
                        >
                            <div className="relative aspect-square rounded-lg overflow-hidden mb-3">
                                <LazyLoadImage
                                    src={track.album_art ? `${process.env.REACT_APP_BASE_URL}/storage/${track.album_art}` : '/placeholder.png'}
                                    alt={track.title}
                                    className="w-full h-full object-cover"
                                    effect="blur"
                                    placeholderSrc="/placeholder.png"
                                />
                                <div
                                    className={`absolute inset-0 bg-black/40 opacity-0 group-hover:opacity-100 transition-opacity flex items-center justify-center`}>
                                    <div className="bg-white/20 backdrop-blur-sm p-3 rounded-full">
                                        {currentTrack?.id === track.id && isPlaying ? (
                                            <FiPause className="text-white text-xl"/>
                                        ) : (
                                            <FiPlay className="text-white text-xl" />
                                        )}
                                    </div>
                                </div>
                                <div className="absolute bottom-2 right-2 flex gap-1">
                                    <button
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            toggleFavorite(track.id);
                                        }}
                                        className={`p-1.5 rounded-full bg-black/50 backdrop-blur-sm opacity-0 group-hover:opacity-100 transition-opacity ${track.is_favorite ? 'opacity-100' : ''}`}
                                    >
                                        <FiHeart
                                            className={
                                                track.is_favorite
                                                    ? 'text-red-500 fill-red-500'
                                                    : 'text-white'
                                            }
                                            size={16}
                                        />
                                    </button>
                                    <button
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setSelectedTrack(track);
                                            setShowContextMenu(track.id);
                                        }}
                                        className="p-1.5 rounded-full bg-black/50 backdrop-blur-sm opacity-0 group-hover:opacity-100 transition-opacity"
                                    >
                                        <FiMoreVertical className="text-white" size={16} />
                                    </button>
                                </div>
                            </div>
                            <div>
                                <p className={`font-medium truncate ${
                                    currentTrack?.id === track.id
                                        ? 'text-purple-400'
                                        : 'text-neutral-800 dark:text-white'
                                }`}>
                                    {track.title}
                                </p>
                                <p className="text-sm truncate text-neutral-500 dark:text-neutral-400">
                                    {formatArtists(track)}
                                </p>
                            </div>
                            {showContextMenu === track.id && (
                                <TrackContextMenu
                                    track={selectedTrack}
                                    playlists={playlists}
                                    onAddToPlaylist={handleAddToPlaylist}
                                    onCreatePlaylist={handleCreatePlaylist}
                                    onClose={() => setShowContextMenu(null)}
                                />
                            )}
                        </motion.div>
                    ))}
                </div>
            </div>
        );
    };

    // Функция для отображения альбомов
    const renderAlbums = () => {
        if (!artist.albums || artist.albums.length === 0) {
            return (
                <div className="flex flex-col items-center justify-center py-16 text-center">
                    <FiMusic className={`text-5xl mb-4 text-neutral-300 dark:text-neutral-600`} />
                    <p className={`text-lg text-neutral-500 dark:text-neutral-400`}>
                        У этого исполнителя пока нет альбомов
                    </p>
                </div>
            );
        }

        return (
            <div>
                <div className="flex items-center justify-between mb-6">
                    <div className="flex items-center gap-2">
                        <h2 className={`text-xl font-bold text-neutral-800 dark:text-white`}>
                            Альбомы
                        </h2>
                        <span className={`text-sm text-neutral-500 dark:text-neutral-400`}>
                            ({artist.albums.length})
                        </span>
                    </div>
                </div>

                <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4">
                    {artist.albums.map((album, index) => (
                        <motion.div
                            key={album.id}
                            initial={{ opacity: 0, y: 10 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ delay: index * 0.03 }}
                            className={`cursor-pointer hover:bg-neutral-50 dark:hover:bg-neutral-800 rounded-lg p-3 transition-colors`}
                            onClick={() => navigate(`/albums/${album.id}`)}
                        >
                            <div className="relative aspect-square rounded-lg overflow-hidden mb-3">
                                <LazyLoadImage
                                    src={`${process.env.REACT_APP_BASE_URL}/storage/${album.album_art}`}
                                    alt={album.title}
                                    className="w-full h-full object-cover"
                                    effect="blur"
                                    placeholderSrc="/placeholder.png"
                                />
                                <div className="absolute inset-0 bg-black/40 opacity-0 hover:opacity-100 transition-opacity flex items-center justify-center">
                                    <div className="bg-white/20 backdrop-blur-sm p-3 rounded-full">
                                        <FiPlay className="text-white text-xl" />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <p className={`font-medium truncate text-neutral-800 dark:text-white`}>
                                    {album.title}
                                </p>
                                <p className={`text-sm truncate text-neutral-500 dark:text-neutral-400`}>
                                    {album.release_year || 'Альбом'} • {formatArtists(album)}
                                </p>
                            </div>
                        </motion.div>
                    ))}
                </div>
            </div>
        );
    };

    // Функция для отображения биографии
    const renderBio = () => {
        if (!artist.bio) {
            return (
                <div className="flex flex-col items-center justify-center py-16 text-center">
                    <FiInfo className={`text-5xl mb-4 text-neutral-300 dark:text-neutral-600`} />
                    <p className={`text-lg text-neutral-500 dark:text-neutral-400`}>
                        Нет информации о биографии
                    </p>
                </div>
            );
        }

        return (
            <div className="max-w-3xl mx-auto">
                <h2 className={`text-xl font-bold mb-6 text-neutral-800 dark:text-white`}>
                    О исполнителе
                </h2>

                <div className={`prose prose-sm sm:prose lg:prose-lg xl:prose-xl mx-auto text-neutral-800 dark:text-white`}>
                    {artist.bio}
                </div>
            </div>
        );
    };

    const renderContent = () => {
        switch (activeTab) {
            case 'tracks':
                return viewMode === 'list' ? renderTracksList() : renderTracksGrid();
            case 'albums':
                return renderAlbums();
            case 'bio':
                return renderBio();
            default:
                return null;
        }
    };

    if (loading) {
        return (
            <div className={`h-screen flex flex-col items-center justify-center bg-neutral-50 dark:bg-neutral-900`}>
                <ClipLoader color={theme === 'dark' ? "#d4d4d4" : "#737373"} size={50} />
                <p className={`mt-4 text-neutral-500 dark:text-neutral-400`}>
                    Загрузка...
                </p>
            </div>
        );
    }

    if (error) {
        return (
            <div className={`h-screen flex flex-col items-center justify-center bg-neutral-50 dark:bg-neutral-900`}>
                <div className="bg-red-500/10 p-6 rounded-lg text-center max-w-md">
                    <FiInfo className="text-red-500 text-4xl mx-auto mb-4" />
                    <p className="text-red-500 text-xl font-medium mb-2">Ошибка</p>
                    <p className={`text-neutral-600 dark:text-neutral-300`}>{error}</p>
                    <button
                        onClick={() => window.location.reload()}
                        className="mt-4 px-4 py-2 bg-purple-600 text-white rounded-lg hover:bg-purple-700 transition-colors"
                    >
                        Попробовать снова
                    </button>
                </div>
            </div>
        );
    }

    function copyLink() {
        const link = `${window.location.origin}/artist/${artist.id}`;
        navigator.clipboard.writeText(link);
        toast.success('Ссылка на артиста скопирована в буфер обмена');
    }

    return (
        <div className={`min-h-screen bg-white dark:bg-neutral-900`} ref={mainRef}>
            <Header isMusicPage />
            {isMobile && <BottomNav />}

            {/* Верхняя панель с кнопкой назад */}
            <div className={`fixed top-0 left-0 right-0 z-10 pt-14 bg-white/80 dark:bg-neutral-900/80 backdrop-blur-md`}>
                <div className="container mx-auto px-4 py-2 flex items-center">
                    <button
                        onClick={() => navigate(-1)}
                        className={`p-2 rounded-full hover:bg-neutral-100 dark:hover:bg-neutral-800 transition-colors mr-2`}
                    >
                        <FiChevronLeft className={`text-neutral-800 dark:text-white`} />
                    </button>
                    <h1 className={`text-lg font-medium truncate text-neutral-800 dark:text-white`}>
                        {artist.name}
                    </h1>
                </div>
            </div>

            <div className="container mx-auto px-4 pt-28 pb-28">
                {/* Информация об исполнителе */}
                <div className="flex flex-col md:flex-row items-center md:items-start gap-6 md:gap-8 mb-8">
                    <div className="w-48 h-48 md:w-56 md:h-56 rounded-full overflow-hidden cursor-pointer" onClick={() => setIsPhotoModalOpen(true)}>
                        <LazyLoadImage
                            src={artist.avatar_url ?
                                `${process.env.REACT_APP_BASE_URL}/storage/${artist.avatar_url}` :
                                'https://via.placeholder.com/300'}
                            alt={artist.name}
                            className="w-full h-full object-cover"
                            effect="blur"
                            placeholderSrc="/placeholder.png"
                        />
                    </div>

                    <div className="flex-1 text-center md:text-left">
                        <h1 className={`text-3xl md:text-4xl font-bold mb-4 text-neutral-800 dark:text-white`}>
                            {artist.name}
                        </h1>

                        <div className="flex flex-wrap justify-center md:justify-start gap-4 mb-6">
                            <div className={`flex items-center gap-2 text-neutral-500 dark:text-neutral-400`}>
                                <FiMusic />
                                <span>{artist.albums_count || 0} альбомов</span>
                            </div>
                            <div className={`flex items-center gap-2 text-neutral-500 dark:text-neutral-400`}>
                                <FiHeadphones />
                                <span>{artist.tracks_count || 0} треков</span>
                            </div>
                        </div>

                        <div className="flex flex-wrap justify-center md:justify-start gap-3">
                            <button
                                className="px-6 py-2.5 bg-purple-700 hover:bg-purple-600 text-white rounded-full transition-colors flex items-center gap-2"
                                onClick={() => {
                                    if (artist.tracks && artist.tracks.length > 0) {
                                        const trackWithArtist = {
                                            ...artist.tracks[0],
                                            artist: { id: artist.id, name: artist.name }
                                        };
                                        playTrack(trackWithArtist);
                                    }
                                }}
                            >
                                <FiPlay />
                                Слушать
                            </button>
                            <button className="px-6 py-2.5 border border-purple-600 text-purple-400 hover:bg-purple-900/20 rounded-full transition-colors flex items-center gap-2">
                                <FiHeart />
                                Подписаться
                            </button>
                            <button onClick={copyLink}
                                    className={`p-2.5 rounded-full bg-neutral-100 hover:bg-neutral-200 dark:bg-neutral-800 transition-colors`}>
                                <FiShare2 className={`text-neutral-800 dark:text-white`} />
                            </button>
                        </div>
                    </div>
                </div>

                {/* Навигация */}
                <div className={`mb-8 border-b border-neutral-200 dark:border-neutral-800`}>
                    <div className="flex">
                        {['tracks', 'albums', 'bio'].map((tab) => (
                            <button
                                key={tab}
                                onClick={() => setActiveTab(tab)}
                                className={`py-3 px-5 capitalize relative text-neutral-800 dark:text-white font-medium transition-all`}
                            >
                                {tab === 'bio' ? 'О себе' :
                                    tab === 'tracks' ? 'Треки' : 'Альбомы'}

                                {activeTab === tab && (
                                    <motion.div
                                        layoutId="activeTabIndicator"
                                        className="absolute bottom-0 left-0 right-0 h-0.5 bg-purple-500"
                                    />
                                )}
                            </button>
                        ))}
                    </div>
                </div>

                {/* Основной контент */}
                <AnimatePresence mode="wait">
                    <motion.div
                        key={activeTab + viewMode}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.2 }}
                    >
                        {renderContent()}
                    </motion.div>
                </AnimatePresence>
            </div>

            {/* Модальное окно для фото */}
            <Modal
                isOpen={isPhotoModalOpen}
                onRequestClose={() => setIsPhotoModalOpen(false)}
                className="modal"
                overlayClassName="modal-overlay"
            >
                {artist?.avatar_url && (
                    <img
                        src={`${process.env.REACT_APP_BASE_URL}/storage/${artist.avatar_url}`}
                        alt={artist.name}
                        className="max-h-[90vh] max-w-full rounded-lg"
                    />
                )}
                <button
                    onClick={() => setIsPhotoModalOpen(false)}
                    className="absolute top-4 right-4 text-white text-3xl bg-black/30 w-10 h-10 rounded-full flex items-center justify-center hover:bg-black/50 transition-colors"
                >
                    &times;
                </button>
            </Modal>

            <style>{`
                .modal {
                    position: fixed;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    background: transparent;
                    border: none;
                    outline: none;
                    padding: 0;
                    max-width: 90%;
                    z-index: 1001;
                }
                
                .modal-overlay {
                    position: fixed;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background: rgba(0, 0, 0, 0.9);
                    backdrop-filter: blur(5px);
                    z-index: 1000;
                }
            `}</style>
        </div>
    );
};

export default ArtistPage;
