import React, { useState, useRef, useContext } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import toast from 'react-hot-toast';
import { QRCodeCanvas } from 'qrcode.react';
import {Eye, EyeOff} from "lucide-react"; // Импорт компонента для генерации QR-кода

const notify = () => toast.error('Произошла ошибка при входе! Проверьте правильность введенных данных!');

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [otp, setOtp] = useState(new Array(6).fill(''));
  const [userId, setUserId] = useState(null);
  const [isOtpRequired, setIsOtpRequired] = useState(false);
  const [isQrLogin, setIsQrLogin] = useState(false); // Режим входа по QR-коду
  const [qrCodeUrl, setQrCodeUrl] = useState(''); // URL для QR-кода
  const navigate = useNavigate();
  const { login } = useContext(AuthContext);

  const inputRefs = useRef([]);

  const handleQrLogin = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/auth/qr/generate`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setQrCodeUrl(response.data.qr_code_url);
      setIsQrLogin(true);
    } catch (error) {
      notify();
      console.error('Ошибка при генерации QR-кода!', error);
    }
  };

  const handleChange = (element, index) => {
    if (isNaN(element.value)) return;

    const newOtp = [...otp];
    newOtp[index] = element.value;
    setOtp(newOtp);

    if (element.nextSibling && element.value) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleKeyDown = (event, index) => {
    if (event.key === 'Backspace' && !otp[index] && index > 0) {
      inputRefs.current[index - 1].focus();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isOtpRequired) {
      try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/verify-otp`, {
          user_id: userId,
          otp: otp.join(''),
        });

        const { token } = response.data;
        localStorage.setItem('token', token);
        localStorage.setItem('user_id', userId);
        login(token);
        navigate('/');
      } catch (error) {
        notify();
        console.error('Ошибка при проверке OTP!', error);
      }
    } else {
      try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/login`, {
          email,
          password,
        });

        if (response.data.otp_required) {
          setUserId(response.data.user_id);
          setIsOtpRequired(true);
        } else {
          const { token, user_id } = response.data;
          localStorage.setItem('token', token);
          localStorage.setItem('user_id', user_id);
          login(token);
          navigate('/');
        }
      } catch (error) {
        notify();
        console.error('Ошибка при входе!', error);
      }
    }
  };

  return (
      <div className="relative w-full h-screen flex items-center justify-center bg-gray-100 dark:bg-neutral-900">
        <div className="absolute inset-0 z-0">
          <div
              className="absolute top-10 left-10 w-48 h-48 bg-purple-400 rounded-full mix-blend-multiply filter blur-3xl opacity-50 animate-blob1"></div>
          <div
              className="absolute top-1 right-1/3 w-48 h-48 dark:w-20 dark:h-20 bg-purple-500 rounded-full mix-blend-multiply filter blur-3xl opacity-60 animate-blob2 animation-delay-1000"></div>
          <div
              className="absolute bottom-1/2 right-10 w-40 h-40 bg-purple-500 rounded-full mix-blend-multiply filter blur-3xl opacity-60 animate-blob3 animation-delay-3000"></div>
          <div
              className="absolute bottom-1 left-1 w-16 h-16 bg-purple-400 rounded-full mix-blend-multiply filter blur-3xl opacity-60 animate-blob4 animation-delay-2000"></div>
          <div
              className="absolute top-1/2 left-1/4 w-48 h-48 bg-purple-400 rounded-full mix-blend-multiply filter blur-3xl opacity-60 animate-blob1 animation-delay-2000"></div>
          <div
              className="absolute bottom-10 left-1 w-40 h-40 dark:w-20 dark:h-24 bg-violet-300 rounded-full mix-blend-multiply filter blur-2xl opacity-60 animate-blob2 animation-delay-3000"></div>
          <div
              className="absolute bottom-10 right-1/4 w-48 h-48 dark:w-20 dark:h-24 bg-purple-300 rounded-full mix-blend-multiply filter blur-2xl opacity-60 animate-blob3 animation-delay-1000"></div>
        </div>
        <div
            className="relative z-10 w-full max-w-6xl p-8 bg-white dark:bg-neutral-800 rounded-xl shadow-lg flex flex-col lg:flex-row lg:space-x-6">
          <div className="flex-shrink-0 mb-6 lg:mb-0 lg:w-1/2">
            <iframe
                src="https://lottie.host/embed/8e1366d6-2366-4ef6-9994-3e3442c81c81/lFVf0xaI2S.json"
                className="w-full h-full rounded-xl dark:bg-neutral-800"
                loading="eager"
            ></iframe>
          </div>
          <div className="flex-1 flex flex-col justify-center">
            <h2 className="text-4xl font-extrabold mb-6 text-center text-gray-900 dark:text-white">Вход</h2>
            {isQrLogin ? (
                <div className="flex flex-col items-center space-y-4">
                  <p className="text-lg text-gray-700 dark:text-gray-300">
                    Сканируйте этот QR-код с вашего мобильного устройства:
                  </p>
                  {qrCodeUrl && <QRCodeCanvas value={qrCodeUrl} size={200}/>}
                  <button
                      onClick={() => setIsQrLogin(false)}
                      className="w-full py-3 px-6 text-lg font-semibold text-white bg-indigo-600 rounded-lg shadow-md hover:bg-indigo-700 transition-all duration-300"
                  >
                    Назад к форме входа
                  </button>
                </div>
            ) : (
                <form onSubmit={handleSubmit} className="flex flex-col space-y-4">
                  {!isOtpRequired ? (
                      <>
                        <div>
                          <label
                              className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">Email</label>
                          <input
                              type="email"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                              className="w-full px-4 py-3 border border-gray-300 rounded-lg dark:border-neutral-700 dark:bg-neutral-800 dark:text-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-400 transition duration-300"
                              placeholder="Введите ваш email"
                          />
                        </div>
                        <div>
                          <label
                              className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">Пароль</label>
                          <div className="relative">
                            <input
                                type={showPassword ? "text" : "password"}
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                className="w-full px-4 py-3 border border-gray-300 rounded-lg dark:border-neutral-700 dark:bg-neutral-800 dark:text-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-400 transition duration-300 pr-10"
                                placeholder="Введите ваш пароль"
                            />
                            <button
                                type="button"
                                onClick={() => setShowPassword(!showPassword)}
                                className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5"
                                aria-label={showPassword ? "Скрыть пароль" : "Показать пароль"}
                            >
                              {showPassword ? (
                                  <EyeOff className="w-5 h-5 text-gray-500 dark:text-gray-400"/>
                              ) : (
                                  <Eye className="w-5 h-5 text-gray-500 dark:text-gray-400"/>
                              )}
                            </button>
                          </div>
                        </div>
                      </>
                  ) : (
                      <div>
                        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                          Проверочный код
                        </label>
                        <div className="flex space-x-4 justify-center items-center">
                          {otp.map((_, index) => (
                              <input
                                  key={index}
                                  type="text"
                                  maxLength="1"
                                  value={otp[index]}
                                  inputMode="numeric"
                                  onChange={(e) => handleChange(e.target, index)}
                                  onKeyDown={(e) => handleKeyDown(e, index)}
                                  ref={(el) => (inputRefs.current[index] = el)}
                                  className="w-12 h-12 text-center text-lg border border-gray-300 rounded-lg dark:border-neutral-700 dark:bg-neutral-800 dark:text-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-400 transition duration-300"
                              />
                          ))}
                        </div>
                      </div>
                  )}

                  <button
                      type="submit"
                      className="w-full py-3 px-6 text-lg font-semibold text-white bg-indigo-600 rounded-lg shadow-md hover:bg-indigo-700 transition-all duration-300"
                  >
                    {isOtpRequired ? 'Подтвердить' : 'Войти'}
                  </button>

                  <button
                      disabled
                      type="button"
                      onClick={handleQrLogin}
                      className="w-full py-3 px-6 text-lg font-semibold text-white bg-indigo-300 rounded-lg shadow-md hover:bg-indigo-300 transition-all duration-300"
                  >
                    Войти с помощью QR
                  </button>
                </form>
            )}
            <div className="mt-6 text-center">
              <p className="text-gray-700 dark:text-gray-300">
                Нет аккаунта?
                <Link
                    to="/register"
                    className="text-indigo-500 hover:text-indigo-600 dark:text-indigo-400 dark:hover:text-indigo-500 transition duration-300"
                >
                  {' '}
                  Зарегистрироваться!
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
  );
}

export default Login;
