import React, { useState, useEffect } from 'react';
import { FiX, FiImage, FiUpload, FiMusic, FiPlus, FiCamera, FiCheck, FiHeart } from 'react-icons/fi';
import { motion, AnimatePresence } from 'framer-motion';

const CreatePlaylistModal = ({ isOpen, onClose, onCreate }) => {
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [coverImage, setCoverImage] = useState(null);
    const [previewImage, setPreviewImage] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isMobile, setIsMobile] = useState(false);

    // Check if device is mobile
    useEffect(() => {
        const checkMobile = () => {
            setIsMobile(window.innerWidth < 768);
        };

        checkMobile();
        window.addEventListener('resize', checkMobile);

        return () => window.removeEventListener('resize', checkMobile);
    }, []);

    // Reset form when modal closes
    useEffect(() => {
        if (!isOpen) {
            setTimeout(() => {
                setName('');
                setDescription('');
                setCoverImage(null);
                setPreviewImage(null);
                setIsSubmitting(false);
            }, 300); // Wait for close animation to finish
        }
    }, [isOpen]);

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setCoverImage(file);
            setPreviewImage(URL.createObjectURL(file));
        }
    };

    const handleRemoveImage = () => {
        setCoverImage(null);
        setPreviewImage(null);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);

        try {
            const formData = new FormData();
            formData.append('name', name);
            formData.append('description', description);
            if (coverImage) {
                formData.append('cover_image', coverImage);
            }

            await onCreate(formData);
            onClose();
        } catch (error) {
            console.error('Error creating playlist:', error);
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <AnimatePresence>
            {isOpen && (
                <motion.div
                    className="fixed inset-0 z-50 flex items-center justify-center p-0 sm:p-4 bg-black/60 backdrop-blur-sm"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.3 }}
                    onClick={onClose}
                >
                    <motion.div
                        className={`bg-white dark:bg-gray-900 w-full ${isMobile ? 'h-full rounded-none' : 'max-h-[90vh] max-w-3xl rounded-3xl'} relative overflow-hidden overflow-y-auto`}
                        initial={isMobile ? { y: '100%' } : { y: 20, opacity: 0, scale: 0.95 }}
                        animate={isMobile ? { y: 0 } : { y: 0, opacity: 1, scale: 1 }}
                        exit={isMobile ? { y: '100%' } : { y: 20, opacity: 0, scale: 0.95 }}
                        transition={{
                            duration: 0.4,
                            type: "spring",
                            stiffness: 300,
                            damping: 25
                        }}
                        onClick={(e) => e.stopPropagation()}
                    >
                        {/* Decorative elements - hidden on very small screens */}
                        {/*<div className="absolute top-0 right-0 w-64 h-64 bg-gradient-to-b from-purple-200 to-transparent dark:from-purple-900/30 dark:to-transparent rounded-full -mr-32 -mt-32 opacity-50 hidden sm:block"></div>*/}
                        {/*<div className="absolute bottom-0 left-0 w-64 h-64 bg-gradient-to-t from-purple-200 to-transparent dark:from-purple-900/30 dark:to-transparent rounded-full -ml-32 -mb-32 opacity-50 hidden sm:block"></div>*/}

                        {/* Header */}
                        <div className={`relative px-4 sm:px-8 pt-4 sm:pt-8 pb-4 sm:pb-6 flex justify-between items-center ${isMobile ? 'border-b border-purple-100 dark:border-purple-900/50' : ''}`}>
                            <div className="flex items-center">
                                <div className="w-8 h-8 sm:w-10 sm:h-10 rounded-full bg-purple-100 dark:bg-purple-900/50 flex items-center justify-center mr-3 sm:mr-4">
                                    <FiMusic className="w-4 h-4 sm:w-5 sm:h-5 text-purple-600 dark:text-purple-400" />
                                </div>
                                <h2 className="text-xl sm:text-2xl font-bold text-gray-800 dark:text-white">
                                    Новый плейлист
                                </h2>
                            </div>
                            <motion.button
                                onClick={onClose}
                                className="w-8 h-8 sm:w-10 sm:h-10 rounded-full bg-gray-100 dark:bg-gray-800 flex items-center justify-center text-gray-500 dark:text-gray-400"
                                whileHover={{
                                    scale: 1.1,
                                    backgroundColor: "#FEE2E2",
                                    color: "#EF4444"
                                }}
                                whileTap={{ scale: 0.95 }}
                            >
                                <FiX className="w-4 h-4 sm:w-5 sm:h-5" />
                            </motion.button>
                        </div>

                        {/* Form with responsive layout */}
                        <form onSubmit={handleSubmit} className="flex flex-col h-full">
                            <div className="px-4 sm:px-8 py-4 sm:py-6 flex flex-col md:flex-row gap-6 sm:gap-8 relative flex-grow overflow-y-auto">
                                {/* Image upload - smaller on mobile */}
                                <div className="md:w-1/3 flex justify-center md:block">
                                    <motion.div
                                        className={`relative ${isMobile ? 'w-48 h-48' : 'aspect-square w-full'} rounded-2xl overflow-hidden flex items-center justify-center ${
                                            previewImage
                                                ? 'bg-gray-100 dark:bg-gray-800'
                                                : 'bg-purple-50 dark:bg-purple-900/20 border-2 border-dashed border-purple-300 dark:border-purple-700'
                                        }`}
                                        whileHover={{ scale: 1.02 }}
                                        transition={{ type: "spring", stiffness: 400, damping: 25 }}
                                    >
                                        {previewImage ? (
                                            <div className="relative w-full h-full group">
                                                <img
                                                    src={previewImage}
                                                    alt="Превью обложки"
                                                    className="w-full h-full object-cover"
                                                />
                                                <div className="absolute inset-0 bg-gradient-to-t from-purple-900/80 via-purple-800/50 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300 flex items-end justify-center pb-6 gap-4">
                                                    <motion.label
                                                        className="cursor-pointer p-2 sm:p-3 bg-white/20 backdrop-blur-sm rounded-full text-white hover:bg-white/30 transition-colors"
                                                        whileHover={{ scale: 1.1 }}
                                                        whileTap={{ scale: 0.95 }}
                                                    >
                                                        <FiCamera className="w-4 h-4 sm:w-5 sm:h-5" />
                                                        <input
                                                            type="file"
                                                            accept="image/*"
                                                            onChange={handleImageChange}
                                                            className="hidden"
                                                        />
                                                    </motion.label>
                                                    <motion.button
                                                        type="button"
                                                        onClick={handleRemoveImage}
                                                        className="p-2 sm:p-3 bg-white/20 backdrop-blur-sm rounded-full text-white hover:bg-white/30 transition-colors"
                                                        whileHover={{ scale: 1.1 }}
                                                        whileTap={{ scale: 0.95 }}
                                                    >
                                                        <FiX className="w-4 h-4 sm:w-5 sm:h-5" />
                                                    </motion.button>
                                                </div>
                                            </div>
                                        ) : (
                                            <label className="cursor-pointer flex flex-col items-center justify-center p-4 sm:p-6 text-center h-full w-full">
                                                <motion.div
                                                    className="w-16 h-16 sm:w-20 sm:h-20 rounded-full bg-purple-100 dark:bg-purple-800/30 flex items-center justify-center mb-3 sm:mb-4"
                                                    animate={{
                                                        scale: [1, 1.05, 1],
                                                    }}
                                                    transition={{
                                                        repeat: Infinity,
                                                        repeatType: "reverse",
                                                        duration: 2,
                                                    }}
                                                >
                                                    <FiImage className="w-8 h-8 sm:w-10 sm:h-10 text-purple-600 dark:text-purple-400" />
                                                </motion.div>
                                                <span className="text-sm sm:text-base font-medium text-purple-700 dark:text-purple-300 mb-1 sm:mb-2">Добавить обложку</span>
                                                <span className="text-xs sm:text-sm text-purple-600/70 dark:text-purple-400/70">Нажмите для выбора</span>
                                                <input
                                                    type="file"
                                                    accept="image/*"
                                                    onChange={handleImageChange}
                                                    className="hidden"
                                                />
                                            </label>
                                        )}
                                    </motion.div>
                                </div>

                                {/* Form fields */}
                                <div className="md:w-2/3 flex flex-col gap-4 sm:gap-6">
                                    {/* Name Field */}
                                    <div>
                                        <label className="block text-sm sm:text-base font-medium text-gray-700 dark:text-gray-300 mb-1 sm:mb-2">
                                            Название плейлиста
                                        </label>
                                        <motion.div
                                            whileHover={{ scale: 1.01 }}
                                            whileTap={{ scale: 0.99 }}
                                        >
                                            <input
                                                type="text"
                                                required
                                                value={name}
                                                onChange={(e) => setName(e.target.value)}
                                                className="w-full px-3 sm:px-4 py-2 sm:py-3 bg-purple-50 dark:bg-purple-900/20 border border-purple-200 dark:border-purple-700 rounded-xl text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-purple-500 dark:focus:ring-purple-400 focus:border-transparent transition-all"
                                                placeholder="Мой новый плейлист"
                                            />
                                        </motion.div>
                                    </div>

                                    {/* Description Field */}
                                    <div>
                                        <label className="block text-sm sm:text-base font-medium text-gray-700 dark:text-gray-300 mb-1 sm:mb-2">
                                            Описание
                                        </label>
                                        <motion.div
                                            whileHover={{ scale: 1.01 }}
                                            whileTap={{ scale: 0.99 }}
                                        >
                                            <textarea
                                                value={description}
                                                onChange={(e) => setDescription(e.target.value)}
                                                className="w-full px-3 sm:px-4 py-2 sm:py-3 bg-purple-50 dark:bg-purple-900/20 border border-purple-200 dark:border-purple-700 rounded-xl text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-purple-500 dark:focus:ring-purple-400 focus:border-transparent transition-all"
                                                rows={isMobile ? "3" : "4"}
                                                placeholder="Расскажите о вашем плейлисте..."
                                            />
                                        </motion.div>
                                        <p className="mt-1 sm:mt-2 text-xs sm:text-sm text-purple-600/70 dark:text-purple-400/70 flex items-center">
                                            <FiHeart className="w-3 h-3 sm:w-4 sm:h-4 mr-1" />
                                            <span className="line-clamp-1">Необязательное поле. Поделитесь историей вашего плейлиста.</span>
                                        </p>
                                    </div>
                                </div>
                            </div>

                            {/* Footer with action buttons - fixed at bottom on mobile */}
                            <div className={`px-4 sm:px-8 py-4 sm:py-6 bg-purple-50 dark:bg-purple-900/20 flex ${isMobile ? 'flex-col' : 'justify-end'} gap-3 sm:gap-4 ${isMobile ? 'rounded-none' : 'rounded-b-3xl'} ${isMobile ? 'mt-auto' : ''}`}>
                                {isMobile ? (
                                    <>
                                        <motion.button
                                            type="submit"
                                            disabled={isSubmitting}
                                            className="w-full px-4 sm:px-6 py-3 bg-gradient-to-r from-purple-600 to-purple-500 text-white rounded-xl shadow-lg shadow-purple-500/20 disabled:opacity-70 disabled:pointer-events-none flex items-center justify-center gap-2"
                                            whileHover={{
                                                scale: 1.02,
                                                boxShadow: "0 8px 15px -3px rgba(168, 85, 247, 0.4)",
                                                backgroundImage: "linear-gradient(to right, #9333ea, #a855f7)"
                                            }}
                                            whileTap={{ scale: 0.98 }}
                                        >
                                            {isSubmitting ? (
                                                <>
                                                    <svg className="animate-spin -ml-1 mr-2 h-4 w-4 sm:h-5 sm:w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                    </svg>
                                                    Создание...
                                                </>
                                            ) : (
                                                <>
                                                    <FiCheck className="w-4 h-4 sm:w-5 sm:h-5" />
                                                    Создать плейлист
                                                </>
                                            )}
                                        </motion.button>
                                        <motion.button
                                            type="button"
                                            onClick={onClose}
                                            className="w-full px-4 sm:px-6 py-3 text-purple-700 dark:text-purple-300 bg-white dark:bg-gray-800 rounded-xl shadow-sm border border-purple-100 dark:border-purple-800 flex items-center justify-center"
                                            whileHover={{ scale: 1.02, boxShadow: "0 4px 6px -1px rgba(168, 85, 247, 0.1)" }}
                                            whileTap={{ scale: 0.98 }}
                                        >
                                            Отмена
                                        </motion.button>
                                    </>
                                ) : (
                                    <>
                                        <motion.button
                                            type="button"
                                            onClick={onClose}
                                            className="px-4 sm:px-6 py-2 sm:py-3 text-purple-700 dark:text-purple-300 bg-white dark:bg-gray-800 rounded-xl shadow-sm border border-purple-100 dark:border-purple-800"
                                            whileHover={{ scale: 1.02, boxShadow: "0 4px 6px -1px rgba(168, 85, 247, 0.1)" }}
                                            whileTap={{ scale: 0.98 }}
                                        >
                                            Отмена
                                        </motion.button>
                                        <motion.button
                                            type="submit"
                                            disabled={isSubmitting}
                                            className="px-4 sm:px-6 py-2 sm:py-3 bg-gradient-to-r from-purple-600 to-purple-500 text-white rounded-xl shadow-lg shadow-purple-500/20 disabled:opacity-70 disabled:pointer-events-none flex items-center gap-2"
                                            whileHover={{
                                                scale: 1.02,
                                                boxShadow: "0 8px 15px -3px rgba(168, 85, 247, 0.4)",
                                                backgroundImage: "linear-gradient(to right, #9333ea, #a855f7)"
                                            }}
                                            whileTap={{ scale: 0.98 }}
                                        >
                                            {isSubmitting ? (
                                                <>
                                                    <svg className="animate-spin -ml-1 mr-2 h-4 w-4 sm:h-5 sm:w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                    </svg>
                                                    Создание...
                                                </>
                                            ) : (
                                                <>
                                                    <FiCheck className="w-4 h-4 sm:w-5 sm:h-5" />
                                                    Создать плейлист
                                                </>
                                            )}
                                        </motion.button>
                                    </>
                                )}
                            </div>
                        </form>
                    </motion.div>
                </motion.div>
            )}
        </AnimatePresence>
    );
};

export default CreatePlaylistModal;
