import React, { useState, useEffect, useRef } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { X, ArrowLeft, ArrowRight, Grid, List, Expand } from "lucide-react";

function AvatarGallery({ avatars }) {
    // Состояние модального окна:
    // null – модальное окно закрыто,
    // "fullscreen" – полноэкранный режим просмотра,
    // "gallery" – режим галереи (миниатюры)
    const [modalMode, setModalMode] = useState(null);
    // Выбранный индекс картинки
    const [selectedIndex, setSelectedIndex] = useState(0);
    // Режим отображения в галерее: "grid" или "list"
    const [galleryView, setGalleryView] = useState("grid");
    // Определяем, мобильное ли устройство (ширина окна меньше 768px)
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    // Для отслеживания координаты начала свайпа
    const touchStartX = useRef(null);

    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth < 768);
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    // Блокировка прокрутки фона при открытом модальном окне
    useEffect(() => {
        if (modalMode) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "auto";
        }
        return () => (document.body.style.overflow = "auto");
    }, [modalMode]);

    const openFullscreen = (index) => {
        setSelectedIndex(index);
        setModalMode("fullscreen");
    };

    const openGallery = (index = 0) => {
        setSelectedIndex(index);
        setModalMode("gallery");
    };

    const closeModal = () => setModalMode(null);

    const goNext = () =>
        setSelectedIndex((prev) => (prev < avatars.length - 1 ? prev + 1 : 0));
    const goPrev = () =>
        setSelectedIndex((prev) => (prev > 0 ? prev - 1 : avatars.length - 1));

    useEffect(() => {
        const handleKeyDown = (e) => {
            if (modalMode === "fullscreen") {
                if (e.key === "ArrowRight") goNext();
                if (e.key === "ArrowLeft") goPrev();
            }
            if (modalMode && e.key === "Escape") closeModal();
        };
        window.addEventListener("keydown", handleKeyDown);
        return () => window.removeEventListener("keydown", handleKeyDown);
    }, [modalMode, avatars]);

    if (!avatars || avatars.length === 0) {
        return (
            <div className="container mx-auto mt-4 p-4 rounded-lg bg-neutral-50 dark:bg-neutral-800">
                <h3 className="text-xl font-semibold text-neutral-800 dark:text-neutral-100 mb-4">
                    Фото
                </h3>
                <p className="text-neutral-500 dark:text-neutral-300">
                    Нет доступных фотографий
                </p>
            </div>
        );
    }

    const getImageSrc = (avatar) => {
        const path = typeof avatar === "string" ? avatar : avatar.avatar_path;
        return `${process.env.REACT_APP_BASE_URL}/storage/${path}`;
    };

    // Обработка начала свайпа
    const handleTouchStart = (e) => {
        touchStartX.current = e.touches[0].clientX;
    };

    // Обработка окончания свайпа
    const handleTouchEnd = (e) => {
        const touchEndX = e.changedTouches[0].clientX;
        const swipeThreshold = 50; // минимальная дистанция для срабатывания свайпа
        if (touchStartX.current - touchEndX > swipeThreshold) {
            goNext();
        } else if (touchEndX - touchStartX.current > swipeThreshold) {
            goPrev();
        }
    };

    return (
        <div className="max-w-4xl mx-auto p-4 rounded-lg">
            <h3 className="text-xl font-semibold text-gray-800 dark:text-gray-100 mb-4">
                Фото
            </h3>

            {/* Превью – показываем до 3-х картинок */}
            <div className="grid grid-cols-3 gap-2">
                {avatars.slice(0, 3).map((avatar, index) => (
                    <div
                        key={index}
                        className="relative group overflow-hidden rounded-lg shadow-md cursor-pointer hover:scale-105 transition-transform"
                        onClick={() => openFullscreen(index)}
                    >
                        <img
                            src={getImageSrc(avatar)}
                            alt={`Фото ${index + 1}`}
                            className="w-full h-32 sm:h-48 object-cover"
                        />
                        <div className="absolute inset-0 bg-black bg-opacity-0 group-hover:bg-opacity-40 flex items-center justify-center transition">
                            <Expand
                                size={24}
                                className="text-white opacity-0 group-hover:opacity-100 transition"
                            />
                        </div>
                    </div>
                ))}
            </div>

            {/* Кнопка "Показать все", если фотографий больше 3 */}
            {avatars.length > 3 && (
                <button
                    className="mt-4 flex items-center gap-2 text-gray-600 dark:text-gray-300 hover:text-gray-800 dark:hover:text-gray-100 transition"
                    onClick={() => openGallery(0)}
                >
                    <Grid size={20} />
                    Показать все ({avatars.length})
                </button>
            )}

            {/* Модальное окно */}
            <AnimatePresence>
                {modalMode && (
                    <motion.div
                        className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-90 z-50 p-4"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        onClick={closeModal}
                    >
                        {/* Модальное окно для полноэкранного просмотра фото */}
                        {modalMode === "fullscreen" && (
                            <motion.div
                                className="relative w-full max-w-4xl"
                                initial={{ scale: 0.95 }}
                                animate={{ scale: 1 }}
                                exit={{ scale: 0.95 }}
                                onClick={(e) => e.stopPropagation()}
                            >
                                <div
                                    className="relative"
                                    onTouchStart={isMobile ? handleTouchStart : undefined}
                                    onTouchEnd={isMobile ? handleTouchEnd : undefined}
                                >
                                    <img
                                        src={getImageSrc(avatars[selectedIndex])}
                                        alt={`Фото ${selectedIndex + 1}`}
                                        className="w-full max-h-[80vh] object-contain rounded-lg"
                                    />
                                    {/* На десктопе показываем стрелки навигации */}
                                    {!isMobile && avatars.length > 1 && (
                                        <>
                                            <button
                                                className="absolute inset-y-0 left-0 flex items-center justify-center w-16 bg-gradient-to-r from-neutral-900 to-transparent hover:from-neutral-900/70 transition"
                                                onClick={goPrev}
                                            >
                                                <ArrowLeft size={32} className="text-white" />
                                            </button>
                                            <button
                                                className="absolute inset-y-0 right-0 flex items-center justify-center w-16 bg-gradient-to-l from-neutral-900 to-transparent hover:from-neutral-900/70 transition"
                                                onClick={goNext}
                                            >
                                                <ArrowRight size={32} className="text-white" />
                                            </button>
                                        </>
                                    )}
                                </div>
                                {/* Панель управления модальным окном */}
                                <div className="flex justify-between items-center mt-4">
                                    <button
                                        onClick={closeModal}
                                        className="bg-gray-200 dark:bg-neutral-600 w-8 h-8 rounded-full flex items-center justify-center hover:bg-gray-300 dark:hover:bg-neutral-700 transition duration-200"
                                    >
                                        <X size={24} className="text-gray-700 dark:text-gray-300" />
                                    </button>
                                    <button
                                        onClick={() => setModalMode("gallery")}
                                        className="bg-gray-200 dark:bg-neutral-600 px-4 py-1 dark:text-gray-300 rounded-full flex items-center justify-center hover:bg-gray-300 dark:hover:bg-neutral-700 transition duration-200"
                                    >
                                        Галерея
                                    </button>
                                </div>
                            </motion.div>
                        )}

                        {/* Режим галереи с миниатюрами */}
                        {modalMode === "gallery" && (
                            <motion.div
                                className="relative w-full max-w-5xl bg-white dark:bg-neutral-800 p-6 rounded-lg shadow-lg max-h-[90vh] overflow-y-auto"
                                initial={{ scale: 0.9 }}
                                animate={{ scale: 1 }}
                                exit={{ scale: 0.9 }}
                                onClick={(e) => e.stopPropagation()}
                            >
                                <div className="flex justify-between items-center mb-4">
                                    <h3 className="text-xl font-semibold text-gray-800 dark:text-gray-100">
                                        Все фото
                                    </h3>
                                    <div className="flex items-center gap-2">
                                        <button
                                            className={`p-2 rounded-full ${
                                                galleryView === "grid"
                                                    ? "bg-gray-300 dark:bg-neutral-600"
                                                    : "bg-transparent"
                                            } transition`}
                                            onClick={() => setGalleryView("grid")}
                                        >
                                            <Grid
                                                size={20}
                                                className="text-gray-800 dark:text-gray-200"
                                            />
                                        </button>
                                        <button
                                            className={`p-2 rounded-full ${
                                                galleryView === "list"
                                                    ? "bg-gray-300 dark:bg-neutral-600"
                                                    : "bg-transparent"
                                            } transition`}
                                            onClick={() => setGalleryView("list")}
                                        >
                                            <List
                                                size={20}
                                                className="text-gray-800 dark:text-gray-200"
                                            />
                                        </button>
                                        <button
                                            className="bg-gray-200 dark:bg-neutral-600 w-8 h-8 rounded-full flex items-center justify-center hover:bg-gray-300 dark:hover:bg-neutral-700 transition duration-200"
                                            onClick={closeModal}
                                        >
                                            <X
                                                size={24}
                                                className="text-gray-700 dark:text-gray-300"
                                            />
                                        </button>
                                    </div>
                                </div>
                                <div
                                    className={
                                        galleryView === "grid"
                                            ? "grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-2 sm:gap-3"
                                            : "flex flex-col gap-2"
                                    }
                                >
                                    {avatars.map((avatar, index) => (
                                        <img
                                            key={index}
                                            src={getImageSrc(avatar)}
                                            alt={`Фото ${index + 1}`}
                                            className={
                                                galleryView === "grid"
                                                    ? "w-full h-full object-cover rounded-lg cursor-pointer hover:scale-105 transition"
                                                    : "w-full h-32 object-cover rounded-lg cursor-pointer hover:scale-105 transition"
                                            }
                                            onClick={() => openFullscreen(index)}
                                        />
                                    ))}
                                </div>
                            </motion.div>
                        )}
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
}

export default AvatarGallery;
