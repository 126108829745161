import React, { useState } from 'react';
import UpdateAvatar from './UpdateAvatar';
import ImageModal from './ImageModal';

function ProfileAvatar({ user, showModal, setShowModal }) {
    const defaultAvatar = `https://ui-avatars.com/api/?name=${user.name}&background=random`;
    const avatarSrc = user.avatar ? `${process.env.REACT_APP_BASE_URL}/storage/${user.avatar}` : defaultAvatar;

    const [isImageModalOpen, setImageModalOpen] = useState(false);
    const [modalImageSrc, setModalImageSrc] = useState(null);

    const openModal = () => setShowModal(true);
    const closeModal = () => setShowModal(false);
    const openImageModal = (src) => {
        setModalImageSrc(src);
        setImageModalOpen(true);
    };
    const closeImageModal = () => setImageModalOpen(false);

    return (
        <div className="flex flex-col items-center p-4 dark:bg-neutral-900 rounded-lg">
            {showModal && <UpdateAvatar user={user} closeModal={closeModal}/>}
            {isImageModalOpen && (
                <ImageModal src={modalImageSrc} alt={user.name} onClose={closeImageModal}/>
            )}

            {/* Текущий аватар с кнопкой обновления */}
            <div className="relative mb-4">
                <img
                    src={avatarSrc}
                    alt={user.name}
                    className="w-40 h-40 object-cover rounded-full cursor-pointer"
                    onClick={() => openImageModal(
                        user.avatar
                            ? `${process.env.REACT_APP_BASE_URL}/storage/${user.avatar}`
                            : defaultAvatar
                    )}
                />
                <button
                    onClick={openModal}
                    className="absolute bottom-0 right-0 bg-violet-600 text-white rounded-full w-7 h-7 shadow-md transition duration-300 hover:bg-violet-700"
                >
                    <i className="fas fa-plus"></i>
                </button>
            </div>
        </div>
    );
}

export default ProfileAvatar;
