import React from "react";
import { Link } from "react-router-dom";
import BackLink from "./BackLink";
import Aside from "./Aside";

export default function Settings() {
    return (
        <div className="flex container mx-auto p-2 pb-28">
            <Aside/>
            <div className="container mx-auto pt-16">
                <BackLink/>
                <h1 className="text-3xl font-extrabold dark:text-white">Настройки</h1>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-6">
                    {[
                        {
                            src: "https://lottie.host/embed/8e1366d6-2366-4ef6-9994-3e3442c81c81/lFVf0xaI2S.json",
                            title: "Настройки аккаунта",
                            description: "Здесь вы можете изменить вашу почту и пароль.",
                            link: "/settings/change-password",
                            linkText: "Изменить данные"
                        },
                        {
                            src: "https://lottie.host/embed/9fbf5235-a134-4029-a977-8e1f9d6530f9/91nixheEDS.json",
                            title: "Удаление аккаунта",
                            description: "Здесь вы можете удалить аккаунт.",
                            link: "/settings/delete-account",
                            linkText: "Удалить аккаунт"
                        },
                        {
                            src: "https://lottie.host/embed/31d10123-094a-4013-9743-e8156e1697cf/ZuYE7HQSP7.json",
                            title: "Связаться с поддержкой",
                            description: "Здесь вы можете связаться с поддержкой.",
                            link: "/settings/contact-support",
                            linkText: "Связаться с поддержкой"
                        },
                        {
                            src: "https://lottie.host/embed/c4b0a85e-c70e-4227-aa7a-6d389afb87a5/Df3GPigWPY.json",
                            title: "Сообщить о проблеме",
                            description: "Сообщить о проблеме здесь.",
                            link: "/settings/report-issue",
                            linkText: "Сообщить о проблеме"
                        },
                        {
                            src: "https://lottie.host/embed/cbffa88f-731b-4a6b-81df-fd2321e1bd61/YpaQXhUswZ.json",
                            title: "Язык",
                            description: "Здесь вы можете изменить язык приложения.",
                            link: "/settings/change-language",
                            linkText: "Изменить язык",
                            badge: "Скоро"
                        }
                    ].map((item, index) => (
                        <div key={index}
                             className="bg-white p-3 md:p-4 rounded-xl shadow-md flex flex-col justify-between dark:bg-neutral-800">
                            <iframe src={item.src} className="w-auto h-24 md:h-56 rounded-lg" loading="eager"></iframe>
                            <div className="flex flex-col justify-between mt-3">
                                <div className="flex items-center justify-between">
                                    <h2 className="text-xl font-semibold text-gray-800 dark:text-white">
                                        {item.title}
                                    </h2>
                                    {item.badge && (
                                        <span
                                            className="text-red-100 dark:text-red-500 font-semibold text-sm rounded-full px-2 bg-red-500 dark:bg-red-200">{item.badge}</span>
                                    )}
                                </div>
                                <p className="text-sm text-gray-600 dark:text-gray-300 mb-3">
                                    {item.description}
                                </p>
                                <Link
                                    to={item.link}
                                    className="text-sm font-medium text-violet-500 hover:text-violet-600 transition"
                                >
                                    {item.linkText}
                                    <i className="fas fa-chevron-right ml-2"></i>
                                </Link>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}