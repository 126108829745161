import React, { createContext, useState, useContext, useRef, useEffect, useCallback } from "react";

const PlayerContext = createContext();

export const usePlayer = () => useContext(PlayerContext);

export const PlayerProvider = ({ children, tracks }) => {
    const audioRef = useRef(new Audio());
    const [isPlaying, setIsPlaying] = useState(false);
    const [progress, setProgress] = useState(0);
    const [duration, setDuration] = useState(0);
    const [volume, setVolume] = useState(() => {
        // Восстанавливаем громкость из localStorage или используем значение по умолчанию
        const savedVolume = localStorage.getItem('playerVolume');
        return savedVolume ? parseFloat(savedVolume) : 0.8;
    });
    const [buffered, setBuffered] = useState(0);
    const [isShuffling, setIsShuffling] = useState(false);
    const [isRepeating, setIsRepeating] = useState(false);
    const [tracksState, setTracksState] = useState(tracks || []);
    const [playHistory, setPlayHistory] = useState([]);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    // Refs для предотвращения проблем с замыканиями в эффектах
    const isRepeatingRef = useRef(isRepeating);
    const isShufflingRef = useRef(isShuffling);
    const tracksRef = useRef(tracksState);
    const currentTrackRef = useRef(null);
    const volumeRef = useRef(volume);

    // Обновляем refs при изменении соответствующих состояний
    useEffect(() => {
        tracksRef.current = tracksState;
    }, [tracksState]);

    useEffect(() => {
        if (tracks) {
            setTracksState(tracks);
        }
    }, [tracks]);

    useEffect(() => {
        isRepeatingRef.current = isRepeating;
        isShufflingRef.current = isShuffling;
    }, [isRepeating, isShuffling]);

    useEffect(() => {
        volumeRef.current = volume;
        // Сохраняем громкость в localStorage
        localStorage.setItem('playerVolume', volume.toString());
    }, [volume]);

    // Инициализация аудио элемента
    useEffect(() => {
        const audio = audioRef.current;

        // Устанавливаем начальную громкость
        audio.volume = volume;

        // Обработчик окончания трека
        const handleEnded = () => {
            if (isRepeatingRef.current) {
                audio.currentTime = 0;
                audio.play().catch(err => {
                    console.error("Ошибка при повторном воспроизведении:", err);
                    setError("Не удалось повторно воспроизвести трек");
                });
            } else {
                skipToNextTrack();
            }
        };

        // Обработчик ошибок
        const handleError = (e) => {
            console.error("Ошибка аудио:", e);
            setError("Ошибка воспроизведения аудио");
            setIsLoading(false);

            // Автоматически пробуем следующий трек при ошибке
            if (currentTrackRef.current) {
                setTimeout(() => skipToNextTrack(), 1000);
            }
        };

        // Обработчик для восстановления воспроизведения после потери фокуса
        const handleVisibilityChange = () => {
            if (document.visibilityState === 'visible' && isPlaying) {
                // Пытаемся восстановить воспроизведение
                audio.play().catch(err => {
                    console.warn("Не удалось автоматически возобновить воспроизведение:", err);
                });
            }
        };

        audio.addEventListener("ended", handleEnded);
        audio.addEventListener("error", handleError);
        document.addEventListener("visibilitychange", handleVisibilityChange);

        return () => {
            audio.removeEventListener("ended", handleEnded);
            audio.removeEventListener("error", handleError);
            document.removeEventListener("visibilitychange", handleVisibilityChange);
        };
    }, []);

    // Мемоизированные функции для управления плеером
    const pauseTrack = useCallback(() => {
        const audio = audioRef.current;
        audio.pause();
        setIsPlaying(false);
    }, []);

    const playTrack = useCallback((track, playlist = []) => {
        if (!track || !track.file_path) {
            setError("Трек не содержит путь к файлу");
            return;
        }

        const audio = audioRef.current;

        // Если передан новый плейлист - обновляем список треков
        if (playlist.length) {
            setTracksState(playlist);
            tracksRef.current = playlist;
        }

        // Если пытаемся играть текущий трек - toggle пауза/воспроизведение
        if (currentTrackRef.current?.id === track.id) {
            return togglePlayPause();
        }

        // Сбрасываем ошибку при попытке воспроизвести новый трек
        setError(null);
        setIsLoading(true);

        // Логика загрузки нового трека
        audio.pause();
        audio.currentTime = 0;
        currentTrackRef.current = track;

        // Добавляем трек в историю воспроизведения
        addToHistory(track);

        // Полный URL к аудио файлу
        const audioUrl = `${process.env.REACT_APP_BASE_URL}/storage/${track.file_path}`;

        // Напрямую устанавливаем источник аудио без предварительной проверки
        // Это решает проблему с CORS при использовании fetch
        try {
            audio.src = audioUrl;
            audio.load();

            audio.onloadeddata = () => {
                setIsLoading(false);
                audio.play()
                    .then(() => setIsPlaying(true))
                    .catch((err) => {
                        console.error("Ошибка воспроизведения:", err);
                        setError("Не удалось воспроизвести трек");
                        setIsLoading(false);
                    });
            };

            audio.onerror = (e) => {
                console.error("Ошибка загрузки аудио:", e);
                setError("Не удалось загрузить аудио файл");
                setIsLoading(false);
                setTimeout(() => skipToNextTrack(), 1000);
            };
        } catch (err) {
            console.error("Ошибка при установке источника аудио:", err);
            setError(`Ошибка при загрузке трека: ${err.message}`);
            setIsLoading(false);
            setTimeout(() => skipToNextTrack(), 1000);
        }
    }, []);

    const togglePlayPause = useCallback(() => {
        const audio = audioRef.current;

        if (!currentTrackRef.current) {
            // Если нет текущего трека, пытаемся воспроизвести первый трек из списка
            if (tracksRef.current.length > 0) {
                return playTrack(tracksRef.current[0]);
            }
            return;
        }

        if (isPlaying) {
            audio.pause();
            setIsPlaying(false);
        } else {
            setError(null); // Сбрасываем ошибку при попытке воспроизведения
            audio.play()
                .then(() => setIsPlaying(true))
                .catch(err => {
                    console.error("Ошибка при возобновлении воспроизведения:", err);
                    setError("Не удалось возобновить воспроизведение");
                });
        }
    }, [isPlaying, playTrack]);

    const setAudioVolume = useCallback((value) => {
        const audio = audioRef.current;
        // Ограничиваем значение в диапазоне 0-1
        const safeValue = Math.max(0, Math.min(1, value));
        audio.volume = safeValue;
        setVolume(safeValue);
    }, []);

    const skipToNextTrack = useCallback(() => {
        const tracks = tracksRef.current;
        if (!tracks?.length) return;

        const currentId = currentTrackRef.current?.id;
        let nextTrack;

        if (!currentTrackRef.current) {
            nextTrack = tracks[0];
        } else if (isShufflingRef.current) {
            // При перемешивании выбираем случайный трек, исключая текущий
            const availableTracks = tracks.filter((t) => t.id !== currentId);
            nextTrack = availableTracks.length > 0
                ? availableTracks[Math.floor(Math.random() * availableTracks.length)]
                : tracks[0];
        } else {
            // Последовательное воспроизведение
            const currentIndex = tracks.findIndex((t) => t.id === currentId);
            nextTrack = currentIndex < tracks.length - 1 ? tracks[currentIndex + 1] : tracks[0];
        }

        if (nextTrack) {
            playTrack(nextTrack);
        }
    }, [playTrack]);

    const skipToPreviousTrack = useCallback(() => {
        const tracks = tracksRef.current;
        if (!tracks?.length) return;

        const audio = audioRef.current;

        // Если прошло менее 3 секунд воспроизведения, переходим к предыдущему треку
        // Иначе перематываем текущий трек в начало
        if (audio.currentTime > 3) {
            audio.currentTime = 0;
            return;
        }

        const currentIndex = tracks.findIndex((t) => t.id === currentTrackRef.current?.id);
        if (currentIndex === -1) {
            // Если текущий трек не найден, воспроизводим первый трек
            playTrack(tracks[0]);
        } else {
            // Переходим к предыдущему треку или к последнему, если текущий - первый
            const prevIndex = (currentIndex - 1 + tracks.length) % tracks.length;
            playTrack(tracks[prevIndex]);
        }
    }, [playTrack]);

    const seekTrack = useCallback((time) => {
        const audio = audioRef.current;
        if (!audio.duration) return;

        // Ограничиваем время в пределах длительности трека
        const safeTime = Math.max(0, Math.min(time, audio.duration - 0.1));
        audio.currentTime = safeTime;
    }, []);

    const toggleShuffle = useCallback(() => {
        setIsShuffling(prev => !prev);
    }, []);

    const toggleRepeat = useCallback(() => {
        setIsRepeating(prev => !prev);
    }, []);

    // Обновление прогресса и буфера воспроизведения
    useEffect(() => {
        const audio = audioRef.current;

        const updateProgress = () => {
            if (audio.duration) {
                setProgress(audio.currentTime / audio.duration);
            }
            setBuffered(getBufferedProgress(audio));
        };

        const updateDuration = () => {
            setDuration(audio.duration || 0);
        };

        audio.addEventListener("timeupdate", updateProgress);
        audio.addEventListener("durationchange", updateDuration);
        audio.addEventListener("progress", updateProgress);

        return () => {
            audio.removeEventListener("timeupdate", updateProgress);
            audio.removeEventListener("durationchange", updateDuration);
            audio.removeEventListener("progress", updateProgress);
        };
    }, []);

    // Вспомогательные функции
    const getBufferedProgress = (audio) => {
        if (!audio.buffered.length || !audio.duration) return 0;
        return Math.min(1, audio.buffered.end(audio.buffered.length - 1) / audio.duration);
    };

    const addToHistory = useCallback((track) => {
        if (!track) return;
        setPlayHistory(prev => [track, ...prev.filter(t => t.id !== track.id)].slice(0, 10));
    }, []);

    const playRandomTrack = useCallback(() => {
        const tracks = tracksRef.current;
        if (tracks.length > 0) {
            const randomTrack = tracks[Math.floor(Math.random() * tracks.length)];
            playTrack(randomTrack);
        }
    }, [playTrack]);

    const stopPlayback = useCallback(() => {
        const audio = audioRef.current;
        audio.pause();
        audio.currentTime = 0;
        setIsPlaying(false);
        setProgress(0);
        setDuration(0);
        setBuffered(0);
        setError(null);
        currentTrackRef.current = null;
    }, []);

    // Функция для перемотки вперед/назад
    const seekRelative = useCallback((seconds) => {
        const audio = audioRef.current;
        if (!audio.duration) return;

        const newTime = Math.max(0, Math.min(audio.currentTime + seconds, audio.duration - 0.1));
        audio.currentTime = newTime;
    }, []);

    // Функция для изменения скорости воспроизведения
    const setPlaybackRate = useCallback((rate) => {
        const audio = audioRef.current;
        audio.playbackRate = rate;
    }, []);

    return (
        <PlayerContext.Provider
            value={{
                tracks: tracksState,
                setTracks: setTracksState,
                currentTrack: currentTrackRef.current,
                setCurrentTrack: (track) => (currentTrackRef.current = track),
                isPlaying,
                progress,
                duration,
                buffered,
                volume,
                isShuffling,
                isRepeating,
                error,
                isLoading,
                playTrack,
                pauseTrack,
                togglePlayPause,
                setAudioVolume,
                skipToNextTrack,
                skipToPreviousTrack,
                seekTrack,
                seekRelative,
                toggleShuffle,
                toggleRepeat,
                playHistory,
                addToHistory,
                playRandomTrack,
                stopPlayback,
                setPlaybackRate
            }}
        >
            {children}
        </PlayerContext.Provider>
    );
};
