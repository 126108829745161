import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import useWindowSize from "../hooks/useWindowSize";
import BottomNav from "./BottomNav";
import BackLink from "./BackLink";
import { debounce } from "lodash";
import { AiOutlineClose, AiOutlineSearch } from "react-icons/ai";
import {HiUserGroup, HiNewspaper, HiMusicNote, HiPlay, HiViewGrid} from "react-icons/hi";
import { ImProfile } from "react-icons/im";
import EmptyState from "./EmptyState";
import {formatArtists, formatDuration} from "./utils/helpers";
import { usePlayer } from "../context/PlayerContext";
import {HiPlayCircle} from "react-icons/hi2";
import Aside from "./Aside";

const Search = () => {
    const [searchResults, setSearchResults] = useState({
        users: [],
        posts: [],
        groups: [],
        tracks: []
    });

    const [searchValue, setSearchValue] = useState('');
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const { width } = useWindowSize();
    const [showSearchResults, setShowSearchResults] = useState(false);
    const { playTrack, currentTrack } = usePlayer();

    const handleSearchDebounced = debounce(async () => {
        // Добавляем проверку длины запроса
        if (searchValue.trim().length < 1) {
            setSearchResults({ users: [], posts: [], groups: [], tracks: [] });
            setShowSearchResults(false);
            return;
        }

        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/search`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
                params: {
                    query: searchValue,
                    category: selectedCategory,
                },
            });
            setSearchResults(response.data);
            setShowSearchResults(true);
        } catch (error) {
            console.error(error);
            // Добавляем обработку ошибок
            if (error.response?.status === 400) {
                setShowSearchResults(true);
            }
        }
    }, 500);

    useEffect(() => {
        handleSearchDebounced();
        return () => handleSearchDebounced.cancel();
    }, [searchValue, selectedCategory]);

    const handleCategoryClick = (category) => {
        setSelectedCategory(category);
    };

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/search/categories`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                    },
                });
                setCategories(response.data);
            } catch (error) {
                console.error(error);
            }
        };
        fetchCategories();
    }, []);

    const categoryNames = {
        profile: 'Пользователи',
        posts: 'Посты',
        group: 'Группы',
        tracks: 'Треки',
    };

    const isEmptyResults = Object.values(searchResults).every(result => result.length === 0);

    const clearSearch = () => {
        setSearchValue('');
    };

    return (
        <div className="flex container mx-auto p-2">
            <Aside/>
            <div className="container mx-auto relative dark:bg-neutral-900 min-h-screen pt-16">
                {width < 768 && <BottomNav/>}
                <BackLink/>

                <div className="md:pt-4">
                    <h2 className="md:block text-3xl font-bold text-gray-900 mb-5 dark:text-white">
                        Найдите музыку, людей и сообщества
                    </h2>

                    {/* Поисковая строка */}
                    <div className="sticky top-16 md:top-4 bg-white dark:bg-neutral-800 shadow-sm py-4 px-4 z-10 rounded-full
                    border border-neutral-100 dark:border-neutral-700">
                        <form onSubmit={(e) => e.preventDefault()} className="flex items-center gap-3">
                            <AiOutlineSearch className="text-gray-500 dark:text-gray-400 ml-2 flex-shrink-0" size={20}/>
                            <input
                                type="text"
                                value={searchValue}
                                onChange={(e) => setSearchValue(e.target.value)}
                                placeholder="Поиск треков, исполнителей, пользователей..."
                                className="flex-1 bg-transparent px-2 py-1.5
                                text-gray-900 dark:text-white focus:outline-none
                                placeholder-gray-400 dark:placeholder-neutral-500 text-sm md:text-base"
                            />
                            {searchValue && (
                                <button
                                    type="button"
                                    onClick={clearSearch}
                                    className="p-1.5 rounded-full bg-gray-100 dark:bg-neutral-700
                                    hover:bg-gray-200 dark:hover:bg-neutral-600 transition-colors"
                                >
                                    <AiOutlineClose className="text-gray-500 dark:text-gray-400" size={18}/>
                                </button>
                            )}
                        </form>
                    </div>

                    {/* Категории */}
                    <div className="py-4">
                        <div className="overflow-x-auto pb-2 scrollbar-hide">
                            <div className="flex gap-2 w-max">
                                {/* Кнопка "Все" */}
                                <button
                                    onClick={() => handleCategoryClick(null)}
                                    className={`py-2 px-4 rounded-full font-medium transition-all text-sm
        flex items-center gap-2 ${
                                        !selectedCategory
                                            ? 'bg-violet-600 text-white shadow-md dark:bg-violet-700'
                                            : 'bg-gray-100 dark:bg-neutral-700 text-gray-700 dark:text-neutral-200 hover:bg-gray-200 dark:hover:bg-neutral-600'
                                    }`}
                                >
                                    <HiViewGrid size={16}
                                                className={!selectedCategory ? 'text-white' : 'text-current'}/>
                                    Все
                                </button>

                                {categories.map((category) => {
                                    const getIcon = () => {
                                        switch (category.id) {
                                            case 'users':
                                                return <ImProfile size={16}/>;
                                            case 'posts':
                                                return <HiNewspaper size={16}/>;
                                            case 'groups':
                                                return <HiUserGroup size={16}/>;
                                            case 'tracks':
                                                return <HiMusicNote size={16}/>;
                                            default:
                                                return null;
                                        }
                                    };

                                    return (
                                        <button
                                            key={category.id}
                                            onClick={() => handleCategoryClick(category.id)}
                                            className={`py-2 px-4 rounded-full font-medium transition-all text-sm
            flex items-center gap-2 ${
                                                selectedCategory === category.id
                                                    ? 'bg-violet-600 text-white shadow-md dark:bg-violet-700'
                                                    : 'bg-gray-100 dark:bg-neutral-700 text-gray-700 dark:text-neutral-200 hover:bg-gray-200 dark:hover:bg-neutral-600'
                                            }`}
                                        >
          <span className={selectedCategory === category.id ? 'text-white' : 'text-current'}>
            {getIcon()}
          </span>
                                            {category.name}
                                        </button>
                                    );
                                })}
                            </div>
                        </div>

                        {/* Результаты поиска */}
                        {showSearchResults ? (
                            isEmptyResults ? (
                                <EmptyState
                                    title="Ничего не найдено"
                                    description="Попробуйте изменить поисковый запрос или выбрать другую категорию"
                                    icon={<AiOutlineSearch size={40} className="text-gray-400"/>}
                                />
                            ) : (
                                <div className="space-y-4 mt-4">
                                    {Object.entries(searchResults).map(([key, results]) => (
                                        results.length > 0 && (
                                            <div key={key} className="mb-6">
                                                <div className="flex items-center gap-2 mb-4">
                                                    <div className="p-2 rounded-full bg-violet-100 dark:bg-neutral-700">
                                                        {key === 'profile' &&
                                                            <ImProfile className="text-violet-600 dark:text-violet-400"
                                                                       size={20}/>}
                                                        {key === 'posts' &&
                                                            <HiNewspaper
                                                                className="text-violet-600 dark:text-violet-400"
                                                                size={20}/>}
                                                        {key === 'group' &&
                                                            <HiUserGroup
                                                                className="text-violet-600 dark:text-violet-400"
                                                                size={20}/>}
                                                        {key === 'tracks' &&
                                                            <HiMusicNote
                                                                className="text-violet-600 dark:text-violet-400"
                                                                size={20}/>}
                                                    </div>
                                                    <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                                                        {categoryNames[key]}
                                                        <span className="text-gray-500 dark:text-gray-400 ml-2">
                                                        ({results.length})
                                                    </span>
                                                    </h3>
                                                </div>
                                                <div className="grid gap-3">
                                                    {results.map((result) => {
                                                        const isTrack = key === 'tracks';

                                                        return (
                                                            <Link
                                                                to={`/${key}/${result.id}`}
                                                                key={result.id}
                                                                onClick={(e) => {
                                                                    if (isTrack) {
                                                                        e.preventDefault();
                                                                        playTrack(result);
                                                                    }
                                                                }}
                                                                className={`group block bg-white dark:bg-neutral-800 rounded-xl p-4 shadow-sm 
                                                                  hover:shadow-md transition-all border border-transparent 
                                                                  hover:border-violet-100 dark:hover:border-neutral-700
                                                                  ${currentTrack?.id === result.id && isTrack ? 'ring-2 ring-violet-500' : ''}
                                                                  relative`}
                                                            >
                                                                <div className="flex items-center gap-4">
                                                                    {(result.avatar || result.cover_url) && (
                                                                        <div className="relative flex-shrink-0">
                                                                            <img
                                                                                src={
                                                                                    result.cover_url
                                                                                        ? result.cover_url.startsWith('http')
                                                                                            ? result.cover_url
                                                                                            : `${process.env.REACT_APP_BASE_URL}/storage/${result.cover_url}`
                                                                                        : result.avatar
                                                                                            ? `${process.env.REACT_APP_BASE_URL}/storage/${result.avatar}`
                                                                                            : 'https://ui-avatars.com/api/?name=${result.name}&background=random'
                                                                                }
                                                                                alt={result.title || result.name}
                                                                                className="w-12 h-12 rounded-lg object-cover border-2 border-white dark:border-neutral-800"
                                                                                onError={(e) => {
                                                                                    e.target.onerror = null;
                                                                                    e.target.src = 'https://via.placeholder.com/600x400.png?text=Image+Error';
                                                                                }}
                                                                            />
                                                                            {isTrack && (
                                                                                <div
                                                                                    className="absolute top-2 right-2"
                                                                                    onClick={(e) => {
                                                                                        e.stopPropagation();
                                                                                        playTrack(result);
                                                                                    }}
                                                                                >
                                                                                    {currentTrack?.id === result.id ? (
                                                                                        <HiPlay
                                                                                            className="text-violet-500"
                                                                                            size={20}/>
                                                                                    ) : (
                                                                                        <HiPlayCircle
                                                                                            className="text-gray-400 hover:text-violet-500"
                                                                                            size={20}/>
                                                                                    )}
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    )}
                                                                    <div className="flex-1 min-w-0">
                                                                        {isTrack ? (
                                                                            <>
                                                                                <p className={`text-sm font-medium truncate ${
                                                                                    currentTrack?.id === result.id
                                                                                        ? 'text-violet-600 dark:text-violet-400'
                                                                                        : 'text-gray-900 dark:text-white'
                                                                                }`}>
                                                                                    {result.title}
                                                                                </p>
                                                                                <p className="text-sm text-gray-500 dark:text-gray-400 mt-1">
                                                                                    {formatArtists(result)}
                                                                                </p>
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                <p className="text-sm font-medium text-gray-900 dark:text-white truncate">
                                                                                    {result.name || result.title}
                                                                                </p>
                                                                                {result.description && (
                                                                                    <p className="text-sm text-gray-500 dark:text-gray-400 mt-1 line-clamp-2">
                                                                                        {result.description}
                                                                                    </p>
                                                                                )}
                                                                            </>
                                                                        )}
                                                                        {result.created_at && (
                                                                            <div
                                                                                className="flex items-center gap-2 mt-2">
                                                                            <span
                                                                                className="text-xs text-gray-400 dark:text-gray-500">
                                                                              {new Date(result.created_at)
                                                                                  .toLocaleDateString('ru-RU', {
                                                                                      day: 'numeric',
                                                                                      month: 'short',
                                                                                      year: 'numeric'
                                                                                  })}
                                                                            </span>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </Link>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                        )
                                    ))}
                                </div>
                            )
                        ) : (
                            <EmptyState
                                title="Начните поиск"
                                description="Введите запрос в поле выше для поиска по платформе"
                                icon={<AiOutlineSearch size={40} className="text-gray-400"/>}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Search;