import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import NewsCard from './NewsCard';
import { AuthContext } from "../context/AuthContext";
import BackLink from "./BackLink";
import { motion } from 'framer-motion';
import NewsCardSkeleton from "./NewsCardSkeleton";
import { Plus, Search, RefreshCw, Filter, Calendar, ChevronLeft, ChevronRight } from "lucide-react";
import useWindowSize from "../hooks/useWindowSize";
import BottomNav from "./BottomNav";
import { Link } from 'react-router-dom';

const NewsList = () => {
    const [news, setNews] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [searchTerm, setSearchTerm] = useState('');
    const [isSearching, setIsSearching] = useState(false);
    const [isRefreshing, setIsRefreshing] = useState(false);
    const { user } = useContext(AuthContext);
    const { width } = useWindowSize();

    const fetchNews = async (pageNum = page, search = searchTerm) => {
        try {
            setIsLoading(true);
            const queryParams = new URLSearchParams({
                page: pageNum,
            });

            if (search) {
                queryParams.append('search', search);
            }

            const response = await axios.get(`${process.env.REACT_APP_API_URL}/news?${queryParams}`);
            setNews(response.data.data);
            setTotalPages(response.data.last_page);
            setIsLoading(false);
            setIsRefreshing(false);
        } catch (error) {
            console.error('Error fetching news:', error);
            setIsLoading(false);
            setIsRefreshing(false);
        }
    };

    useEffect(() => {
        fetchNews();
    }, [page]);

    const handleSearch = (e) => {
        e.preventDefault();
        setPage(1); // Reset to first page on new search
        fetchNews(1, searchTerm);
    };

    const handleRefresh = () => {
        setIsRefreshing(true);
        fetchNews();
    };

    const handleNextPage = () => {
        if (page < totalPages) {
            setPage(page + 1);
        }
    };

    const handlePrevPage = () => {
        if (page > 1) {
            setPage(page - 1);
        }
    };

    const containerVariants = {
        hidden: { opacity: 0 },
        visible: { opacity: 1, transition: { staggerChildren: 0.1 } }
    };

    const itemVariants = {
        hidden: { y: 20, opacity: 0 },
        visible: { y: 0, opacity: 1 }
    };

    return (
        <div className="min-h-screen bg-gray-50 dark:bg-neutral-900">
            <div className="container mx-auto p-4 pt-16 px-4 sm:px-6 lg:px-8">
                {width < 768 && <BottomNav />}
                <BackLink />

                {/* Header Section with Improved Design */}
                <div className="mb-8">
                    <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4 mb-6">
                        <div className="space-y-2">
                            <h1 className="text-2xl sm:text-3xl font-extrabold text-gray-900 dark:text-white">
                                Новости платформы
                            </h1>
                            <p className="text-gray-500 dark:text-gray-400 text-base sm:text-lg">
                                Последние обновления и важные анонсы
                            </p>
                        </div>

                        {user?.is_admin && (
                            <Link
                                to="/news/create"
                                className="flex items-center px-4 py-2 bg-gradient-to-r from-purple-600 to-indigo-600
                                text-white font-medium rounded-xl hover:from-purple-700 hover:to-indigo-700
                                shadow-md hover:shadow-lg transition-all duration-300 transform hover:scale-[1.02]"
                            >
                                <Plus className="w-5 h-5 mr-2" />
                                <span>Новая запись</span>
                            </Link>
                        )}
                    </div>

                    {/* Search and Filter Bar */}
                    <div className="flex flex-col sm:flex-row gap-4 items-center bg-white dark:bg-neutral-800 p-4 rounded-xl shadow-sm">
                        <form onSubmit={handleSearch} className="w-full sm:w-auto flex-1">
                            <div className="relative">
                                <input
                                    type="text"
                                    placeholder="Поиск новостей..."
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                    className="w-full pl-10 pr-4 py-2.5 rounded-lg border border-gray-200 dark:border-neutral-700
                                    bg-gray-50 dark:bg-neutral-900 focus:ring-2 focus:ring-purple-500/30 focus:border-purple-500
                                    dark:text-white transition-all"
                                />
                                <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 dark:text-gray-500 w-4 h-4" />
                                {searchTerm && (
                                    <button
                                        type="button"
                                        onClick={() => {
                                            setSearchTerm('');
                                            if (searchTerm) fetchNews(1, '');
                                        }}
                                        className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400
                                        hover:text-gray-600 dark:text-gray-500 dark:hover:text-gray-300"
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                            <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
                                        </svg>
                                    </button>
                                )}
                            </div>
                        </form>

                        <div className="flex items-center gap-2">
                            <button
                                onClick={handleRefresh}
                                className={`p-2.5 rounded-lg border border-gray-200 dark:border-neutral-700 
                                bg-gray-50 dark:bg-neutral-900 text-gray-500 dark:text-gray-400
                                hover:bg-gray-100 dark:hover:bg-neutral-800 transition-all ${isRefreshing ? 'animate-spin' : ''}`}
                                disabled={isRefreshing}
                                title="Обновить"
                            >
                                <RefreshCw className="w-4 h-4" />
                            </button>

                            <div className="relative group">
                                <button
                                    className="p-2.5 rounded-lg border border-gray-200 dark:border-neutral-700
                                    bg-gray-50 dark:bg-neutral-900 text-gray-500 dark:text-gray-400
                                    hover:bg-gray-100 dark:hover:bg-neutral-800 transition-all"
                                    title="Фильтры"
                                >
                                    <Filter className="w-4 h-4" />
                                </button>
                                <div className="absolute right-0 mt-2 w-48 bg-white dark:bg-neutral-800 rounded-lg shadow-lg
                                    border border-gray-100 dark:border-neutral-700 p-2 z-10 opacity-0 invisible
                                    group-hover:opacity-100 group-hover:visible transition-all duration-200">
                                    <div className="p-2 text-sm text-gray-600 dark:text-gray-300">
                                        <div className="flex items-center gap-2 mb-2">
                                            <Calendar className="w-4 h-4" />
                                            <span>Сортировка по дате</span>
                                        </div>
                                        <div className="flex flex-col gap-1 ml-6">
                                            <label className="flex items-center gap-2 cursor-pointer">
                                                <input type="radio" name="sort" value="newest" defaultChecked className="text-purple-600" />
                                                <span>Сначала новые</span>
                                            </label>
                                            <label className="flex items-center gap-2 cursor-pointer">
                                                <input type="radio" name="sort" value="oldest" className="text-purple-600" />
                                                <span>Сначала старые</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Content Section */}
                {isLoading ? (
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                        {[...Array(6)].map((_, i) => <NewsCardSkeleton key={i} />)}
                    </div>
                ) : news.length > 0 ? (
                    <>
                        <motion.div
                            variants={containerVariants}
                            initial="hidden"
                            animate="visible"
                            className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 pb-8"
                        >
                            {news.map((item) => (
                                <motion.div
                                    key={item.id}
                                    variants={itemVariants}
                                    className="transform transition-all duration-300 hover:scale-[1.02] hover:shadow-lg"
                                >
                                    <NewsCard news={item} />
                                </motion.div>
                            ))}
                        </motion.div>

                        {/* Improved Pagination */}
                        <div className="flex justify-center items-center gap-2 mt-8 pb-12">
                            <button
                                onClick={handlePrevPage}
                                disabled={page === 1}
                                className={`flex items-center justify-center w-10 h-10 rounded-lg transition-all ${
                                    page === 1
                                        ? 'bg-gray-100 dark:bg-neutral-800 text-gray-400 dark:text-gray-600 cursor-not-allowed'
                                        : 'bg-white dark:bg-neutral-800 text-gray-700 dark:text-gray-300 hover:bg-purple-50 dark:hover:bg-purple-900/20 hover:text-purple-600 dark:hover:text-purple-400 shadow-sm'
                                }`}
                            >
                                <ChevronLeft className="w-5 h-5" />
                            </button>

                            <div className="flex gap-1">
                                {Array.from({length: totalPages}, (_, i) => {
                                    // Show limited page numbers with ellipsis for better UX
                                    const pageNum = i + 1;
                                    const isCurrentPage = page === pageNum;
                                    const isFirstPage = pageNum === 1;
                                    const isLastPage = pageNum === totalPages;
                                    const isWithinRange = Math.abs(pageNum - page) <= 1;

                                    if (isFirstPage || isLastPage || isWithinRange) {
                                        return (
                                            <button
                                                key={pageNum}
                                                onClick={() => setPage(pageNum)}
                                                className={`flex items-center justify-center min-w-[40px] h-10 px-3 rounded-lg font-medium transition-all ${
                                                    isCurrentPage
                                                        ? 'bg-purple-600 text-white shadow-md'
                                                        : 'bg-white dark:bg-neutral-800 text-gray-700 dark:text-gray-300 hover:bg-purple-50 dark:hover:bg-purple-900/20 hover:text-purple-600 dark:hover:text-purple-400 shadow-sm'
                                                }`}
                                            >
                                                {pageNum}
                                            </button>
                                        );
                                    } else if ((pageNum === page - 2 && pageNum > 1) || (pageNum === page + 2 && pageNum < totalPages)) {
                                        return (
                                            <span key={pageNum} className="flex items-center justify-center w-10 h-10 text-gray-500 dark:text-gray-400">
                                                ...
                                            </span>
                                        );
                                    }
                                    return null;
                                })}
                            </div>

                            <button
                                onClick={handleNextPage}
                                disabled={page === totalPages}
                                className={`flex items-center justify-center w-10 h-10 rounded-lg transition-all ${
                                    page === totalPages
                                        ? 'bg-gray-100 dark:bg-neutral-800 text-gray-400 dark:text-gray-600 cursor-not-allowed'
                                        : 'bg-white dark:bg-neutral-800 text-gray-700 dark:text-gray-300 hover:bg-purple-50 dark:hover:bg-purple-900/20 hover:text-purple-600 dark:hover:text-purple-400 shadow-sm'
                                }`}
                            >
                                <ChevronRight className="w-5 h-5" />
                            </button>
                        </div>
                    </>
                ) : (
                    <div className="flex flex-col items-center justify-center py-16 bg-white dark:bg-neutral-800 rounded-xl shadow-sm">
                        <svg className="w-16 h-16 text-gray-300 dark:text-neutral-700 mb-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 20H5a2 2 0 01-2-2V6a2 2 0 012-2h10a2 2 0 012 2v1m2 13a2 2 0 01-2-2V7m2 13a2 2 0 002-2V9a2 2 0 00-2-2h-2m-4-3H9M7 16h6M7 8h6v4H7V8z" />
                        </svg>
                        <h3 className="text-xl font-medium text-gray-900 dark:text-white mb-2">
                            Новостей пока нет
                        </h3>
                        <p className="text-gray-500 dark:text-gray-400 text-center max-w-md">
                            {searchTerm ?
                                `По запросу "${searchTerm}" ничего не найдено. Попробуйте изменить параметры поиска.` :
                                'Здесь будут отображаться новости и обновления платформы.'}
                        </p>
                        {searchTerm && (
                            <button
                                onClick={() => {
                                    setSearchTerm('');
                                    fetchNews(1, '');
                                }}
                                className="mt-4 px-4 py-2 bg-purple-100 dark:bg-purple-900/20 text-purple-600 dark:text-purple-400
                                rounded-lg hover:bg-purple-200 dark:hover:bg-purple-900/30 transition-colors"
                            >
                                Сбросить поиск
                            </button>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default NewsList;
