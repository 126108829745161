import React, { useEffect } from 'react';
import { X } from 'lucide-react';
import useWindowSize from "../../hooks/useWindowSize";

function ImageModal({ src, alt, onClose }) {
    const { width } = useWindowSize();
    // Обработка закрытия по клавише Esc
    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.key === 'Escape') onClose();
        };

        document.addEventListener('keydown', handleKeyDown);
        return () => document.removeEventListener('keydown', handleKeyDown);
    }, [onClose]);

    return (
        <div
            role="dialog"
            aria-modal="true"
            className="fixed inset-0 z-50 flex items-center justify-center p-4 backdrop-blur-sm bg-black/70 animate-fade-in"
            onClick={(e) => e.target === e.currentTarget && onClose()}
        >
            <div
                className="relative flex flex-col max-w-6xl w-full max-h-[90vh] bg-white dark:bg-neutral-800 rounded-xl shadow-2xl overflow-hidden">
                {/* Хедер с кнопкой закрытия */}
                <div className="flex justify-between p-2 bg-gray-100 dark:bg-neutral-700">
                    <h2 className="text-xl font-bold text-gray-900 dark:text-white flex-1 p-2">{alt}</h2>
                    <button
                        onClick={onClose}
                        className="p-2 hover:bg-gray-200 dark:hover:bg-neutral-600 rounded-full transition-colors"
                        aria-label="Close image"
                    >
                        <X className="w-6 h-6 text-gray-900 dark:text-white"/>
                    </button>
                </div>

                {/* Область с изображением */}
                <div className="flex-1 relative min-h-[200px] p-4">
                    <img
                        src={src}
                        alt={alt}
                        className={`w-full h-full object-contain mx-auto ${width > 768 ? 'max-h-[80vh]' : ''}`}
                        loading="lazy"
                        decoding="async"
                    />
                </div>
            </div>
        </div>
    );
}

export default ImageModal;