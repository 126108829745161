import { motion } from 'framer-motion';
import { FiTool, FiGithub, FiMail, FiClock } from 'react-icons/fi';
import { Link } from 'react-router-dom';

const UnderConstruction = () => {
    return (
        <div className="min-h-screen bg-gradient-to-br from-purple-50 to-blue-50 dark:from-gray-900 dark:to-gray-800 flex items-center justify-center p-4">
            <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8 }}
                className="max-w-2xl w-full"
            >
                <div className="bg-white dark:bg-gray-800 rounded-3xl shadow-2xl p-8 md:p-12 relative overflow-hidden">
                    {/* Decorative elements */}
                    <div className="absolute top-0 right-0 w-32 h-32 bg-purple-500/10 rounded-full -mt-16 -mr-16" />
                    <div className="absolute bottom-0 left-0 w-48 h-48 bg-blue-500/10 rounded-full -mb-24 -ml-24" />

                    {/* Main content */}
                    <div className="relative z-10 text-center">
                        <motion.div
                            animate={{
                                scale: [1, 1.2, 1.2, 1, 1],
                                rotate: [0, 0, 20, 0, 0],
                                opacity: [0.5, 1, 1, 1, 0.5],
                            }}
                            transition={{ repeat: Infinity, duration: 8, ease: "linear" }}
                            className="inline-block mb-8"
                        >
                            <FiTool className="w-20 h-20 text-purple-600 dark:text-purple-400 mx-auto" />
                        </motion.div>

                        <h1 className="text-4xl md:text-5xl font-bold text-gray-800 dark:text-white mb-4">
                            Страница в разработке
                        </h1>

                        <p className="text-lg md:text-xl text-gray-600 dark:text-gray-300 mb-8 max-w-2xl mx-auto">
                            Мы усердно работаем над созданием чего-то удивительного. Подпишитесь на наши обновления,
                            чтобы быть первым, кто узнает о запуске!
                        </p>

                        {/* Progress bar */}
                        <div className="bg-gray-200 dark:bg-gray-700 rounded-full h-3 mb-8 mx-auto max-w-md">
                            <motion.div
                                initial={{ width: 0 }}
                                animate={{ width: "65%" }}
                                transition={{ duration: 2, ease: "easeInOut" }}
                                className="h-full bg-gradient-to-r from-purple-500 to-blue-500 rounded-full"
                            />
                        </div>

                        {/* Features grid */}
                        <div className="grid md:grid-cols-3 gap-6 mb-12">
                            <div className="p-4 bg-purple-50 dark:bg-gray-700 rounded-xl">
                                <FiClock className="w-8 h-8 text-purple-600 dark:text-purple-400 mx-auto mb-3" />
                                <h3 className="font-semibold text-gray-800 dark:text-white mb-2">Скоро</h3>
                                <p className="text-gray-600 dark:text-gray-300 text-sm">
                                    Оставайтесь на связи для получения обновлений
                                </p>
                            </div>

                            <div className="p-4 bg-blue-50 dark:bg-gray-700 rounded-xl">
                                <FiGithub className="w-8 h-8 text-blue-600 dark:text-blue-400 mx-auto mb-3" />
                                <h3 className="font-semibold text-gray-800 dark:text-white mb-2">GitHub</h3>
                                <p className="text-gray-600 dark:text-gray-300 text-sm">
                                    Следите за прогрессом разработки
                                </p>
                            </div>

                            <div className="p-4 bg-pink-50 dark:bg-gray-700 rounded-xl">
                                <FiMail className="w-8 h-8 text-pink-600 dark:text-pink-400 mx-auto mb-3" />
                                <h3 className="font-semibold text-gray-800 dark:text-white mb-2">Рассылка</h3>
                                <p className="text-gray-600 dark:text-gray-300 text-sm">
                                    Уведомления о запуске
                                </p>
                            </div>
                        </div>

                        {/* CTA button */}
                        <Link
                            to={window.history.length > 1 ? -1 : "/"}
                            className="inline-block px-8 py-4 bg-gradient-to-r from-purple-600 to-blue-500 text-white rounded-xl font-semibold
                hover:shadow-lg hover:shadow-purple-500/30 transition-all duration-300"
                        >
                            Вернуться
                        </Link>
                    </div>
                </div>

                {/* Floating tools */}
                <motion.div
                    animate={{ y: [-10, 10, -10] }}
                    transition={{ duration: 4, repeat: Infinity }}
                    className="absolute top-20 left-12 opacity-20 dark:opacity-10"
                >
                    <FiTool className="w-12 h-12 text-gray-600 dark:text-gray-400" />
                </motion.div>

                <motion.div
                    animate={{ y: [10, -10, 10] }}
                    transition={{ duration: 5, repeat: Infinity }}
                    className="absolute top-1/3 right-16 opacity-20 dark:opacity-10"
                >
                    <FiTool className="w-10 h-10 text-gray-600 dark:text-gray-400" />
                </motion.div>
            </motion.div>
        </div>
    );
};

export default UnderConstruction;