import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import { throttle } from 'lodash';
import Post from "./Post";
import { ClipLoader } from "react-spinners";
import { motion, AnimatePresence } from 'framer-motion';

function PostList({
                    externalPosts = [],
                    setExternalPosts,
                    userId,
                    userPreferences = {},
                    groupId = null
                  }) {
  const [internalPosts, setInternalPosts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [error, setError] = useState(null);

  const isControlled = setExternalPosts !== undefined;
  const posts = isControlled ? externalPosts : internalPosts;
  const setPosts = isControlled ? setExternalPosts : setInternalPosts;

  const fetchPosts = useCallback(async (page = 1, reset = false) => {
    setIsLoading(true);
    setError(null);
    try {
      const params = {
        page,
        per_page: 10,
        userId,
        groupId
      };

      const response = await axios.get(`${process.env.REACT_APP_API_URL}/posts`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        params
      });

      const { data, meta } = response.data;

      const processedPosts = data.map(post => ({
        ...post,
        postAgeInHours: (Date.now() - new Date(post.created_at).getTime()) / 1000 / 3600
      }));

      if (reset) {
        setPosts(processedPosts);
      } else {
        setPosts(prev => [...prev, ...processedPosts]);
      }

      setTotalPages(meta.last_page);
      setHasMore(meta.current_page < meta.last_page);

    } catch (error) {
      console.error('Ошибка при получении постов:', error);
      setError('Не удалось загрузить посты');
    } finally {
      setIsLoading(false);
    }
  }, [userId, groupId, setPosts]);

  useEffect(() => {
    const handleScroll = throttle(() => {
      if (window.innerHeight + document.documentElement.scrollTop >=
          document.documentElement.offsetHeight - 100 &&
          !isLoading &&
          hasMore) {
        setCurrentPage(prev => prev + 1);
      }
    }, 500);

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [isLoading, hasMore]);

  useEffect(() => {
    if (currentPage > 1) {
      fetchPosts(currentPage);
    }
  }, [currentPage, fetchPosts]);

  useEffect(() => {
    setCurrentPage(1);
    fetchPosts(1, true);
  }, [userId, groupId, fetchPosts]);

  if (isLoading && posts.length === 0) {
    return (
        <div className="container min-h-screen w-full dark:bg-neutral-900 pt-2 space-y-4">
          {[...Array(3)].map((_, index) => (
              <div key={index} className="bg-white dark:bg-neutral-800 rounded-lg p-4 shadow animate-pulse">
                <div className="flex items-center mb-4">
                  <div className="h-10 w-10 bg-gray-300 dark:bg-neutral-700 rounded-full"></div>
                  <div className="ml-3 space-y-2">
                    <div className="h-3 bg-gray-300 dark:bg-neutral-700 rounded w-24"></div>
                    <div className="h-2 bg-gray-300 dark:bg-neutral-700 rounded w-16"></div>
                  </div>
                </div>
                <div className="space-y-3">
                  <div className="h-4 bg-gray-300 dark:bg-neutral-700 rounded w-3/4"></div>
                  <div className="h-4 bg-gray-300 dark:bg-neutral-700 rounded w-1/2"></div>
                  <div className="h-64 bg-gray-300 dark:bg-neutral-700 rounded-lg"></div>
                  <div className="flex gap-4 pt-4">
                    <div className="h-6 w-16 bg-gray-300 dark:bg-neutral-700 rounded"></div>
                    <div className="h-6 w-16 bg-gray-300 dark:bg-neutral-700 rounded"></div>
                    <div className="h-6 w-16 bg-gray-300 dark:bg-neutral-700 rounded"></div>
                  </div>
                </div>
              </div>
          ))}
        </div>
    );
  }

  if (error) {
    return (
        <div className="container mx-auto rounded-lg min-h-screen dark:bg-neutral-900 p-4">
          <p className="text-center text-red-500 dark:text-red-400">{error}</p>
        </div>
    );
  }

  if (posts.length === 0 && !isLoading) {
    return (
        <div className="container mx-auto rounded-lg min-h-screen dark:bg-neutral-900 p-4">
          <div className="flex items-center justify-center mb-4">
            <i className="fas fa-ghost text-5xl text-gray-700 dark:text-gray-300 animate-blob2"></i>
          </div>
          <p className="text-center text-gray-700 dark:text-white">Посты не найдены.</p>
        </div>
    );
  }

  return (
      <div className="container min-h-screen pt-2 w-full dark:bg-neutral-900">
        <div className="space-y-4">
          <AnimatePresence>
            {posts.map((post, index) => (
                <motion.div
                    key={post.id}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: 20 }}
                    transition={{ duration: 0.3 }}
                    style={{
                      marginBottom: index === posts.length - 1 ? '7rem' : '',
                      display: 'block !important'
                    }}
                >
                  <Post post={post} />
                </motion.div>
            ))}
          </AnimatePresence>

          {isLoading && (
              <div className="flex justify-center p-4">
                <ClipLoader color="#6B46C1" size={40} />
              </div>
          )}

          {!hasMore && !isLoading && (
              <p className="text-center text-gray-500 dark:text-gray-400 py-4 pb-28">
                Пока на этом все
              </p>
          )}
        </div>
      </div>
  );
}

export default PostList;