import React, { useRef, useState, useEffect, useCallback } from 'react';
import {
    FaPlay, FaPause, FaVolumeUp, FaVolumeMute,
    FaExpand, FaForward, FaBackward, FaCompress
} from 'react-icons/fa';
import { motion, AnimatePresence } from 'framer-motion';
import useWindowSize from "../hooks/useWindowSize";
import {ChevronDown} from "lucide-react";

const CustomVideoPlayer = ({ videoUrl }) => {
    const videoRef = useRef(null);
    const containerRef = useRef(null);
    const controlsTimeout = useRef(null);
    const { width } = useWindowSize();
    const isMobile = width < 768;

    const [state, setState] = useState({
        isPlaying: false,
        isMuted: false,
        progress: 0,
        duration: 0,
        currentTime: 0,
        volume: 1,
        isFullscreen: false,
        showControls: true,
        isBuffering: false,
        playbackRate: 1,
    });

    const [hasStarted, setHasStarted] = useState(false);

    const formatTime = useCallback((time) => {
        const minutes = Math.floor(time / 60);
        const seconds = Math.floor(time % 60);
        return `${minutes}:${seconds.toString().padStart(2, '0')}`;
    }, []);

    useEffect(() => {
        const video = videoRef.current;
        if (!video) return;

        const handleEvents = {
            loadedmetadata: () => setState(s => ({...s, duration: video.duration})),
            timeupdate: () => setState(s => ({
                ...s,
                currentTime: video.currentTime,
                progress: (video.currentTime / video.duration) * 100 || 0
            })),
            play: () => setState(s => ({...s, isPlaying: true})),
            pause: () => setState(s => ({...s, isPlaying: false})),
            waiting: () => setState(s => ({...s, isBuffering: true})),
            playing: () => setState(s => ({...s, isBuffering: false})),
            volumechange: () => setState(s => ({
                ...s,
                volume: video.volume,
                isMuted: video.muted
            })),
        };

        Object.entries(handleEvents).forEach(([event, handler]) => {
            video.addEventListener(event, handler);
        });

        return () => {
            Object.entries(handleEvents).forEach(([event, handler]) => {
                video.removeEventListener(event, handler);
            });
        };
    }, []);

    const resetControlsTimeout = useCallback(() => {
        setState(s => ({...s, showControls: true}));
        clearTimeout(controlsTimeout.current);
        controlsTimeout.current = setTimeout(() => {
            setState(s => ({...s, showControls: false}));
        }, 3000);
    }, []);

    const handleKeyDown = useCallback((e) => {
        const video = videoRef.current;
        if (!video || !hasStarted) return;

        const keyActions = {
            ' ': () => {
                e.preventDefault();
                togglePlayPause();
            },
            ArrowRight: () => seek(5),
            ArrowLeft: () => seek(-5),
            m: () => toggleMute(),
            f: () => toggleFullscreen(),
        };

        if (keyActions[e.key]) {
            e.preventDefault();
            e.stopPropagation();
            keyActions[e.key]();
        }
    }, [state.isPlaying, state.isMuted, state.isFullscreen, hasStarted]);

    useEffect(() => {
        const container = containerRef.current;
        container.addEventListener('keydown', handleKeyDown);
        return () => container.removeEventListener('keydown', handleKeyDown);
    }, [handleKeyDown]);

    const togglePlayPause = useCallback(() => {
        const video = videoRef.current;
        if (!hasStarted) {
            video.play();
            setHasStarted(true);
            setState(s => ({...s, showControls: true}));
        } else {
            video[state.isPlaying ? 'pause' : 'play']();
        }
        resetControlsTimeout();
    }, [state.isPlaying, hasStarted, resetControlsTimeout]);

    const toggleMute = useCallback(() => {
        const video = videoRef.current;
        video.muted = !video.muted;
    }, []);

    const toggleFullscreen = useCallback(() => {
        if (!document.fullscreenElement) {
            containerRef.current.requestFullscreen?.();
            setState(s => ({...s, isFullscreen: true}));
        } else {
            document.exitFullscreen?.();
            setState(s => ({...s, isFullscreen: false}));
        }
        resetControlsTimeout();
    }, []);

    const seek = useCallback((seconds) => {
        videoRef.current.currentTime += seconds;
        resetControlsTimeout();
    }, [resetControlsTimeout]);

    const handleProgressChange = useCallback((e) => {
        const time = (e.target.value / 100) * state.duration;
        videoRef.current.currentTime = time;
    }, [state.duration]);

    const handleVolumeChange = useCallback((e) => {
        const volume = parseFloat(e.target.value);
        videoRef.current.volume = volume;
        videoRef.current.muted = volume === 0;
    }, []);

    const handleTouchControls = useCallback(() => {
        setState(s => ({...s, showControls: !s.showControls}));
        resetControlsTimeout();
    }, [resetControlsTimeout]);

    return (
        <div
            ref={containerRef}
            className="relative bg-black rounded-xl overflow-hidden shadow-2xl group transition-all"
            style={{ aspectRatio: '16/9' }}
            onMouseMove={resetControlsTimeout}
            onTouchStart={resetControlsTimeout}
            onClick={isMobile ? handleTouchControls : undefined}
            tabIndex={0}
        >
            <video
                ref={videoRef}
                className="w-full h-full object-cover"
                src={videoUrl}
                onClick={isMobile ? undefined : togglePlayPause}
                muted={state.isMuted}
            />

            <AnimatePresence>
                {!hasStarted && (
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        className="absolute inset-0 flex items-center justify-center bg-black/30 backdrop-blur-sm"
                    >
                        <button
                            onClick={togglePlayPause}
                            className="flex items-center justify-center bg-purple-500/90 hover:bg-purple-600 rounded-full shadow-2xl transition-all duration-300 hover:scale-110 active:scale-95"
                            style={{
                                width: isMobile ? '50px' : '50px',
                                height: isMobile ? '50px' : '50px'
                            }}
                        >
                            <FaPlay
                                className="text-white ml-1"
                                style={{
                                    fontSize: isMobile ? '1rem' : '1.5rem',
                                }}
                            />
                        </button>
                    </motion.div>
                )}
            </AnimatePresence>

            <AnimatePresence>
                {state.isBuffering && (
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        className="absolute inset-0 flex items-center justify-center bg-black/50 backdrop-blur-sm"
                    >
                        <div className="animate-spin rounded-full h-12 w-12 border-4 border-purple-500 border-t-transparent" />
                    </motion.div>
                )}
            </AnimatePresence>

            <AnimatePresence>
                {hasStarted && state.showControls && (
                    <motion.div
                        initial={{ opacity: 0, y: 50 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: 50 }}
                        transition={{ duration: 0.2 }}
                        className={`absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black/90 via-black/70 to-transparent ${
                            isMobile ? 'p-2 pb-4' : 'p-4'
                        } space-y-4`}
                    >
                        <div className="relative group">
                            <div className="h-2 bg-gray-600/30 rounded-full">
                                <div
                                    className="h-full bg-purple-500 rounded-full transition-all duration-100"
                                    style={{ width: `${state.progress}%` }}
                                />
                            </div>
                            <input
                                type="range"
                                min="0"
                                max="100"
                                value={state.progress}
                                onChange={handleProgressChange}
                                className="absolute top-0 w-full h-4 opacity-0 cursor-pointer z-20"
                            />
                            <div
                                className="absolute top-1/2 -translate-y-1/2 h-4 w-4 bg-purple-500 rounded-full shadow-lg transition-all duration-100 opacity-0 group-hover:opacity-100"
                                style={{ left: `calc(${state.progress}% - 8px)` }}
                            />
                        </div>

                        <div className="flex items-center justify-between text-gray-100">
                            <div className="flex items-center gap-2">
                                <button
                                    onClick={() => seek(-10)}
                                    className={`p-2 hover:bg-white/10 rounded-lg transition-colors ${
                                        isMobile ? 'text-2xl' : 'text-xl'
                                    }`}
                                >
                                    <FaBackward />
                                </button>

                                <button
                                    onClick={togglePlayPause}
                                    className={`bg-purple-500 hover:bg-purple-600 rounded-lg shadow-lg transition-all ${
                                        isMobile ? 'p-3 text-2xl' : 'p-2.5 text-xl'
                                    }`}
                                >
                                    {state.isPlaying ? (
                                        <FaPause className="translate-y-px" />
                                    ) : (
                                        <FaPlay className="pl-0.5 translate-y-px" />
                                    )}
                                </button>

                                <button
                                    onClick={() => seek(10)}
                                    className={`p-2 hover:bg-white/10 rounded-lg transition-colors ${
                                        isMobile ? 'text-2xl' : 'text-xl'
                                    }`}
                                >
                                    <FaForward />
                                </button>

                                <div className="flex items-center gap-2 ml-2">
                                    <button
                                        onClick={toggleMute}
                                        className={`p-2 hover:bg-white/10 rounded-lg ${
                                            isMobile ? 'text-2xl' : 'text-xl'
                                        }`}
                                    >
                                        {state.isMuted ? <FaVolumeMute /> : <FaVolumeUp />}
                                    </button>
                                    {!isMobile && (
                                        <div className="relative w-24 h-1 group">
                                            <input
                                                type="range"
                                                min="0"
                                                max="1"
                                                step="0.1"
                                                value={state.volume}
                                                onChange={handleVolumeChange}
                                                className="absolute top-0 w-full h-full opacity-0 cursor-pointer z-20"
                                            />
                                            <div
                                                className="absolute top-0 h-full bg-purple-500 rounded-full transition-all duration-100"
                                                style={{ width: `${state.volume * 100}%` }}
                                            />
                                        </div>
                                    )}
                                </div>

                                <span className={`font-medium ${isMobile ? 'text-sm' : 'text-base'}`}>
                                    {formatTime(state.currentTime)} / {formatTime(state.duration)}
                                </span>
                            </div>

                            <div className="flex items-center gap-3">
                                {!isMobile && (
                                    <select
                                        value={state.playbackRate}
                                        onChange={(e) => {
                                            const rate = parseFloat(e.target.value);
                                            videoRef.current.playbackRate = rate;
                                            setState(s => ({...s, playbackRate: rate}));
                                        }}
                                        className="
                                            appearance-none bg-white/80 dark:bg-neutral-800/80
                                            text-neutral-600 dark:text-neutral-200
                                            border border-gray-200/50 dark:border-neutral-700/50
                                            rounded-lg px-3 py-1.5 pr-8 text-sm
                                            backdrop-blur-sm
                                            transition-all
                                            hover:bg-white/90 dark:hover:bg-neutral-700/80
                                            hover:shadow-sm
                                            focus:outline-none
                                            focus:ring-2 focus:ring-purple-500/30
                                            focus:border-purple-500/50
                                            cursor-pointer
                                            relative
                                        "
                                    >
                                        {[0.5, 1, 1.5, 2].map(speed => (
                                            <option
                                                key={speed}
                                                value={speed}
                                                className="
                                                    bg-white dark:bg-neutral-800
                                                    text-neutral-900 dark:text-neutral-100
                                                "
                                            >
                                                {speed}x
                                            </option>
                                        ))}
                                        <div className="
                                            absolute right-2 top-1/2 -translate-y-1/2
                                            pointer-events-none
                                        ">
                                            <ChevronDown className="w-4 h-4 text-neutral-600 dark:text-neutral-200" />
                                        </div>
                                    </select>
                                )}

                                <button
                                    onClick={toggleFullscreen}
                                    className={`p-2 hover:bg-white/10 rounded-lg transition-colors ${
                                        isMobile ? 'text-2xl' : 'text-xl'
                                    }`}
                                >
                                    {state.isFullscreen ? <FaCompress/> : <FaExpand/>}
                                </button>
                            </div>
                        </div>

                        {isMobile && (
                            <div className="px-2">
                                <div className="relative h-1">
                                    <input
                                        type="range"
                                        min="0"
                                        max="1"
                                        step="0.1"
                                        value={state.volume}
                                        onChange={handleVolumeChange}
                                        className="absolute w-full h-1 bg-gray-600/50 rounded-full appearance-none thumb-hidden z-50"
                                    />
                                    <div
                                        className="absolute h-full bg-purple-500 rounded-full"
                                        style={{width: `${state.volume * 100}%`}}
                                    />
                                </div>
                            </div>
                        )}
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
};

export default CustomVideoPlayer;