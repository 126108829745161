import React from 'react';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';

const GenreTags = ({ tracks, selectedGenre, onSelectGenre }) => {
    // Получаем уникальные жанры из треков
    const genres = Array.from(
        new Set(
            tracks
                .flatMap(track => track.genre?.split(',').map(g => g.trim())) // Разделяем жанры через запятую
                .filter(genre => genre && genre.length > 0)
        )
    );

    if (genres.length === 0) return null;



    return (
        <div className="flex flex-wrap gap-2">
            {genres.map((genre, index) => (
                <motion.button
                    key={genre + index}
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    className={`px-4 py-2 rounded-full text-sm font-medium transition-colors ${
                        selectedGenre === genre
                            ? 'bg-purple-600 text-white'
                            : 'bg-neutral-700 text-gray-300 hover:bg-neutral-600'
                    }`}
                    onClick={() => onSelectGenre(selectedGenre === genre ? null : genre)}
                >
                    {genre}
                </motion.button>
            ))}

            {selectedGenre && (
                <motion.button
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    className="px-4 py-2 text-sm text-gray-400 hover:text-white"
                    onClick={() => onSelectGenre(null)}
                >
                    Сбросить фильтр
                </motion.button>
            )}
        </div>
    );
};

GenreTags.propTypes = {
    tracks: PropTypes.arrayOf(
        PropTypes.shape({
            genre: PropTypes.string,
        })
    ).isRequired,
    selectedGenre: PropTypes.string,
    onSelectGenre: PropTypes.func.isRequired,
};

export default GenreTags;