import React, { useState, useEffect, useContext, useCallback, useRef } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import CreateChat from './CreateChat';
import Chat from './Chat';
import { motion, AnimatePresence } from 'framer-motion';
import Pusher from 'pusher-js';
import useWindowSize from "../hooks/useWindowSize";
import BottomNav from "./BottomNav";
import ConfirmationModal from "./ConfirmationModal";
import toast from "react-hot-toast";
import setupPusher from '../services/pusher';
import BackLink from "./BackLink";
import OnlineStatusIndicator from "./OnlineStatusIndicator";
import UserAvatar from "./UserAvatar";
import Aside from "./Aside";

function ChatList() {
    const [chats, setChats] = useState([]);
    const [selectedChatId, setSelectedChatId] = useState(null);
    const [isClosing, setIsClosing] = useState(false);
    const { isLoggedIn } = useContext(AuthContext);
    const [isInitialLoading, setIsInitialLoading] = useState(true);
    const [isRefreshing, setIsRefreshing] = useState(false);
    const [contextMenu, setContextMenu] = useState({
        visible: false,
        chatId: null,
        position: { x: 0, y: 0 },
    });
    const { width } = useWindowSize();  // Detect screen width
    const contextMenuRef = useRef(null);
    const chatButtonRefs = useRef({});
    const [swipeStartX, setSwipeStartX] = useState(0);
    const [swipePosition, setSwipePosition] = useState({});
    const [isDeleteConfirmVisible, setIsDeleteConfirmVisible] = useState(false);
    const [chatToDelete, setChatToDelete] = useState(null);
    const [pusherInstance, setPusherInstance] = useState(null);
    const [isSearchVisible, setIsSearchVisible] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [refreshing, setRefreshing] = useState(false);
    const [startY, setStartY] = useState(0);
    const [pullDistance, setPullDistance] = useState(0);
    const chatListRef = useRef(null);
    const [showSwipeHint, setShowSwipeHint] = useState(true);
    const [activeFilter, setActiveFilter] = useState('all');
    const [showArchived, setShowArchived] = useState(false);
    const [typingStatus, setTypingStatus] = useState({});
    const [isScrolled, setIsScrolled] = useState(false);

    const isMounted = useRef(false);

    // Обработчики свайпа для мобильных устройств
    const handleTouchStart = (event, chatId) => {
        setSwipeStartX(event.touches[0].clientX);
        setSwipePosition(prev => ({ ...prev, [chatId]: 0 }));
    };

    const handleTouchMove = (event, chatId) => {
        const swipeEndX = event.touches[0].clientX;
        const swipeDistance = swipeEndX - swipeStartX;

        if (swipeDistance <= 160 && swipeDistance >= -160) {
            setSwipePosition(prev => ({ ...prev, [chatId]: swipeDistance }));
        }
    };

    const handleTouchEnd = async (chatId) => {
        const swipeDistance = swipePosition[chatId] || 0;

        if (swipeDistance < -150) {
            handleDeleteConfirmation(chatId);
            // Вибрация для тактильной обратной связи
            if (navigator.vibrate) {
                navigator.vibrate(50);
            }
            // Скрываем подсказку после первого использования
            setShowSwipeHint(false);
        } else if (swipeDistance > 150) {
            await handlePinChat(chatId);
            // Вибрация для тактильной обратной связи
            if (navigator.vibrate) {
                navigator.vibrate(50);
            }
            // Скрываем подсказку после первого использования
            setShowSwipeHint(false);
        }

        // Reset swipe position
        setSwipePosition(prev => ({ ...prev, [chatId]: 0 }));
    };

    // Pull-to-refresh функциональность
    const handlePullStart = (e) => {
        if (chatListRef.current && chatListRef.current.scrollTop === 0) {
            setStartY(e.touches[0].clientY);
        }
    };

    const handlePullMove = (e) => {
        if (startY > 0 && chatListRef.current && chatListRef.current.scrollTop === 0) {
            const y = e.touches[0].clientY;
            const distance = y - startY;
            if (distance > 0 && distance < 100) {
                setPullDistance(distance);
            }
        }
    };

    const handlePullEnd = async () => {
        if (pullDistance > 50) {
            setRefreshing(true);
            await fetchChats(false);
            setTimeout(() => {
                setRefreshing(false);
            }, 800);
        }
        setStartY(0);
        setPullDistance(0);
    };

    // Отслеживание скролла для эффекта тени
    const handleScroll = () => {
        if (chatListRef.current) {
            setIsScrolled(chatListRef.current.scrollTop > 10);
        }
    };

    // Привязка обработчиков событий к чатам
    useEffect(() => {
        const setupChatEventListeners = () => {
            const chatButtons = document.querySelectorAll('.chat-button');

            chatButtons.forEach((button, index) => {
                const chatId = button.getAttribute('data-chat-id');
                if (!chatId) return;

                // Сохраняем ссылки на элементы для последующего использования
                chatButtonRefs.current[chatId] = button;

                // Добавляем обработчики событий
                const touchStartHandler = (e) => handleTouchStart(e, chatId);
                const touchMoveHandler = (e) => handleTouchMove(e, chatId);
                const touchEndHandler = () => handleTouchEnd(chatId);

                button.addEventListener('touchstart', touchStartHandler);
                button.addEventListener('touchmove', touchMoveHandler);
                button.addEventListener('touchend', touchEndHandler);

                // Сохраняем обработчики для последующего удаления
                button._touchStartHandler = touchStartHandler;
                button._touchMoveHandler = touchMoveHandler;
                button._touchEndHandler = touchEndHandler;
            });
        };

        // Устанавливаем обработчики с небольшой задержкой, чтобы DOM успел обновиться
        const timerId = setTimeout(setupChatEventListeners, 100);

        return () => {
            clearTimeout(timerId);

            // Удаляем обработчики событий при размонтировании
            Object.values(chatButtonRefs.current).forEach(button => {
                if (!button) return;

                if (button._touchStartHandler) {
                    button.removeEventListener('touchstart', button._touchStartHandler);
                }
                if (button._touchMoveHandler) {
                    button.removeEventListener('touchmove', button._touchMoveHandler);
                }
                if (button._touchEndHandler) {
                    button.removeEventListener('touchend', button._touchEndHandler);
                }
            });

            // Очищаем ссылки
            chatButtonRefs.current = {};
        };
    }, [chats, selectedChatId, activeFilter, searchQuery, showArchived]);

    const handleDeleteConfirmation = (chatId) => {
        setChatToDelete(chatId);
        setIsDeleteConfirmVisible(true);
    };

    const confirmDeleteChat = async () => {
        if (chatToDelete) {
            await handleDeleteChat(chatToDelete);
            setIsDeleteConfirmVisible(false);
            setChatToDelete(null);
        }
    };

    const closeDeleteConfirmation = () => {
        setIsDeleteConfirmVisible(false);
        setChatToDelete(null);
    };

    useEffect(() => {
        const cachedChats = localStorage.getItem('cachedChats');
        if (cachedChats) {
            setChats(JSON.parse(cachedChats));
        }
    }, []);

    useEffect(() => {
        if (chats.length > 0) {
            localStorage.setItem('cachedChats', JSON.stringify(chats));
        }
    }, [chats]);

    const fetchChats = useCallback(async (isInitial = false) => {
        if (!isMounted.current && !isInitial) return;

        const token = localStorage.getItem('token');
        if (!token) return;

        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/chats`, {
                headers: { Authorization: `Bearer ${token}` },
            });

            // Обновляем только если данные изменились
            setChats(prev => {
                const isSame = JSON.stringify(prev) === JSON.stringify(response.data);
                return isSame ? prev : response.data;
            });
        } catch (error) {
            console.error('Error fetching chats:', error);
        } finally {
            setIsInitialLoading(false);
            setIsRefreshing(false);
        }
    }, []);

    useEffect(() => {
        isMounted.current = true;

        // Загружаем чаты только если их нет в кэше
        const cachedChats = localStorage.getItem('cachedChats');
        if (cachedChats) {
            setChats(JSON.parse(cachedChats));
            // Делаем фоновое обновление
            fetchChats(false);
        } else {
            fetchChats(true);
        }

        return () => {
            isMounted.current = false;
        };
    }, [fetchChats]);

    useEffect(() => {
        let pusher = null;
        let channel = null;

        const setupPusherAndSubscribe = async () => {
            if (!isLoggedIn) return;

            try {
                // Загружаем чаты
                await fetchChats(true);

                // Настраиваем Pusher
                pusher = await setupPusher();
                setPusherInstance(pusher);

                // Подписываемся на канал
                const userId = localStorage.getItem('user_id');
                if (!userId) {
                    console.error('User ID not found in localStorage');
                    return;
                }

                const channelName = `private-chat-updates.${userId}`;

                // Проверяем, существует ли уже подписка на этот канал
                if (pusher.channel(channelName)) {
                    console.log('Already subscribed to channel:', channelName);
                    channel = pusher.channel(channelName);
                } else {
                    console.log('Subscribing to channel:', channelName);
                    channel = pusher.subscribe(channelName);
                }

                // Мониторим состояние подписки
                channel.bind('pusher:subscription_succeeded', () => {
                    console.log('Successfully subscribed to channel:', channelName);
                });

                channel.bind('pusher:subscription_error', (error) => {
                    console.error('Error subscribing to channel:', error);

                    // Пробуем переподключиться через 5 секунд
                    setTimeout(() => {
                        if (pusher && pusher.connection.state === 'connected') {
                            console.log('Attempting to resubscribe to channel:', channelName);
                            channel = pusher.subscribe(channelName);
                        }
                    }, 5000);
                });

                // Подписываемся на событие с сообщением
                channel.bind('message.sent', (data) => {
                    console.log('Received message event:', data);

                    if (!data || !data.chat || !data.message) {
                        console.error('Invalid message data received:', data);
                        return;
                    }

                    setChats(prevChats => {
                        // Проверяем, существует ли уже этот чат
                        const chatExists = prevChats.some(c => c.id === data.chat.id);

                        if (chatExists) {
                            // Обновляем существующий чат
                            return prevChats.map(chat => {
                                if (chat.id === data.chat.id) {
                                    return {
                                        ...chat,
                                        last_message: data.message,
                                        unread_count: data.chat.unread_count
                                    };
                                }
                                return chat;
                            });
                        } else {
                            // Добавляем новый чат в начало списка
                            return [data.chat, ...prevChats];
                        }
                    });

                    // Если чат уже открыт, обновляем его как прочитанный
                    if (selectedChatId === data.chat.id) {
                        const token = localStorage.getItem('token');
                        axios.post(
                            `${process.env.REACT_APP_API_URL}/chats/mark-as-read/${data.chat.id}`,
                            {},
                            {
                                headers: {
                                    Authorization: `Bearer ${token}`,
                                },
                            }
                        ).catch(error => {
                            console.error('Error marking chat as read:', error);
                        });
                    }
                });

                // Подписываемся на событие печати
                channel.bind('user.typing', (data) => {
                    console.log('Received typing event:', data);

                    if (!data || !data.chat_id || !data.user_id) {
                        console.error('Invalid typing data received:', data);
                        return;
                    }

                    setTypingStatus(prev => ({
                        ...prev,
                        [data.chat_id]: {
                            userId: data.user_id,
                            isTyping: true,
                            timestamp: Date.now()
                        }
                    }));

                    // Сбрасываем статус печати через 3 секунды
                    setTimeout(() => {
                        setTypingStatus(prev => {
                            if (!prev[data.chat_id]) return prev;

                            return {
                                ...prev,
                                [data.chat_id]: {
                                    ...prev[data.chat_id],
                                    isTyping: false
                                }
                            };
                        });
                    }, 3000);
                });

                // Обработка обновления статуса чата
                channel.bind('chat.updated', (data) => {
                    console.log('Received chat update event:', data);

                    if (!data || !data.chat) {
                        console.error('Invalid chat update data received:', data);
                        return;
                    }

                    setChats(prevChats => {
                        return prevChats.map(chat => {
                            if (chat.id === data.chat.id) {
                                return {
                                    ...chat,
                                    ...data.chat
                                };
                            }
                            return chat;
                        });
                    });
                });

                // Обработка ошибок соединения
                pusher.connection.bind('error', (err) => {
                    console.error('Pusher connection error:', err);

                    // Пробуем переподключиться
                    if (pusher.connection.state !== 'connected') {
                        setTimeout(() => {
                            console.log('Attempting to reconnect to Pusher...');
                            pusher.connect();
                        }, 3000);
                    }
                });

                // Обработка восстановления соединения
                pusher.connection.bind('connected', () => {
                    console.log('Pusher connection established');

                    // Переподписываемся на канал
                    if (!pusher.channel(channelName)) {
                        console.log('Resubscribing to channel after reconnection:', channelName);
                        channel = pusher.subscribe(channelName);
                    }
                });
            } catch (error) {
                console.error('Error setting up Pusher:', error);
            }
        };

        setupPusherAndSubscribe();

        // Очистка при размонтировании компонента
        return () => {
            if (channel) {
                channel.unbind_all();
            }

            if (pusher) {
                const userId = localStorage.getItem('user_id');
                if (userId) {
                    const channelName = `private-chat-updates.${userId}`;
                    pusher.unsubscribe(channelName);
                }

                pusher.disconnect();
                setPusherInstance(null);
            }
        };
    }, [isLoggedIn, selectedChatId]);

    useEffect(() => {
        if (!isMounted.current) return;
        const serialized = JSON.stringify(chats);
        if (serialized !== localStorage.getItem('cachedChats')) {
            localStorage.setItem('cachedChats', serialized);
        }
    }, [chats]);

    const handlePinChat = async (chatId) => {
        try {
            const token = localStorage.getItem('token');
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/user/pin-chat/${chatId}`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            const { is_pinned } = response.data;

            setChats((prevChats) =>
                prevChats.map((chat) =>
                    chat.id === chatId ? { ...chat, is_pinned } : chat
                )
            );

            // Показываем уведомление
            if (is_pinned) {
                toast.success('Чат закреплен', {
                    duration: 2000,
                    style: {
                        borderRadius: '10px',
                        background: '#333',
                        color: '#fff',
                    },
                    icon: '📌'
                });
            } else {
                toast.success('Чат откреплен', {
                    duration: 2000,
                    style: {
                        borderRadius: '10px',
                        background: '#333',
                        color: '#fff',
                    },
                    icon: '📌'
                });
            }
        } catch (error) {
            console.error('Ошибка при закреплении чата:', error);
            toast.error('Не удалось изменить статус чата', { duration: 2000 });
        }
    };

    // Архивация чата
    const handleArchiveChat = async (chatId) => {
        try {
            // Здесь должен быть запрос к API для архивации чата
            // Для демонстрации просто обновляем локальное состояние
            setChats((prevChats) =>
                prevChats.map((chat) =>
                    chat.id === chatId ? { ...chat, is_archived: !chat.is_archived } : chat
                )
            );

            const chat = chats.find(c => c.id === chatId);
            const isArchived = chat?.is_archived;

            // Показываем уведомление
            if (!isArchived) {
                toast.success('Чат архивирован', {
                    duration: 2000,
                    style: {
                        borderRadius: '10px',
                        background: '#333',
                        color: '#fff',
                    },
                    icon: '📁'
                });
            } else {
                toast.success('Чат разархивирован', {
                    duration: 2000,
                    style: {
                        borderRadius: '10px',
                        background: '#333',
                        color: '#fff',
                    },
                    icon: '📂'
                });
            }
        } catch (error) {
            console.error('Ошибка при архивации чата:', error);
            toast.error('Не удалось архивировать чат', { duration: 2000 });
        }
    };

    // Фильтрация чатов
    const filterChats = (chats) => {
        // Сначала фильтруем по архивации
        let filteredChats = showArchived
            ? chats.filter(chat => chat.is_archived)
            : chats.filter(chat => !chat.is_archived);

        // Затем применяем фильтр по типу
        switch (activeFilter) {
            case 'unread':
                return filteredChats.filter(chat => chat.unread_count > 0);
            case 'personal':
                return filteredChats.filter(chat => !chat.is_group);
            case 'groups':
                return filteredChats.filter(chat => chat.is_group);
            case 'all':
            default:
                return filteredChats;
        }
    };

    const sortedChats = chats.slice().sort((a, b) => {
        if (a.is_pinned && !b.is_pinned) return -1;
        if (!a.is_pinned && b.is_pinned) return 1;
        const aLastMessageTime = a.last_message ? new Date(a.last_message.created_at) : null;
        const bLastMessageTime = b.last_message ? new Date(b.last_message.created_at) : null;

        if (!aLastMessageTime && !bLastMessageTime) return 0;
        if (!aLastMessageTime) return 1;
        if (!bLastMessageTime) return -1;

        return bLastMessageTime - aLastMessageTime;
    });

    // Фильтрация чатов по поисковому запросу и активному фильтру
    const filteredChats = searchQuery
        ? filterChats(sortedChats).filter(chat => {
            const partnerName = getChatPartnerName(chat).toLowerCase();
            const lastMessage = chat.last_message ? chat.last_message.content?.toLowerCase() || '' : '';
            return partnerName.includes(searchQuery.toLowerCase()) || lastMessage.includes(searchQuery.toLowerCase());
        })
        : filterChats(sortedChats);

    const handleSendMessage = async (message) => {
        try {
            const token = localStorage.getItem('token');
            const formData = new FormData();

            formData.append('content', message.content);
            if (message.attachment) {
                formData.append('attachment', message.attachment);
            }

            await axios.post(`${process.env.REACT_APP_API_URL}/messages`, formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data',
                },
            });
            fetchChats(false);
        } catch (error) {
            console.error('Ошибка при отправке сообщения:', error);
        }
    };


    const handleDeleteChat = async (chatId) => {
        try {
            const token = localStorage.getItem('token');
            await axios.delete(`${process.env.REACT_APP_API_URL}/chats/${chatId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setChats((prevChats) => prevChats.filter((chat) => chat.id !== chatId));

            // Если удаляемый чат был выбран, сбрасываем выбор
            if (selectedChatId === chatId) {
                setSelectedChatId(null);
            }

            toast.success('Чат успешно удален!', {
                duration: 2000,
                style: {
                    borderRadius: '10px',
                    background: '#333',
                    color: '#fff',
                },
                icon: '🗑️'
            });
        } catch (error) {
            toast.error('Не удалось удалить чат!', { duration: 2000 });
            console.error('Error deleting chat:', error);
        }
    };


    const handleContextMenu = (event, chatId) => {
        event.preventDefault();
        if (width >= 768) {
            const touch = event.touches ? event.touches[0] : event;
            const x = touch.clientX;
            const y = touch.clientY;

            const menuWidth = 200;
            const menuHeight = 150;
            const adjustedX = x + menuWidth > window.innerWidth ? x - menuWidth : x;
            const adjustedY = y + menuHeight > window.innerHeight ? y - menuHeight : y;

            setContextMenu({
                visible: true,
                chatId,
                position: { x: adjustedX, y: adjustedY },
            });
        }
    };

    // Для мобильных устройств - длительное нажатие для вызова контекстного меню
    const handleLongPress = (event, chatId) => {
        event.preventDefault();

        if (width < 768) {
            const rect = event.currentTarget.getBoundingClientRect();
            const x = rect.left + rect.width / 2;
            const y = rect.top + rect.height / 2;

            // Вибрация для тактильной обратной связи (если поддерживается)
            if (navigator.vibrate) {
                navigator.vibrate(50);
            }

            setContextMenu({
                visible: true,
                chatId,
                position: { x, y },
            });
        }
    };

    const handleContextMenuAction = async (action) => {
        if (action === 'mark-as-read') {
            await handleMarkAsRead(contextMenu.chatId);
        } else if (action === 'mark-as-unread') {
            await handleMarkAsUnread(contextMenu.chatId);
        } else if (action === 'pin') {
            await handlePinChat(contextMenu.chatId);
        } else if (action === 'archive') {
            await handleArchiveChat(contextMenu.chatId);
        } else if (action === 'delete') {
            await handleDeleteConfirmation(contextMenu.chatId);
        }
        handleCloseContextMenu();
    };

    const handleCloseContextMenu = () => {
        setContextMenu({ visible: false, chatId: null, position: { x: 0, y: 0 } });
    };


    const handleMarkAsUnread = async (chatId) => {
        try {
            const token = localStorage.getItem('token');
            await axios.post(
                `${process.env.REACT_APP_API_URL}/chats/mark-as-unread/${chatId}`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            await fetchChats(false);
            toast.success('Отмечено как непрочитанное', {
                duration: 2000,
                style: {
                    borderRadius: '10px',
                    background: '#333',
                    color: '#fff',
                },
                icon: '📩'
            });
        } catch (error) {
            console.error('Ошибка при изменении статуса чата:', error);
            toast.error('Не удалось изменить статус чата', { duration: 2000 });
        }
    };

    const handleMarkAsRead = async (chatId) => {
        try {
            const token = localStorage.getItem('token');
            await axios.post(
                `${process.env.REACT_APP_API_URL}/chats/mark-as-read/${chatId}`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            fetchChats(false);
            toast.success('Отмечено как прочитанное', {
                duration: 2000,
                style: {
                    borderRadius: '10px',
                    background: '#333',
                    color: '#fff',
                },
                icon: '✓'
            });
        } catch (error) {
            console.error('Ошибка при изменении статуса чата:', error);
            toast.error('Не удалось изменить статус чата', { duration: 2000 });
        }
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (contextMenu.visible && contextMenuRef.current && !contextMenuRef.current.contains(event.target)) {
                setContextMenu({ visible: false, chatId: null, position: { x: 0, y: 0 } });
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        document.addEventListener('touchstart', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            document.removeEventListener('touchstart', handleClickOutside);
        };
    }, [contextMenu.visible]);

    const getChatPartnerName = (chat) => {
        const userId = parseInt(localStorage.getItem('user_id'), 10);
        if (chat.user1_id === userId) {
            return chat.user2?.name || 'Неизвестный пользователь';
        } else {
            return chat.user1?.name || 'Неизвестный пользователь';
        }
    };

    const getLastMessage = (chat) => {
        const maxLength = 35;

        if (!chat.last_message) {
            return 'Сообщений нет.';
        }

        // Если пользователь печатает, показываем это вместо последнего сообщения
        if (typingStatus[chat.id]?.isTyping) {
            return 'печатает...';
        }

        if (chat.last_message.is_image) {
            return 'Фотография';
        }

        if (chat.last_message.is_voice) {
            return 'Голосовое сообщение';
        }

        if (chat.last_message.is_file) {
            return 'Файл';
        }

        if (chat.last_message.is_sticker) {
            return 'Стикер';
        }

        const message = chat.last_message.content;

        if (!message) {
            return 'Сообщение отсутствует';
        }

        if (message.length <= maxLength) {
            return message;
        }

        if (message.length > maxLength) {
            return `${message.substring(0, maxLength)}...`;
        } else {
            return message;
        }
    };

    const getLastMessageTime = (chat) => {
        if (!chat.last_message || !chat.last_message.created_at) {
            return 'Нет сообщений';
        }

        const messageDate = new Date(chat.last_message.created_at);
        if (isNaN(messageDate.getTime())) {
            return 'Некорректная дата';
        }

        const now = new Date();
        const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
        const yesterday = new Date(today);
        yesterday.setDate(yesterday.getDate() - 1);

        const isToday = messageDate >= today;
        const isYesterday = messageDate >= yesterday && messageDate < today;
        const isThisYear = messageDate.getFullYear() === now.getFullYear();

        if (isToday) {
            return messageDate.toLocaleTimeString('ru-RU', { hour: '2-digit', minute: '2-digit' });
        } else if (isYesterday) {
            return 'Вчера';
        } else if (isThisYear) {
            return messageDate.toLocaleDateString('ru-RU', { day: 'numeric', month: 'short' });
        } else {
            return messageDate.toLocaleDateString('ru-RU', { day: 'numeric', month: 'short', year: '2-digit' });
        }
    };

    const getMessageStatusIcon = (chat) => {
        if (!chat.last_message) return null;

        // Если сообщение от текущего пользователя
        const userId = parseInt(localStorage.getItem('user_id'), 10);
        if (chat.last_message.user_id === userId) {
            if (chat.last_message.is_read) {
                return <i className="fas fa-check-double text-violet-500 text-xs ml-1"></i>;
            } else if (chat.last_message.is_delivered) {
                return <i className="fas fa-check text-gray-400 text-xs ml-1"></i>;
            } else {
                return <i className="fas fa-clock text-gray-400 text-xs ml-1"></i>;
            }
        }
        return null;
    };

    // Компоненты скелетона для загрузки
    const ChatSkeleton = () => (
        <div className="bg-white dark:bg-neutral-700 rounded-xl p-4 animate-pulse">
            <div className="flex items-center">
                <div className="w-12 h-12 rounded-full bg-gray-200 dark:bg-gray-600 mr-4"></div>
                <div className="flex-1">
                    <div className="h-4 bg-gray-200 dark:bg-gray-600 rounded w-1/3 mb-2"></div>
                    <div className="h-3 bg-gray-200 dark:bg-gray-600 rounded w-2/3"></div>
                </div>
                <div className="flex flex-col items-end">
                    <div className="h-3 bg-gray-200 dark:bg-gray-600 rounded w-10 mb-2"></div>
                    <div className="h-5 w-5 rounded-full bg-gray-200 dark:bg-gray-600"></div>
                </div>
            </div>
        </div>
    );

    // Компонент загрузки
    if (isInitialLoading) {
        return (
            <div className="flex container mx-auto p-4">
                <Aside/>
            <div className={`container mx-auto flex h-3/4 dark:bg-neutral-900 pt-10 ${width < 768 ? 'bg-white dark:bg-neutral-900 h-full w-full fixed top-0 inset-x-0 z-50 -mt-16' : ''}`}>
                <div className="p-4 w-full lg:w-1/3 h-[calc(100vh-100px)] overflow-y-auto mt-4 rounded-lg dark:bg-neutral-900 mb-4">
                    <Link
                        to="/"
                        className={`cursor-pointer text-gray-700 hover:text-violet-500 transition duration-200 dark:text-white hover:dark:text-violet-400 ${width < 768 ? 'hidden' : ''}`}                    >
                        <i className="fas fa-chevron-left mr-2 mb-4"></i>Назад
                    </Link>
                    <h2 className="text-3xl font-extrabold text-gray-800 mb-5 dark:text-white">
                        Чаты
                    </h2>
                    <div className="space-y-4">
                        {[...Array(6)].map((_, index) => (
                            <ChatSkeleton key={index} />
                        ))}
                    </div>
                </div>
                {width < 768 && (
                    <BottomNav />
                )}
            </div>
            </div>
        );
    }

    // Пустое состояние
    if (chats.length === 0) {
        return (
            <div className="flex container mx-auto p-4">
                <Aside/>
                <div className="container mx-auto flex h-3/4 dark:bg-neutral-900 pt-10">
                    <div
                        className="p-4 w-full lg:w-1/3 h-[calc(100vh-100px)] overflow-y-auto mt-4 rounded-lg dark:bg-neutral-900 mb-4">
                        <Link
                            to="/"
                            className={`cursor-pointer text-gray-700 hover:text-violet-500 transition duration-200 dark:text-white hover:dark:text-violet-400 ${width < 768 ? 'hidden' : ''}`}>
                            <i className="fas fa-chevron-left mr-2 mb-4"></i>Назад
                        </Link>
                        <div className="flex items-center justify-between">
                            <h2 className="text-3xl font-extrabold text-gray-800 mb-5 dark:text-white">
                                Чаты
                            </h2>
                            <button
                                className="mb-5 text-violet-500 dark:text-violet-400"
                                onClick={() => setIsSearchVisible(!isSearchVisible)}
                            >
                                <i className="fas fa-search text-xl"></i>
                            </button>
                        </div>

                        <AnimatePresence>
                            {isSearchVisible && (
                                <motion.div
                                    initial={{height: 0, opacity: 0}}
                                    animate={{height: 'auto', opacity: 1}}
                                    exit={{height: 0, opacity: 0}}
                                    transition={{duration: 0.2}}
                                    className="overflow-hidden mb-4"
                                >
                                    <div className="px-2 py-2">
                                        <input
                                            type="text"
                                            placeholder="Поиск"
                                            className="w-full p-2 bg-gray-100 dark:bg-gray-800 rounded-lg text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-violet-500 dark:focus:ring-violet-400"
                                            value={searchQuery}
                                            onChange={(e) => setSearchQuery(e.target.value)}
                                            autoFocus
                                        />
                                    </div>
                                </motion.div>
                            )}
                        </AnimatePresence>

                        <div
                            className="text-center rounded-lg p-8 bg-white dark:bg-neutral-800 mb-4 shadow-sm">
                            <div
                                className="w-20 h-20 rounded-full bg-violet-100 dark:bg-violet-900/30 flex items-center justify-center mx-auto mb-4">
                                <i className="fas fa-comments text-3xl text-violet-500 dark:text-violet-400"></i>
                            </div>
                            <h3 className="text-xl font-semibold text-gray-800 dark:text-white mb-2">
                                Нет активных чатов
                            </h3>
                            <p className="text-gray-500 dark:text-gray-400 mb-6">
                                Начните новый диалог, чтобы общаться с друзьями
                            </p>
                            <button
                                className="w-full flex items-center justify-center px-5 py-3 bg-violet-500 hover:bg-violet-600 text-white rounded-lg transition-colors duration-200">
                                <i className="fas fa-plus mr-2"></i>
                                <span>Создать чат</span>
                                <CreateChat className="ml-2"/>
                            </button>
                        </div>
                    </div>
                    {width < 768 && (
                        <BottomNav/>
                    )}
                </div>
            </div>
        );
    }

                // Основной интерфейс
                return (
                <div className="flex container mx-auto p-4">
                    <Aside/>
                    <div
                        className={`container mx-auto flex flex-col lg:flex-row h-3/4 dark:bg-neutral-900 pt-14 ${width < 768 ? 'bg-white dark:bg-neutral-800 h-full w-full fixed top-0 inset-x-0 z-50' : ''}`}>
                        <div
                            className={`p-2 ${selectedChatId ? 'hidden lg:block' : 'block'} lg:w-1/3 h-[calc(100vh-90px)] overflow-y-auto rounded-lg dark:bg-neutral-900 mb-4 relative p-4 ${width < 768 ? 'fixed top-0 inset-x-0 z-50 -mt-14' : ''}`}
                            ref={chatListRef}
                            onTouchStart={handlePullStart}
                            onTouchMove={handlePullMove}
                            onTouchEnd={handlePullEnd}
                            onScroll={handleScroll}
                        >
                            {/* Фиксированный заголовок с эффектом тени при скролле */}
                            <div
                                className={`sticky top-0 bg-white dark:bg-neutral-900 z-10 pb-2 ${isScrolled ? 'shadow-md dark:shadow-gray-800' : ''}`}>
                                <div className="flex items-center justify-between">
                                    <h2 className="text-3xl font-extrabold text-gray-800 mb-3 dark:text-white">
                                        {showArchived ? 'Архив' : 'Чаты'}
                                    </h2>
                                    <div className="flex items-center mb-3 space-x-3">
                                        <button
                                            className="text-violet-500 dark:text-violet-400"
                                            onClick={() => setIsSearchVisible(!isSearchVisible)}
                                        >
                                            <i className="fas fa-search text-xl"></i>
                                        </button>
                                        {!showArchived && (
                                            <button
                                                className="text-violet-500 dark:text-violet-400 relative"
                                                onClick={() => setShowArchived(true)}
                                            >
                                                <i className="fas fa-archive text-xl"></i>
                                                {chats.filter(chat => chat.is_archived).length > 0 && (
                                                    <span
                                                        className="absolute -top-1 -right-1 bg-violet-500 text-white text-xs rounded-full w-4 h-4 flex items-center justify-center">
                                            {chats.filter(chat => chat.is_archived).length}
                                        </span>
                                                )}
                                            </button>
                                        )}
                                        {showArchived && (
                                            <button
                                                className="text-violet-500 dark:text-violet-400"
                                                onClick={() => setShowArchived(false)}
                                            >
                                                <i className="fas fa-comments text-xl"></i>
                                            </button>
                                        )}
                                        <span><CreateChat/></span>
                                    </div>
                                </div>

                                {/* Поиск */}
                                <AnimatePresence>
                                    {isSearchVisible && (
                                        <motion.div
                                            initial={{height: 0, opacity: 0}}
                                            animate={{height: 'auto', opacity: 1}}
                                            exit={{height: 0, opacity: 0}}
                                            transition={{duration: 0.2}}
                                            className="overflow-hidden mb-4"
                                        >
                                            <div className="px-2 py-2">
                                                <div className="relative">
                                                    <i className="fas fa-search absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"></i>
                                                    <input
                                                        type="text"
                                                        placeholder="Поиск"
                                                        className="w-full p-2 pl-10 bg-gray-100 dark:bg-gray-800 rounded-lg text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-violet-500 dark:focus:ring-violet-400"
                                                        value={searchQuery}
                                                        onChange={(e) => setSearchQuery(e.target.value)}
                                                        autoFocus
                                                    />
                                                    {searchQuery && (
                                                        <button
                                                            className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400"
                                                            onClick={() => setSearchQuery('')}
                                                        >
                                                            <i className="fas fa-times"></i>
                                                        </button>
                                                    )}
                                                </div>
                                            </div>
                                        </motion.div>
                                    )}
                                </AnimatePresence>

                                {/* Фильтры чатов */}
                                {!showArchived && !isSearchVisible && (
                                    <div className="flex space-x-2 overflow-x-auto pb-2 mb-2 scrollbar-hide">
                                        <button
                                            className={`px-3 py-1 rounded-full text-sm whitespace-nowrap transition-colors ${activeFilter === 'all' ? 'bg-violet-500 text-white' : 'bg-gray-100 dark:bg-gray-800 text-gray-700 dark:text-gray-300'}`}
                                            onClick={() => setActiveFilter('all')}
                                        >
                                            Все
                                        </button>
                                        <button
                                            className={`px-3 py-1 rounded-full text-sm whitespace-nowrap transition-colors ${activeFilter === 'unread' ? 'bg-violet-500 text-white' : 'bg-gray-100 dark:bg-gray-800 text-gray-700 dark:text-gray-300'}`}
                                            onClick={() => setActiveFilter('unread')}
                                        >
                                            Непрочитанные
                                        </button>
                                        <button
                                            className={`px-3 py-1 rounded-full text-sm whitespace-nowrap transition-colors ${activeFilter === 'personal' ? 'bg-violet-500 text-white' : 'bg-gray-100 dark:bg-gray-800 text-gray-700 dark:text-gray-300'}`}
                                            onClick={() => setActiveFilter('personal')}
                                        >
                                            Личные
                                        </button>
                                        <button
                                            className={`px-3 py-1 rounded-full text-sm whitespace-nowrap transition-colors ${activeFilter === 'groups' ? 'bg-violet-500 text-white' : 'bg-gray-100 dark:bg-gray-800 text-gray-700 dark:text-gray-300'}`}
                                            onClick={() => setActiveFilter('groups')}
                                        >
                                            Группы
                                        </button>
                                    </div>
                                )}
                            </div>

                            {/* Pull-to-refresh индикатор */}
                            {pullDistance > 0 && (
                                <div
                                    className="flex justify-center items-center py-2 text-violet-500 dark:text-violet-400"
                                    style={{height: `${pullDistance}px`}}
                                >
                                    <motion.div
                                        animate={{rotate: refreshing ? 360 : 0}}
                                        transition={{duration: 1, repeat: refreshing ? Infinity : 0, ease: "linear"}}
                                    >
                                        <i className="fas fa-sync-alt"></i>
                                    </motion.div>
                                </div>
                            )}

                            {/* Подсказка о свайпах для мобильных устройств */}
                            {/*{width < 768 && showSwipeHint && (*/}
                            {/*    <motion.div*/}
                            {/*        initial={{ opacity: 0, y: -10 }}*/}
                            {/*        animate={{ opacity: 1, y: 0 }}*/}
                            {/*        className="px-4 py-2 mb-2 text-xs text-gray-500 dark:text-gray-400 text-center bg-white dark:bg-gray-800 rounded-lg shadow-sm"*/}
                            {/*    >*/}
                            {/*        <div className="flex items-center justify-center">*/}
                            {/*            <div className="flex items-center mr-3">*/}
                            {/*                <i className="fas fa-arrow-left text-red-500 mr-1"></i>*/}
                            {/*                <span>Удалить</span>*/}
                            {/*            </div>*/}
                            {/*            <div className="flex items-center">*/}
                            {/*                <i className="fas fa-arrow-right text-violet-500 mr-1"></i>*/}
                            {/*                <span>Закрепить</span>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*    </motion.div>*/}
                            {/*)}*/}

                            {contextMenu.visible && (
                                <div className="fixed z-50 inset-0 overflow-y-auto bg-black/10 dark:bg-black/30"
                                     onClick={handleCloseContextMenu}>
                                    <motion.div
                                        className="context-menu absolute z-50 p-2 bg-white shadow-lg rounded-lg dark:bg-neutral-800"
                                        style={{
                                            position: 'absolute',
                                            top: width < 768 ? 'auto' : contextMenu.position.y,
                                            left: width < 768 ? 0 : contextMenu.position.x,
                                            right: width < 768 ? 0 : 'auto',
                                            bottom: width < 768 ? 0 : 'auto',
                                            width: width < 768 ? '100%' : 'auto'
                                        }}
                                        ref={contextMenuRef}
                                        initial={{opacity: 0, scale: 0.9}}
                                        animate={{opacity: 1, scale: 1}}
                                        exit={{opacity: 0, scale: 0.9, transition: {duration: 0.3}}}
                                        onClick={(e) => e.stopPropagation()}
                                    >
                                        <button
                                            onClick={() => {
                                                const chat = chats.find(chat => chat.id === contextMenu.chatId);
                                                const action = chat?.is_read ? 'mark-as-unread' : 'mark-as-read';
                                                handleContextMenuAction(action);
                                            }}
                                            className="flex items-center w-full px-4 py-3 text-sm hover:bg-gray-100 hover:rounded-lg dark:text-white dark:hover:bg-neutral-700 focus:outline-none"
                                        >
                                            <i className={`${chats.find(chat => chat.id === contextMenu.chatId)?.is_read ? 'fa-regular fa-envelope' : 'fa-regular fa-envelope-open'} mr-4 text-violet-500`}></i>
                                            {chats.find(chat => chat.id === contextMenu.chatId)?.is_read ? 'Отметить как непрочитанное' : 'Отметить как прочитанное'}
                                        </button>
                                        <button
                                            onClick={() => {
                                                handlePinChat(contextMenu.chatId);
                                                handleCloseContextMenu();
                                            }}
                                            className="flex items-center w-full px-4 py-3 text-sm hover:bg-gray-100 hover:rounded-lg dark:text-white dark:hover:bg-neutral-700 focus:outline-none"
                                        >
                                            <i className="fas fa-thumbtack mr-4 text-violet-500"></i>
                                            {chats.find(chat => chat.id === contextMenu.chatId)?.is_pinned ? 'Открепить' : 'Закрепить'}
                                        </button>
                                        <button
                                            onClick={() => {
                                                handleArchiveChat(contextMenu.chatId);
                                                handleCloseContextMenu();
                                            }}
                                            className="flex items-center w-full px-4 py-3 text-sm hover:bg-gray-100 hover:rounded-lg dark:text-white dark:hover:bg-neutral-700 focus:outline-none"
                                        >
                                            <i className="fas fa-archive mr-4 text-violet-500"></i>
                                            {chats.find(chat => chat.id === contextMenu.chatId)?.is_archived ? 'Разархивировать' : 'Архивировать'}
                                        </button>
                                        <button
                                            onClick={() => {
                                                handleDeleteConfirmation(contextMenu.chatId);
                                                handleCloseContextMenu();
                                            }}
                                            className="flex items-center w-full px-4 py-3 text-sm text-red-500 hover:bg-gray-100 hover:rounded-lg dark:text-red-500 dark:hover:bg-neutral-700 focus:outline-none"
                                        >
                                            <i className="fas fa-trash mr-4"></i>Удалить
                                        </button>

                                        {/* Кнопка закрытия для мобильных устройств */}
                                        {width < 768 && (
                                            <button
                                                onClick={handleCloseContextMenu}
                                                className="flex items-center justify-center w-full px-4 py-3 mt-2 text-sm text-gray-700 dark:text-gray-200 bg-gray-100 dark:bg-gray-700 rounded-lg"
                                            >
                                                Отмена
                                            </button>
                                        )}
                                    </motion.div>
                                </div>
                            )}

                            {/* Пустое состояние для фильтров */}
                            {filteredChats.length === 0 && (
                                <div className="flex flex-col items-center justify-center py-10">
                                    <div
                                        className="w-16 h-16 rounded-full bg-gray-100 dark:bg-gray-800 flex items-center justify-center mb-4">
                                        <i className="fas fa-search text-gray-400 text-xl"></i>
                                    </div>
                                    <p className="text-gray-500 dark:text-gray-400 text-center">
                                        {searchQuery ? 'Ничего не найдено' : 'Нет чатов, соответствующих фильтру'}
                                    </p>
                                    {searchQuery && (
                                        <button
                                            className="mt-4 px-4 py-2 bg-violet-500 text-white rounded-lg"
                                            onClick={() => setSearchQuery('')}
                                        >
                                            Сбросить поиск
                                        </button>
                                    )}
                                    {!searchQuery && activeFilter !== 'all' && (
                                        <button
                                            className="mt-4 px-4 py-2 bg-violet-500 text-white rounded-lg"
                                            onClick={() => setActiveFilter('all')}
                                        >
                                            Показать все чаты
                                        </button>
                                    )}
                                </div>
                            )}

                            <ul className="space-y-3">
                                {/* Закрепленные чаты */}
                                {filteredChats.some(chat => chat.is_pinned) && (
                                    <div
                                        className="text-xs font-medium text-gray-500 dark:text-gray-400 px-2 py-1 mb-1 mt-2">
                                        ЗАКРЕПЛЕННЫЕ
                                    </div>
                                )}

                                {filteredChats.filter(chat => chat.is_pinned).map((chat) => (
                                    <li key={chat.id} className="relative">
                                        <motion.button
                                            data-chat-id={chat.id}
                                            className={`chat-button w-full text-left relative rounded-xl overflow-hidden transition-all duration-200 ${
                                                selectedChatId === chat.id
                                                    ? 'bg-violet-50 dark:bg-violet-700 shadow-md'
                                                    : 'bg-white dark:bg-neutral-700 hover:bg-gray-50 dark:hover:bg-neutral-600'
                                            }`}
                                            style={{transform: `translateX(${swipePosition[chat.id] || 0}px)`}}
                                            onClick={() => setSelectedChatId(chat.id)}
                                            onContextMenu={(e) => handleContextMenu(e, chat.id)}
                                            onTouchStart={(e) => {
                                                // Настройка долгого нажатия
                                                const timer = setTimeout(() => {
                                                    handleLongPress(e, chat.id);
                                                }, 500);

                                                // Очистка таймера при движении или отпускании
                                                const clearTimer = () => clearTimeout(timer);
                                                e.currentTarget.addEventListener('touchmove', clearTimer, {once: true});
                                                e.currentTarget.addEventListener('touchend', clearTimer, {once: true});
                                            }}
                                        >
                                            <motion.div
                                                className="flex p-3 rounded-lg"
                                                initial={{opacity: 0, scale: 0.95}}
                                                animate={{opacity: 1, scale: 1}}
                                                exit={{opacity: 0, scale: 0.95}}
                                                transition={{type: 'spring', stiffness: 200, damping: 20}}
                                            >
                                                <div className="relative mr-4">
                                                    <UserAvatar
                                                        user={chat.user1_id === parseInt(localStorage.getItem('user_id'), 10) ? chat.user2 : chat.user1}
                                                        size="md" showStatus={true}/>
                                                </div>
                                                <div className="flex-1 min-w-0">
                                                    <div className="flex items-center">
                                                        <p className={`text-[clamp(16px,1.5vw,18px)] font-semibold text-gray-700 dark:text-white ${width < 768 ? 'truncate text-sm' : ''}`}>
                                                            {getChatPartnerName(chat)}
                                                            <i className="fas fa-thumbtack ml-2 text-violet-500 dark:text-violet-300 rotate-45 text-xs"></i>
                                                        </p>
                                                    </div>
                                                    <div className="flex items-center">
                                                        {typingStatus[chat.id]?.isTyping ? (
                                                            <p className="text-[clamp(14px,1.5vw,15px)] text-violet-500 dark:text-violet-400 truncate">
                                                                <motion.span
                                                                    initial={{opacity: 0.5}}
                                                                    animate={{opacity: 1}}
                                                                    transition={{
                                                                        repeat: Infinity,
                                                                        repeatType: "reverse",
                                                                        duration: 0.8
                                                                    }}
                                                                >
                                                                    печатает...
                                                                </motion.span>
                                                            </p>
                                                        ) : (
                                                            <p className={`text-[clamp(14px,1.5vw,15px)] ${chat.unread_count > 0 ? 'text-gray-700 dark:text-gray-200 font-medium' : 'text-gray-500 dark:text-gray-300'} truncate`}>
                                                                {getLastMessage(chat)}
                                                                {getMessageStatusIcon(chat)}
                                                            </p>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="flex flex-col items-end ml-2">
                                                    <p className={`text-xs ${chat.unread_count > 0 ? 'text-violet-500 dark:text-violet-400 font-medium' : 'text-gray-500 dark:text-gray-400'} mt-1 whitespace-nowrap`}>
                                                        {getLastMessageTime(chat)}
                                                    </p>
                                                    {chat.unread_count > 0 && (
                                                        <motion.span
                                                            initial={{scale: 0.8}}
                                                            animate={{scale: 1}}
                                                            className="text-white bg-violet-500 rounded-full text-xs w-5 h-5 flex items-center justify-center mt-1"
                                                        >
                                                            {chat.unread_count}
                                                        </motion.span>
                                                    )}
                                                </div>
                                            </motion.div>
                                        </motion.button>

                                        {/* Индикаторы свайпа (видны только при активном свайпе) */}
                                        {width < 768 && swipePosition[chat.id] && (
                                            <>
                                                {swipePosition[chat.id] < 0 && (
                                                    <div
                                                        className="absolute top-0 right-0 bottom-0 flex items-center justify-center px-4 text-red-500"
                                                        style={{opacity: Math.min(1, Math.abs(swipePosition[chat.id]) / 80)}}
                                                    >
                                                        <i className="fas fa-trash"></i>
                                                    </div>
                                                )}
                                                {swipePosition[chat.id] > 0 && (
                                                    <div
                                                        className="absolute top-0 left-0 bottom-0 flex items-center justify-center px-4 text-violet-500"
                                                        style={{opacity: Math.min(1, Math.abs(swipePosition[chat.id]) / 80)}}
                                                    >
                                                        <i className="fas fa-thumbtack"></i>
                                                    </div>
                                                )}
                                            </>
                                        )}
                                    </li>
                                ))}

                                {/* Заголовок для обычных чатов */}
                                {filteredChats.some(chat => chat.is_pinned) && filteredChats.some(chat => !chat.is_pinned) && (
                                    <div
                                        className="text-xs font-medium text-gray-500 dark:text-gray-400 px-2 py-1 mb-1 mt-3">
                                        ВСЕ ЧАТЫ
                                    </div>
                                )}

                                {/* Обычные чаты */}
                                {filteredChats.filter(chat => !chat.is_pinned).map((chat) => (
                                    <li key={chat.id} className="relative">
                                        <motion.button
                                            data-chat-id={chat.id}
                                            className={`chat-button w-full text-left relative rounded-xl overflow-hidden transition-all duration-200 ${
                                                selectedChatId === chat.id
                                                    ? 'bg-violet-50 dark:bg-violet-700 shadow-md'
                                                    : 'bg-white dark:bg-neutral-700 hover:bg-gray-50 dark:hover:bg-neutral-600'
                                            }`}
                                            style={{transform: `translateX(${swipePosition[chat.id] || 0}px)`}}
                                            onClick={() => setSelectedChatId(chat.id)}
                                            onContextMenu={(e) => handleContextMenu(e, chat.id)}
                                            onTouchStart={(e) => {
                                                // Настройка долгого нажатия
                                                const timer = setTimeout(() => {
                                                    handleLongPress(e, chat.id);
                                                }, 500);

                                                // Очистка таймера при движении или отпускании
                                                const clearTimer = () => clearTimeout(timer);
                                                e.currentTarget.addEventListener('touchmove', clearTimer, {once: true});
                                                e.currentTarget.addEventListener('touchend', clearTimer, {once: true});
                                            }}
                                        >
                                            <motion.div
                                                className="flex p-3 rounded-lg"
                                                initial={{opacity: 0, scale: 0.95}}
                                                animate={{opacity: 1, scale: 1}}
                                                exit={{opacity: 0, scale: 0.95}}
                                                transition={{type: 'spring', stiffness: 200, damping: 20}}
                                            >
                                                <div className="relative mr-4">
                                                    <UserAvatar
                                                        user={chat.user1_id === parseInt(localStorage.getItem('user_id'), 10) ? chat.user2 : chat.user1}
                                                        size="md" showStatus={true}/>
                                                </div>
                                                <div className="flex-1 min-w-0">
                                                    <div className="flex items-center">
                                                        <p className={`text-[clamp(16px,1.5vw,18px)] font-semibold text-gray-700 dark:text-white ${width < 768 ? 'truncate text-sm' : ''}`}>
                                                            {getChatPartnerName(chat)}
                                                            {chat.is_group && (
                                                                <i className="fas fa-users ml-2 text-gray-400 dark:text-gray-500 text-xs"></i>
                                                            )}
                                                        </p>
                                                    </div>
                                                    <div className="flex items-center">
                                                        {typingStatus[chat.id]?.isTyping ? (
                                                            <p className="text-[clamp(14px,1.5vw,15px)] text-violet-500 dark:text-violet-400 truncate">
                                                                <motion.span
                                                                    initial={{opacity: 0.5}}
                                                                    animate={{opacity: 1}}
                                                                    transition={{
                                                                        repeat: Infinity,
                                                                        repeatType: "reverse",
                                                                        duration: 0.8
                                                                    }}
                                                                >
                                                                    печатает...
                                                                </motion.span>
                                                            </p>
                                                        ) : (
                                                            <p className={`text-[clamp(14px,1.5vw,15px)] ${chat.unread_count > 0 ? 'text-gray-700 dark:text-gray-200 font-medium' : 'text-gray-500 dark:text-gray-300'} truncate`}>
                                                                {getLastMessage(chat)}
                                                                {getMessageStatusIcon(chat)}
                                                            </p>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="flex flex-col items-end ml-2">
                                                    <p className={`text-xs ${chat.unread_count > 0 ? 'text-violet-500 dark:text-violet-400 font-medium' : 'text-gray-500 dark:text-gray-400'} mt-1 whitespace-nowrap`}>
                                                        {getLastMessageTime(chat)}
                                                    </p>
                                                    {chat.unread_count > 0 && (
                                                        <motion.span
                                                            initial={{scale: 0.8}}
                                                            animate={{scale: 1}}
                                                            className="text-white bg-violet-500 rounded-full text-xs w-5 h-5 flex items-center justify-center mt-1"
                                                        >
                                                            {chat.unread_count}
                                                        </motion.span>
                                                    )}
                                                </div>
                                            </motion.div>
                                        </motion.button>

                                        {/* Индикаторы свайпа (видны только при активном свайпе) */}
                                        {width < 768 && swipePosition[chat.id] && (
                                            <>
                                                {swipePosition[chat.id] < 0 && (
                                                    <div
                                                        className="absolute top-0 right-0 bottom-0 flex items-center justify-center px-4 text-red-500"
                                                        style={{opacity: Math.min(1, Math.abs(swipePosition[chat.id]) / 80)}}
                                                    >
                                                        <i className="fas fa-trash"></i>
                                                    </div>
                                                )}
                                                {swipePosition[chat.id] > 0 && (
                                                    <div
                                                        className="absolute top-0 left-0 bottom-0 flex items-center justify-center px-4 text-violet-500"
                                                        style={{opacity: Math.min(1, Math.abs(swipePosition[chat.id]) / 80)}}
                                                    >
                                                        <i className="fas fa-thumbtack"></i>
                                                    </div>
                                                )}
                                            </>
                                        )}
                                    </li>
                                ))}
                            </ul>
                        </div>

                        {isDeleteConfirmVisible && (
                            <ConfirmationModal onConfirm={confirmDeleteChat} onCancel={closeDeleteConfirmation}/>
                        )}


                        <div className={`p-4 ${selectedChatId ? 'block' : 'hidden lg:block'} lg:w-2/3 pt-6`}>
                            <AnimatePresence mode="wait">
                                {!isClosing && selectedChatId && (
                                    <motion.div
                                        key={selectedChatId}
                                        initial={{opacity: 0, x: 20}}
                                        animate={{opacity: 1, x: 0}}
                                        exit={{opacity: 0, x: 20}}
                                        transition={{type: 'spring', stiffness: 300, damping: 30}}
                                    >
                                        <Chat
                                            chatId={selectedChatId}
                                            onNewMessage={handleSendMessage}
                                            onCloseChat={() => setSelectedChatId(null)}
                                        />
                                    </motion.div>
                                )}
                            </AnimatePresence>

                            {!selectedChatId && (
                                <div
                                    className="flex flex-col items-center justify-center h-[70vh] text-gray-500 dark:text-gray-400">
                                    <div
                                        className="w-20 h-20 rounded-full bg-violet-100 dark:bg-violet-900/30 flex items-center justify-center mb-4">
                                        <i className="fas fa-comments text-violet-500 dark:text-violet-400 text-3xl"></i>
                                    </div>
                                    <h3 className="text-xl font-semibold text-gray-800 dark:text-white mb-2">
                                        Выберите чат
                                    </h3>
                                    <p className="text-gray-500 dark:text-gray-400 text-center max-w-md">
                                        Выберите чат из списка или создайте новый, чтобы начать общение
                                    </p>
                                </div>
                            )}
                        </div>
                        {!selectedChatId && width < 768 && <BottomNav/>}
                    </div>
                </div>
                );
                }

                export default ChatList;
