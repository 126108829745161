import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from "react-router-dom";
import {Users, ChevronRight, FolderX, UserSearch} from 'lucide-react';
import { motion } from 'framer-motion';
import LatestNews from "./LatestNews";

function RightSidebar({ groupId }) {
    const [recommendedGroups, setRecommendedGroups] = useState(null);

    const fetchRecommendedGroups = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/groups/${groupId}/recommendations`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
            setRecommendedGroups(response.data);
        } catch (error) {
            console.error('Error fetching recommended groups:', error);
            setRecommendedGroups([]); // Пустой массив, если произошла ошибка
        }
    };

    useEffect(() => {
        fetchRecommendedGroups();
    }, []);

    const pluralizeMembers = (count) => {
        const lastDigit = count % 10;
        if (count > 10 && count < 20) return 'подписчиков';
        if (lastDigit === 1) return 'подписчик';
        if (lastDigit > 1 && lastDigit < 5) return 'подписчика';
        return 'подписчиков';
    };

    return (
        <aside className="w-full lg:w-1/3 p-6 pt-16 mt-2 space-y-6 sticky top-0 h-screen overflow-y-auto flex flex-col">
            <motion.section
                initial={{opacity: 0, y: 20}}
                animate={{opacity: 1, y: 0}}
                transition={{duration: 0.5}}
                className="bg-white dark:bg-neutral-800 rounded-3xl p-4 shadow-lg"
            >
                <div className="flex items-center mb-4">
                    <h2 className="text-xl font-bold text-gray-800 dark:text-gray-100">Последние новости</h2>
                    <span
                        className="ml-auto px-2 text-sm font-medium bg-purple-500 dark:bg-purple-600 text-white rounded-full">New</span>
                </div>
                <LatestNews/>
            </motion.section>
            {recommendedGroups !== null && (
                <motion.section
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.4 }}
                    className="bg-white dark:bg-neutral-800 rounded-3xl p-5 shadow-xl hover:shadow-md transition-shadow duration-300 "
                >
                    <div className="flex items-center justify-between mb-5">
                        <h2 className="text-xl font-bold text-gray-900 dark:text-gray-100 flex items-center gap-2">
                            Рекомендованные группы
                        </h2>
                    </div>

                    {recommendedGroups.length > 0 ? (
                        <motion.ul
                            className="space-y-3"
                            initial="hidden"
                            animate="show"
                            variants={{
                                hidden: { opacity: 0 },
                                show: {
                                    opacity: 1,
                                    transition: {
                                        staggerChildren: 0.1
                                    }
                                }
                            }}
                        >
                            {recommendedGroups.map((group) => (
                                <motion.li
                                    key={group.id}
                                    variants={{
                                        hidden: { opacity: 0, y: 10 },
                                        show: { opacity: 1, y: 0 }
                                    }}
                                >
                                    <Link
                                        to={`/group/${group.id}`}
                                        className="flex items-center rounded-xl bg-gray-50/50 dark:bg-neutral-700/20 hover:bg-purple-50/30 dark:hover:bg-neutral-700/40 transition-colors duration-200 group"
                                    >
                                        <div className="relative">
                                            <img
                                                src={group.avatar ? `${process.env.REACT_APP_BASE_URL}/storage/${group.avatar}` : 'https://via.placeholder.com/600x400.png?text=Group+Avatar'}
                                                alt={group.name}
                                                className="w-12 h-12 rounded-lg object-cover border-2 border-white dark:border-neutral-800 shadow-sm"
                                            />
                                            <div className="absolute inset-0 bg-gradient-to-t from-black/20 to-transparent rounded-lg" />
                                        </div>

                                        <div className="ml-4 flex-1 min-w-0">
                                            <h3 className="text-base font-semibold text-gray-900 dark:text-gray-100 truncate">
                                                {group.name}
                                            </h3>
                                            <p className="text-xs text-gray-500 dark:text-neutral-400 mt-1">
                                                {group.members_count} {pluralizeMembers(group.members_count)}
                                            </p>
                                        </div>

                                        <ChevronRight className="w-5 h-5 text-gray-400 dark:text-neutral-500 ml-4 group-hover:text-purple-500 transition-colors" />
                                    </Link>
                                </motion.li>
                            ))}
                        </motion.ul>
                    ) : (
                        <div className="flex items-center justify-center py-6">
                            <div className="text-center">
                                <UserSearch className="w-16 h-16 mx-auto text-gray-400 dark:text-neutral-600" />
                                <p className="text-sm text-gray-500 dark:text-neutral-400">
                                    Мы не нашли подходящие группы
                                </p>
                            </div>
                        </div>
                    )}
                </motion.section>
            )}

            {recommendedGroups === null && (
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    className="bg-white dark:bg-neutral-800 rounded-2xl p-5 shadow-sm"
                >
                    <div className="flex items-center mb-5">
                        <div className="w-40 h-5 bg-gray-200 dark:bg-neutral-700 rounded animate-pulse" />
                    </div>

                    <div className="space-y-3">
                        {[...Array(3)].map((_, i) => (
                            <div
                                key={i}
                                className="flex items-center rounded-xl bg-gray-100/30 dark:bg-neutral-700/20 animate-pulse"
                            >
                                <div className="w-12 h-12 bg-gray-200 dark:bg-neutral-700 rounded-lg" />
                                <div className="ml-4 flex-1 space-y-2">
                                    <div className="w-3/4 h-4 bg-gray-200 dark:bg-neutral-700 rounded" />
                                    <div className="w-1/2 h-3 bg-gray-200 dark:bg-neutral-700 rounded" />
                                </div>
                                <div className="w-5 h-5 bg-gray-200 dark:bg-neutral-700 rounded-full ml-4" />
                            </div>
                        ))}
                    </div>
                </motion.div>
            )}
        </aside>
    );
}

export default RightSidebar;
