import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { usePlayer } from "../context/PlayerContext";
import BackLink from "./BackLink";
import { motion, AnimatePresence } from "framer-motion";
import { ClipLoader } from "react-spinners";
import useWindowSize from "../hooks/useWindowSize";
import BottomNav from "./BottomNav";
import Header from "./Header";
import {formatArtists, formatDuration} from "./utils/helpers";

// Иконки
import { FiMoreVertical, FiHeart, FiHeart as FiHeartFilled } from 'react-icons/fi';

// Компонент контекстного меню (три точки)
import TrackContextMenu from "./TrackContextMenu";

// Для всплывающих уведомлений
import toast from "react-hot-toast";

const AlbumDetails = () => {
    const { id } = useParams();
    const { playTrack, currentTrack, isPlaying } = usePlayer();

    // Стейт для данных об альбоме
    const [album, setAlbum] = useState(null);

    // Стейт для загрузки
    const [loading, setLoading] = useState(true);

    // Стейт для списка треков (чтобы отдельно управлять, например, is_favorite)
    const [tracks, setTracks] = useState([]);

    // Контекстное меню
    const [contextMenuTrackId, setContextMenuTrackId] = useState(null);
    const [contextMenuPosition, setContextMenuPosition] = useState({ x: 0, y: 0 });

    // Список всех плейлистов (для добавления трека в конкретный плейлист)
    const [playlists, setPlaylists] = useState([]);

    // Для вывода ошибок при загрузке
    const [error, setError] = useState(null);

    // Ширина окна, чтобы понять, нужно ли показывать BottomNav
    const { width } = useWindowSize();

    // 1. Загрузка альбома
    useEffect(() => {
        const fetchAlbum = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/albums/${id}`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                });
                if (!response.ok) {
                    throw new Error("Не удалось загрузить альбом");
                }
                const data = await response.json();
                setAlbum(data);
                if (data?.tracks) {
                    setTracks(data.tracks);
                }
            } catch (err) {
                console.error("Ошибка загрузки альбома:", err);
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchAlbum();
    }, [id]);

    // 2. Загрузка плейлистов
    useEffect(() => {
        const fetchPlaylists = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/playlists`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                });
                if (!response.ok) throw new Error("Ошибка загрузки плейлистов");
                const data = await response.json();
                setPlaylists(data);
            } catch (err) {
                setError(err.message);
            }
        };

        fetchPlaylists();
    }, []);

    // --- Функция добавления/удаления из избранного ---
    const toggleFavorite = async (trackId) => {
        try {
            const track = tracks.find((t) => t.id === trackId);
            if (!track) return;
            const method = track.is_favorite ? "DELETE" : "POST";

            const response = await fetch(
                `${process.env.REACT_APP_API_URL}/tracks/${trackId}/favorite`,
                {
                    method,
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                }
            );

            if (!response.ok) throw new Error("Ошибка обновления избранного");

            // Обновляем состояние треков
            setTracks(
                tracks.map((t) =>
                    t.id === trackId ? { ...t, is_favorite: !t.is_favorite } : t
                )
            );
        } catch (err) {
            console.error(err);
            toast.error("Ошибка при обновлении избранного");
        }
    };

    // --- Контекстное меню (три точки) ---
    const handleOpenContextMenu = (trackId, event) => {
        event.stopPropagation();
        setContextMenuTrackId(trackId);
        setContextMenuPosition({
            x: event.clientX,
            y: event.clientY,
        });
    };

    const handleCloseContextMenu = () => {
        setContextMenuTrackId(null);
    };

    // --- Создание нового плейлиста ---
    const handleCreatePlaylist = async (formData) => {
        try {
            const response = await fetch(
                `${process.env.REACT_APP_API_URL}/playlists`,
                {
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                        // Добавляем заголовок для FormData
                    },
                    body: formData
                }
            );

            // Обрабатываем не-JSON ответы
            const contentType = response.headers.get("content-type");
            const responseData = contentType?.includes("application/json")
                ? await response.json()
                : await response.text();

            if (!response.ok) {
                console.error('Ошибка:', responseData);
                throw new Error(responseData.message || "Ошибка создания плейлиста");
            }

            setPlaylists([...playlists, responseData]);
            toast.success('Плейлист успешно создан!');
            return responseData;
        } catch (err) {
            console.error('Полная ошибка:', err);
            toast.error(err.message || "Ошибка при создании плейлиста");
            throw err;
        }
    };

    // --- Добавление трека в существующий плейлист ---
    const handleAddToPlaylist = async (playlistId, trackId) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/playlists/${playlistId}/tracks`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ track_ids: [trackId] }),
            });

            if (!response.ok) {
                const errorData = await response.json().catch(() => null);
                console.error("Ошибка сервера:", errorData);
                throw new Error("Ошибка добавления трека в плейлист");
            }

            toast.success("Трек успешно добавлен в плейлист!");
        } catch (err) {
            console.error("Ошибка добавления трека в плейлист:", err);
            toast.error("Не удалось добавить трек в плейлист");
        }
    };

    // --- Состояние загрузки ---
    if (loading) {
        return (
            <div className="flex justify-center items-center h-screen bg-gradient-to-b from-indigo-50 to-white dark:from-neutral-900 dark:to-neutral-800">
                <ClipLoader
                    color="#6366f1"
                    loading={loading}
                    size={50}
                    cssOverride={{
                        borderWidth: '3px',
                        borderLeftColor: 'transparent'
                    }}
                />
            </div>
        );
    }

    // --- Ошибка или альбом не найден ---
    if (!album || error) {
        return (
            <div className="flex flex-col items-center justify-center h-screen bg-gradient-to-b from-indigo-50 to-white dark:from-neutral-900 dark:to-neutral-800">
                <div className="text-6xl mb-4">🎵</div>
                <p className="text-center text-xl text-neutral-600 dark:text-neutral-300 font-medium mb-6">
                    {error || "Альбом не найден"}
                </p>
                <BackLink className="px-6 py-2 bg-indigo-500 text-white rounded-full hover:bg-indigo-600 transition-colors" />
            </div>
        );
    }

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
            className="bg-gradient-to-b from-indigo-50 to-white dark:from-neutral-900 dark:to-neutral-800 min-h-screen pb-36 pt-16 relative"
        >
            <Header isMusicPage />
            <div className="container mx-auto px-4">
                <BackLink className="mb-6" />
                {width < 768 && <BottomNav />}

                {/* Шапка альбома */}
                <div className="grid md:grid-cols-[auto_1fr] gap-8 mb-8 p-6 bg-white dark:bg-neutral-800/50 rounded-2xl shadow-2xl border border-neutral-100 dark:border-neutral-700">
                    <motion.div
                        initial={{ scale: 0.9, opacity: 0 }}
                        animate={{ scale: 1, opacity: 1 }}
                        transition={{ duration: 0.6 }}
                        className="flex justify-center md:justify-start"
                    >
                        <img
                            src={
                                album.album_art
                                    ? `${process.env.REACT_APP_BASE_URL}/storage/${album.album_art}`
                                    : "placeholder.png"
                            }
                            alt={album.title}
                            className="w-52 h-52 md:w-64 md:h-64 rounded-2xl object-cover shadow-2xl ring-4 ring-white/50 dark:ring-neutral-700/50"
                        />
                    </motion.div>

                    <div
                        className={`flex flex-col justify-center ${
                            width < 768 ? "text-center items-center" : "text-left"
                        }`}
                    >
                        <span className="text-sm font-semibold text-indigo-600 dark:text-indigo-400 mb-2 uppercase tracking-wider">
                            Альбом
                        </span>
                        <h2 className="text-3xl md:text-4xl font-bold text-neutral-900 dark:text-white mb-3 leading-tight">
                            {album.title}
                        </h2>
                        <p className="text-lg font-medium text-neutral-600 dark:text-neutral-300 mb-4">
                            {formatArtists(album)}
                        </p>

                        <div className="flex flex-wrap gap-3 mb-6 justify-center md:justify-start">
                            <div className="px-3 py-1 bg-neutral-100 dark:bg-neutral-700 text-neutral-600 dark:text-neutral-300 rounded-full text-sm">
                                {tracks.length} треков
                            </div>
                            <div className="px-3 py-1 bg-neutral-100 dark:bg-neutral-700 text-neutral-600 dark:text-neutral-300 rounded-full text-sm">
                                Год:{" "}
                                {album.year ||
                                    new Date(album.created_at).getFullYear() ||
                                    "Неизвестно"}
                            </div>
                        </div>

                        <button
                            className="px-6 py-3 bg-indigo-600 hover:bg-indigo-700 text-white font-semibold rounded-full flex items-center gap-3 transition-all shadow-lg shadow-indigo-500/30 hover:shadow-indigo-500/40"
                            onClick={() => tracks.length > 0 && playTrack(tracks[0])}
                        >
                            <i className="fas fa-play" />
                            Воспроизвести
                        </button>
                    </div>
                </div>

                {/* Секция треков */}
                <div className="bg-white dark:bg-neutral-800/50 rounded-2xl shadow-2xl border border-neutral-100 dark:border-neutral-700 p-6 mb-6 relative">
                    <h3 className="text-xl font-bold text-neutral-900 dark:text-white mb-4">
                        Треки
                    </h3>
                    <AnimatePresence>
                        <ul className="space-y-2">
                            {tracks.map((track, index) => {
                                const isCurrent = currentTrack?.id === track.id;
                                const isFavorite = track.is_favorite;

                                return (
                                    <motion.li
                                        key={track.id}
                                        initial={{ opacity: 0, x: -20 }}
                                        animate={{ opacity: 1, x: 0 }}
                                        exit={{ opacity: 0, x: 20 }}
                                        transition={{ duration: 0.3, delay: index * 0.05 }}
                                        className={`
                                            flex items-center p-3 rounded-xl transition-all duration-300
                                            ${
                                            isCurrent
                                                ? "bg-indigo-50 dark:bg-indigo-900/20 ring-1 ring-indigo-200 dark:ring-indigo-700/50"
                                                : "hover:bg-neutral-100 dark:hover:bg-neutral-700/50"
                                        }
                                        `}
                                    >
                                        {/* Обложка трека */}
                                        <img
                                            src={
                                                track.album_art
                                                    ? `${process.env.REACT_APP_BASE_URL}/storage/${track.album_art}`
                                                    : "/placeholder.png"
                                            }
                                            alt={`${track.title} Album Art`}
                                            className="w-14 h-14 rounded-lg shadow-sm object-cover mr-4"
                                        />

                                        {/* Информация о треке */}
                                        <div className="flex-1 overflow-hidden min-w-0 mr-4">
                                            <h3
                                                className={`
                                                    font-semibold text-lg truncate 
                                                    ${
                                                    isCurrent
                                                        ? "text-indigo-600 dark:text-indigo-400"
                                                        : "text-neutral-900 dark:text-white"
                                                }
                                                `}
                                            >
                                                {track.title}
                                            </h3>
                                            <p className="text-neutral-500 dark:text-neutral-400 text-sm truncate">
                                                {formatArtists(track)}
                                            </p>
                                        </div>

                                        {/* Длительность трека */}
                                        {track.duration && (
                                            <div className="text-sm text-neutral-500 dark:text-neutral-400 mr-4">
                                                {formatDuration(track.duration)}
                                            </div>
                                        )}

                                        {/* Кнопка избранного */}
                                        <button
                                            className="mr-4 text-neutral-500 dark:text-neutral-400 hover:text-red-500 dark:hover:text-red-400 transition-colors"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                toggleFavorite(track.id);
                                            }}
                                        >
                                            {isFavorite ? (
                                                <FiHeartFilled className="text-red-500 dark:text-red-400" />
                                            ) : (
                                                <FiHeart />
                                            )}
                                        </button>

                                        {/* Кнопка три точки (контекстное меню) */}
                                        <div className="relative">
                                            <button
                                                className="w-10 h-10 flex items-center justify-center rounded-full text-neutral-500 dark:text-neutral-400 hover:bg-neutral-200 dark:hover:bg-neutral-600 transition-colors"
                                                onClick={(e) => handleOpenContextMenu(track.id, e)}
                                            >
                                                <FiMoreVertical />
                                            </button>

                                            {/* Если track.id == contextMenuTrackId, показываем меню */}
                                            {contextMenuTrackId === track.id && (
                                                <div
                                                    style={{
                                                        position: "absolute",
                                                        top: "3rem",
                                                        right: 0,
                                                    }}
                                                >
                                                    <TrackContextMenu
                                                        track={track}
                                                        playlists={playlists}
                                                        onAddToPlaylist={handleAddToPlaylist}
                                                        onCreatePlaylist={handleCreatePlaylist}
                                                        onClose={handleCloseContextMenu}
                                                    />
                                                </div>
                                            )}
                                        </div>

                                        {/* Кнопка плей/пауза */}
                                        <button
                                            className={`
                                                w-12 h-12 ml-2 flex items-center justify-center rounded-full transition-all
                                                ${
                                                isCurrent
                                                    ? "bg-indigo-600 text-white hover:bg-indigo-700 shadow-lg shadow-indigo-500/30"
                                                    : "bg-neutral-100 dark:bg-neutral-700 text-neutral-600 dark:text-neutral-300 hover:bg-neutral-200 dark:hover:bg-neutral-600"
                                            }
                                            `}
                                            onClick={() => playTrack(track)}
                                        >
                                            <i
                                                className={`fas ${
                                                    isCurrent && isPlaying ? "fa-pause" : "fa-play"
                                                }`}
                                            />
                                        </button>
                                    </motion.li>
                                );
                            })}
                        </ul>
                    </AnimatePresence>
                </div>

                {/* Описание альбома */}
                {album.description && (
                    <div className="bg-white dark:bg-neutral-800/50 rounded-2xl shadow-2xl border border-neutral-100 dark:border-neutral-700 p-6">
                        <h3 className="text-xl font-bold text-neutral-900 dark:text-white mb-4">
                            Об альбоме
                        </h3>
                        <p className="text-neutral-600 dark:text-neutral-300 leading-relaxed">
                            {album.description}
                        </p>
                    </div>
                )}
            </div>
        </motion.div>
    );
};

export default AlbumDetails;
