import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import axios from 'axios';
import { AuthContext } from '../context/AuthContext';
import BottomNav from "./BottomNav";
import CustomSkeleton from "./CustomSkeleton";
import useWindowSize from "../hooks/useWindowSize";
import { motion, AnimatePresence } from 'framer-motion';
import ConfirmLogout from "./ConfirmLogout";
import {
    UserCircle, Users2, Compass, Music, ClipboardList,
    Grid2x2Plus, SlidersHorizontal, QrCode, Palette,
    LogOut, ChevronRight, Bell, Moon, Sun, Newspaper
} from "lucide-react";

function MobileMenu() {
    const [user, setUser] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [showConfirmLogout, setShowConfirmLogout] = useState(false);
    const { isLoggedIn, logout } = useContext(AuthContext);
    const { width } = useWindowSize();
    const [activeTheme, setActiveTheme] = useState(
        localStorage.getItem('theme') || 'light'
    );

    const navigate = useNavigate();

    if (width > 768) {
        navigate('/');
    }

    const fetchUser = async () => {
        try {
            const token = localStorage.getItem('token');
            if (!token) return;

            const response = await axios.get(`${process.env.REACT_APP_API_URL}/profile`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setUser(response.data);
        } catch (error) {
            console.error('Ошибка при загрузке профиля!', error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchUser();
    }, []);

    const changeTheme = () => {
        const newTheme = activeTheme === 'dark' ? 'light' : 'dark';
        setActiveTheme(newTheme);
        document.documentElement.classList.toggle('dark');
        localStorage.setItem('theme', newTheme);
    };

    const menuSections = [
        {
            title: "Аккаунт",
            items: [
                { icon: UserCircle, label: "Профиль", link: "/profile" },
                { icon: Users2, label: "Друзья", link: "/friends" },
                { icon: Bell, label: "Уведомления", link: "/notifications" },
            ]
        },
        {
            title: "Контент",
            items: [
                { icon: Compass, label: "Группы", link: "/groups" },
                { icon: Music, label: "Музыка", link: "/music" },
                { icon: ClipboardList, label: "Посты", link: "/posts" },
                { icon: Grid2x2Plus, label: "Сервисы", link: "/services" },
                { icon: Newspaper, label: "Новости", link: "/news" },
                { icon: ClipboardList, label: "Жалобы", link: "/reports" },
            ]
        },
        {
            title: "Настройки",
            items: [
                { icon: SlidersHorizontal, label: "Настройки", link: "/settings" },
                { icon: QrCode, label: "QR-код", link: "/qr-login" },
            ]
        }
    ];

    const handleLogout = () => {
        logout();
        navigate('/login');
    };

    const handleLogoutClick = () => {
        setShowConfirmLogout(true);
    };

    const closeConfirmLogout = () => {
        setShowConfirmLogout(false);
    };

    const confirmLogout = () => {
        setShowConfirmLogout(false);
        handleLogout();
    };

    return (
        <motion.div
            className="flex flex-col w-full h-screen bg-white text-gray-900 dark:bg-neutral-900 dark:text-white px-3 overflow-y-auto md:hidden pt-16 pb-28"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >
            {/* Секция профиля */}
            <motion.div
                className="group relative mb-6"
                whileHover="hover"
            >
                <motion.div
                    className="flex items-center space-x-3 p-3 bg-gray-50 dark:bg-neutral-800 rounded-lg shadow-sm"
                    variants={{
                        hover: {
                            scale: 1.01,
                            boxShadow: "0 5px 15px -5px rgba(168, 85, 247, 0.2)"
                        }
                    }}
                >
                    {isLoading ? (
                        <CustomSkeleton
                            circle={true}
                            width={56}
                            height={56}
                            shimmer={true}
                        />
                    ) : (
                        <motion.div whileHover={{ scale: 1.05 }}>
                            <img
                                src={user?.avatar ? `${process.env.REACT_APP_BASE_URL || ""}/storage/${user?.avatar}`
                                    : `https://ui-avatars.com/api/?name=${user?.name}&background=random`}
                                alt={`Профиль ${user?.name}`}
                                className="w-12 h-12 rounded-full object-cover border-2 border-white dark:border-neutral-700"
                                onError={(e) => e.target.src = "/default-avatar.svg"}
                            />
                        </motion.div>
                    )}
                    <div className="flex-1 min-w-0">
                        {isLoading ? (
                            <>
                                <CustomSkeleton width={140} height={20} shimmer={true} />
                                <CustomSkeleton width={100} height={16} className="mt-1" shimmer={true} />
                            </>
                        ) : (
                            <>
                                <p className="text-lg font-bold truncate text-gray-900 dark:text-white">
                                    {isLoggedIn ? user?.name : "Гость"}
                                </p>
                                <Link
                                    to={isLoggedIn ? "/profile" : "/login"}
                                    className="inline-flex items-center mt-1 text-sm text-purple-600 dark:text-purple-400 hover:text-purple-700 dark:hover:text-purple-300 transition-colors"
                                >
                                    <span>{isLoggedIn ? "Профиль" : "Войти в систему"}</span>
                                    <ChevronRight className="ml-1 w-4 h-4" />
                                </Link>
                            </>
                        )}
                    </div>
                </motion.div>
            </motion.div>

            {/* Меню */}
            <div className="space-y-4">
                {menuSections.map((section, sectionIndex) => (
                    <div key={sectionIndex} className="space-y-1">
                        <motion.h3
                            className="px-2 text-xs font-semibold text-gray-500 dark:text-gray-400 uppercase tracking-wide mb-1"
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ delay: 0.2 * sectionIndex }}
                        >
                            {section.title}
                        </motion.h3>
                        <motion.div
                            className="bg-gray-50 dark:bg-neutral-800 rounded-lg p-1.5 space-y-0.5"
                            layout
                        >
                            {section.items.map((item, itemIndex) => (
                                <motion.div
                                    key={itemIndex}
                                    initial={{ opacity: 0, x: -20 }}
                                    animate={{ opacity: 1, x: 0 }}
                                >
                                    <Link
                                        to={item.link}
                                        className="flex items-center px-3 py-2 text-gray-700 hover:bg-white dark:hover:bg-neutral-700 dark:text-gray-300 rounded-md transition-all duration-300"
                                    >
                                        <item.icon className="mr-2 w-5 h-5 stroke-current text-purple-600 dark:text-purple-400" />
                                        <span className="font-medium flex-1 text-sm">{item.label}</span>
                                        <ChevronRight className="ml-2 w-4 h-4 text-gray-400" />
                                    </Link>
                                </motion.div>
                            ))}
                        </motion.div>
                    </div>
                ))}
            </div>

            {/* Переключатель темы */}
            <motion.div
                className="mt-4 bg-gray-50 dark:bg-neutral-800 rounded-lg p-1.5"
                whileTap={{ scale: 0.98 }}
            >
                <button
                    onClick={changeTheme}
                    className="flex items-center w-full px-3 py-2 rounded-md hover:bg-white dark:hover:bg-neutral-700 transition-colors duration-300"
                >
                    <Palette className="mr-2 w-5 h-5 stroke-current text-purple-600 dark:text-purple-400" />
                    <span className="font-medium flex-1 text-sm">Тема оформления</span>
                    <motion.div
                        key={activeTheme}
                        initial={{ rotate: 180, scale: 0 }}
                        animate={{ rotate: 0, scale: 1 }}
                        transition={{ type: "spring" }}
                    >
                        {activeTheme === 'dark' ? (
                            <Moon className="w-4 h-4 text-purple-400" />
                        ) : (
                            <Sun className="w-4 h-4 text-yellow-500" />
                        )}
                    </motion.div>
                </button>
            </motion.div>

            {/* Кнопка выхода */}
            {isLoggedIn && (
                <motion.div
                    className="mt-4"
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.4 }}
                >
                    <button
                        onClick={handleLogoutClick}
                        className="w-full flex items-center px-4 py-3 bg-red-50 dark:bg-red-900/20 text-red-600 dark:text-red-400 hover:bg-red-100 dark:hover:bg-red-900/30 rounded-lg transition-colors duration-300"
                    >
                        <LogOut className="mr-2 w-5 h-5 stroke-current" />
                        <span className="font-medium text-sm">Выйти из аккаунта</span>
                    </button>
                </motion.div>
            )}

            {/* Нижняя навигация */}
            <BottomNav />

            <ConfirmLogout
                isOpen={showConfirmLogout}
                onClose={closeConfirmLogout}
                onConfirm={confirmLogout}
            />
        </motion.div>
    );
}

export default MobileMenu;