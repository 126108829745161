import React, {useEffect, useState, useContext, useRef} from 'react';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';
import PostModal from './PostModal';
import CreatePost from './CreatePost';
import useWindowSize from '../hooks/useWindowSize';
import GroupPostList from "./GroupPostList";
import {ClipLoader} from "react-spinners";
import BackLink from "./BackLink";
import { motion, AnimatePresence} from "framer-motion";

const GroupDetails = () => {
    const { id: groupId } = useParams();
    const [group, setGroup] = useState(null);
    const [loading, setLoading] = useState(true);
    const [isMember, setIsMember] = useState(false);
    const [isCreator, setIsCreator] = useState(false);
    const [members, setMembers] = useState([]);
    const [isEditing, setIsEditing] = useState(false);
    const [editFormData, setEditFormData] = useState({
        name: '',
        description: '',
        privacy: 'public',
        avatar: null,
        cover: null,
    });
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showMenu, setShowMenu] = useState(false);
    const [posts, setPosts] = useState([]);
    const [showCreateButton, setShowCreateButton] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { width } = useWindowSize();
    const menuRef = useRef(null);


    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setShowMenu(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [menuRef]);

    useEffect(() => {
        const fetchGroupDetails = async () => {
            if (!groupId) {
                toast.error('Group ID is undefined');
                return;
            }

            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/groups/${groupId}`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                    },
                });

                const groupData = response.data.group;
                setGroup(groupData);
                setIsMember(response.data.is_member);
                setIsCreator(response.data.is_creator);
                setMembers(groupData.members);

                const postsResponse = await axios.get(`${process.env.REACT_APP_API_URL}/groups/${groupId}/posts`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            });

            setPosts(postsResponse.data);

            setEditFormData({
                name: groupData.name || '',
                description: groupData.description || '',
                privacy: groupData.privacy || 'public',
            });
        } catch (error) {
            toast.error('Ошибка при загрузке данных группы');
        } finally {
            setLoading(false);
        }
    };

    fetchGroupDetails();
}, [groupId]);


useEffect(() => {
    let lastScrollY = window.scrollY;

    const handleScroll = () => {
        if (window.scrollY > lastScrollY) {
            setShowCreateButton(false);
        } else {
            setShowCreateButton(true);
        }
        lastScrollY = window.scrollY;
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
        window.removeEventListener('scroll', handleScroll);
    };
}, []);

const openModal = () => {
    setIsModalOpen(true);
};

const closeModal = () => {
    setIsModalOpen(false);
};

const addPost = (newPost) => {
    setPosts([newPost, ...posts]); // Добавляем новый пост в начало списка
};

const handleEdit = () => {
    setIsEditing(true);
    setShowMenu(false);
};

const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === 'avatar' || name === 'cover') {
        setEditFormData((prevData) => ({
            ...prevData,
            [name]: files[0],
        }));
    } else {
        setEditFormData({
            ...editFormData,
            [name]: value,
        });
    }
};

const handleUpdate = async (e) => {
    e.preventDefault();

    try {
        const formData = new FormData();

        if (editFormData.name) {
            formData.append('name', editFormData.name);
        }
        if (editFormData.description) {
            formData.append('description', editFormData.description);
        }
        if (editFormData.privacy) {
            formData.append('privacy', editFormData.privacy);
        }
        if (editFormData.avatar) {
            formData.append('avatar', editFormData.avatar);
        }
        if (editFormData.cover) {
            formData.append('cover', editFormData.cover);
        }

        // Логируем все данные перед отправкой
        for (let pair of formData.entries()) {
            console.log(pair[0] + ': ' + pair[1]);
        }

        const response = await axios.put(`${process.env.REACT_APP_API_URL}/groups/${groupId}, formData`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
                'Content-Type': 'multipart/form-data',
            },
        });

        if (response.data) {
            setGroup(response.data);
        }

        setIsEditing(false);
        toast.success('Группа успешно обновлена');
    } catch (error) {
        if (error.response && error.response.data.errors) {
            Object.entries(error.response.data.errors).forEach(([field, messages]) => {
                toast.error(`${field}: ${messages.join(', ')}`);
            });
        } else {
            toast.error('Ошибка при обновлении группы');
        }
    }
};

const handleDelete = async () => {
    try {
        await axios.delete(`${process.env.REACT_APP_API_URL}/groups/${groupId}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        });
        toast.success('Группа успешно удалена');
        window.location.href = '/groups';
    } catch (error) {
        toast.error('Ошибка удаления группы');
    }
};

const handleJoin = async () => {
    try {
        await axios.post(`${process.env.REACT_APP_API_URL}/groups/${groupId}/join`, {}, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
    });
    const userId = localStorage.getItem('user_id');
    setIsMember(true);
    setMembers((prevMembers) => [...prevMembers, { id: userId, name: localStorage.getItem('user_name') }]);
    toast.success('Вы присоединились к группе');
} catch (error) {
    toast.error('Ошибка присоединения к группе');
}
};

const handleLeave = async () => {
    try {
        await axios.post(`${process.env.REACT_APP_API_URL}/groups/${groupId}/leave`, {}, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
    });
    const userId = localStorage.getItem('user_id');
    setIsMember(false);
    setMembers((prevMembers) => prevMembers.filter((member) => member.id !== userId));
    toast.success('Вы вышли из группы');
} catch (error) {
    toast.error('Ошибка выхода из группы');
}
};

const copyGroupLink = () => {
    const groupLink = `${window.location.origin}/groups/${groupId}`;
    navigator.clipboard.writeText(groupLink);
    toast.success('Ссылка на группу скопирована в буфер обмена');
};

    if (loading) return (
        <div className="flex items-center justify-center min-h-screen w-full bg-white dark:bg-neutral-900">
            <ClipLoader
                color="#6B46C1"
                size={60}
                cssOverride={{
                    display: 'block',
                    margin: '0 auto',
                    borderWidth: '4px'
                }}
            />
        </div>
    );
if (!group) return <p className="text-center">Группа не найдена.</p>;



    return (
        <div className="container mx-auto p-4 min-h-screen dark:bg-neutral-900 pt-16">
            <BackLink />
            <Toaster position="bottom-center"/>

            {/* Обложка группы */}
            <div
                className="relative mx-auto mt-4 max-w-[100%] rounded-2xl overflow-hidden shadow-xl bg-gradient-to-br from-violet-100/50 to-blue-50/50 dark:from-neutral-800/80 dark:to-neutral-700/80 backdrop-blur-sm">
                <div className="aspect-[6/1] min-h-[200px] relative">
                    {group.cover ? (
                        <motion.img
                            initial={{opacity: 0, scale: 1.1}}
                            animate={{opacity: 1, scale: 1}}
                            transition={{duration: 0.5}}
                            src={`${process.env.REACT_APP_BASE_URL}/storage/${group.cover}`}
                            alt="Обложка группы"
                            className="w-full h-full object-cover absolute inset-0"
                        />
                    ) : (
                        <div
                            className="w-full h-full bg-gradient-to-br from-violet-200 to-blue-100 dark:from-neutral-800 dark:to-neutral-700"/>
                    )}

                    <div className="absolute inset-0 bg-gradient-to-t from-black/20 to-transparent"/>
                </div>
            </div>

            {/* Основной контент */}
            <div className="max-w-[100%] mx-auto mt-10">
                {/* Аватар и заголовок */}
                <div className="flex items-end gap-4 mb-6">
                    <motion.div
                        initial={{scale: 0.8}}
                        animate={{scale: 1}}
                        className="w-24 h-24 rounded-2xl border-4 border-white dark:border-neutral-900 shadow-lg overflow-hidden object-cover"
                    >
                        <img
                            src={`${process.env.REACT_APP_BASE_URL}/storage/${group.avatar}`}
                            alt="Аватар группы"
                            className="w-full h-full object-cover"
                        />
                    </motion.div>

                    <div className="flex-1">
                        <h1 className="text-3xl font-bold text-gray-900 dark:text-white mb-1">
                            {group.name}
                        </h1>
                        <p className="text-gray-500 dark:text-neutral-400">
                            {group.members?.length} участников
                        </p>
                    </div>

                    {isCreator && (
                        <motion.div
                            ref={menuRef}
                            className="relative bottom-14">
                            <motion.button
                                whileHover={{ rotate: 90 }}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setShowMenu(!showMenu);
                                }}
                                className="w-10 h-10 flex items-center justify-center rounded-full bg-black/5 dark:bg-white/5 hover:bg-black/10 dark:hover:bg-white/10"
                            >
                                <i className="fas fa-ellipsis-h text-gray-600 dark:text-neutral-300" />
                            </motion.button>

                            <AnimatePresence>
                                {showMenu && (
                                    <motion.div
                                        initial={{ opacity: 0, y: 10 }}
                                        animate={{ opacity: 1, y: 0 }}
                                        exit={{ opacity: 0, y: 10 }}
                                        className="absolute right-0 mt-2 w-48 bg-white dark:bg-neutral-800 rounded-xl shadow-xl border dark:border-neutral-700 overflow-hidden z-50"
                                        onClick={(e) => e.stopPropagation()}
                                    >
                                        <div className="p-2 space-y-1">
                                            <button
                                                onClick={() => {
                                                    copyGroupLink();
                                                    setShowMenu(false);
                                                }}
                                                className="w-full px-3 py-2 text-left text-sm hover:bg-gray-100 dark:hover:bg-neutral-700 dark:text-neutral-300 rounded-lg"
                                            >
                                                Скопировать ссылку
                                            </button>
                                            <button
                                                onClick={() => {
                                                    handleEdit();
                                                    setShowMenu(false);
                                                }}
                                                className="w-full px-3 py-2 text-left text-sm hover:bg-gray-100 dark:hover:bg-neutral-700 rounded-lg dark:text-neutral-300"
                                            >
                                                Редактировать
                                            </button>
                                            <button
                                                onClick={() => {
                                                    setShowDeleteModal(true);
                                                    setShowMenu(false);
                                                }}
                                                className="w-full px-3 py-2 text-left text-sm text-red-500 hover:bg-red-50 dark:hover:bg-red-900/20 rounded-lg"
                                            >
                                                Удалить группу
                                            </button>
                                        </div>
                                    </motion.div>
                                )}
                            </AnimatePresence>
                        </motion.div>
                    )}
                </div>

                {/* Описание и действия */}
                <div className="flex flex-col md:flex-row gap-6 mb-8">
                    <div className="flex-1 space-y-4">
                        <p className="text-gray-600 dark:text-neutral-300 leading-relaxed">
                            {group.description || 'Описание группы пока отсутствует'}
                        </p>

                        <div className="flex gap-3">
                            {!isCreator && (
                                <motion.button
                                    whileHover={{y: -2}}
                                    whileTap={{scale: 0.95}}
                                    onClick={isMember ? handleLeave : handleJoin}
                                    className={`px-4 py-2 rounded-full font-medium transition-colors ${
                                        isMember
                                            ? 'bg-red-500/10 text-red-600 dark:text-red-400'
                                            : 'bg-violet-500/10 text-violet-600 dark:text-violet-400'
                                    }`}
                                >
                                    {isMember ? 'Покинуть группу' : 'Присоединиться'}
                                </motion.button>
                            )}


                        </div>
                    </div>
                </div>

                {/* Список постов */}
                <section className="space-y-4">
                    <div className="flex flex-wrap items-center justify-between sm:px-0">
                        <h2 className="text-xl font-semibold text-gray-900 dark:text-white sm:text-xl">
                            Публикации сообщества
                        </h2>

                        <motion.button
                            whileHover={{scale: 1.05}}
                            whileTap={{scale: 0.95}}
                            onClick={openModal}
                            className="flex items-center gap-2 px-4 py-3 sm:py-2 bg-violet-500 text-white rounded-full shadow-sm"
                        >
                            <i className="fas fa-plus text-sm sm:text-base"/>
                            <span className="hidden sm:inline">Новая публикация</span>
                        </motion.button>
                    </div>

                    <GroupPostList groupId={groupId} posts={posts}/>
                </section>
            </div>

            {/* Модальные окна */}
            <AnimatePresence>
                {isModalOpen && (
                    <PostModal closeModal={closeModal}>
                        <CreatePost addPost={addPost} closeModal={closeModal} groupId={group.id}/>
                    </PostModal>
                )}

                {showDeleteModal && (
                    <motion.div
                        initial={{opacity: 0}}
                        animate={{opacity: 1}}
                        exit={{opacity: 0}}
                        className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center p-4 z-50"
                    >
                        <div className="bg-white dark:bg-neutral-800 rounded-2xl p-6 max-w-md w-full shadow-xl">
                            <h3 className="text-xl font-semibold mb-4 dark:text-white">Удалить группу?</h3>
                            <p className="text-gray-600 dark:text-neutral-300 mb-6">
                                Все публикации и данные будут безвозвратно удалены.
                            </p>
                            <div className="flex justify-end gap-3">
                                <button
                                    onClick={() => setShowDeleteModal(false)}
                                    className="px-4 py-2 text-gray-500 dark:text-neutral-300 hover:bg-gray-100 dark:hover:bg-neutral-700 rounded-lg"
                                >
                                    Отмена
                                </button>
                                <button
                                    onClick={handleDelete}
                                    className="px-4 py-2 bg-red-500 text-white hover:bg-red-600 rounded-lg"
                                >
                                    Подтвердить удаление
                                </button>
                            </div>
                        </div>
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
};

export default GroupDetails;