import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import { Link, useNavigate } from "react-router-dom";
import useWindowSize from "../hooks/useWindowSize";
import BottomNav from "./BottomNav";
import BackLink from "./BackLink";
import Aside from "./Aside";
import { formatDistanceToNow } from 'date-fns';
import { ru } from 'date-fns/locale';

const Notifications = () => {
    const { width } = useWindowSize();
    const [notifications, setNotifications] = useState([]);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [unreadCount, setUnreadCount] = useState(0);
    const navigate = useNavigate();

    // Fetch notifications on component mount
    useEffect(() => {
        fetchNotifications();
        fetchUnreadCount();
    }, [page]);

    const fetchNotifications = async () => {
        try {
            setLoading(true);
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/notifications?page=${page}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
            
            const newNotifications = response.data.data;
            
            // Update notifications based on pagination
            if (page === 1) {
                setNotifications(newNotifications);
            } else {
                setNotifications(prev => [...prev, ...newNotifications]);
            }
            
            // Check if there are more pages
            setHasMore(response.data.current_page < response.data.last_page);
        } catch (error) {
            console.error("Failed to fetch notifications:", error.response ? error.response.data : error.message);
        } finally {
            setLoading(false);
        }
    };

    const fetchUnreadCount = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/notifications/unread-count`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
            setUnreadCount(response.data.unread_count);
        } catch (error) {
            console.error("Failed to fetch unread count:", error);
        }
    };

    const markAllAsRead = async () => {
        try {
            await axios.post(
                `${process.env.REACT_APP_API_URL}/notifications/mark-read`,
                null,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                }
            );
            
            // Update local state to mark all notifications as read
            setNotifications(notifications.map(notification => ({
                ...notification,
                read_at: notification.read_at || new Date().toISOString()
            })));
            
            setUnreadCount(0);
        } catch (error) {
            console.error("Failed to mark all notifications as read:", error);
        }
    };

    const markAsRead = async (notificationId) => {
        try {
            await axios.post(
                `${process.env.REACT_APP_API_URL}/notifications/${notificationId}/mark-read`, 
                null,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                }
            );
            
            // Update local state to mark notification as read
            setNotifications(notifications.map(notification => 
                notification.id === notificationId 
                    ? { ...notification, read_at: new Date().toISOString() } 
                    : notification
            ));
            
            // Update unread count
            setUnreadCount(prev => Math.max(0, prev - 1));
        } catch (error) {
            console.error("Failed to mark notification as read:", error);
        }
    };

    const deleteNotification = async (notificationId) => {
        try {
            await axios.delete(`${process.env.REACT_APP_API_URL}/notifications/${notificationId}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
            
            const deletedNotification = notifications.find(n => n.id === notificationId);
            const wasUnread = !deletedNotification.read_at;
            
            // Remove notification from list
            setNotifications(notifications.filter(notification => notification.id !== notificationId));
            
            // Update unread count if needed
            if (wasUnread) {
                setUnreadCount(prev => Math.max(0, prev - 1));
            }
        } catch (error) {
            console.error("Failed to delete notification:", error);
        }
    };

    const loadMore = () => {
        if (!loading && hasMore) {
            setPage(prev => prev + 1);
        }
    };

    const handleNotificationClick = (notification) => {
        // Mark as read when clicking on a notification
        if (!notification.read_at) {
            markAsRead(notification.id);
        }
        
        // Navigate to the content if there's a link
        if (notification.data && notification.data.link) {
            navigate(notification.data.link);
        }
    };

    const renderNotificationMessage = (notification) => {
        // Parse the data to display a friendly message
        if (!notification.data) return "Уведомление";
        
        const { message } = notification.data;
        return message || "Уведомление";
    };

    // Get icon based on notification type
    const getNotificationIcon = (notification) => {
        if (!notification.data || !notification.data.type) return null;
        
        const { type } = notification.data;
        switch (type) {
            case 'message':
                return (
                    <svg className="w-6 h-6 text-blue-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z" />
                    </svg>
                );
            case 'alert':
                return (
                    <svg className="w-6 h-6 text-red-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                    </svg>
                );
            case 'update':
                return (
                    <svg className="w-6 h-6 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                    </svg>
                );
            default:
                return (
                    <svg className="w-6 h-6 text-violet-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
                    </svg>
                );
        }
    };

    return (
        <div className="flex container mx-auto p-2 pb-28">
            <Aside />
            <div className="flex-1 mx-auto p-2 pt-16">
            <BackLink />
                <div className="flex justify-between items-center mb-6">
                    <div className="flex flex-col items-center space-x-2">
                        <h2 className="text-3xl font-extrabold text-neutral-900 dark:text-white">Уведомления</h2>
                        {unreadCount > 0 && (
                            <span className="bg-violet-500 text-white text-xs font-medium px-2.5 py-1 rounded-full">
                                {unreadCount}
                            </span>
                        )}
                    </div>
                    {/* {notifications.length > 0 && (
                        <div className="flex space-x-2">
                            <button
                                onClick={markAllAsRead}
                                className="px-4 py-2 text-sm font-medium text-violet-700 bg-violet-100 rounded-lg hover:bg-violet-200 dark:bg-violet-800/30 dark:text-violet-300 dark:hover:bg-violet-700 transition-colors"
                                disabled={unreadCount === 0}
                            >
                                {unreadCount > 0 ? 'Отметить' : 'Все прочитаны'}
                            </button>

                        </div>
                    )} */}
                </div>

                {loading && page === 1 ? (
                    <div className="space-y-4">
                        {[...Array(3)].map((_, i) => (
                            <div key={i} className="animate-pulse bg-neutral-100 dark:bg-neutral-800 rounded-xl p-5">
                                <div className="flex space-x-4">
                                    <div className="rounded-full bg-neutral-300 dark:bg-neutral-700 h-10 w-10"></div>
                                    <div className="flex-1 space-y-3 py-1">
                                        <div className="h-4 bg-neutral-300 dark:bg-neutral-700 rounded w-3/4"></div>
                                        <div className="h-3 bg-neutral-300 dark:bg-neutral-700 rounded w-1/2"></div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                ) : (
                    <>
                        {notifications.length > 0 ? (
                            <div className="space-y-3">
                                {notifications.map(notification => (
                                    <div
                                        key={notification.id}
                                        className={`group relative p-5 hover:bg-neutral-50 dark:hover:bg-neutral-800/50 transition-all duration-200 ease-in-out rounded-xl ${
                                            !notification.read_at 
                                                ? 'border-l-4 border-violet-500 bg-violet-50 dark:bg-violet-900/20 shadow-lg' 
                                                : 'border-l-4 border-transparent bg-white dark:bg-neutral-900 shadow-md'
                                        }`}
                                        onClick={() => handleNotificationClick(notification)}
                                    >
                                        <div className="flex items-start">
                                            <div className="flex-shrink-0 mr-4">
                                                {getNotificationIcon(notification)}
                                            </div>
                                            <div className="flex-1 min-w-0">
                                                <p className={`text-base font-medium ${
                                                    notification.read_at ? 'text-neutral-700 dark:text-neutral-300' : 'text-neutral-900 dark:text-white'
                                                }`}>
                                                    {renderNotificationMessage(notification)}
                                                </p>
                                                <div className="mt-1 flex items-center">
                                                    <time className="text-sm text-neutral-500 dark:text-neutral-400">
                                                        {formatDistanceToNow(new Date(notification.created_at), {
                                                            addSuffix: true,
                                                            locale: ru
                                                        })}
                                                    </time>
                                                    {!notification.read_at && (
                                                        <span className="ml-3 flex-shrink-0 w-2 h-2 bg-violet-500 rounded-full"></span>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="ml-4 flex-shrink-0 flex items-center space-x-1">
                                                {!notification.read_at && (
                                                    <button
                                                        className="opacity-0 group-hover:opacity-100 p-2 hover:bg-violet-100 dark:hover:bg-violet-800/50 rounded-full transition-all duration-200"
                                                        title="Отметить как прочитанное"
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            markAsRead(notification.id);
                                                        }}
                                                    >
                                                        <svg className="w-5 h-5 text-violet-600 dark:text-violet-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                                                        </svg>
                                                    </button>
                                                )}
                                                <button
                                                    className="opacity-0 group-hover:opacity-100 p-2 hover:bg-red-100 dark:hover:bg-red-800/50 rounded-full transition-all duration-200"
                                                    title="Удалить уведомление"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        deleteNotification(notification.id);
                                                    }}
                                                >
                                                    <svg className="w-5 h-5 text-red-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                                    </svg>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                
                                {hasMore && (
                                    <div className="flex justify-center mt-6">
                                        <button 
                                            onClick={loadMore}
                                            disabled={loading}
                                            className="px-5 py-2.5 text-sm font-medium text-white bg-violet-600 rounded-lg hover:bg-violet-700 focus:ring-4 focus:ring-violet-300 dark:focus:ring-violet-800 disabled:opacity-50 transition-colors"
                                        >
                                            {loading ? (
                                                <span className="flex items-center">
                                                    <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" fill="none" viewBox="0 0 24 24">
                                                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                    </svg>
                                                    Загрузка...
                                                </span>
                                            ) : 'Загрузить еще'}
                                        </button>
                                    </div>
                                )}
                            </div>
                        ) : (
                            <div className="py-12 px-6 text-center bg-white dark:bg-neutral-900 rounded-xl shadow-md border border-neutral-100 dark:border-neutral-800">
                                <div className="mb-6 p-6 mx-auto w-24 h-24 rounded-full bg-violet-50 dark:bg-violet-900/20 flex items-center justify-center">
                                    <svg className="w-12 h-12 text-violet-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
                                    </svg>
                                </div>
                                <h3 className="text-xl font-medium text-neutral-800 dark:text-neutral-200 mb-2">Нет уведомлений</h3>
                                <p className="text-neutral-500 dark:text-neutral-400 max-w-sm mx-auto">
                                    Здесь будут отображаться важные обновления и сообщения о вашей активности
                                </p>
                            </div>
                        )}
                    </>
                )}

                {width < 768 && <BottomNav />}
            </div>
        </div>
    );
};

export default Notifications;