import React from "react";
import BackLink from "../BackLink";

const Language = () => {
  return (
    <div className="container mx-auto p-4 grid pt-16">
        <BackLink />
      <h1 className="text-3xl font-extrabold mb-4 dark:text-white">Сменить язык</h1>

      
    </div>
  );
};

export default Language;
