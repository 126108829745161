import React from 'react';
import { Link } from 'react-router-dom';
import { ChevronRight, User } from "lucide-react";

const NewsCard = ({ news, isFeatured, parent }) => {
    return (
        <article
            className={`news-card group relative overflow-hidden ${
                isFeatured ? 'news-card--featured' : ''
            } bg-white dark:bg-neutral-800 transition-all duration-300 rounded-xl ${
                parent === 'RightSidebar' ? 'p-0' : 'p-4'
            }`}
        >
            <Link to={`/news/${news.id}`} className="block h-full space-y-4">
                <div className="flex items-center gap-3 text-xs text-gray-500 dark:text-neutral-400">
                    <span className="flex items-center gap-1.5">
                        <User className="w-4 h-4" />
                        {news.author}
                    </span>
                    <span className="h-1 w-1 bg-gray-400 dark:bg-neutral-600 rounded-full" />
                    <time className="font-medium">
                        {new Date(news.created_at).toLocaleDateString('ru-RU', {
                            day: 'numeric',
                            month: 'long',
                            year: 'numeric'
                        })}
                    </time>
                </div>

                <h2 className="text-lg font-bold text-gray-900 dark:text-white leading-snug tracking-tight">
                    {news.title}
                </h2>

                <p className="text-gray-600 dark:text-gray-400 line-clamp-3 leading-relaxed">
                    {news.content}
                </p>

                <div className="inline-flex items-center font-medium text-violet-600 group-hover:text-violet-900 transition-colors">
                    Читать полностью
                    <ChevronRight className="w-4 h-4 ml-2" />
                </div>
            </Link>
        </article>
    );
};

export default NewsCard;
