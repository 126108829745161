import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { ClipLoader } from 'react-spinners';
import toast from 'react-hot-toast';
import { Upload, X, UserPlus } from 'lucide-react';

const CreateArtist = () => {
    const [formData, setFormData] = useState({
        name: '',
        bio: '',
        avatar: null,
        avatarPreview: null
    });
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleAvatarChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setFormData({
                ...formData,
                avatar: file,
                avatarPreview: URL.createObjectURL(file)
            });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        const formPayload = new FormData();
        formPayload.append('name', formData.name);
        formPayload.append('bio', formData.bio);
        if (formData.avatar) {
            formPayload.append('avatar', formData.avatar);
        }

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/artists`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                    'Accept': 'application/json', // Добавлен заголовок
                },
                body: formPayload
            });

            const data = await response.json(); // Всегда парсим JSON

            if (!response.ok) {
                throw new Error(data.message || Object.values(data.errors).join(', '));
            }

            toast.success('Исполнитель создан!');
            navigate(`/artist/${data.id}`);
        } catch (error) {
            toast.error(error.message || 'Неизвестная ошибка');
        } finally {
            setLoading(false);
        }
    };

    return (
        <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3 }}
            className="container mx-auto p-4 pt-16"
        >
            <div className="flex items-center justify-between mb-8">
                <h1 className="text-3xl font-bold text-gray-900 dark:text-white">
                    Новый исполнитель
                </h1>
                <button
                    onClick={() => navigate(-1)}
                    className="p-2 hover:bg-gray-100 dark:hover:bg-neutral-700 rounded-lg transition-colors"
                >
                    <X className="text-gray-600 dark:text-gray-300" />
                </button>
            </div>

            <form onSubmit={handleSubmit} className="space-y-6 w-full max-w-3xl mx-auto">
                {/* Avatar Upload */}
                <div className="flex flex-col items-center gap-4 mb-8">
                    <label className="relative group cursor-pointer">
                        <div className="w-40 h-40 rounded-full border-4 border-dashed border-gray-300 dark:border-neutral-600 flex items-center justify-center overflow-hidden transition-all group-hover:border-purple-500">
                            {formData.avatarPreview ? (
                                <img
                                    src={formData.avatarPreview}
                                    alt="Avatar preview"
                                    className="w-full h-full object-cover"
                                />
                            ) : (
                                <div className="text-center p-4">
                                    <Upload className="w-8 h-8 text-gray-400 dark:text-neutral-500 mb-2 mx-auto" />
                                    <span className="text-sm text-gray-500 dark:text-neutral-400">
                                        Загрузить фото
                                    </span>
                                </div>
                            )}
                        </div>
                        <input
                            type="file"
                            accept="image/*"
                            onChange={handleAvatarChange}
                            className="hidden"
                        />
                        {formData.avatarPreview && (
                            <button
                                type="button"
                                onClick={() => setFormData({...formData, avatar: null, avatarPreview: null})}
                                className="absolute top-0 right-0 bg-red-500 text-white p-1 rounded-full shadow-lg transform translate-x-1 -translate-y-1"
                            >
                                <X size={16} />
                            </button>
                        )}
                    </label>
                </div>

                {/* Form Fields */}
                <div className="space-y-6">
                    <div>
                        <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-neutral-300">
                            Имя исполнителя *
                        </label>
                        <input
                            type="text"
                            name="name"
                            value={formData.name}
                            onChange={handleInputChange}
                            className="w-full px-4 py-3 bg-white dark:bg-neutral-800 border border-gray-300 dark:border-neutral-600 rounded-lg focus:ring-2 focus:ring-purple-500 focus:border-transparent transition-all"
                            placeholder="Введите имя исполнителя"
                            required
                        />
                    </div>

                    <div>
                        <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-neutral-300">
                            Биография
                        </label>
                        <textarea
                            name="bio"
                            value={formData.bio}
                            onChange={handleInputChange}
                            className="w-full px-4 py-3 bg-white dark:bg-neutral-800 border border-gray-300 dark:border-neutral-600 rounded-lg focus:ring-2 focus:ring-purple-500 focus:border-transparent transition-all h-32"
                            placeholder="Расскажите о творчестве исполнителя..."
                        />
                    </div>
                </div>

                {/* Submit Button */}
                <motion.button
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                    type="submit"
                    disabled={loading}
                    className="w-full bg-purple-600 hover:bg-purple-700 text-white py-4 rounded-xl font-medium transition-colors flex items-center justify-center gap-2"
                >
                    {loading ? (
                        <ClipLoader size={20} color="#fff" />
                    ) : (
                        <>
                            <UserPlus className="w-5 h-5" />
                            Создать исполнителя
                        </>
                    )}
                </motion.button>
            </form>
        </motion.div>
    );
};

export default CreateArtist;