import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import { motion, AnimatePresence } from 'framer-motion';
import {
    Search,
    Grid,
    List,
    Plus,
    Filter,
    Users,
    Clock,
    Flame
} from 'lucide-react';
import useWindowSize from '../hooks/useWindowSize';
import BottomNav from "./BottomNav";
import {ClipLoader} from "react-spinners";
import BackLink from "./BackLink";
import Aside from "./Aside";

const GroupList = ({ groupId }) => {
    const [userGroups, setUserGroups] = useState([]);
    const [subscribedGroups, setSubscribedGroups] = useState([]);
    const [otherGroups, setOtherGroups] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchQuery, setSearchQuery] = useState('');
    const [viewMode, setViewMode] = useState('grid');
    const [sortOption, setSortOption] = useState('name');
    const [showActiveOnly, setShowActiveOnly] = useState(false);
    const [filtersVisible, setFiltersVisible] = useState(false);

    const { width } = useWindowSize();

    const fetchCategorizedGroups = async () => {
        try {
            setLoading(true);
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/groups/${groupId}/categorized`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            });
            setUserGroups(response.data.user_groups);
            setSubscribedGroups(response.data.subscribed_groups);
            setOtherGroups(response.data.other_groups);
        } catch (error) {
            console.error('Error fetching categorized groups:', error);
            toast.error('Failed to load groups.');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchCategorizedGroups();
    }, []);

    const filterAndSortGroups = (groups) => {
        return groups
            .filter(group => group.name.toLowerCase().includes(searchQuery.toLowerCase()))
            .filter(group => !showActiveOnly || new Date(group.updated_at) >= new Date(Date.now() - 7 * 24 * 60 * 60 * 1000))
            .sort((a, b) => {
                if (sortOption === 'name') return a.name.localeCompare(b.name);
                return new Date(b.created_at) - new Date(a.created_at);
            });
    };

    return (
        <motion.div
            className="min-h-screen bg-gray-50 dark:bg-neutral-900"
            initial={{opacity: 0}}
            animate={{opacity: 1}}
        >
            <div className="flex container mx-auto p-2">
                <Aside/>

                <div className="container mx-auto py-16">
                    <BackLink/>
                    <h1 className="text-3xl font-extrabold dark:text-white ">
                        Сообщества
                    </h1>
                    {/* Шапка с контролами */}
                    <div className="mb-8 flex flex-col md:flex-row gap-6 justify-between items-start md:items-center">
                        <div className="space-y-2">
                            <p className="text-gray-600 dark:text-gray-400">
                                Присоединяйтесь к сообществам по интересам или создайте своё
                            </p>
                        </div>

                        <div className="flex items-center gap-4 w-full md:w-auto">
                            <Link
                                to="/create-group"
                                className="flex items-center bg-gradient-to-r from-violet-600 to-blue-500 text-white px-6 py-3 rounded-xl font-semibold shadow-lg hover:shadow-xl transition-all"
                            >
                                <Plus className="w-5 h-5 mr-2"/>
                                Создать группу
                            </Link>
                        </div>
                    </div>

                    {/* Панель фильтров */}
                    <div className="mb-8 bg-white dark:bg-neutral-800 rounded-2xl shadow-lg p-4">
                        <div className="flex flex-col md:flex-row gap-4 items-stretch">
                            {/* Поиск */}
                            <div className="relative flex-1">
                                <Search className="absolute left-3 top-1/2 -translate-y-1/2 w-5 h-5 text-gray-400"/>
                                <input
                                    type="text"
                                    placeholder="Поиск сообществ..."
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                    className="w-full pl-10 pr-4 py-3 rounded-xl bg-gray-50 dark:bg-neutral-700 border border-gray-200 dark:border-neutral-600 focus:ring-2 focus:ring-violet-500 focus:border-transparent transition-all"
                                />
                            </div>

                            {/* Фильтры */}
                            <div className="flex gap-2">
                                <button
                                    onClick={() => setFiltersVisible(!filtersVisible)}
                                    className={`p-3 rounded-xl flex items-center gap-2 ${
                                        filtersVisible
                                            ? 'bg-violet-100 dark:bg-neutral-700 text-violet-600'
                                            : 'bg-gray-100 dark:bg-neutral-700 hover:bg-gray-200 dark:text-white'
                                    }`}
                                >
                                    <Filter className="w-5 h-5"/>
                                    {width > 768 && 'Фильтры'}
                                </button>

                                <div className="flex gap-2">
                                    <button
                                        onClick={() => setViewMode('grid')}
                                        className={`p-3 rounded-xl ${
                                            viewMode === 'grid'
                                                ? 'bg-violet-100 dark:bg-neutral-700 text-violet-600'
                                                : 'bg-gray-100 dark:bg-neutral-700 hover:bg-gray-200 dark:text-white'
                                        }`}
                                    >
                                        <Grid className="w-5 h-5"/>
                                    </button>
                                    <button
                                        onClick={() => setViewMode('list')}
                                        className={`p-3 rounded-xl ${
                                            viewMode === 'list'
                                                ? 'bg-violet-100 dark:bg-neutral-700 text-violet-600'
                                                : 'bg-gray-100 dark:bg-neutral-700 hover:bg-gray-200 dark:text-white'
                                        }`}
                                    >
                                        <List className="w-5 h-5"/>
                                    </button>
                                </div>
                            </div>
                        </div>

                        {/* Дополнительные фильтры */}
                        {filtersVisible && (
                            <motion.div
                                initial={{opacity: 0, y: -10}}
                                animate={{opacity: 1, y: 0}}
                                className="mt-6 pt-6 border-t border-gray-200 dark:border-neutral-700 flex flex-wrap gap-4 dark:text-white"
                            >
                                <div
                                    className="flex items-center gap-2 bg-gray-100 dark:bg-neutral-700 px-4 py-2 rounded-xl">
                                    <select
                                        value={sortOption}
                                        onChange={(e) => setSortOption(e.target.value)}
                                        className="bg-transparent outline-none"
                                    >
                                        <option value="name">По названию</option>
                                        <option value="date">По дате создания</option>
                                    </select>
                                </div>

                                <label
                                    className="flex items-center gap-2 bg-gray-100 dark:bg-neutral-700 px-4 py-2 rounded-xl cursor-pointer">
                                    <input
                                        type="checkbox"
                                        checked={showActiveOnly}
                                        onChange={() => setShowActiveOnly(!showActiveOnly)}
                                        className="w-4 h-4 rounded-lg border-gray-300 dark:bg-neutral-700"
                                    />
                                    Только активные
                                </label>
                            </motion.div>
                        )}
                    </div>

                    {/* Список групп */}
                    {loading ? (
                        <div className="flex justify-center items-center min-h-[300px]">
                            <ClipLoader color="#6B46C1" size={50}/>
                        </div>
                    ) : (
                        <div className="space-y-12">
                            <GroupCategory
                                title="Ваши сообщества"
                                groups={filterAndSortGroups(userGroups)}
                                viewMode={viewMode}
                                icon={<Users className="w-6 h-6"/>}
                            />

                            <GroupCategory
                                title="Подписки"
                                groups={filterAndSortGroups(subscribedGroups)}
                                viewMode={viewMode}
                                icon={<Clock className="w-6 h-6"/>}
                            />

                            <GroupCategory
                                title="Популярное"
                                groups={filterAndSortGroups(otherGroups)}
                                viewMode={viewMode}
                                icon={<Flame className="w-6 h-6"/>}
                            />
                        </div>
                    )}
                </div>
                {width < 768 && <BottomNav/>}
                <Toaster position="top-right" reverseOrder={false}/>
            </div>
        </motion.div>
    );
};

const GroupCategory = ({
    title, groups, viewMode, icon
}) => (
    <div className="space-y-6">
        <div className="flex items-center gap-3">
            <div className="p-2 bg-violet-100 dark:bg-neutral-700 rounded-xl text-violet-600 ">
                {icon}
            </div>
            <h2 className="text-2xl font-bold dark:text-white">{title}</h2>
        </div>

        <AnimatePresence mode="wait">
            <motion.div
                key={viewMode}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className={viewMode === 'grid'
                    ? 'grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6'
                    : 'space-y-4'
                }
            >
                {groups.map(group => (
                    <GroupCard key={group.id} group={group} viewMode={viewMode} />
                ))}
            </motion.div>
        </AnimatePresence>
    </div>
);

const GroupCard = ({ group, viewMode }) => {
    const isActive = new Date(group.updated_at) >= new Date(Date.now() - 7 * 24 * 60 * 60 * 1000);
    const isNew = new Date(group.created_at) >= new Date(Date.now() - 3 * 24 * 60 * 60 * 1000);

    return (
        <motion.div
            className={`group relative ${
                viewMode === 'grid'
                    ? 'bg-white dark:bg-neutral-800 rounded-2xl shadow-lg hover:shadow-xl transition-shadow duration-300 overflow-hidden'
                    : 'bg-white dark:bg-neutral-800 rounded-xl shadow-sm hover:shadow-md transition-all duration-200'
            }`}
            whileHover={{ y: -5 }}
        >
            <Link to={`/group/${group.id}`} className="h-full flex flex-col">
                {viewMode === 'grid' ? (
                    <>
                        {/* Grid View */}
                        <div className="relative h-48 overflow-hidden">
                            <img
                                src={group.cover ? `${process.env.REACT_APP_BASE_URL}/storage/${group.cover}`
                                    : 'https://via.placeholder.com/600x400.png?text=Community+Cover'}
                                alt={group.name}
                                className="w-full h-full object-cover transform transition-transform duration-500 group-hover:scale-105"
                            />
                            <div className="absolute inset-0 bg-gradient-to-t from-black/50 via-transparent" />

                            {/* Status Badges */}
                            <div className="absolute top-3 right-3 flex gap-2">
                                {isNew && (
                                    <span className="px-3 py-1 bg-blue-100 dark:bg-blue-900 text-blue-600 dark:text-blue-300 text-xs rounded-full shadow-sm">
                                        Новое
                                    </span>
                                )}
                                {isActive && (
                                    <span className="px-3 py-1 bg-green-100 dark:bg-green-900 text-green-600 dark:text-green-300 text-xs rounded-full shadow-sm">
                                        Активно
                                    </span>
                                )}
                            </div>
                        </div>

                        <div className="p-5 pt-12 relative">
                            <div className="absolute -top-6 left-5">
                                <motion.img
                                    src={group.avatar ? `${process.env.REACT_APP_BASE_URL}/storage/${group.avatar}`
                                        : 'https://via.placeholder.com/150.png?text=Community'}
                                    alt={group.name}
                                    className="w-14 h-14 rounded-full border-4 border-white dark:border-neutral-800 shadow-lg transform transition-transform duration-300 hover:scale-110"
                                    whileHover={{ scale: 1.1 }}
                                />
                            </div>

                            <h3 className="text-xl font-bold mb-2 line-clamp-1 dark:text-white">
                                {group.name}
                            </h3>

                            <p className="text-gray-600 dark:text-gray-300 text-sm mb-4 line-clamp-2">
                                {group.description}
                            </p>

                            <div className="flex items-center justify-between text-sm">
                                <div className="flex items-center gap-2 text-gray-500 dark:text-gray-400">
                                    <Users className="w-5 h-5" />
                                    <span className="font-medium">
                                        {group.members_count.toLocaleString()}
                                    </span>
                                </div>

                                <div className="text-gray-500 dark:text-gray-400">
                                    {new Date(group.created_at).toLocaleDateString('ru-RU', {
                                        year: 'numeric',
                                        month: 'short',
                                        day: 'numeric'
                                    })}
                                </div>
                            </div>
                        </div>
                    </>
                ) : (
                    <div className="flex items-center gap-4 p-4">
                    <motion.img
                    src={group.avatar ? `${process.env.REACT_APP_BASE_URL}/storage/${group.avatar}`
                    : 'https://via.placeholder.com/80.png?text=Community'}
                  alt={group.name}
                  className="w-14 h-14 rounded-full border-2 border-white dark:border-neutral-800 shadow-lg"
                  whileHover={{ scale: 1.05 }}
            />

            <div className="flex-1 min-w-0">
                <div className="flex items-center gap-3 mb-1">
                    <h3 className="text-lg font-semibold truncate dark:text-white">
                        {group.name}
                    </h3>
                    {isActive && (
                        <span className="w-2 h-2 bg-green-500 rounded-full"></span>
                    )}
                </div>

                <div className="flex items-center gap-4 text-sm text-gray-600 dark:text-gray-300">
                                <span className="flex items-center gap-1">
                                    <Users className="w-4 h-4" />
                                    {group.members_count.toLocaleString()}
                                </span>

                    <span className="hidden md:inline-flex items-center gap-1">
                                    <Clock className="w-4 h-4" />
                        {new Date(group.created_at).toLocaleDateString()}
                                </span>

                    {isNew && (
                        <span className="px-2 py-1 bg-blue-100 dark:bg-blue-900 text-blue-600 dark:text-blue-300 text-xs rounded-full">
                                        Новое
                                    </span>
                    )}
                </div>
            </div>
        </div>
                )}
            </Link>
        </motion.div>
    );
};

export default GroupList;