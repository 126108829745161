import React, {useState} from 'react';
import ConfirmationModalOpenLink from "./components/ConformationModalOpenLink";

function LinkifyText({ text }) {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const parts = text.split(urlRegex);
    const [pendingUrl, setPendingUrl] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    // Обработка клика по ссылке
    const handleLinkClick = (e, url) => {
        try {
            const parsedUrl = new URL(url);
            // Если ссылка с домена chattify.ru, модальное окно не показываем
            if (parsedUrl.hostname.endsWith('chattify.ru')) {
                e.preventDefault();
                window.open(url, '_blank');
                return;
            }
        } catch (err) {
            // Если не удалось распарсить URL, продолжаем стандартное поведение
        }
        e.preventDefault();
        setPendingUrl(url);
        setIsModalOpen(true);
    };

    const handleConfirm = () => {
        if (pendingUrl) {
            window.open(pendingUrl, '_blank');
        }
        setIsModalOpen(false);
        setPendingUrl(null);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        setPendingUrl(null);
    };

    return (
        <>
            {parts.map((part, index) => {
                if (urlRegex.test(part)) {
                    return (
                        <a
                            key={index}
                            href={part}
                            onClick={(e) => handleLinkClick(e, part)}
                            className="text-violet-500 dark:text-violet-400 hover:text-violet-600 dark:hover:text-violet-500 transition-all duration-300"
                        >
                            {part}
                        </a>
                    );
                }
                return <React.Fragment key={index}>{part}</React.Fragment>;
            })}
            <ConfirmationModalOpenLink
                isOpen={isModalOpen}
                message="Вы уверены, что хотите перейти по ссылке?"
                onConfirm={handleConfirm}
                onCancel={handleCancel}
            />
        </>
    );
}

export default LinkifyText;