import React, {useState, useRef, useEffect} from "react";
import { usePlayer } from "../context/PlayerContext";
import { motion, AnimatePresence } from "framer-motion";
import VolumeControl from "./VolumeControl";
import useWindowSize from "../hooks/useWindowSize";

const MusicPlayer = () => {
    const {
        currentTrack,
        isPlaying,
        togglePlayPause,
        progress,
        duration,
        buffered,
        seekTrack,
        skipToNextTrack,
        skipToPreviousTrack,
        isShuffling,
        isRepeating,
        toggleShuffle,
        toggleRepeat,
        setCurrentTrack
    } = usePlayer();

    const [isExpanded, setIsExpanded] = useState(false);
    const [isDragging, setIsDragging] = useState(false);
    const [isVisible, setIsVisible] = useState(true);
    const sliderRef = useRef(null);
    const collapsedSliderRef = useRef(null);
    const { width } = useWindowSize();
    const isMobile = width < 640;
    const isMusicPage = window.location.pathname === '/music';

    const toggleExpand = (e) => {
        if (e.target === e.currentTarget) {
            setIsExpanded(!isExpanded);
        }
    };

    const closePlayer = () => {
        setIsVisible(false);
        setCurrentTrack(null);
    };

    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = Math.floor(time % 60).toString().padStart(2, "0");
        return `${minutes}:${seconds}`;
    };

    const handleSeek = (e, isTouch = false) => {
        const slider = sliderRef.current || collapsedSliderRef.current;
        if (!slider) return;

        const rect = slider.getBoundingClientRect();
        const clientX = isTouch ? e.touches[0].clientX : e.clientX;
        const offsetX = Math.max(0, Math.min(clientX - rect.left, rect.width)); // Ограничиваем в пределах слайдера
        const seekRatio = offsetX / rect.width;
        seekTrack(seekRatio * duration);
    };

    const handleDragStart = (e) => {
        setIsDragging(true);
        handleSeek(e);
    };

    const handleTouchStart = (e) => {
        setIsDragging(true);
        handleSeek(e, true);
    };

    useEffect(() => {
        let animationFrameId;

        const handleMove = (e) => {
            if (isDragging) {
                animationFrameId = requestAnimationFrame(() => {
                    const isTouch = e.type === 'touchmove';
                    const clientX = isTouch ? e.touches[0].clientX : e.clientX;
                    const fakeEvent = { clientX, touches: isTouch ? e.touches : [] };
                    handleSeek(fakeEvent, isTouch);
                });
            }
        };

        const handleEnd = () => {
            setIsDragging(false);
            if (animationFrameId) cancelAnimationFrame(animationFrameId);
        };

        document.addEventListener('mousemove', handleMove);
        document.addEventListener('touchmove', handleMove);
        document.addEventListener('mouseup', handleEnd);
        document.addEventListener('touchend', handleEnd);

        return () => {
            document.removeEventListener('mousemove', handleMove);
            document.removeEventListener('touchmove', handleMove);
            document.removeEventListener('mouseup', handleEnd);
            document.removeEventListener('touchend', handleEnd);
            if (animationFrameId) cancelAnimationFrame(animationFrameId);
        };
    }, [isDragging]);

    // Reset visibility when a new track is loaded
    useEffect(() => {
        if (currentTrack) {
            setIsVisible(true);
        }
    }, [currentTrack]);

    if (!currentTrack || !isVisible) return null;

    return (
        <div className={`fixed left-0 right-0 z-40 ${isMobile ? 'bottom-[60px]' : 'bottom-0'}`}>
            <AnimatePresence mode="wait">
                {isExpanded ? (
                    <motion.div
                        key="expanded"
                        initial={{ y: '100%', opacity: 0 }}
                        animate={{
                            y: 0,
                            opacity: 1,
                            transition: {
                                type: "spring",
                                damping: 20,
                                stiffness: 150,
                            }
                        }}
                        exit={{
                            y: '100%',
                            opacity: 0,
                            transition: {
                                type: "spring",
                                damping: 20,
                                stiffness: 150
                            }
                        }}
                        className={`w-full bg-neutral-50 dark:bg-neutral-900 backdrop-blur-2xl ${
                            isMobile ?
                                'fixed inset-0 h-[calc(100vh-50px)]' :
                                'h-screen'
                        }`}
                    >
                        <div className="container mx-auto px-4 py-6 h-full overflow-y-auto">
                            <div className="flex justify-between items-center mb-8">
                                <motion.h2
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    className="text-3xl font-bold text-neutral-800 dark:text-neutral-200"
                                >
                                    Сейчас играет
                                </motion.h2>
                                <div className="flex items-center gap-2">
                                    {!isPlaying && (
                                        <motion.button
                                            whileHover={{ scale: 1.05 }}
                                            whileTap={{ scale: 0.95 }}
                                            onClick={closePlayer}
                                            className="w-10 h-10 flex items-center justify-center rounded-full bg-white dark:bg-neutral-800 shadow-lg hover:shadow-xl transition-all z-50"
                                        >
                                            <i className="fas fa-times text-xl text-purple-600 dark:text-purple-300" />
                                        </motion.button>
                                    )}
                                    <motion.button
                                        whileHover={{ scale: 1.05 }}
                                        whileTap={{ scale: 0.95 }}
                                        onClick={toggleExpand}
                                        className="w-10 h-10 flex items-center justify-center rounded-full bg-white dark:bg-neutral-800 shadow-lg hover:shadow-xl transition-all z-50"
                                    >
                                        <i className="fas fa-chevron-down text-xl text-purple-600 dark:text-purple-300" />
                                    </motion.button>
                                </div>
                            </div>

                            <motion.div
                                initial={{ scale: 0.9, rotate: -2 }}
                                animate={{ scale: 1, rotate: 0 }}
                                className="relative mx-auto mb-8 w-64 h-64 md:w-80 md:h-80 rounded-2xl shadow-2xl overflow-hidden"
                            >
                                <img
                                    src={`${process.env.REACT_APP_BASE_URL}/storage/${currentTrack.album_art}` || "/placeholder.png"}
                                    alt="Album Art"
                                    className="w-full h-full object-cover"
                                />
                                <div className="absolute inset-0 bg-gradient-to-t from-black/30 to-transparent"></div>
                            </motion.div>

                            <motion.div
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                className="text-center mb-8 px-4"
                            >
                                <h3 className="text-2xl font-bold mb-2 text-neutral-800 dark:text-neutral-100 truncate">
                                    {currentTrack.title}
                                </h3>
                                <p className="text-lg text-neutral-500 dark:text-neutral-400 truncate">
                                    {currentTrack.artist.name}
                                </p>
                            </motion.div>

                            <motion.div
                                className="mb-8 mx-4"
                                initial={{opacity: 0}}
                                animate={{opacity: 1}}
                            >
                                <div
                                    className="flex justify-between text-sm mb-2 text-neutral-500 dark:text-neutral-400 user-select-none">
                                    <span>{formatTime(progress * duration)}</span>
                                    <span>{formatTime(duration)}</span>
                                </div>
                                <div
                                    ref={sliderRef}
                                    className="h-2 bg-neutral-200/50 dark:bg-neutral-700/50 rounded-full cursor-pointer relative"
                                    onMouseDown={handleDragStart}
                                    onTouchStart={handleTouchStart}
                                    style={{touchAction: 'none'}}
                                >
                                    <div
                                        className="absolute h-2 bg-neutral-300/30 dark:bg-neutral-600/30 rounded-full"
                                        style={{width: `${buffered * 100}%`}}
                                    />
                                    <div
                                        className="absolute h-2 bg-gradient-to-r from-purple-500 to-violet-500 rounded-full z-10"
                                        style={{width: `${progress * 100}%`}}
                                    >
                                        <div
                                            className="absolute -right-2 -top-1 w-4 h-4 bg-purple-600 rounded-full shadow-lg"/>
                                    </div>
                                </div>
                            </motion.div>

                            <motion.div
                                className="flex flex-col items-center gap-6"
                                initial={{opacity: 0}}
                                animate={{opacity: 1}}
                            >
                                <div className="w-full flex items-center justify-between">
                                    <motion.button
                                        whileHover={{scale: 1.05}}
                                        whileTap={{scale: 0.95}}
                                        onClick={toggleShuffle}
                                        className={`p-3 rounded-full ${
                                            isShuffling
                                                ? "bg-gradient-to-r from-purple-500 to-violet-500 text-white"
                                                : "bg-white dark:bg-neutral-800 text-neutral-600 dark:text-neutral-300"
                                        } shadow-lg hover:shadow-xl transition-all w-12 h-12 flex items-center justify-center`}
                                    >
                                        <i className="fas fa-random text-xl" />
                                    </motion.button>

                                    <div className="flex items-center gap-4">
                                        <motion.button
                                            whileHover={{ scale: 1.05 }}
                                            whileTap={{ scale: 0.95 }}
                                            onClick={skipToPreviousTrack}
                                            className="w-12 h-12 flex items-center justify-center bg-white dark:bg-neutral-800 rounded-full shadow-lg hover:shadow-xl transition-all"
                                        >
                                            <i className="fas fa-step-backward text-xl text-purple-600 dark:text-purple-300" />
                                        </motion.button>

                                        <motion.button
                                            whileHover={{ scale: 1.1 }}
                                            whileTap={{ scale: 0.9 }}
                                            onClick={togglePlayPause}
                                            className="w-16 h-16 flex items-center justify-center bg-gradient-to-r from-purple-500 to-violet-500 rounded-full shadow-xl hover:shadow-2xl transition-all"
                                        >
                                            <i className={`fas ${
                                                isPlaying ? "fa-pause" : "fa-play"
                                            } text-3xl text-white`} />
                                        </motion.button>

                                        <motion.button
                                            whileHover={{ scale: 1.05 }}
                                            whileTap={{ scale: 0.95 }}
                                            onClick={skipToNextTrack}
                                            className="w-12 h-12 flex items-center justify-center bg-white dark:bg-neutral-800 rounded-full shadow-lg hover:shadow-xl transition-all"
                                        >
                                            <i className="fas fa-step-forward text-xl text-purple-600 dark:text-purple-300" />
                                        </motion.button>
                                    </div>

                                    <motion.button
                                        whileHover={{ scale: 1.05 }}
                                        whileTap={{ scale: 0.95 }}
                                        onClick={toggleRepeat}
                                        className={`p-3 rounded-full ${
                                            isRepeating
                                                ? "bg-gradient-to-r from-purple-500 to-violet-500 text-white"
                                                : "bg-white dark:bg-neutral-800 text-neutral-600 dark:text-neutral-300"
                                        } shadow-lg hover:shadow-xl transition-all w-12 h-12 flex items-center justify-center`}
                                    >
                                        <i className="fas fa-redo text-xl" />
                                    </motion.button>
                                </div>

                                {width >= 768 && <VolumeControl />}
                            </motion.div>
                        </div>
                    </motion.div>
                ) : (
                    <motion.div
                        key="collapsed"
                        initial={{y: 100, opacity: 0}}
                        animate={{y: 0, opacity: 1}}
                        exit={{y: 100, opacity: 0}}
                        transition={{type: "spring", damping: 20, stiffness: 150}}
                        className={`mx-auto ${isMobile ? 'px-2' : 'px-4'} pb-1`}
                    >
                        <div className="relative group">
                            <div
                                className={`flex items-center justify-between ${isMobile ? 'w-full' : 'w-1/5 right-0 absolute bottom-0 mr-20'} ${
                                    isMusicPage
                                        ? 'bg-white/80 dark:bg-neutral-800/80 backdrop-blur-lg rounded-xl shadow-lg p-2 border border-white/30 dark:border-neutral-700/30'
                                        : 'bg-white/90 dark:bg-neutral-800/90 backdrop-blur-sm rounded-lg shadow-md p-1.5 pr-2'
                                } cursor-pointer`}
                                onClick={toggleExpand}
                            >
                                {isMusicPage && (
                                    <motion.div
                                        whileHover={{scale: 1.05}}
                                        className="w-12 h-12 relative overflow-hidden rounded-lg shadow-md"
                                        onClick={(e) => e.stopPropagation()}
                                    >
                                        <img
                                            src={`${process.env.REACT_APP_BASE_URL}/storage/${currentTrack.album_art}`}
                                            alt="Album Art"
                                            className="w-full h-full object-cover"
                                        />
                                    </motion.div>
                                )}

                                <div className={`flex-1 ${isMusicPage ? 'px-3' : 'px-2'} min-w-0`}>
                                    <div className="flex flex-col truncate">
                                        <span className={`${
                                            isMusicPage
                                                ? 'text-sm font-semibold'
                                                : 'text-sm font-medium'
                                        } text-neutral-800 dark:text-neutral-100 truncate`}>
                                            {currentTrack.title}
                                        </span>
                                        <span className={`${
                                            isMusicPage
                                                ? 'text-xs'
                                                : 'text-xs'
                                        } text-neutral-600/80 dark:text-neutral-300/80 truncate`}>
                                            {currentTrack.artist.name}
                                        </span>
                                    </div>
                                    <div
                                        ref={collapsedSliderRef}
                                        className={`${
                                            isMusicPage
                                                ? 'h-1 mt-1.5'
                                                : 'absolute bottom-0 left-0 right-0 h-0.5'
                                        } bg-purple-100/30 dark:bg-purple-900/20 rounded-full`}
                                        onMouseDown={(e) => {
                                            e.stopPropagation();
                                            handleDragStart(e);
                                        }}
                                        onTouchStart={(e) => {
                                            e.stopPropagation();
                                            handleTouchStart(e);
                                        }}
                                        style={{touchAction: 'none'}}
                                    >
                                        <div
                                            className={`h-full ${
                                                isMusicPage
                                                    ? 'bg-gradient-to-r from-purple-400 to-violet-500'
                                                    : 'bg-gradient-to-r from-purple-400 to-violet-500'
                                            } transition-all duration-300`}
                                            style={{width: `${progress * 100}%`}}
                                        />
                                    </div>
                                </div>

                                <div className="flex items-center gap-1" onClick={(e) => e.stopPropagation()}>
                                    {!isPlaying && (
                                        <motion.button
                                            whileHover={{scale: 1.1}}
                                            whileTap={{scale: 0.95}}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                closePlayer();
                                            }}
                                            className={`${
                                                isMobile ? 'w-7 h-7' : 'w-8 h-8'
                                            } flex items-center justify-center bg-gradient-to-r from-purple-500 to-violet-500 rounded-full shadow-sm`}
                                        >
                                            <i className="fas fa-times text-xs text-white"/>
                                        </motion.button>
                                    )}

                                    <motion.button
                                        whileHover={{scale: 1.1}}
                                        whileTap={{scale: 0.95}}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            skipToPreviousTrack();
                                        }}
                                        className={`${
                                            isMobile ? 'w-7 h-7' : 'w-8 h-8'
                                        } flex items-center justify-center rounded-full `}
                                    >
                                        <i className="fas fa-step-backward text-xs dark:text-white"/>
                                    </motion.button>

                                    <motion.button
                                        whileHover={{scale: 1.1}}
                                        whileTap={{scale: 0.95}}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            togglePlayPause();
                                        }}
                                        className={`${
                                            isMobile ? 'w-7 h-7' : 'w-8 h-8'
                                        } flex items-center justify-center bg-gradient-to-r from-purple-500 to-violet-500 rounded-full shadow-sm`}
                                    >
                                        <i
                                            className={`fas ${
                                                isPlaying ? 'fa-pause' : 'fa-play'
                                            } ${isMobile ? 'text-[10px]' : 'text-xs'} text-white`}
                                        />
                                    </motion.button>

                                    <motion.button
                                        whileHover={{scale: 1.1}}
                                        whileTap={{scale: 0.95}}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            skipToNextTrack();
                                        }}
                                        className={`${
                                            isMobile ? 'w-7 h-7' : 'w-8 h-8'
                                        } flex items-center justify-center rounded-full`}
                                    >
                                        <i className="fas fa-step-forward text-xs dark:text-white"/>
                                    </motion.button>
                                </div>
                            </div>
                        </div>
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
};

export default MusicPlayer;