import React, { useState, useContext, useRef } from 'react';
import axios from 'axios';
import { AuthContext } from '../../context/AuthContext';
import toast from 'react-hot-toast';
import { motion, AnimatePresence } from 'framer-motion';
import { X, Trash2, Upload, Camera, Loader2, UserCircle } from 'lucide-react';

const UpdateAvatar = ({ closeModal }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const { user, setUser } = useContext(AuthContext);
  const fileInputRef = useRef(null);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    const allowedTypes = ['image/jpeg', 'image/png', 'image/webp'];
    if (!allowedTypes.includes(file.type)) {
      toast.error('Неподдерживаемый формат! Используйте JPG, PNG или WEBP.');
      return;
    }

    if (file.size > 5 * 1024 * 1024) {
      toast.error('Размер файла превышает 5MB!');
      return;
    }

    setSelectedFile(file);

    // Создаем превью
    const reader = new FileReader();
    reader.onloadend = () => {
      setPreviewUrl(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const handleUpload = async () => {
    if (!selectedFile) {
      toast.error('Выберите изображение');
      return;
    }

    setIsLoading(true);
    try {
      const token = localStorage.getItem('token');
      const formData = new FormData();
      formData.append('avatar', selectedFile);

      const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/update-avatar`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: `Bearer ${token}`,
            },
          }
      );

      setUser(response.data.user);
      toast.success('Аватар успешно обновлён!');
      closeModal();
    } catch (error) {
      console.error('Ошибка обновления аватара:', error);
      toast.error(error.response?.data?.message || 'Не удалось обновить аватар.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteAvatar = async () => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem('token');
      await axios.delete(`${process.env.REACT_APP_API_URL}/delete-avatar`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      setUser((prevUser) => ({ ...prevUser, avatar: null }));
      toast.success('Аватар успешно удалён!');
      closeModal();
    } catch (error) {
      console.error('Ошибка удаления аватара:', error);
      toast.error(error.response?.data?.message || 'Не удалось удалить аватар.');
    } finally {
      setIsLoading(false);
      setShowDeleteConfirm(false);
    }
  };

  const triggerFileInput = () => {
    fileInputRef.current.click();
  };

  const getUserAvatar = () => {
    if (!user) return null;

    if (user.avatar) {
      return `${process.env.REACT_APP_BASE_URL}/storage/${user.avatar}`;
    }

    return `https://ui-avatars.com/api/?name=${encodeURIComponent(user.name || 'User')}&background=random`;
  };

  return (
      <div className="fixed inset-0 z-50 flex items-center justify-center p-4 bg-black/60 backdrop-blur-sm">
        <AnimatePresence>
          {showDeleteConfirm ? (
              <motion.div
                  initial={{ opacity: 0, scale: 0.95 }}
                  animate={{ opacity: 1, scale: 1 }}
                  exit={{ opacity: 0, scale: 0.95 }}
                  className="relative bg-white dark:bg-neutral-900 rounded-xl shadow-2xl max-w-md w-full p-6"
              >
                <h3 className="text-xl font-medium mb-4 text-center dark:text-white">Удалить аватар?</h3>
                <p className="text-neutral-600 dark:text-neutral-400 text-center mb-6">
                  Вы уверены, что хотите удалить текущий аватар? Это действие нельзя отменить.
                </p>
                <div className="flex gap-3">
                  <button
                      onClick={() => setShowDeleteConfirm(false)}
                      className="flex-1 border border-neutral-300 dark:border-neutral-700 text-neutral-700 dark:text-neutral-300 py-2 rounded-lg hover:bg-neutral-100 dark:hover:bg-neutral-800 transition-colors"
                  >
                    Отмена
                  </button>
                  <button
                      onClick={handleDeleteAvatar}
                      disabled={isLoading}
                      className="flex-1 bg-red-500 hover:bg-red-600 text-white py-2 rounded-lg transition-colors disabled:opacity-50 flex justify-center items-center gap-2"
                  >
                    {isLoading ? <Loader2 className="w-4 h-4 animate-spin" /> : <Trash2 className="w-4 h-4" />}
                    Удалить
                  </button>
                </div>
              </motion.div>
          ) : (
              <motion.div
                  initial={{ opacity: 0, scale: 0.95 }}
                  animate={{ opacity: 1, scale: 1 }}
                  exit={{ opacity: 0, scale: 0.95 }}
                  className="relative bg-white dark:bg-neutral-900 rounded-xl shadow-2xl max-w-lg w-full overflow-hidden"
              >
                {/* Заголовок */}
                <div className="p-5 border-b border-neutral-200 dark:border-neutral-800 flex justify-between items-center">
                  <h2 className="text-xl font-semibold dark:text-white">Управление аватаром</h2>
                  <button
                      onClick={closeModal}
                      className="p-2 text-neutral-500 hover:text-neutral-700 dark:text-neutral-400 dark:hover:text-neutral-200 transition-colors"
                  >
                    <X className="w-5 h-5" />
                  </button>
                </div>

                {/* Контент */}
                <div className="p-6 flex flex-col md:flex-row gap-6">
                  {/* Текущий аватар и превью */}
                  <div className="flex flex-col items-center space-y-4 w-full md:w-1/3">
                    <div className="relative group">
                      {!user ? (
                          <div className="w-36 h-36 rounded-full bg-neutral-200 dark:bg-neutral-800 animate-pulse"></div>
                      ) : (
                          <div className="w-36 h-36 rounded-full overflow-hidden border-4 border-violet-100 dark:border-violet-900 relative">
                            {previewUrl ? (
                                <img
                                    src={previewUrl}
                                    alt="Avatar Preview"
                                    className="w-full h-full object-cover"
                                />
                            ) : (
                                <img
                                    src={getUserAvatar()}
                                    alt="Current Avatar"
                                    className="w-full h-full object-cover"
                                    onError={(e) => {
                                      e.target.src = `https://ui-avatars.com/api/?name=${encodeURIComponent(
                                          user.name || 'User'
                                      )}&background=random`;
                                    }}
                                />
                            )}

                            <div className="absolute inset-0 bg-black/0 hover:bg-black/30 flex items-center justify-center transition-all opacity-0 hover:opacity-100">
                              <button
                                  onClick={triggerFileInput}
                                  className="bg-white dark:bg-neutral-800 p-2 rounded-full shadow-lg"
                              >
                                <Camera className="w-5 h-5 text-violet-600 dark:text-violet-400" />
                              </button>
                            </div>
                          </div>
                      )}
                    </div>

                    {user?.avatar && (
                        <button
                            onClick={() => setShowDeleteConfirm(true)}
                            className="text-red-500 hover:text-red-600 flex items-center gap-1 text-sm transition-colors"
                        >
                          <Trash2 className="w-4 h-4" />
                          Удалить аватар
                        </button>
                    )}
                  </div>

                  {/* Форма загрузки */}
                  <div className="w-full md:w-2/3 space-y-6">
                    <div>
                      <h3 className="text-lg font-medium mb-3 dark:text-white">Загрузить новый аватар</h3>
                      <p className="text-sm text-neutral-500 dark:text-neutral-400 mb-4">
                        Загрузите изображение в формате JPG, PNG или WEBP. Максимальный размер файла - 5MB.
                      </p>
                    </div>

                    <input
                        ref={fileInputRef}
                        type="file"
                        accept="image/*"
                        onChange={handleFileChange}
                        className="hidden"
                    />

                    <div
                        onClick={triggerFileInput}
                        className="border-2 border-dashed border-neutral-300 dark:border-neutral-700 rounded-xl p-6 cursor-pointer hover:border-violet-400 dark:hover:border-violet-600 transition-colors flex flex-col items-center justify-center"
                    >
                      {selectedFile ? (
                          <div className="text-center">
                            <div className="text-violet-600 dark:text-violet-400 text-sm font-medium mb-1">
                              Файл выбран
                            </div>
                            <p className="text-neutral-600 dark:text-neutral-400 text-sm truncate max-w-xs">
                              {selectedFile.name}
                            </p>
                            <p className="text-neutral-500 dark:text-neutral-500 text-xs mt-1">
                              {(selectedFile.size / (1024 * 1024)).toFixed(2)} MB
                            </p>
                          </div>
                      ) : (
                          <>
                            <Upload className="w-8 h-8 text-neutral-400 mb-2" />
                            <p className="text-neutral-600 dark:text-neutral-400 font-medium">
                              Перетащите файл или нажмите для выбора
                            </p>
                            <p className="text-neutral-500 dark:text-neutral-500 text-sm mt-1">
                              JPG, PNG или WEBP • До 5MB
                            </p>
                          </>
                      )}
                    </div>

                    <motion.button
                        whileTap={{ scale: 0.98 }}
                        onClick={handleUpload}
                        disabled={!selectedFile || isLoading}
                        className="w-full bg-violet-600 hover:bg-violet-700 dark:bg-violet-700 dark:hover:bg-violet-800 text-white px-6 py-3 rounded-lg font-medium disabled:opacity-50 transition-colors flex items-center justify-center gap-2"
                    >
                      {isLoading ? (
                          <>
                            <Loader2 className="w-5 h-5 animate-spin" />
                            Загрузка...
                          </>
                      ) : (
                          'Сохранить изменения'
                      )}
                    </motion.button>
                  </div>
                </div>
              </motion.div>
          )}
        </AnimatePresence>
      </div>
  );
};

export default UpdateAvatar;