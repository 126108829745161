import React, { useState, useEffect } from "react";
import { usePlayer } from "../context/PlayerContext";
import { useNavigate } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import { FiHeart, FiPlay, FiPause, FiMoreHorizontal, FiChevronLeft } from "react-icons/fi";
import { LazyLoadImage } from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/blur.css';
import BottomNav from "./BottomNav";
import useWindowSize from "../hooks/useWindowSize";
import Header from "./Header";
import {formatArtists} from "./utils/helpers";

const FavoriteTracks = () => {
    const {
        tracks,
        playTrack,
        pauseTrack,
        currentTrack,
        isPlaying,
        toggleFavorite,
    } = usePlayer();

    const navigate = useNavigate();
    const { width } = useWindowSize();
    const theme = localStorage.getItem('theme');
    const [headerBg, setHeaderBg] = useState('transparent');

    const favoriteTracks = tracks.filter(t => t.is_favorite);
    const totalDuration = favoriteTracks.reduce((acc, track) => acc + parseInt(track.duration, 10), 0);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 100) {
                setHeaderBg(theme === 'dark' ? 'bg-neutral-900/90' : 'bg-white/90');
            } else {
                setHeaderBg('transparent');
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [theme]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handlePlay = (track) => {
        if (currentTrack?.id === track.id && isPlaying) {
            pauseTrack();
        } else {
            playTrack(track);
        }
    };

    const formatDuration = (duration) => {
        const seconds = parseInt(duration, 10);
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
    };

    const formatTotalDuration = (totalSeconds) => {
        const hours = Math.floor(totalSeconds / 3600);
        const minutes = Math.floor((totalSeconds % 3600) / 60);

        if (hours > 0) {
            return `${hours} ч ${minutes} мин`;
        }
        return `${minutes} мин`;
    };

    if (!favoriteTracks.length) {
        return (
            <div className={`min-h-screen flex flex-col ${
                theme === 'dark'
                    ? 'bg-gradient-to-b from-pink-900/30 to-neutral-900 text-white'
                    : 'bg-gradient-to-b from-pink-100 to-gray-100 text-gray-900'
            }`}>
                <div className="sticky top-0 z-10 backdrop-blur-md transition-colors">
                    <div className="container mx-auto px-4">
                        <div className="flex items-center h-16">
                            <button
                                onClick={() => navigate('/')}
                                className="mr-4 p-2 rounded-full hover:bg-black/10"
                            >
                                <FiChevronLeft className="text-xl" />
                            </button>
                            <h1 className="text-xl font-bold">Моя музыка</h1>
                        </div>
                    </div>
                </div>

                <div className="flex-1 flex flex-col items-center justify-center text-center p-8">
                    <div className="w-36 h-36 mb-6 rounded-full bg-gradient-to-br from-pink-500 to-red-500 flex items-center justify-center">
                        <FiHeart className="text-6xl text-white" />
                    </div>
                    <h2 className="text-xl font-bold mb-2">Нет избранных треков</h2>
                    <p className={`${theme === 'dark' ? 'text-gray-400' : 'text-gray-600'} mb-8 max-w-md text-sm`}>
                        Добавляйте музыку в эту коллекцию, нажимая на иконку сердца рядом с любимыми треками
                    </p>
                    <button
                        onClick={() => navigate('/music')}
                        className="px-6 py-2.5 rounded-full bg-gradient-to-r from-pink-600 to-red-500 text-white hover:opacity-90 transition-opacity shadow-lg text-sm"
                    >
                        Найти музыку
                    </button>
                </div>

                {width < 768 && <BottomNav />}
            </div>
        );
    }

    return (
        <div className={`min-h-screen pb-20 ${
            theme === 'dark'
                ? 'bg-gradient-to-b from-pink-900/20 to-neutral-900 text-white'
                : 'bg-gradient-to-b from-pink-50 to-gray-100 text-gray-900'
        }`}>
            <Header isMusicPage/>
            {/* Fixed top header for mobile */}
            <div className={`fixed left-0 right-0 top-0 mt-12 z-20 ${headerBg} backdrop-blur-md transition-all duration-300`}>
                <div className="container mx-auto px-4">
                    <div className="flex items-center h-16">
                        <button
                            onClick={() => navigate('/music')}
                            className="mr-2 p-2 rounded-full hover:bg-black/10"
                        >
                            <FiChevronLeft className="text-xl" />
                        </button>
                        <h1 className="text-xl font-bold">Мои любимые</h1>
                    </div>
                </div>
            </div>

            <main className={`container mx-auto mt-28 pb-12 ${width < 768 ? 'px-2' : 'px-4'}`}>
                {/* Адаптивная секция заголовка с сердечком */}
                <div className="relative mb-4 sm:mb-8 rounded-xl sm:rounded-2xl overflow-hidden mt-2 sm:mt-4">
                    <div className="absolute inset-0 bg-gradient-to-r from-pink-600/80 to-red-500/80 z-0"></div>
                    <div className="absolute inset-0 z-0 opacity-20" style={{
                        backgroundImage: `url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M30 30c-5.523 0-10-4.477-10-10s4.477-10 10-10v20zm0 0c5.523 0 10 4.477 10 10s-4.477 10-10 10V30z' fill='%23ffffff' fill-opacity='0.2' fill-rule='evenodd'/%3E%3C/svg%3E")`,
                        backgroundRepeat: 'repeat'
                    }}></div>

                    <div className="relative z-10 p-6 md:p-12 flex flex-col sm:flex-row items-center sm:items-end gap-4 sm:gap-6">
                        <div className="w-24 h-24 sm:w-40 sm:h-40 rounded-xl overflow-hidden shadow-2xl bg-gradient-to-br from-pink-500 to-red-500 flex items-center justify-center">
                            <FiHeart className="text-4xl sm:text-6xl text-white"/>
                        </div>

                        <div className="text-white text-center sm:text-left">
                            <h2 className="text-xs sm:text-sm font-medium uppercase tracking-wider opacity-90 mb-1">Плейлист</h2>
                            <h1 className="text-2xl sm:text-4xl font-bold mb-1 sm:mb-2">Мои любимые</h1>
                            <div className="flex flex-wrap justify-center sm:justify-start gap-2 text-xs sm:text-sm opacity-80">
                                <span>{favoriteTracks.length} треков</span>
                                <span>•</span>
                                <span>{formatTotalDuration(totalDuration)}</span>
                            </div>
                        </div>

                        <div className="sm:ml-auto mt-4 sm:mt-0">
                            <button
                                onClick={() => favoriteTracks.length > 0 && playTrack(favoriteTracks[0])}
                                className="px-6 sm:px-8 py-2 sm:py-3 rounded-full bg-white text-pink-600 hover:bg-white/90 transition-opacity shadow-lg flex items-center gap-2 text-sm sm:text-base"
                            >
                                <FiPlay/>
                                <span>Слушать</span>
                            </button>
                        </div>
                    </div>
                </div>

                {/* Список треков */}
                <div
                    className={`rounded-xl sm:rounded-2xl overflow-hidden shadow-lg p-3 sm:p-4 ${
                        theme === 'dark' ? 'bg-neutral-800/80' : 'bg-white/90'
                    } backdrop-blur-md transition-all duration-300`}
                >
                    {favoriteTracks.map((track, index) => (
                        <motion.div
                            key={track.id}
                            initial={{opacity: 0, y: 10}}
                            animate={{opacity: 1, y: 0}}
                            transition={{delay: index * 0.03, duration: 0.3}}
                            onClick={() => handlePlay(track)}
                            className={`group flex items-center gap-3 p-3 relative rounded-md ${
                                currentTrack?.id === track.id
                                    ? theme === 'dark'
                                        ? 'bg-pink-900/30'
                                        : 'bg-pink-50/90'
                                    : theme === 'dark'
                                        ? 'hover:bg-neutral-700/40'
                                        : 'hover:bg-gray-50/90'
                            } transition-all duration-200 cursor-pointer`}
                        >
                            {/* Индикатор активного трека */}
                            {currentTrack?.id === track.id && (
                                <div
                                    className={`absolute left-0 top-0 bottom-0 w-1 ${
                                        theme === 'dark' ? 'bg-pink-500' : 'bg-pink-500'
                                    }`}
                                ></div>
                            )}

                            {/* Левый блок: обложка */}
                            <div className="relative w-10 h-10 sm:w-12 sm:h-12 flex-shrink-0">
                                <LazyLoadImage
                                    src={
                                        track.album_art
                                            ? `${process.env.REACT_APP_BASE_URL}/storage/${track.album_art}`
                                            : '/placeholder.png'
                                    }
                                    className={`w-10 h-10 sm:w-12 sm:h-12 rounded-md object-cover shadow ${
                                        currentTrack?.id === track.id ? 'ring-2 ring-pink-500/50' : ''
                                    }`}
                                    effect="blur"
                                    placeholderSrc="/placeholder.png"
                                />
                                <div
                                    className={`absolute inset-0 bg-black/30 rounded-md flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-200 ${
                                        currentTrack?.id === track.id && isPlaying ? 'opacity-100' : ''
                                    }`}
                                >
                                    {(currentTrack?.id === track.id && isPlaying) ? (
                                        <FiPause className="text-white text-xl"/>
                                    ) : (
                                        <FiPlay className="text-white text-xl"/>
                                    )}
                                </div>
                            </div>

                            {/* Центральный блок: Номер трека, название трека и исполнитель */}
                            <div className="flex flex-col min-w-0 flex-1">
                                <h3
                                    className={`font-medium truncate text-sm sm:text-base ${
                                        currentTrack?.id === track.id
                                            ? 'text-pink-500'
                                            : theme === 'dark'
                                                ? 'text-white'
                                                : 'text-gray-800'
                                    }`}
                                >
                                    {track.title}
                                </h3>
                                <p
                                    className={`text-xs sm:text-sm truncate ${
                                        theme === 'dark' ? 'text-gray-400' : 'text-gray-500'
                                    }`}
                                >
                                    {formatArtists(track)}
                                </p>
                            </div>

                            {/* Правый блок: Кнопки и время */}
                            <div className="flex items-center gap-1 sm:gap-3 flex-shrink-0">
                                {/* Отображаем время только на больших экранах */}
                                <span
                                    className={`hidden sm:inline-block text-xs sm:text-sm ${
                                        theme === 'dark' ? 'text-gray-400' : 'text-gray-500'
                                    }`}
                                >
                                    {formatDuration(track.duration)}
                                </span>

                                {/* Всегда показываем индикатор воспроизведения на мобильных */}
                                {(currentTrack?.id === track.id && isPlaying) && (
                                    <motion.div
                                        initial={{opacity: 0, scale: 0.8}}
                                        animate={{opacity: 1, scale: 1}}
                                        className="flex gap-0.5 h-3 sm:hidden"
                                    >
                                        <span
                                            className={`block w-1 h-full inline-block rounded-full animate-waveform-3 ${
                                                theme === 'dark' ? 'bg-pink-400' : 'bg-pink-600'
                                            }`}
                                        ></span>
                                        <span
                                            className={`block w-1 h-full inline-block rounded-full animate-waveform-1 ${
                                                theme === 'dark' ? 'bg-pink-400' : 'bg-pink-600'
                                            }`}
                                        ></span>
                                        <span
                                            className={`block w-1 h-full inline-block rounded-full animate-waveform-2 ${
                                                theme === 'dark' ? 'bg-pink-400' : 'bg-pink-600'
                                            }`}
                                        ></span>
                                    </motion.div>
                                )}

                                <button
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        toggleFavorite(track.id);
                                    }}
                                    className={`transition-opacity p-1.5 hover:bg-black/10 dark:hover:bg-white/10 rounded-full`}
                                >
                                    <FiHeart
                                        className={`${
                                            track.is_favorite
                                                ? 'text-pink-500 fill-pink-500'
                                                : theme === 'dark'
                                                    ? 'text-gray-400'
                                                    : 'text-gray-600'
                                        }`}
                                        size={16}
                                    />
                                </button>
                            </div>
                        </motion.div>
                    ))}
                </div>

            </main>

            {/* Всегда показываем BottomNav на мобильных устройствах */}
            {width < 768 && <BottomNav/>}

            <style jsx global>{`
                @keyframes waveform-1 {
                    0%, 100% {
                        height: 8px;
                    }
                    50% {
                        height: 16px;
                    }
                }

                @keyframes waveform-2 {
                    0%, 100% {
                        height: 16px;
                    }
                    50% {
                        height: 6px;
                    }
                }

                @keyframes waveform-3 {
                    0%, 100% {
                        height: 12px;
                    }
                    25% {
                        height: 16px;
                    }
                    75% {
                        height: 8px;
                    }
                }

                .animate-waveform-1 {
                    animation: waveform-1 1s infinite;
                }

                .animate-waveform-2 {
                    animation: waveform-2 1.3s infinite;
                }

                .animate-waveform-3 {
                    animation: waveform-3 0.9s infinite;
                }
            `}</style>
        </div>
    );
};

export default FavoriteTracks;