import React from 'react';

const SocialShare = ({ url, title }) => {
    const encodedUrl = encodeURIComponent(url);
    const encodedTitle = encodeURIComponent(title);

    return (
        <div className="flex gap-3 mt-8 border-t pt-6 border-gray-200 dark:border-neutral-700">
            <span className="text-gray-500 dark:text-gray-400">Поделиться:</span>
            <a
                href={`https://t.me/share/url?url=${encodedUrl}&text=${encodedTitle}`}
                target="_blank"
                rel="noopener noreferrer"
                className="px-2 py-1 rounded-full bg-gray-100 dark:bg-neutral-700 hover:bg-gray-200 transition-colors"
            >
                <i className="fa fa-telegram text-blue-500"></i>
            </a>
        </div>
    );
};

export default SocialShare;