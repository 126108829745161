import React, { useEffect, useState } from "react";
import BackLink from "../BackLink";
import { motion, AnimatePresence } from "framer-motion";
import { Search, ArrowUpDown } from "lucide-react";
import { TbCurrencyDollar, TbCurrencyEuro, TbCurrencyRubel } from "react-icons/tb";
import {ClipLoader} from "react-spinners";

export default function CurrencyDetails() {
    const [exchangeRates, setExchangeRates] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [searchQuery, setSearchQuery] = useState("");
    const [amount, setAmount] = useState(1);
    const [selectedCurrency, setSelectedCurrency] = useState("USD");
    const [showAllRates, setShowAllRates] = useState(false);

    useEffect(() => {
        fetchExchangeRates();
    }, []);

    const fetchExchangeRates = async () => {
        setLoading(true);
        setError(null);
        try {
            const response = await fetch("https://api.exchangerate-api.com/v4/latest/RUB");
            if (!response.ok) throw new Error("Не удалось загрузить курсы валют");
            const data = await response.json();
            const rates = {
                USD: (1 / data.rates.USD).toFixed(2),
                EUR: (1 / data.rates.EUR).toFixed(2),
                ...Object.entries(data.rates).reduce((acc, [currency, rate]) => {
                    acc[currency] = (1 / rate).toFixed(2);
                    return acc;
                }, {})
            };
            setExchangeRates(rates);
        } catch (err) {
            setError(err.message);
            setExchangeRates(null);
        } finally {
            setLoading(false);
        }
    };

    const filteredRates = () => {
        if (!exchangeRates) return {};
        if (!searchQuery) return exchangeRates;
        return Object.fromEntries(
            Object.entries(exchangeRates).filter(([currency]) =>
                currency.toLowerCase().includes(searchQuery.toLowerCase())
            )
        );
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const handleAmountChange = (e) => {
        setAmount(e.target.value);
    };

    const handleCurrencyChange = (e) => {
        setSelectedCurrency(e.target.value);
    };

    const toggleShowAllRates = () => {
        setShowAllRates(!showAllRates);
    };

    const convertedAmount = exchangeRates && exchangeRates[selectedCurrency]
        ? (amount * exchangeRates[selectedCurrency]).toFixed(2)
        : 0;

    const containerVariants = {
        hidden: { opacity: 0 },
        visible: { opacity: 1, transition: { staggerChildren: 0.1 } }
    };

    const itemVariants = {
        hidden: { y: 20, opacity: 0 },
        visible: { y: 0, opacity: 1 }
    };

    return (
        <div className="min-h-screen bg-gradient-to-br from-blue-50 to-purple-50 dark:from-neutral-900 dark:to-neutral-800 transition-all duration-500">
            <div className="container mx-auto p-4 pt-16">
                <BackLink />
                <motion.h1
                    initial={{ y: -20, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    className="text-4xl font-extrabold bg-gradient-to-r from-purple-600 to-blue-500 bg-clip-text text-transparent mb-8"
                >
                    Курсы валют
                </motion.h1>

                {/* Конвертер */}
                <motion.div
                    className="glass-container bg-white/80 dark:bg-neutral-800/80 backdrop-blur-lg rounded-xl p-6 mb-8 shadow-xl"
                    initial={{ scale: 0.95, opacity: 0 }}
                    animate={{ scale: 1, opacity: 1 }}
                >
                    <div className="flex items-center gap-2 mb-4">
                        <ArrowUpDown className="text-2xl text-purple-600" />
                        <h2 className="text-xl font-semibold text-neutral-800 dark:text-neutral-200">Конвертер валют</h2>
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-3 gap-4 items-end">
                        <div>
                            <label className="block text-sm text-neutral-600 dark:text-neutral-400 mb-2">Сумма</label>
                            <input
                                type="number"
                                value={amount}
                                onChange={handleAmountChange}
                                className="w-full px-4 py-3 rounded-lg bg-white dark:bg-neutral-700 border-2 border-purple-100 dark:border-neutral-600 focus:border-purple-500 focus:ring-2 focus:ring-purple-200 transition-all"
                                placeholder="0.00"
                            />
                        </div>

                        <div>
                            <label className="block text-sm text-neutral-600 dark:text-neutral-400 mb-2">Валюта</label>
                            <div className="relative">
                                <select
                                    value={selectedCurrency}
                                    onChange={handleCurrencyChange}
                                    className="w-full px-4 py-3 rounded-lg bg-white dark:bg-neutral-700 border-2 border-purple-100 dark:border-neutral-600 appearance-none focus:border-purple-500 focus:ring-2 focus:ring-purple-200 transition-all"
                                >
                                    {exchangeRates && Object.keys(exchangeRates).map((currency) => (
                                        <option key={currency} value={currency}>
                                            {currency}
                                        </option>
                                    ))}
                                </select>
                                <div className="absolute right-3 top-3 text-purple-500">
                                    <TbCurrencyDollar className="text-xl" />
                                </div>
                            </div>
                        </div>

                        <div className="bg-purple-100 dark:bg-neutral-700 p-4 rounded-lg">
                            <div className="text-sm text-purple-800 dark:text-purple-200">Результат</div>
                            <AnimatePresence mode="wait">
                                <motion.div
                                    key={convertedAmount}
                                    initial={{ y: 10, opacity: 0 }}
                                    animate={{ y: 0, opacity: 1 }}
                                    exit={{ y: -10, opacity: 0 }}
                                    className="text-2xl font-bold text-purple-600 dark:text-purple-400"
                                >
                                    {convertedAmount} RUB
                                </motion.div>
                            </AnimatePresence>
                        </div>
                    </div>
                </motion.div>

                {/* Поиск и управление */}
                <motion.div
                    className="flex flex-col sm:flex-row gap-4 mb-6"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                >
                    <div className="relative flex-1">
                        <Search className="absolute left-3 top-3 text-xl text-neutral-400" />
                        <input
                            type="text"
                            placeholder="Поиск валюты..."
                            value={searchQuery}
                            onChange={handleSearchChange}
                            className="w-full pl-10 pr-4 py-3 rounded-lg bg-white dark:bg-neutral-800 border-2 border-transparent focus:border-purple-500 focus:ring-2 focus:ring-purple-200 transition-all"
                        />
                    </div>
                    <button
                        onClick={toggleShowAllRates}
                        className="px-6 py-3 bg-purple-600 hover:bg-purple-700 dark:bg-purple-500 dark:hover:bg-purple-600 text-white font-medium rounded-lg flex items-center gap-2 transition-transform hover:scale-[1.02]"
                    >
                        <span>{showAllRates ? "Свернуть" : "Все валюты"}</span>
                        <ArrowUpDown className="text-lg" />
                    </button>
                </motion.div>

                {/* Список курсов */}
                {loading ? (
                    <div className="flex justify-center items-center h-64">
                        <div className="animate-spin rounded-full h-12 w-12 border-4 border-purple-500 border-t-transparent"></div>
                    </div>
                ) : error ? (
                    <motion.div
                        initial={{ scale: 0.9 }}
                        animate={{ scale: 1 }}
                        className="p-4 bg-red-100 dark:bg-red-900/30 text-red-700 dark:text-red-300 rounded-lg text-center"
                    >
                        {error}
                    </motion.div>
                ) : (
                    <motion.div
                        variants={containerVariants}
                        initial="hidden"
                        animate="visible"
                        className="grid gap-4 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4"
                    >
                        {Object.entries(filteredRates())
                            .slice(0, showAllRates ? undefined : 3)
                            .map(([currency, rate]) => (
                                <motion.div
                                    key={currency}
                                    variants={itemVariants}
                                    className="group bg-white/90 dark:bg-neutral-800/90 backdrop-blur-sm p-4 rounded-xl border-2 border-transparent hover:border-purple-200 dark:hover:border-purple-800 transition-all shadow-sm hover:shadow-md"
                                >
                                    <div className="flex items-center gap-3 mb-2">
                                        <div className="p-2 bg-purple-100 dark:bg-neutral-700 rounded-lg">
                                            <TbCurrencyDollar className="text-2xl text-purple-600" />
                                        </div>
                                        <div className="font-bold text-xl text-neutral-800 dark:text-neutral-200">
                                            {currency}
                                        </div>
                                    </div>
                                    <div className="text-lg font-medium text-purple-600 dark:text-purple-400">
                                        1 {currency} = {rate} RUB
                                    </div>
                                    <div className="mt-3 text-sm text-neutral-600 dark:text-neutral-400">
                                        Обновлено: {new Date().toLocaleTimeString()}
                                    </div>
                                </motion.div>
                            ))}
                    </motion.div>
                )}
            </div>
        </div>
    );
}