import React from "react";
import { motion } from "framer-motion";

export const Waves = ({ className = "" }) => {
    return (
        <div className={`absolute inset-0 overflow-hidden ${className}`}>
            <motion.svg
                viewBox="0 0 1440 320"
                className="absolute bottom-0 w-full h-auto"
                initial={{ y: 20 }}
                animate={{ y: [20, 0, 20] }}
                transition={{ repeat: Infinity, duration: 6, ease: "easeInOut" }}
            >
                <path
                    fill="rgba(255, 255, 255, 0.75)"
                    d="M0,128L48,144C96,160,192,192,288,197.3C384,203,480,181,576,186.7C672,192,768,224,864,245.3C960,267,1056,277,1152,261.3C1248,245,1344,203,1392,181.3L1440,160L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
                />
                <filter id="gooey">
                    <feGaussianBlur
                        in="SourceGraphic"
                        stdDeviation="30"
                        result="blur"
                    />
                    <feColorMatrix
                        in="blur"
                        type="matrix"
                        values="1 0 0 0 0
                                0 1 0 0 0
                                0 0 1 0 0
                                0 0 0 20 -10"
                        result="gooey"
                    />
                    <feComposite
                        in="SourceGraphic"
                        in2="gooey"
                        operator="atop"
                    />
                </filter>
            </motion.svg>
        </div>
    );
};