import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { usePlayer } from "../context/PlayerContext";
import { ClipLoader } from "react-spinners";
import { motion, AnimatePresence } from "framer-motion";
import BottomNav from "./BottomNav";
import useWindowSize from "../hooks/useWindowSize";
import {
    FiMusic,
    FiHeart,
    FiPlay,
    FiClock,
    FiChevronRight,
    FiShuffle,
    FiPause,
    FiList,
    FiFilter,
    FiCalendar,
    FiUser,
    FiTrendingUp,
    FiPlus,
    FiSearch,
    FiGrid, FiMoreVertical, FiUpload, FiArrowRight
} from "react-icons/fi";
import {ArrowUpFromLine, ChevronRight, Heart} from 'lucide-react';
import Header from "./Header";

import GenreTags from "./GenreTags";
import { LazyLoadImage } from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/blur.css';
import { Waves } from "./Decorations";
import AlbumList from "./AlbumList";
import TrackContextMenu from "./TrackContextMenu";
import toast from 'react-hot-toast'
import CreatePlaylistModal from "./CreatePlaylistModal";
import {formatArtists} from "./utils/helpers";

const TrackList = () => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedGenre, setSelectedGenre] = useState(null);
    const [viewMode, setViewMode] = useState("grid"); // grid или list
    const [showFilters, setShowFilters] = useState(false);
    const [sortBy, setSortBy] = useState("date"); // date, name, artist, duration

    const {
        playTrack,
        pauseTrack,
        tracks,
        setTracks,
        addToHistory,
        playRandomTrack,
        currentTrack,
        isPlaying
    } = usePlayer();
    const navigate = useNavigate();
    const {width} = useWindowSize();
    const [albums, setAlbums] = useState([]);
    const [topArtists, setTopArtists] = useState([]);
    const [newReleases, setNewReleases] = useState([]);
    const [playlists, setPlaylists] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const theme = localStorage.getItem('theme')
    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
    const [showContextMenu, setShowContextMenu] = useState(false);
    const [topArtistsLoading, setTopArtistsLoading] = useState(false);
    const [newReleasesLoading, setNewReleasesLoading] = useState(false);

    useEffect(() => {
        const fetchTracks = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/tracks`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                });
                if (!response.ok) throw new Error("Ошибка загрузки треков");
                const data = await response.json();
                setTracks(data);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchTracks();
    }, [setTracks]);

    useEffect(() => {
        const fetchPlaylists = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/playlists`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                });
                if (!response.ok) throw new Error("Ошибка загрузки плейлистов");
                const data = await response.json();
                setPlaylists(data);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchPlaylists();
    }, []);

    useEffect(() => {
        const fetchAlbums = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/albums`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                });
                if (!response.ok) throw new Error("Ошибка загрузки альбомов");
                const data = await response.json();
                setAlbums(data);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };
        fetchAlbums();
    }, [setAlbums]);

    useEffect(() => {
        const fetchTopArtists = async () => {
            try {
                setTopArtistsLoading(true);
                const response = await fetch(`${process.env.REACT_APP_API_URL}/top-artists`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                });
                if (!response.ok) throw new Error("Ошибка загрузки топ исполнителей");
                const data = await response.json();
                setTopArtists(data);
            } catch (err) {
                setError(err.message);
            } finally {
                setTopArtistsLoading(false);
            }
        }
        fetchTopArtists();
    }, [setTopArtists])

    useEffect(() => {
        const fetchNewReleases = async () => {
            try {
                setNewReleasesLoading(true);
                const response = await fetch(`${process.env.REACT_APP_API_URL}/new-releases`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                });
                if (!response.ok) throw new Error("Ошибка загрузки новых релизов");
                const data = await response.json();
                setNewReleases(data);
            } catch (err) {
                setError(err.message);
            } finally {
                setNewReleasesLoading(false);
            }
        }

        fetchNewReleases();
    }, [setNewReleases]);


    const toggleFavorite = async (trackId) => {
        try {
            const track = tracks.find((t) => t.id === trackId);
            const method = track.is_favorite ? "DELETE" : "POST";

            const response = await fetch(
                `${process.env.REACT_APP_API_URL}/tracks/${trackId}/favorite`,
                {
                    method,
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                }
            );

            if (!response.ok) throw new Error('Ошибка обновления избранного');
            setTracks(
                tracks.map((t) =>
                    t.id === trackId ? {...t, is_favorite: !t.is_favorite} : t
                )
            );
        } catch (err) {
            console.error(err);
            alert('Ошибка при обновлении избранного');
        }
    };

    const handlePlay = (track) => {
        if (currentTrack?.id === track.id && isPlaying) {
            pauseTrack();
        } else {
            addToHistory(track);
            playTrack(track);
        }
    };

    const handleCreatePlaylist = async (formData) => {
        const response = await fetch(
            `${process.env.REACT_APP_API_URL}/playlists`,
            {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
                body: formData
            }
        );

        if (!response.ok) {
            const errorText = await response.text();
            console.error('Ответ сервера:', errorText);
            throw new Error("Ошибка создания плейлиста");
        }

        const newPlaylist = await response.json();
        setPlaylists([...playlists, newPlaylist]);
        toast.success('Плейлист успешно создан!');
    };



    const handleAddToPlaylist = async (playlistId, trackId) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/playlists/${playlistId}/tracks`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ track_ids: [trackId] }), // Изменяем здесь
            });

            if (!response.ok) {
                const errorData = await response.json().catch(() => null);
                console.error('Ошибка сервера:', errorData);
                throw new Error('Ошибка добавления трека в плейлист');
            }

            toast.success('Трек успешно добавлен в плейлист!');
        } catch (err) {
            console.error('Ошибка добавления трека в плейлист:', err);
            toast.error('Не удалось добавить трек в плейлист');
        }
    };


    const formatDuration = (duration) => {
        const seconds = parseInt(duration, 10);
        if (isNaN(seconds)) return '0:00';

        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
    };

    const filteredTracks = selectedGenre
        ? tracks.filter(t => t.genre === selectedGenre)
        : tracks;

    const searchedTracks = searchQuery
        ? filteredTracks.filter(t =>
            t.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
            t.artist.name.toLowerCase().includes(searchQuery.toLowerCase())
        )
        : filteredTracks;

    // Сортировка треков
    const sortedTracks = [...searchedTracks].sort((a, b) => {
        if (sortBy === "date") {
            return new Date(b.created_at || 0) - new Date(a.created_at || 0);
        } else if (sortBy === "name") {
            return a.title.localeCompare(b.title);
        } else if (sortBy === "artist") {
            return a.artist.localeCompare(b.artist.name);
        } else if (sortBy === "duration") {
            return parseInt(a.duration || 0) - parseInt(b.duration || 0);
        }
        return 0;
    });




    if (loading) {
        return (
            <div className={`h-screen w-screen flex flex-col items-center justify-center ${theme === 'dark' ? 'bg-purple-900' : 'bg-gray-100'}`}>
                <Header isMusicPage/>
                <div className="relative w-48 h-48 flex items-center justify-center">
                    <div className="absolute w-full h-full rounded-full bg-gray-900 border-4 border-black/20 animate-spin">
                        <div className="absolute inset-0 rounded-full opacity-50 bg-[radial-gradient(circle_at_center,_#ffffff0a_0%,_transparent_70%)]"/>

                        <div className="absolute top-1/2 left-1/2 w-3 h-3 bg-gray-800/80 rounded-full transform -translate-x-1/2 -translate-y-1/2 shadow-inner"/>
                    </div>

                    <div className="absolute w-20 h-20 bg-gradient-to-br from-purple-600 to-purple-400 rounded-full shadow-lg animate-spin">
                        <div className="absolute inset-0 flex items-center justify-center">
                            <div className="w-12 h-12 rounded-full bg-purple-200/30 shadow-inner">
                                <div className="w-4 h-4 rounded-full bg-purple-500/80"/>
                            </div>
                        </div>
                    </div>
                </div>

                <p className={`mt-8 text-xl ${theme === 'dark' ? 'text-purple-200' : 'text-purple-900'} font-medium tracking-wider`}>
                    Загрузка...
                </p>
            </div>
        );
    }



    if (error) {
        return (
            <div className="h-screen flex items-center justify-center text-red-500">
                {error}
            </div>
        );
    }

    const handleGenreSelect = (genre) => {
        setSelectedGenre(genre);
    };

    let favoriteTracksCount = 0;
    tracks.forEach((track) => {
        if (track.is_favorite) {
            favoriteTracksCount++;
        }
    });

    // console.log('Top Artists Data:', topArtists);

    const getFilterOptions = (filterType) => {
        switch(filterType) {
            case 'Год выпуска': return ['Все', '2024', '2023', 'Раньше'];
            case 'Длительность': return ['Все', '3 мин', '3-5 мин', '> 5 мин'];
            case 'Избранное': return ['Все', 'Избранное'];
            default: return [];
        }
    };

    return (
        <div className={`min-h-screen pt-16 ${
            theme === 'dark'
                ? 'bg-gradient-to-b from-neutral-900 to-neutral-950 text-white'
                : 'bg-gradient-to-b from-gray-100 to-white text-gray-900'
        }`}>
            <Header isMusicPage/>

            <main className="container mx-auto px-4">
                {/* Hero Section */}
                <motion.section
                    initial={{opacity: 0, y: 20}}
                    animate={{opacity: 1, y: 0}}
                    className="mb-12 relative overflow-hidden"
                >
                    <div
                        className={`${
                            theme === 'dark'
                                ? 'bg-gradient-to-br from-purple-600 via-pink-600 to-orange-600'
                                : 'bg-gradient-to-br from-purple-500 via-pink-500 to-orange-400'
                        } rounded-3xl p-10 shadow-2xl relative h-72 flex items-center`}>
                        {/* Динамический фон */}
                        <div className="absolute inset-0 overflow-hidden">
                            <motion.div
                                animate={{
                                    rotate: 360,
                                    scale: [1, 1.2, 1]
                                }}
                                transition={{
                                    rotate: {duration: 20, repeat: Infinity, ease: "linear"},
                                    scale: {duration: 8, repeat: Infinity, ease: "easeInOut"}
                                }}
                                className="absolute -top-48 -left-96 w-96 h-96 bg-gradient-to-r from-purple-400/30 to-pink-400/30 rounded-full blur-3xl"
                            />
                            <motion.div
                                animate={{
                                    rotate: -360,
                                    scale: [1, 1.3, 1]
                                }}
                                transition={{
                                    rotate: {duration: 25, repeat: Infinity, ease: "linear"},
                                    scale: {duration: 10, repeat: Infinity, ease: "easeInOut"}
                                }}
                                className="absolute -top-64 -right-96 w-96 h-96 bg-gradient-to-r from-orange-400/30 to-yellow-400/30 rounded-full blur-3xl"
                            />
                        </div>

                        {/* Улучшенные частицы */}
                        <div className="absolute inset-0">
                            {Array(30).fill(0).map((_, i) => (
                                <motion.div
                                    key={i}
                                    className={`absolute rounded-full ${i % 3 === 0 ? 'bg-white' : i % 3 === 1 ? 'bg-pink-200' : 'bg-purple-200'}`}
                                    style={{
                                        left: `${Math.random() * 100}%`,
                                        top: `${Math.random() * 100}%`,
                                        width: `${Math.random() * 2 + 1}px`,
                                        height: `${Math.random() * 2 + 1}px`,
                                    }}
                                    animate={{
                                        scale: [0, 1, 0],
                                        opacity: [0, 0.4, 0],
                                        y: [0, -30 - Math.random() * 20],
                                        x: [0, (Math.random() - 0.5) * 20],
                                    }}
                                    transition={{
                                        duration: 2 + Math.random() * 6,
                                        repeat: Infinity,
                                        delay: Math.random() * 5,
                                        ease: "easeInOut",
                                    }}
                                />
                            ))}
                        </div>

                        {/* Анимированные волны с большей прозрачностью */}
                        <Waves className="absolute top-0 left-0 w-full h-full opacity-10 text-white/10"/>

                        {/* Улучшенная анимация музыкальных нот */}
                        <div className="absolute inset-0">
                            {['♩', '♪', '♫', '♬', '♭', '♮'].map((note, i) => (
                                <motion.div
                                    key={i}
                                    className="absolute text-4xl font-bold text-white"
                                    style={{
                                        left: `${10 + Math.random() * 80}%`,
                                        top: `${Math.random() * 100}%`,
                                        textShadow: "0 0 5px rgba(255,255,255,0.3)",
                                        opacity: 0.12,
                                    }}
                                    animate={{
                                        y: [0, -120],
                                        x: [(Math.random() - 0.5) * 20, (Math.random() - 0.5) * 40],
                                        rotate: [0, (Math.random() - 0.5) * 180],
                                        opacity: [0.12, 0],
                                        scale: [1, 0.8],
                                    }}
                                    transition={{
                                        duration: 8 + Math.random() * 5,
                                        repeat: Infinity,
                                        ease: "easeOut",
                                        delay: i * 1.5,
                                    }}
                                >
                                    {note}
                                </motion.div>
                            ))}
                        </div>

                        {/* Контент с улучшенным дизайном */}
                        <div className="max-w-full relative z-10 ml-4">
                            <motion.h2
                                initial={{opacity: 0, y: 10}}
                                animate={{opacity: 1, y: 0}}
                                transition={{delay: 0.2, duration: 0.6}}
                                className="text-5xl font-black text-white mb-5 drop-shadow-xl"
                            >
                                chattify <span
                                className="bg-clip-text text-transparent bg-gradient-to-r from-white to-gray-200">music</span>
                            </motion.h2>
                            <div
                                className="flex flex-col sm:flex-row items-start sm:items-center space-y-3 sm:space-y-0 sm:space-x-4">
                                <motion.button
                                    whileHover={{scale: 1.05, backgroundColor: "rgba(255,255,255,0.25)"}}
                                    whileTap={{scale: 0.95}}
                                    onClick={playRandomTrack}
                                    className="bg-white/15 backdrop-blur-md text-white px-6 py-3 rounded-xl font-bold transition flex items-center gap-2 shadow-lg"
                                >
                                    <FiShuffle className="text-lg"/>
                                    <span>Случайный трек</span>
                                </motion.button>
                                <motion.div
                                    initial={{opacity: 0}}
                                    animate={{opacity: 1}}
                                    transition={{delay: 0.4}}
                                    className="flex items-center"
                                >
                    <span
                        className="text-white text-sm font-medium bg-white/10 px-3 py-1.5 rounded-full backdrop-blur-sm">
                        {sortedTracks.length > 1 ? `${sortedTracks.length} треков` : `${sortedTracks.length} трек`}
                    </span>
                                </motion.div>
                            </div>
                        </div>

                        {/* Декоративные элементы */}
                        <motion.div
                            className="absolute -bottom-6 -right-6 w-32 h-32 bg-white/5 rounded-full blur-md"
                            animate={{
                                scale: [1, 1.2, 1],
                                opacity: [0.1, 0.2, 0.1],
                            }}
                            transition={{
                                duration: 8,
                                repeat: Infinity,
                                ease: "easeInOut",
                            }}
                        />
                    </div>
                </motion.section>

                {/* Search & Filters Bar */}
                <section className="mb-6 md:mb-10">
                    <div className={`flex flex-col md:flex-row gap-3 md:gap-5 ${
                        theme === 'dark'
                            ? 'bg-neutral-800/70 backdrop-blur-sm'
                            : 'bg-white/95 backdrop-blur-sm'
                    } p-3 md:p-4 rounded-xl shadow-xl transition-colors duration-300`}>

                        {/* Поисковая строка с улучшенной фокусировкой */}
                        <div className="relative flex-1">
                            <FiSearch className={`absolute left-4 top-1/2 -translate-y-1/2 ${
                                theme === 'dark' ? 'text-neutral-400' : 'text-neutral-500'
                            } transition-colors`}/>
                            <input
                                type="text"
                                placeholder="Поиск..."
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                                className={`w-full pl-11 pr-4 py-2.5 text-sm md:text-base rounded-xl ${
                                    theme === 'dark'
                                        ? 'bg-neutral-700/50 text-neutral-100 placeholder-neutral-400 focus:bg-neutral-700'
                                        : 'bg-neutral-100/70 text-neutral-700 placeholder-neutral-500 focus:bg-neutral-100'
                                } focus:outline-none focus:ring-2 focus:ring-purple-400/50 transition-all`}
                            />
                        </div>

                        {/* Контролы с улучшенной интерактивностью */}
                        <div className="flex items-center gap-2 md:gap-3 w-full md:w-auto">
                            <Link
                                to="/upload-track"
                                className={`p-2.5 rounded-xl ${
                                    theme === 'dark'
                                        ? 'hover:bg-white/5 text-neutral-300'
                                        : 'hover:bg-neutral-100 text-neutral-600'
                                } transition-colors duration-200`}
                                title="Загрузить трек"
                            >
                                <FiUpload className="text-xl"/>
                            </Link>

                            <button
                                onClick={() => setShowFilters(!showFilters)}
                                className={`p-2.5 rounded-xl transition-colors duration-200 ${
                                    theme === 'dark'
                                        ? 'hover:bg-white/5 text-neutral-300'
                                        : 'hover:bg-neutral-100 text-neutral-600'
                                } ${showFilters ? '!bg-purple-500/20 !text-purple-400' : ''}`}
                                title="Показать фильтры"
                            >
                                <FiFilter className="text-xl"/>
                            </button>

                            <select
                                value={sortBy}
                                onChange={(e) => setSortBy(e.target.value)}
                                className={`px-4 py-2.5 rounded-xl text-sm md:text-base ${
                                    theme === 'dark'
                                        ? 'bg-neutral-700/50 text-neutral-200 border-neutral-600'
                                        : 'bg-neutral-100/70 text-neutral-700 border-neutral-200'
                                } border focus:outline-none focus:ring-2 focus:ring-purple-400/30 transition-all`}
                            >
                                <option value="date">По дате</option>
                                <option value="name">По названию</option>
                                <option value="artist">По исполнителю</option>
                                <option value="duration">По длительности</option>
                            </select>
                        </div>
                    </div>

                    {/* Анимированная панель фильтров с оптимизациями */}
                    <AnimatePresence initial={false}>
                        {showFilters && (
                            <motion.div
                                initial={{opacity: 0, scaleY: 0.95, transformOrigin: 'top'}}
                                animate={{opacity: 1, scaleY: 1}}
                                exit={{opacity: 0, scaleY: 0.95}}
                                transition={{duration: 0.2, ease: 'easeInOut'}}
                                className={`mt-3 rounded-xl ${
                                    theme === 'dark'
                                        ? 'bg-neutral-800/60'
                                        : 'bg-white/90'
                                } shadow-lg backdrop-blur-sm`}
                                style={{willChange: 'opacity, transform'}}
                            >
                                <div className="p-4">
                                    <h3 className={`text-lg font-semibold ${
                                        theme === 'dark' ? 'text-neutral-200' : 'text-neutral-700'
                                    } mb-4`}>Фильтры</h3>
                                    <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                                        {['Год выпуска', 'Длительность', 'Избранное'].map((filter, idx) => (
                                            <div key={filter} className="space-y-2">
                                                <h4 className={`text-sm font-medium ${
                                                    theme === 'dark' ? 'text-neutral-400' : 'text-neutral-600'
                                                }`}>{filter}</h4>
                                                <div className="flex flex-wrap gap-2">
                                                    {getFilterOptions(filter).map((label) => (
                                                        <button
                                                            key={label}
                                                            className={`px-3 py-1.5 rounded-lg text-sm ${
                                                                label === 'Все'
                                                                    ? theme === 'dark'
                                                                        ? 'bg-purple-500/25 text-purple-400'
                                                                        : 'bg-purple-100 text-purple-600'
                                                                    : theme === 'dark'
                                                                        ? 'bg-neutral-700/40 text-neutral-300 hover:bg-neutral-700/60'
                                                                        : 'bg-neutral-100 text-neutral-700 hover:bg-neutral-200/70'
                                                            } transition-colors duration-150`}
                                                        >
                                                            {label}
                                                        </button>
                                                    ))}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </motion.div>
                        )}
                    </AnimatePresence>
                </section>

                {/* Quick Stats */}


                {/* Recently Played */}
                {/*                <section className="mb-12">*/}
                {/*                    <div className={`flex items-center justify-between mb-4`}>*/}
                {/*                        <h3 className={`text-lg font-semibold ${*/}
                {/*                            theme === 'dark' ? 'text-white/90' : 'text-gray-800'*/}
                {/*                        }`}>Недавно прослушано</h3>*/}
                {/*                        <button*/}
                {/*                            className={`${*/}
                {/*                                theme === 'dark' ? 'text-white/60 hover:text-white' : 'text-gray-500 hover:text-gray-800'*/}
                {/*                            } transition-colors`}*/}
                {/*                        >*/}
                {/*                            Показать все*/}
                {/*                        </button>*/}
                {/*                    </div>*/}
                {/*                    <div className="grid grid-cols-1 md:grid-cols-5 gap-4">*/}
                {/*                        {recentlyPlayed.map((track, index) => (*/}
                {/*                            <motion.div*/}
                {/*                                key={`recent-${track.id}`}*/}
                {/*                                initial={{opacity: 0, y: 20}}*/}
                {/*                                animate={{opacity: 1, y: 0}}*/}
                {/*                                transition={{delay: index * 0.05}}*/}
                {/*                                className={`${*/}
                {/*                                    theme === 'dark' ? 'bg-neutral-800/50 hover:bg-neutral-700/50' : 'bg-white hover:bg-gray-50'*/}
                {/*                                } p-3 rounded-xl cursor-pointer transition-colors group`}*/}
                {/*                                onClick={() => handlePlay(track)}*/}
                {/*                            >*/}
                {/*                                <div className="relative w-full aspect-square mb-3 overflow-hidden rounded-lg">*/}
                {/*                                    <LazyLoadImage*/}
                {/*                                        src={track.album_art ?*/}
                {/*                                            `${process.env.REACT_APP_BASE_URL}/storage/${track.album_art}` :*/}
                {/*                                            '/placeholder.png'}*/}
                {/*                                        alt={track.name}*/}
                {/*                                        className="w-full h-full object-cover transition-transform duration-300"*/}
                {/*                                        effect="blur"*/}
                {/*                                        placeholderSrc="/placeholder.png"*/}
                {/*                                    />*/}
                {/*                                <div className={`absolute inset-0 ${*/}
                {/*                                    theme === 'dark' ? 'bg-black/50' : 'bg-black/30'*/}
                {/*                                } opacity-0 group-hover:opacity-100 transition-opacity flex items-center justify-center rounded-lg`}>*/}
                {/*                                    <button className="text-white transform scale-125">*/}
                {/*                                        {currentTrack?.id === track.id && isPlaying ?*/}
                {/*                                            <FiPause className="text-2xl"/> :*/}
                {/*                                            <FiPlay className="text-2xl"/>*/}
                {/*                                        }*/}
                {/*                                    </button>*/}
                {/*                                </div>*/}
                {/*                            </div>*/}
                {/*                            <h4 className={`font-medium text-sm truncate ${*/}
                {/*                            theme === 'dark' ? 'text-white' : 'text-gray-800'*/}
                {/*                        }`}>{track.title}</h4>*/}
                {/*                    <p className={`text-xs truncate ${*/}
                {/*                        theme === 'dark' ? 'text-white/60' : 'text-gray-500'*/}
                {/*                    }`}>{track.artist}</p>*/}
                {/*                </motion.div>*/}
                {/*                ))}*/}
                {/*        </div>*/}
                {/*</section>*/}


                {/* Топ исполнители */
                }
                <section className="mb-16">
                    <div className={`flex items-center justify-between mb-6`}>
                        <h3 className={`text-xl font-bold ${theme === 'dark' ? 'text-white/90' : 'text-gray-800'}`}>
                            Топ исполнители
                        </h3>
                        <button
                            onClick={() => navigate('/artists')}
                            className={`${theme === 'dark' ? 'text-purple-400 hover:text-purple-300' : 'text-purple-600 hover:text-purple-800'} transition-colors flex items-center gap-1 font-medium text-sm py-1 px-3 rounded-lg ${theme === 'dark' ? 'hover:bg-purple-900/30' : 'hover:bg-purple-100/70'}`}
                        >
                            Показать все <FiChevronRight className="ml-1"/>
                        </button>
                    </div>

                    {topArtistsLoading ? (
                        <div className="flex justify-center py-4">
                            <ClipLoader color={theme === 'dark' ? "#fff" : "#4f46e5"} size={24}/>
                        </div>
                    ) : error ? (
                        <div className={`text-center py-4 ${theme === 'dark' ? 'text-red-400' : 'text-red-600'}`}>
                            Ошибка загрузки исполнителей
                        </div>
                    ) : (
                        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-5">
                            {topArtists.map((artist, index) => (
                                <motion.div
                                    key={artist.id}
                                    initial={{opacity: 0, y: 20}}
                                    animate={{opacity: 1, y: 0}}
                                    transition={{delay: index * 0.05}}
                                    className="group cursor-pointer"
                                    onClick={() => navigate(`/artist/${artist.id}`)}
                                >
                                    <div
                                        className={`relative overflow-hidden rounded-2xl aspect-square ${theme === 'dark' ? 'shadow-xl shadow-black/20' : 'shadow-lg shadow-gray-200/70'}`}>
                                        <LazyLoadImage
                                            src={`${process.env.REACT_APP_BASE_URL}/storage/${artist.avatar_url}`}
                                            alt={artist.name}
                                            className="w-full h-full object-cover"
                                            effect="blur"
                                        />
                                        <div
                                            className="absolute inset-0 bg-gradient-to-t from-black/60 flex items-end p-4">
                                            <h4 className="font-bold text-white truncate w-full">
                                                {artist.name}
                                            </h4>
                                        </div>
                                    </div>
                                </motion.div>
                            ))}
                        </div>
                    )}
                </section>

                {/* Новые релизы */}
                <section className="mb-14">
                    <div className="flex items-center justify-between mb-5">
                        <h3 className={`text-lg font-semibold tracking-tight ${
                            theme === 'dark' ? 'text-white' : 'text-gray-900'
                        }`}>
                            Новые релизы
                        </h3>
                        <button
                            className={`${
                                theme === 'dark' ? 'text-white/70 hover:text-white' : 'text-gray-600 hover:text-gray-900'
                            } transition-colors flex items-center gap-1 text-sm`}
                        >
                            Показать все <FiChevronRight className="ml-1"/>
                        </button>
                    </div>
                    <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-4">
                        {newReleases.map((track, index) => (
                            <motion.div
                                key={`new-${track.id}`}
                                initial={{opacity: 0, y: 10}}
                                animate={{opacity: 1, y: 0}}
                                transition={{delay: index * 0.04}}
                                className={`${
                                    theme === 'dark'
                                        ? 'hover:bg-neutral-800/80'
                                        : 'hover:bg-gray-100/80'
                                } rounded-lg cursor-pointer transition-all duration-200 group relative`}
                                onClick={() => handlePlay(track)}
                            >
                                <div className="relative w-full aspect-square mb-3 overflow-hidden rounded-lg">
                                    <LazyLoadImage
                                        src={track.album_art ?
                                            `${process.env.REACT_APP_BASE_URL}/storage/${track.album_art}` :
                                            '/placeholder.png'}
                                        alt={track.title}
                                        className="w-full h-full object-cover"
                                        effect="blur"
                                        placeholderSrc="/placeholder.png"
                                    />
                                    <div
                                        className={`absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity`}>
                                        <button className={`${
                                            theme === 'dark' ? 'bg-white/10' : 'bg-black/10'
                                        } text-white p-3 rounded-full backdrop-blur-md`}>
                                            {currentTrack?.id === track.id && isPlaying ?
                                                <FiPause className="text-2xl"/> :
                                                <FiPlay className="text-2xl ml-0.5"/>
                                            }
                                        </button>
                                    </div>
                                </div>
                                <div className="px-1 pb-2">
                                    <h4 className={`font-medium text-sm truncate ${
                                        theme === 'dark' ? 'text-white' : 'text-gray-900'
                                    }`}>{track.title}</h4>
                                    <div className="flex items-center mt-1">
                        <span className={`text-xs inline-block mr-2 px-2 py-0.5 rounded-full ${
                            theme === 'dark' ? 'bg-purple-500/20 text-purple-300' : 'bg-purple-100 text-purple-700'
                        }`}>Новое</span>
                                        <p className={`text-xs truncate ${
                                            theme === 'dark' ? 'text-white/60' : 'text-gray-500'
                                        }`}>
                                            {formatArtists(track)}
                                        </p>
                                    </div>
                                </div>
                            </motion.div>
                        ))}
                    </div>
                </section>

                {/* Плейлисты */}
                <section className="mb-16">
                    {/* Заголовок с обновленным дизайном */}
                    <div className="flex sm:flex-row sm:items-center justify-between mb-6 gap-4">
                        <div className="flex items-center gap-3">
                            <h3 className={`text-xl font-bold ${
                                theme === 'dark' ? 'text-white' : 'text-gray-800'
                            }`}>Ваши плейлисты</h3>
                        </div>
                        <div className="flex items-center gap-2">
                            <button
                                onClick={() => setIsCreateModalOpen(true)}
                                className={`p-2 rounded-lg transition-colors ${
                                    theme === 'dark'
                                        ? 'bg-white/10 hover:bg-white/20 text-white'
                                        : 'bg-purple-100 hover:bg-purple-200 text-purple-600'
                                }`}
                            >
                                <FiPlus className="text-lg"/>
                            </button>
                            <button
                                onClick={() => navigate('/playlists')}
                                className={`${
                                    theme === 'dark'
                                        ? 'text-white/60 hover:text-white'
                                        : 'text-gray-500 hover:text-gray-800'
                                } transition-colors flex items-center gap-1`}
                            >
                                Показать все <FiChevronRight/>
                            </button>
                        </div>
                    </div>

                    <CreatePlaylistModal
                        isOpen={isCreateModalOpen}
                        onClose={() => setIsCreateModalOpen(false)}
                        onCreate={handleCreatePlaylist}
                    />

                    {/* Контейнер плейлистов с обновленным дизайном */}
                    <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-5">
                        {/* Системный плейлист "Мои любимые" */}
                        <motion.div
                            initial={{opacity: 0, y: 20}}
                            animate={{opacity: 1, y: 0}}
                            transition={{delay: 0.1}}
                            className={`group cursor-pointer`}
                            onClick={() => navigate('/favorites')}
                        >
                            <div className="relative w-full aspect-square mb-4 rounded-xl overflow-hidden shadow-lg">
                                <div className={`w-full h-full flex items-center justify-center ${
                                    theme === 'dark'
                                        ? 'bg-gradient-to-br from-pink-500 to-purple-600'
                                        : 'bg-gradient-to-br from-pink-400 to-purple-500'
                                }`}>
                                    <Heart
                                        className="w-20 h-20 text-white"
                                        fill="currentColor"
                                        fillOpacity={0.3}
                                        strokeWidth={1.5}
                                    />
                                </div>
                                {/* Оверлей при наведении */}
                                <div
                                    className="absolute inset-0 bg-black/40 opacity-0 group-hover:opacity-100 transition-all duration-300 flex items-center justify-center">
                                    <div
                                        className="transform translate-y-4 group-hover:translate-y-0 transition-transform duration-300">
                                        <div className="bg-white/20 backdrop-blur-md p-3 rounded-full">
                                            <FiPlay className="text-2xl text-white"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="px-1">
                                <h4 className={`font-semibold text-sm ${
                                    theme === 'dark' ? 'text-white' : 'text-gray-800'
                                }`}>Мои любимые</h4>
                                <div className="flex items-center gap-2 mt-1">
                                    <Heart className={`w-3 h-3 ${
                                        theme === 'dark' ? 'text-pink-400' : 'text-pink-500'
                                    }`} fill="currentColor"/>
                                    <p className={`text-xs ${
                                        theme === 'dark' ? 'text-white/60' : 'text-gray-500'
                                    }`}>{favoriteTracksCount} треков</p>
                                </div>
                            </div>
                        </motion.div>

                        {/* Пользовательские плейлисты */}
                        {playlists.map((playlist, index) => (
                            <motion.div
                                key={playlist.id}
                                initial={{opacity: 0, y: 20}}
                                animate={{opacity: 1, y: 0}}
                                transition={{delay: 0.1 + index * 0.05}}
                                className="group cursor-pointer"
                                onClick={() => navigate(`/playlists/${playlist.id}`)}
                            >
                                <div
                                    className="relative w-full aspect-square mb-4 rounded-xl overflow-hidden shadow-lg">
                                    {playlist.cover_image ? (
                                        <LazyLoadImage
                                            src={`${process.env.REACT_APP_BASE_URL}/storage/${playlist.cover_image}`}
                                            alt={playlist.name}
                                            className="w-full h-full object-cover transform group-hover:scale-105 transition-transform duration-500"
                                            effect="blur"
                                            placeholderSrc="/placeholder.png"
                                        />
                                    ) : (
                                        <div className={`w-full h-full flex items-center justify-center ${
                                            theme === 'dark'
                                                ? 'bg-gradient-to-br from-neutral-700 to-neutral-800'
                                                : 'bg-gradient-to-br from-gray-100 to-gray-200'
                                        }`}>
                                            <FiMusic className={`text-4xl ${
                                                theme === 'dark' ? 'text-purple-400' : 'text-purple-600'
                                            }`}/>
                                        </div>
                                    )}
                                    {/* Оверлей при наведении */}
                                    <div
                                        className="absolute inset-0 bg-black/40 opacity-0 group-hover:opacity-100 transition-all duration-300 flex items-center justify-center">
                                        <div
                                            className="transform translate-y-4 group-hover:translate-y-0 transition-transform duration-300">
                                            <div className="bg-white/20 backdrop-blur-md p-3 rounded-full">
                                                <FiPlay className="text-2xl text-white"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="px-1">
                                    <h4 className={`font-semibold text-sm ${
                                        theme === 'dark' ? 'text-white' : 'text-gray-800'
                                    }`}>{playlist.name}</h4>
                                    <div className="flex items-center gap-2 mt-1">
                                        <FiMusic className={`w-3 h-3 ${
                                            theme === 'dark' ? 'text-purple-400' : 'text-purple-600'
                                        }`}/>
                                        <p className={`text-xs ${
                                            theme === 'dark' ? 'text-white/60' : 'text-gray-500'
                                        }`}>{playlist.tracks_count} треков</p>
                                    </div>
                                </div>
                            </motion.div>
                        ))}
                    </div>
                </section>

                {/* Альбомы */}
                <section className="mb-20">
                    <div className="flex justify-between items-center mb-8 ">
                        <h3 className={`text-lg font-semibold ${
                            theme === 'dark' ? 'text-white/90' : 'text-gray-800'
                        } mb-4`}>Альбомы</h3>
                        <div className="flex items-center gap-2">
                            <Link
                                to="/upload-album"
                                className={`${theme === 'dark' ? 'bg-white/10 hover:bg-white/20' : 'bg-purple-100 hover:bg-purple-200'} p-2 rounded-lg transition-colors`}
                            >
                                <ArrowUpFromLine
                                    className={`${theme === 'dark' ? 'text-white' : 'text-purple-600'} w-4 h-4`}/>
                            </Link>
                            <button
                                className={`${theme === 'dark' ? 'text-white/60 hover:text-white' : 'text-gray-500 hover:text-gray-800'} transition-colors flex items-center gap-1`}
                            >
                                Показать все <FiChevronRight/>
                            </button>
                        </div>
                    </div>
                    <AlbumList/>

                </section>

                {/* Жанры */
                }
                <section className="mb-12">
                    <h3 className={`text-lg font-semibold ${
                        theme === 'dark' ? 'text-white/90' : 'text-gray-800'
                    } mb-4`}>Жанры</h3>
                    <GenreTags
                        tracks={tracks}
                        selectedGenre={selectedGenre}
                        onSelectGenre={handleGenreSelect}
                    />
                </section>

                {/* Треки */}
                <section className="pb-36">
                    <div className="flex justify-between items-center mb-6">
                        <h3 className={`text-xl font-bold ${
                            theme === 'dark' ? 'text-white/90' : 'text-gray-800'
                        }`}>{selectedGenre ? `Треки: ${selectedGenre}` : "Все треки"}</h3>

                        <div className="flex items-center gap-3">
                            <button
                                onClick={() => setViewMode("grid")}
                                className={`p-2 rounded-lg transition-colors ${
                                    viewMode === "grid"
                                        ? (theme === 'dark' ? 'bg-purple-800/50 text-purple-400' : 'bg-purple-100 text-purple-600')
                                        : (theme === 'dark' ? 'text-white/60 hover:bg-neutral-700/50' : 'text-gray-500 hover:bg-gray-100')
                                }`}
                            >
                                <FiGrid className="text-lg"/>
                            </button>
                            <button
                                onClick={() => setViewMode("list")}
                                className={`p-2 rounded-lg transition-colors ${
                                    viewMode === "list"
                                        ? (theme === 'dark' ? 'bg-purple-800/50 text-purple-400' : 'bg-purple-100 text-purple-600')
                                        : (theme === 'dark' ? 'text-white/60 hover:bg-neutral-700/50' : 'text-gray-500 hover:bg-gray-100')
                                }`}
                            >
                                <FiList className="text-lg"/>
                            </button>
                        </div>
                    </div>

                    {viewMode === "grid" ? (
                        // Grid view
                        <div
                            className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 gap-5">
                            {sortedTracks.map((track, index) => (
                                <motion.div
                                    key={track.id}
                                    initial={{opacity: 0, y: 20}}
                                    animate={{opacity: 1, y: 0}}
                                    transition={{delay: index * 0.03}}
                                    className={`${
                                        theme === 'dark'
                                            ? 'bg-neutral-800 hover:bg-neutral-750 shadow-xl shadow-black/20'
                                            : 'bg-white hover:bg-gray-50 shadow-lg shadow-gray-200/70'
                                    } rounded-2xl overflow-hidden cursor-pointer transition-all duration-300 group hover:scale-[1.02]`}
                                    onClick={() => handlePlay(track)}
                                >
                                    <div className="relative w-full aspect-square overflow-hidden">
                                        <LazyLoadImage
                                            src={track.album_art ?
                                                `${process.env.REACT_APP_BASE_URL}/storage/${track.album_art}` :
                                                '/placeholder.png'}
                                            alt={track.name}
                                            className="w-full h-full object-cover transition-transform duration-500 group-hover:scale-110"
                                            effect="blur"
                                            placeholderSrc="/placeholder.png"
                                        />
                                        <div className={`absolute inset-0 ${
                                            theme === 'dark' ? 'bg-gradient-to-t from-black/80 to-transparent' : 'bg-gradient-to-t from-black/60 to-transparent'
                                        } opacity-60 group-hover:opacity-100 transition-opacity flex items-center justify-center`}>
                                            <div
                                                className="absolute bottom-0 left-0 right-0 p-3 transform translate-y-10 group-hover:translate-y-0 transition-transform duration-300">
                                                <div className="flex justify-between items-center">
                                                    <button
                                                        className="bg-purple-600 hover:bg-purple-700 text-white p-2.5 rounded-full shadow-lg transform group-hover:scale-110 transition-all">
                                                        {currentTrack?.id === track.id && isPlaying ?
                                                            <FiPause className="text-xl"/> :
                                                            <FiPlay className="text-xl ml-0.5"/>
                                                        }
                                                    </button>
                                                    <button
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            toggleFavorite(track.id);
                                                        }}
                                                        className="p-2 rounded-full bg-white/20 backdrop-blur-sm hover:bg-white/30 transition-colors"
                                                    >
                                                        <FiHeart
                                                            className={`text-lg ${
                                                                track.is_favorite ? 'text-pink-500 fill-pink-500' : 'text-white'
                                                            }`}
                                                        />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="p-4">
                                        <h4 className={`font-semibold truncate ${
                                            theme === 'dark' ? 'text-white' : 'text-gray-800'
                                        } ${currentTrack?.id === track.id ? 'text-purple-500' : ''}`}>
                                            {track.title}
                                        </h4>
                                        <p className={`text-sm truncate mt-1 ${theme === 'dark' ? 'text-white/60' : 'text-gray-500'}`}>
                                            {formatArtists(track)}
                                        </p>
                                    </div>
                                </motion.div>
                            ))}
                        </div>
                    ) : (
                        // List view
                        <div className="space-y-2">
                            {sortedTracks.map((track, index) => (
                                <motion.div
                                    key={track.id}
                                    initial={{opacity: 0, y: 10}}
                                    animate={{opacity: 1, y: 0}}
                                    transition={{delay: index * 0.02}}
                                    onClick={() => handlePlay(track)}
                                    className={`flex items-center p-3 ${
                                        currentTrack?.id === track.id
                                            ? (theme === 'dark' ? 'bg-purple-900/20 border-l-4 border-purple-500' : 'bg-purple-50 border-l-4 border-purple-500')
                                            : (theme === 'dark' ? 'bg-neutral-800/70 border-l-4 border-transparent' : 'bg-white border-l-4 border-transparent')
                                    } hover:${
                                        theme === 'dark' ? 'bg-neutral-700/60' : 'bg-gray-100'
                                    } rounded-xl cursor-pointer group transition-all duration-200 shadow-lg ${
                                        theme === 'dark' ? 'shadow-black/10' : 'shadow-gray-200/70'
                                    }`}
                                >
                                    {/* Обложка альбома */}
                                    <div className="relative w-12 h-12 rounded-lg overflow-hidden shadow mr-4">
                                        <LazyLoadImage
                                            src={track.album_art ?
                                                `${process.env.REACT_APP_BASE_URL}/storage/${track.album_art}` :
                                                '/placeholder.png'}
                                            alt={track.title}
                                            className="w-full h-full object-cover"
                                            effect="blur"
                                            placeholderSrc="/placeholder.png"
                                        />
                                        <div className={`absolute inset-0 ${
                                            theme === 'dark' ? 'bg-black/50' : 'bg-black/30'
                                        } opacity-0 group-hover:opacity-100 transition-opacity flex items-center justify-center`}>
                                            {currentTrack?.id === track.id && isPlaying ?
                                                <FiPause className="text-white text-sm"/> :
                                                <FiPlay className="text-white text-sm"/>
                                            }
                                        </div>
                                    </div>

                                    {/* Информация о треке */}
                                    <div className="flex-1 min-w-0 mr-4">
                                        <div className="flex items-center">
                                            <p className={`font-medium truncate ${
                                                theme === 'dark' ? 'text-white' : 'text-gray-800'
                                            } ${
                                                currentTrack?.id === track.id ? 'text-purple-500' : ''
                                            }`}>{track.title}</p>

                                            {currentTrack?.id === track.id && isPlaying && (
                                                <span className={`ml-2 px-2 py-0.5 text-xs rounded-full ${
                                                    theme === 'dark' ? 'bg-purple-500/20 text-purple-300' : 'bg-purple-100 text-purple-600'
                                                }`}>
                      Играет
                    </span>
                                            )}
                                        </div>
                                        <div className="flex items-center">
                                            <p className={`text-sm truncate ${
                                                theme === 'dark' ? 'text-white/60' : 'text-gray-500'
                                            }`}>
                                                {formatArtists(track)}
                                            </p>
                                            {track.album && (
                                                <>
                    <span className={`mx-2 text-xs ${
                        theme === 'dark' ? 'text-white/30' : 'text-gray-300'
                    }`}>•</span>
                                                    <p className={`text-sm truncate ${
                                                        theme === 'dark' ? 'text-white/50' : 'text-gray-500'
                                                    }`}>{track.album.title}</p>
                                                </>
                                            )}
                                        </div>
                                    </div>

                                    {/* Продолжительность */}
                                    <span className={`mr-4 text-sm ${
                                        theme === 'dark' ? 'text-white/70' : 'text-gray-500'
                                    }`}>{formatDuration(track.duration)}</span>

                                    {/* Кнопка добавления в избранное */}
                                    <button
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            toggleFavorite(track.id);
                                        }}
                                        className="p-2 rounded-full hover:bg-white/10 transition-colors"
                                    >
                                        <FiHeart
                                            className={`text-base ${
                                                track.is_favorite ? 'text-pink-500 fill-pink-500' : (
                                                    theme === 'dark' ? 'text-white/60' : 'text-gray-400'
                                                )
                                            } hover:scale-110 transition-transform`}
                                        />
                                    </button>
                                    <div key={track.id} className="relative">
                                        <button
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                setShowContextMenu(track.id); // Открываем меню для конкретного трека
                                            }}
                                            className="p-2 rounded-full hover:bg-white/10 transition-colors"
                                        >
                                            <FiMoreVertical className="dark:text-white/60"/>
                                        </button>

                                        {showContextMenu === track.id && (
                                            <TrackContextMenu
                                                track={track}
                                                playlists={playlists}
                                                onAddToPlaylist={handleAddToPlaylist}
                                                onClose={() => setShowContextMenu(null)} // Закрываем меню
                                            />
                                        )}
                                    </div>
                                </motion.div>
                            ))}
                        </div>
                    )}

                    {sortedTracks.length === 0 && (
                        <div className={`text-center py-16 ${
                            theme === 'dark' ? 'bg-neutral-800/50 text-white/70' : 'bg-white text-gray-500'
                        } rounded-2xl shadow-lg`}>
                            <motion.div
                                initial={{opacity: 0, scale: 0.9}}
                                animate={{opacity: 1, scale: 1}}
                                transition={{duration: 0.3}}
                                className="max-w-sm mx-auto"
                            >
                                <FiMusic className="text-6xl mx-auto mb-6 opacity-30"/>
                                <p className="text-xl font-medium mb-3">Треки не найдены</p>
                                <p className="text-sm mb-6 opacity-70">По вашему запросу не найдено ни одного трека</p>
                                {selectedGenre && (
                                    <button
                                        onClick={() => setSelectedGenre(null)}
                                        className={`px-6 py-3 rounded-xl font-medium ${
                                            theme === 'dark'
                                                ? 'bg-purple-600 hover:bg-purple-700 text-white'
                                                : 'bg-purple-600 hover:bg-purple-700 text-white'
                                        } transition-colors`}
                                    >
                                        Сбросить фильтры
                                    </button>
                                )}
                            </motion.div>
                        </div>
                    )}
                </section>
            </main>
            {width < 768 && <BottomNav/>}
        </div>

    );
};

export default TrackList;
  