import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import BackLink from './BackLink';
import Aside from './Aside';
import BottomNav from './BottomNav';
import useWindowSize from '../hooks/useWindowSize';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';

const statusStyles = {
  new: {
    bg: 'bg-red-50 dark:bg-red-900/20',
    border: 'border-red-300 dark:border-red-800',
    text: 'text-red-800 dark:text-red-300',
    dot: 'bg-red-600 dark:bg-red-500',
    badgeBg: 'bg-red-100 dark:bg-red-800/40'
  },
  in_progress: {
    bg: 'bg-blue-50 dark:bg-blue-900/20',
    border: 'border-blue-300 dark:border-blue-800',
    text: 'text-blue-800 dark:text-blue-300',
    dot: 'bg-blue-600 dark:bg-blue-500',
    badgeBg: 'bg-blue-100 dark:bg-blue-800/40'
  },
  resolved: {
    bg: 'bg-green-50 dark:bg-green-900/20',
    border: 'border-green-300 dark:border-green-800',
    text: 'text-green-800 dark:text-green-300',
    dot: 'bg-green-600 dark:bg-green-500',
    badgeBg: 'bg-green-100 dark:bg-green-800/40'
  },
  rejected: {  
    bg: 'bg-neutral-50 dark:bg-neutral-800/40',
    border: 'border-neutral-300 dark:border-neutral-700',
    text: 'text-neutral-800 dark:text-neutral-300',
    dot: 'bg-neutral-600 dark:bg-neutral-500',
    badgeBg: 'bg-neutral-100 dark:bg-neutral-700/60'
  }
};

const statusTranslations = {
  new: 'Новый',
  in_progress: 'В работе',
  resolved: 'Решено',
  rejected: 'Отклонено'
};

const CategoryIcon = ({ category }) => {
  return (
    <div className="p-2 rounded-full bg-indigo-100 dark:bg-indigo-900/30 text-indigo-600 dark:text-indigo-400">
      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
        <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd" />
      </svg>
    </div>
  );
};

export default function ReportsList() {
  const { user, logout } = useContext(AuthContext);
  const [reports, setReports] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { width } = useWindowSize();

  useEffect(() => {
    const fetchReports = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          navigate('/login');
          return;
        }

        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/reports`,
          {
            headers: { 
              Authorization: `Bearer ${token}`,
              'User-Id': user?.id
            }
          }
        );

        setReports(response.data);
      } catch (error) {
        handleErrors(error);
      } finally {
        setLoading(false);
      }
    };

    if (user) {
      fetchReports();
    }
  }, [user, navigate]);

  const handleErrors = (error) => {
    if (error.response?.status === 401) {
      toast.error('Сессия истекла');
      logout();
      navigate('/login');
    } else if (error.response?.status === 403) {
      toast.error('Доступ запрещен');
      navigate('/');
    } else {
      toast.error('Ошибка загрузки данных');
    }
  };

  const handleStatusChange = async (reportId, newStatus) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}/reports/${reportId}`,
        { status: newStatus },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      
      setReports(reports.map(report => 
        report.id === reportId ? response.data : report
      ));
      toast.success('Статус обновлен');
    } catch (error) {
      handleErrors(error);
    }
  };

  const groupedReports = {
    new: reports.filter(report => report.status === 'new'),
    in_progress: reports.filter(report => report.status === 'in_progress'),
    resolved: reports.filter(report => report.status === 'resolved'),
    rejected: reports.filter(report => report.status === 'rejected')
  };

  const ReportCard = ({ report }) => {
    const style = statusStyles[report.status];
    
    return (
      <div className={`relative rounded-2xl shadow-sm p-4 sm:p-6 mb-4 ${style.bg} border ${style.border} transition-all hover:shadow-md`}>
        <div className="flex flex-col sm:flex-row sm:items-start sm:justify-between">
          <div className="flex items-start space-x-3 sm:space-x-4 mb-3 sm:mb-0">
            <CategoryIcon category={report.category} />
            <div>
              <h3 className="text-base sm:text-lg font-medium text-neutral-900 dark:text-neutral-100">{report.category}</h3>
              <p className="mt-1 text-sm text-neutral-600 dark:text-neutral-400">{report.description}</p>
              
              {user?.is_admin && (
                <p className="mt-2 text-xs text-neutral-500 dark:text-neutral-400">
                  <span className="font-medium">Email:</span> {report.email}
                </p>
              )}
              
              <div className="mt-3 flex items-center text-xs text-neutral-500 dark:text-neutral-400">
                <svg className="mr-1.5 h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clipRule="evenodd" />
                </svg>
                {new Date(report.created_at).toLocaleDateString()}
              </div>
            </div>
          </div>
          
          <div className="self-start sm:self-center mt-2 sm:mt-0">
            {user?.is_admin ? (
              <select
                value={report.status}
                onChange={(e) => handleStatusChange(report.id, e.target.value)}
                className={`px-2 sm:px-3 py-1 sm:py-1.5 rounded-full text-sm ${style.text} ${style.badgeBg} border ${style.border} focus:outline-none focus:ring-2 focus:ring-indigo-500 dark:focus:ring-indigo-400`}
              >
                <option value="new">Новый</option>
                <option value="in_progress">В работе</option>
                <option value="resolved">Решено</option>
                <option value="rejected">Отклонено</option>
              </select>
            ) : (
              <span className={`inline-flex items-center px-2 sm:px-3 py-1 rounded-full text-xs sm:text-sm font-medium ${style.text} ${style.badgeBg}`}>
                <span className={`mr-1.5 h-2 w-2 sm:h-2.5 sm:w-2.5 rounded-full ${style.dot}`}></span>
                {statusTranslations[report.status]}
              </span>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="min-h-screen bg-neutral-50 dark:bg-neutral-900 transition-colors duration-200 pb-28">
      <div className="container mx-auto flex flex-col lg:flex-row p-2">
        {width >= 1024 ? <Aside /> : <BottomNav />}
        
        <div className="container mx-auto pt-14 lg:pt-16">
          <BackLink />
          <div className="flex justify-between items-center mb-6">
          {/* Скелетон заголовка */}
            <h1 className="text-3xl sm:text-3xl font-extrabold mb-4 sm:mb-6 text-neutral-900 dark:text-white">
              Все жалобы
            </h1>

            <Link 
              to="/settings/report-issue"
              className="hidden lg:flex items-center bg-indigo-600 hover:bg-indigo-700 text-white px-5 py-2.5 rounded-full transition-colors"
            >
              <svg 
                className="w-5 h-5 mr-2" 
                fill="none" 
                stroke="currentColor" 
                viewBox="0 0 24 24"
              >
                <path 
                  strokeLinecap="round" 
                  strokeLinejoin="round" 
                  strokeWidth={2} 
                  d="M12 4v16m8-8H4" 
                />
              </svg>
              Новая жалоба
            </Link>
          </div>

          <div className="lg:hidden fixed bottom-20 right-4 z-50">
            <Link
              to="/settings/report-issue"
              className="flex items-center justify-center w-14 h-14 bg-indigo-600 hover:bg-indigo-700 text-white rounded-full shadow-lg transition-transform transform hover:scale-105"
            >
              <svg 
                className="w-6 h-6" 
                fill="none" 
                stroke="currentColor" 
                viewBox="0 0 24 24"
              >
                <path 
                  strokeLinecap="round" 
                  strokeLinejoin="round" 
                  strokeWidth={2} 
                  d="M12 4v16m8-8H4" 
                />
              </svg>
            </Link>
          </div>

          {/* Скелетоны фильтров */}
          <div className="mb-6 sm:mb-8 grid grid-cols-2 sm:grid-cols-4 gap-2 sm:gap-4">
            {loading ? (
              Array(4).fill().map((_, i) => (
                <div 
                  key={i}
                  className="h-20 bg-neutral-200 dark:bg-neutral-700 rounded-2xl animate-pulse"
                />
              ))
            ) : Object.keys(statusTranslations).map(status => (
              <div 
                key={status} 
                className={`p-3 sm:p-4 rounded-2xl ${statusStyles[status].bg} ${statusStyles[status].border} border`}
              >
                <div className="flex justify-between items-center">
                  <span className={`text-xs sm:text-sm font-medium ${statusStyles[status].text}`}>
                    {statusTranslations[status]}
                  </span>
                  <span className="text-xl sm:text-2xl font-bold text-neutral-800 dark:text-neutral-200">
                    {groupedReports[status].length}
                  </span>
                </div>
              </div>
            ))}
          </div>

          {/* Скелетоны карточек */}
          <div className="space-y-4 sm:space-y-6">
            {loading ? (
              Array(3).fill().map((_, i) => (
                <div 
                  key={i}
                  className="p-4 bg-white dark:bg-neutral-800 rounded-2xl shadow-sm mb-4 animate-pulse"
                >
                  <div className="flex space-x-4">
                    <div className="h-12 w-12 bg-neutral-200 dark:bg-neutral-700 rounded-full" />
                    <div className="flex-1 space-y-3">
                      <div className="h-4 bg-neutral-200 dark:bg-neutral-700 rounded w-3/4" />
                      <div className="h-3 bg-neutral-200 dark:bg-neutral-700 rounded w-full" />
                      <div className="h-3 bg-neutral-200 dark:bg-neutral-700 rounded w-2/3" />
                    </div>
                    <div className="h-6 w-20 bg-neutral-200 dark:bg-neutral-700 rounded-full" />
                  </div>
                </div>
              ))
            ) : reports.length > 0 ? (
              reports.map(report => (
                <ReportCard key={report.id} report={report} />
              ))
            ) : (
              <div className="text-center py-8 sm:py-12 bg-neutral-50 dark:bg-neutral-800 rounded-lg">
                <svg className="mx-auto h-10 w-10 sm:h-12 sm:w-12 text-neutral-400 dark:text-neutral-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                </svg>
                <h3 className="mt-2 text-sm font-medium text-neutral-900 dark:text-neutral-200">Нет отчетов</h3>
                <p className="mt-1 text-sm text-neutral-500 dark:text-neutral-400">На данный момент отчеты отсутствуют.</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}