import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { AuthContext } from '../context/AuthContext';
import { useParams } from 'react-router-dom';
import BackLink from "./BackLink";
import ProfilePosts from "./profile/ProfilePosts";
import { ClipLoader } from "react-spinners";
import AvatarGallery from "./profile/AvatarGallery";
import useWindowSize from "../hooks/useWindowSize";
import BottomNav from "./BottomNav";
import OnlineStatusIndicator from "./OnlineStatusIndicator";
import OnlineStatus from "./OnlineStatus";

function UserProfile() {
    const [user, setUser] = useState(null);
    const [selectedPhoto, setSelectedPhoto] = useState(null);
    const { isLoggedIn } = useContext(AuthContext);
    const { userId } = useParams();
    const { width } = useWindowSize();

    useEffect(() => {
        const fetchUserProfile = async () => {
            const token = localStorage.getItem('token');
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/profile/${userId}`, {
                    headers: { Authorization: `Bearer ${token}` },
                });

                console.log("API response:", response.data);

                const avatarsResponse = await axios.get(`${process.env.REACT_APP_API_URL}/profile/${userId}/avatars`, {
                    headers: { Authorization: `Bearer ${token}` },
                });

                console.log("User avatars:", avatarsResponse.data);

                setUser({ ...response.data, avatars: avatarsResponse.data });
            } catch (error) {
                console.error('Ошибка при загрузке профиля пользователя:', error);
            }
        };

        if (isLoggedIn) {
            fetchUserProfile();
        }
    }, [isLoggedIn, userId]);


    const openPhoto = (photo) => {
        setSelectedPhoto(photo);
    };

    const closePhoto = () => {
        setSelectedPhoto(null);
    };

    if (!user) {
        return (
            <div className="flex items-center justify-center min-h-screen pt-16">
                <ClipLoader color="#6B46C1" size={75} />
            </div>
        );
    }

    return (
        <div className="container mx-auto p-4 h-screen flex flex-col lg:flex-row items-start gap-8 pt-16 pb-28">
            <BackLink />
            {width < 768 && <BottomNav />}
            {/* Левая часть: информация о пользователе */}
            <div className="bg-gray-50 dark:bg-neutral-900 p-6 rounded-2xl shadow-md flex flex-col items-center w-full lg:w-1/3">
                <img
                    src={user.avatar ? `${process.env.REACT_APP_BASE_URL}/storage/${user.avatar}` : `https://ui-avatars.com/api/?name=${user.name}&background=random`}
                    alt={user.name}
                    className="w-32 h-32 rounded-full object-cover shadow-md mb-4 hover:opacity-80 transition-opacity duration-200 cursor-pointer"
                    onClick={() => openPhoto(user.avatar)}
                />
                <div className="text-center">
                    {/*<OnlineStatus userId={user.id} initialStatus={user.is_online} initialLastSeen={user.last_activity} />*/}
                    <OnlineStatusIndicator userId={user.id} size={'md'} showText={true} className="justify-center" />
                    <h2 className="text-xl font-bold dark:text-white mb-2">{user.name}</h2>
                    <p className="text-gray-600 dark:text-gray-300 mb-4">{user.status || 'Описание отсутствует'}</p>
                    <p className="text-gray-600 dark:text-gray-300 mt-2">{user.email}</p>
                </div>

                {/* Галерея или фото */}
                <AvatarGallery avatars={user.avatars || []} onImageClick={openPhoto} />
            </div>

            {/* Правая часть: посты */}
            <div className="flex-1 mt-8 w-full">
                <ProfilePosts userId={userId} />
            </div>

            {/* Модальное окно для просмотра фотографии */}
            {selectedPhoto && (
                <div
                    className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50"
                    onClick={closePhoto}
                >
                    <img
                        src={`${process.env.REACT_APP_BASE_URL}/storage/${selectedPhoto}`}
                        alt="Selected"
                        className="max-w-full max-h-full"
                    />
                </div>
            )}
        </div>
    );
}

export default UserProfile;