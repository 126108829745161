import React, {useCallback, useEffect, useRef, useState} from 'react';
import { format, formatRelative } from 'date-fns';
import { ru } from 'date-fns/locale';
import {motion, AnimatePresence, useAnimation} from 'framer-motion';
import useWindowSize from '../hooks/useWindowSize';
import { useSwipeable } from 'react-swipeable';
import { Link } from "react-router-dom";
import {MagnifyingGlassIcon, MagnifyingGlassMinusIcon, MagnifyingGlassPlusIcon} from "@heroicons/react/16/solid";
import {DownloadIcon, ShareIcon, RotateCw} from "lucide-react";
import { saveAs } from 'file-saver';

function Modal({ images, selectedIndex, setSelectedIndex, onClose, onShare, nextImage, prevImage, post, handleUnlike, handleLike, handleAddComment }) {
    const [localPost, setLocalPost] = useState({
        ...post,
        likes: post.likes || [],
        comments: post.comments || [],
    });
    const [newComment, setNewComment] = useState('');
    const [isCommentsOpen, setIsCommentsOpen] = useState(false);
    const [expandedComments, setExpandedComments] = useState({});
    const [replyingTo, setReplyingTo] = useState(null);
    const { width } = useWindowSize();
    const controls = useAnimation();
    const [zoomLevel, setZoomLevel] = useState(1);
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const [dragConstraints, setDragConstraints] = useState({ left: 0, right: 0, top: 0, bottom: 0 });
    const [rotateDeg, setRotateDeg] = useState(0);
    const imageRef = useRef(null);
    const containerRef = useRef(null);


    const defaultAvatar = `https://ui-avatars.com/api/?name=${post.user.name}&background=random`;

    useEffect(() => {
        document.body.style.overflow = 'hidden';
        return () => {
            document.body.style.overflow = '';
        };
    }, []);

    const onCommentChange = (e) => {
        setNewComment(e.target.value);
    };

    const getImageDimensions = () => {
        if (!imageRef.current) return { width: 0, height: 0 };
        return {
            width: imageRef.current.offsetWidth,
            height: imageRef.current.offsetHeight,
        };
    };

    const getDragConstraints = useCallback(() => {
        const container = containerRef.current;
        if (!container || !imageRef.current) return { left: 0, right: 0, top: 0, bottom: 0 };

        const containerRect = container.getBoundingClientRect();
        const imgWidth = imageRef.current.offsetWidth;
        const imgHeight = imageRef.current.offsetHeight;

        const scaledWidth = imgWidth * zoomLevel;
        const scaledHeight = imgHeight * zoomLevel;

        const excessX = Math.max((scaledWidth - containerRect.width) / 2, 0);
        const excessY = Math.max((scaledHeight - containerRect.height) / 2, 0);

        return {
            left: -excessX,
            right: excessX,
            top: -excessY,
            bottom: excessY,
        };
    }, [zoomLevel]);

    const handleZoom = useCallback((direction, clientX, clientY) => {
        const container = containerRef.current;
        if (!container || !imageRef.current) return;

        const rect = container.getBoundingClientRect();
        const imgWidth = imageRef.current.offsetWidth;
        const imgHeight = imageRef.current.offsetHeight;

        if (clientX === undefined || clientY === undefined) {
            clientX = rect.left + rect.width / 2;
            clientY = rect.top + rect.height / 2;
        }

        const currentZoom = zoomLevel;
        const newZoom = direction === 'in'
            ? Math.min(currentZoom * 1.2, 5)
            : Math.max(currentZoom * 0.8, 0.5);

        const containerX = clientX - rect.left;
        const containerY = clientY - rect.top;

        const imageX = (containerX - position.x) / currentZoom;
        const imageY = (containerY - position.y) / currentZoom;

        let newX = containerX - imageX * newZoom;
        let newY = containerY - imageY * newZoom;

        // Применяем ограничения
        const constraints = getDragConstraints();
        newX = clamp(newX, constraints.left, constraints.right);
        newY = clamp(newY, constraints.top, constraints.bottom);

        setZoomLevel(newZoom);
        setPosition({ x: newX, y: newY });
    }, [zoomLevel, position.x, position.y, getDragConstraints]);

    const handleZoomIn = (e) => handleZoom('in', e.clientX, e.clientY);
    const handleZoomOut = (e) => handleZoom('out', e.clientX, e.clientY);

    useEffect(() => {
        setZoomLevel(1);
        setPosition({ x: 0, y: 0 });
        setRotateDeg(0);
    }, [selectedIndex]);

    const handleWheel = useCallback(
        (e) => {
            e.preventDefault();
            const direction = e.deltaY < 0 ? 'in' : 'out';
            handleZoom(direction, e.clientX, e.clientY);
        },
        [zoomLevel, position]
    );

    const handlePan = (event, info) => {
        if (zoomLevel > 1) {
            const constraints = getDragConstraints();
            setPosition((prev) => ({
                x: clamp(prev.x + info.delta.x, constraints.left, constraints.right),
                y: clamp(prev.y + info.delta.y, constraints.top, constraints.bottom),
            }));
        }
    };

    const clamp = (value, min, max) => Math.min(Math.max(value, min), max);

    const handleDownload = async () => {
        try {
            const image = images[selectedIndex];
            const imageUrl = `${process.env.REACT_APP_BASE_URL}/storage/${image.image_path}`;
            saveAs(imageUrl, image.image_path.split('/').pop());
        } catch (error) {
            console.error('Download failed:', error);
            alert('Не удалось скачать изображение');
        }
    };

    const handleRotate = () => {
        setRotateDeg((prev) => (prev + 90) % 360);
    };

    const onSubmitComment = async () => {
        try {
            const updatedComments = await handleAddComment(post.id, newComment, replyingTo);
            if (updatedComments) {
                setLocalPost((prevPost) => ({
                    ...prevPost,
                    comments: updatedComments,
                }));
                setNewComment('');
                setReplyingTo(null);
            }
        } catch (error) {
            console.error('Ошибка при добавлении комментария:', error);
        }
    };

    const onLike = async () => {
        try {
            const updatedLikes = await handleLike(post.id);
            if (updatedLikes) {
                setLocalPost(prevPost => ({
                    ...prevPost,
                    likes: updatedLikes,
                }));
            }
        } catch (error) {
            console.error('Ошибка при добавлении лайка:', error);
        }
    };

    const onUnlike = async () => {
        try {
            const updatedLikes = await handleUnlike(post.id);
            if (updatedLikes) {
                setLocalPost(prevPost => ({
                    ...prevPost,
                    likes: updatedLikes,
                }));
            }
        } catch (error) {
            console.error('Ошибка при удалении лайка:', error);
        }
    };

    const toggleComments = () => {
        setIsCommentsOpen(!isCommentsOpen);
    };

    const handleLikeComment = async (commentId) => {
        try {
            const updatedComments = await handleLike(commentId);
            setLocalPost(prevPost => ({
                ...prevPost,
                comments: updatedComments,
            }));
        } catch (error) {
            console.error('Ошибка при добавлении лайка на комментарий:', error);
        }
    };

    const handleUnlikeComment = async (commentId) => {
        try {
            const updatedComments = await handleUnlike(commentId);
            setLocalPost(prevPost => ({
                ...prevPost,
                comments: updatedComments,
            }));
        } catch (error) {
            console.error('Ошибка при удалении лайка с комментария:', error);
        }
    };

    const toggleExpand = (commentId) => {
        setExpandedComments(prevExpanded => ({
            ...prevExpanded,
            [commentId]: !prevExpanded[commentId],
        }));
    };


    const renderComments = (comments, parentId = null, level = 0) => {
        return comments
            .filter(comment => comment.parent_id === parentId)
            .map(comment => {
                const hasReplies = comments.some(c => c.parent_id === comment.id);
                const parentComment = comments.find(c => c.id === comment.parent_id);

                return (
                    <motion.div
                        key={comment.id}
                        className={`space-y-4 ${level === 1 ? 'ml-8' : ''}`}
                        initial={{ opacity: 0, y: 10 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -10 }}
                        transition={{ duration: 0.3 }}
                    >
                        <div className="flex items-start space-x-4 bg-neutral-50 dark:bg-neutral-900 p-3 rounded-lg transition duration-300">
                            <img
                                src={comment.user.avatar ? `${process.env.REACT_APP_BASE_URL}/storage/${comment.user.avatar}` : defaultAvatar}
                                alt="Avatar"
                                className="w-8 h-8 rounded-full object-cover"
                            />
                            <div className="flex-1">
                                <div className="flex items-center justify-between">
                                    <p className="font-medium text-gray-900 dark:text-white">{comment.user.name}</p>
                                    <p className="text-xs text-gray-500 dark:text-gray-400">
                                        {formatRelative(new Date(comment.created_at), new Date(), { locale: ru })}
                                    </p>
                                </div>
                                {parentComment && (
                                    <p className="text-sm text-gray-500 dark:text-gray-400 mb-1">
                                        Ответ на комментарий <Link to={`/profile/${parentComment.user.id}`} className="text-violet-600">{parentComment.user.name}</Link>
                                    </p>
                                )}
                                <p className="mt-1 text-sm text-gray-800 dark:text-gray-200">{comment.content}</p>
                                <div className="flex items-center justify-between mt-2">
                                    <div className="flex items-center space-x-3">
                                        <button
                                            className="text-sm text-violet-600 dark:text-violet-400 font-bold"
                                            onClick={() => setReplyingTo(comment.id)}
                                        >
                                            Ответить
                                        </button>
                                        {hasReplies && (
                                            <button
                                                className="text-sm text-violet-600 dark:text-violet-400 font-bold"
                                                onClick={() => toggleExpand(comment.id)}
                                            >
                                                {expandedComments[comment.id] ? 'Скрыть ' : 'Ответы '}
                                                <i className={`fas ${expandedComments[comment.id] ? 'fa-chevron-up' : 'fa-chevron-down'}`}></i>
                                            </button>
                                        )}
                                    </div>
                                    <div className="ml-auto flex items-center space-x-1 align-middle">
                                        {comment.likes?.some(like => like.user_id === parseInt(localStorage.getItem('user_id'), 10)) ? (
                                            <button onClick={() => handleUnlikeComment(comment.id)} className="focus:outline-none">
                                                <i className="fas fa-heart text-red-500 text-[18px]"></i>
                                            </button>
                                        ) : (
                                            <button onClick={() => handleLikeComment(comment.id)} className="focus:outline-none">
                                                <i className="far fa-heart text-neutral-500 text-[18px]"></i>
                                            </button>
                                        )}
                                        <span className="text-base text-gray-600 dark:text-gray-300">{comment.likes?.length || 0}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {expandedComments[comment.id] && renderComments(comments, comment.id, level + 1)}
                    </motion.div>
                );
            });
    };
    const isMobile = width < 768;

    const modalVariants = {
        hidden: { opacity: 0 },
        visible: { opacity: 1, transition: { duration: 0.4, ease: 'easeInOut' } },
        exit: { opacity: 0, transition: { duration: 0.3, ease: 'easeInOut' } },
    };

    const commentsVariants = {
        hidden: { opacity: 0, y: '100%' },
        visible: { opacity: 1, y: 0, transition: { duration: 0.4, ease: 'easeInOut' } },
        exit: { opacity: 0, y: '100%', transition: { duration: 0.3, ease: 'easeInOut' } },
    };

    const buttonVariants = {
        hover: { scale: 1.1 },
        tap: { scale: 0.9 },
        exit: { scale: 1 },
    };

    const imageVariants = {
        enter: {
            opacity: 0,
            scale: 0.95, // Легкое уменьшение при входе
            transition: {
                duration: 0.3,
                ease: "easeInOut",
            },
        },
        center: {
            opacity: 1,
            scale: 1, // Нормальный размер
            transition: {
                duration: 0.3,
                ease: "easeInOut",
            },
        },
        exit: {
            opacity: 0,
            scale: 1.05, // Легкое увеличение при выходе
            transition: {
                duration: 0.3,
                ease: "easeInOut",
            },
        },
    };

    const handlers = useSwipeable({
        onSwipedLeft: () => nextImage(),
        onSwipedRight: () => prevImage(),
        preventDefaultTouchmoveEvent: true,
        trackMouse: true,
    });


    const verticalHandlers = useSwipeable({
        onSwiping: (e) => {
            // Обрабатываем только свайпы вниз с преобладанием вертикального движения
            if (e.dir === 'Down' && Math.abs(e.deltaY) > Math.abs(e.deltaX)) {
                controls.set({ y: e.deltaY }); // Обновляем позицию в реальном времени
            }
        },
        onSwiped: async (e) => {
            // Проверяем достаточно ли сильно свайпнули вниз
            if (e.dir === 'Down' && e.deltaY > 100) {
                // Анимация закрытия
                await controls.start({
                    y: "100vh",
                    transition: { type: 'spring', stiffness: 300, damping: 30 }
                });
                onClose();
            } else {
                // Возвращаем на место с пружинной анимацией
                await controls.start({
                    y: 0,
                    transition: { type: 'spring', stiffness: 400, damping: 30 }
                });
            }
        },
        trackTouch: true,
        delta: 10,
        preventScrollOnSwipe: true,
    });
    {/* Добавим useEffect для инициализации анимации */}
    {useEffect(() => {
        controls.start({
            opacity: 1,
            y: 0
        })
    }, [controls])}

    if (isMobile) {
        return (
            <AnimatePresence>
                <motion.div
                    className="fixed inset-0 z-50 bg-black bg-opacity-80 flex flex-col justify-between"
                    initial={{opacity: 0, y: 50}}
                    animate={{
                        opacity: 1,
                        y: 0,
                        transition: {type: 'spring', stiffness: 300, damping: 30}
                    }}
                    exit={{
                        opacity: 0,
                        y: 100,
                        transition: {duration: 0.3}
                    }}
                    {...verticalHandlers}
                    style={{y: 0}}
                >

                    <motion.button onClick={onClose} className="absolute top-4 right-4 text-white text-2xl z-50"
                                   whileHover="hover" whileTap="tap" variants={buttonVariants}>
                        <i className="fa-solid fa-xmark"></i>
                    </motion.button>

                    <div className="relative w-full flex-grow flex justify-center items-center" {...handlers}>
                        <motion.img
                            src={`${process.env.REACT_APP_BASE_URL}/storage/${images[selectedIndex].image_path}`}
                            alt="Full Size"
                            className="max-h-[75vh] w-auto object-contain"
                            variants={imageVariants}
                            initial="enter"
                            animate="center"
                            exit="exit"
                            custom={1} // Направление для анимации (можно использовать -1 для предыдущего изображения)
                            key={selectedIndex}
                        />
                    </div>

                    <div className="flex justify-center space-x-2 mb-4">
                        {images.map((_, index) => (
                            <button
                                key={index}
                                className={`h-2 transition-all duration-300 ease-in-out rounded-full ${selectedIndex === index ? 'bg-violet-600 w-3 h-2' : 'bg-gray-400 w-2 h-1'}`}
                                onClick={() => setSelectedIndex(index)} // Переключение на изображение по индикатору
                            />
                        ))}
                    </div>


                    <div className="bg-black bg-opacity-80 p-4 flex justify-between items-center text-white">
                        <div className="flex items-center">
                            <img
                                src={localPost.user.avatar ? `${process.env.REACT_APP_BASE_URL}/storage/${localPost.user.avatar}` : defaultAvatar}
                                alt="Profile"
                                className="w-8 h-8 rounded-full mr-2"
                            />
                            <div>
                                <p className="text-sm font-semibold">{localPost.user.name}</p>
                                <p className="text-xs text-gray-300">{format(new Date(localPost.created_at), 'dd MMMM, yyyy', {locale: ru})}</p>
                            </div>
                        </div>
                        <div className="flex space-x-4">
                            <motion.button
                                onClick={localPost.likes.some(like => like.user_id === parseInt(localStorage.getItem('user_id'), 10)) ? onUnlike : onLike}
                                variants={buttonVariants} whileHover="hover" whileTap="tap">
                                <i className={`fa-heart ${localPost.likes.some(like => like.user_id === parseInt(localStorage.getItem('user_id'), 10)) ? 'fas text-red-500' : 'far text-white'} text-2xl`}></i>
                            </motion.button>
                            <motion.button onClick={toggleComments} variants={buttonVariants} whileHover="hover"
                                           whileTap="tap">
                                <i className="fa-solid fa-comment text-2xl"></i>
                            </motion.button>
                            <motion.button onClick={onShare} variants={buttonVariants} whileHover="hover"
                                           whileTap="tap">
                                <i className="fa-solid fa-share text-2xl"></i>
                            </motion.button>
                        </div>
                    </div>

                    <AnimatePresence>
                        {isCommentsOpen && (
                            <>
                                <motion.div
                                    className="fixed inset-0 bg-black bg-opacity-50 z-40"
                                    initial={{opacity: 0}}
                                    animate={{opacity: 1}}
                                    exit={{opacity: 0}}
                                    transition={{duration: 0.3}}
                                />

                                <motion.div
                                    className="absolute bottom-0 left-0 right-0 h-2/3 bg-white p-4 rounded-t-lg shadow-lg dark:bg-neutral-800 z-50"
                                    initial="hidden"
                                    animate="visible"
                                    exit="exit"
                                    variants={commentsVariants}
                                >
                                    <div className="flex justify-between items-center mb-4">
                                        <h2 className="text-xl font-semibold">Комментарии</h2>
                                        <button onClick={toggleComments} className="dark:text-neutral-200">
                                            <i className="fa-solid fa-times text-xl"></i>
                                        </button>
                                    </div>
                                    <div className="flex flex-col h-[calc(100%-60px)] overflow-y-auto">
                                        <div className="flex flex-col space-y-3">
                                            {post.comments.length > 0 ? renderComments(post.comments) :
                                                <p className="text-gray-500">Комментариев пока нет</p>}
                                        </div>
                                    </div>

                                    <div
                                        className="flex fixed bottom-0 left-0 right-0 p-2 bg-white dark:bg-neutral-700 border-t border-gray-300 dark:border-neutral-600">
                                        <input
                                            type="text"
                                            value={newComment}
                                            onChange={onCommentChange}
                                            className="flex-grow text-sm p-2 rounded-full focus:outline-none dark:bg-neutral-800 border border-gray-300 dark:border-none"
                                            placeholder="Напишите комментарий..."
                                        />
                                        <button
                                            onClick={onSubmitComment}
                                            className="bg-violet-500 text-white px-3 py-2 rounded-full ml-2 hover:bg-violet-600 transition duration-200"
                                        >
                                            <i className="fa-solid fa-paper-plane"></i>
                                        </button>
                                    </div>
                                </motion.div>
                            </>
                        )}

                    </AnimatePresence>
                </motion.div>
            </AnimatePresence>
        );
    }

    return (
        <AnimatePresence>
            <motion.div
                className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-90 w-full"
                initial="hidden"
                animate="visible"
                exit="exit"
                variants={modalVariants}
                {...verticalHandlers}
            >
                <div className="absolute top-4 left-4 flex space-x-2 bg-black bg-opacity-50 p-2 rounded-xl z-50">
                    <motion.button
                        onClick={handleZoomIn}
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.9 }}
                        className="p-2 text-white hover:text-violet-400 transition-colors"
                    >
                        <MagnifyingGlassPlusIcon className="w-6 h-6" />
                    </motion.button>

                    <motion.button
                        onClick={handleZoomOut}
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.9 }}
                        className="p-2 text-white hover:text-violet-400 transition-colors"
                    >
                        <MagnifyingGlassMinusIcon className="w-6 h-6" />
                    </motion.button>

                    <motion.button
                        onClick={handleRotate}
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.9 }}
                        className="p-2 text-white hover:text-violet-400 transition-colors"
                    >
                        <RotateCw className="w-6 h-6" />
                    </motion.button>

                    <motion.button
                        onClick={handleDownload}
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.9 }}
                        className="p-2 text-white hover:text-violet-400 transition-colors"
                    >
                        <DownloadIcon className="w-6 h-6" />
                    </motion.button>
                </div>
                <div className="relative w-full">
                    <button
                        onClick={prevImage}
                        className="absolute left-0 top-1/2 transform -translate-y-1/2 text-white text-2xl p-2"
                    >
                        <i className="fa-solid fa-chevron-left"></i>
                    </button>
                    <div className="relative max-w-3xl mx-auto" ref={containerRef}>
                        <motion.div
                            className="image-container"
                            style={{
                                scale: zoomLevel,
                                rotate: rotateDeg,
                                x: position.x,
                                y: position.y,
                                transition: 'transform 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
                            }}
                            {...handlers}
                        >
                            <motion.img
                                ref={imageRef}
                                src={`${process.env.REACT_APP_BASE_URL}/storage/${images[selectedIndex].image_path}`}
                                alt="Full Size"
                                className="max-h-[95vh] w-auto object-contain rounded-lg mx-auto dark:bg-neutral-800"
                                initial="enter"
                                animate="center"
                                exit="exit"
                                key={selectedIndex}
                                dragConstraints={getDragConstraints()}
                                dragElastic={0.5}
                                onDrag={handlePan}
                                drag={zoomLevel > 1}
                                onWheel={handleWheel}
                                onDoubleClick={(e) => {
                                    const rect = e.currentTarget.getBoundingClientRect();
                                    handleZoom('in', rect.left + rect.width / 2, rect.top + rect.height / 2);
                                }}
                            />
                        </motion.div>
                    </div>
                    <button
                        onClick={nextImage}
                        className="absolute right-0 top-1/2 transform -translate-y-1/2 text-white text-2xl p-2"
                    >
                        <i className="fa-solid fa-chevron-right"></i>
                    </button>
                </div>
                <div
                    className="absolute bottom-4 left-12 transform -translate-x-1/2 bg-neutral-500 bg-opacity-70 px-2 rounded-full flex justify-between items-center text-white">
                    <p className="text-sm">
                        {selectedIndex + 1} / {images.length}
                    </p>
                    <p className="text-sm">{images[selectedIndex].name}</p>
                </div>

                <aside
                    className="float-left w-1/3 h-screen bg-white shadow-lg z-50 p-4 rounded-l-xl dark:bg-neutral-900 relative">
                    <button
                        onClick={onClose}
                        className="absolute top-4 right-4 text-neutral-800 dark:text-white text-2xl hover:text-violet-600 dark:hover:text-violet-500 transition duration-300"
                    >
                        <i className="fa-solid fa-xmark"></i>
                    </button>
                    <div className="flex items-center mb-4">
                        <img
                            src={
                                localPost.user.avatar
                                    ? `${process.env.REACT_APP_BASE_URL}/storage/${localPost.user.avatar}`
                                    : defaultAvatar
                            }
                            alt="Profile"
                            className="w-14 h-14 rounded-full border-2 border-violet-500"
                        />
                        <div className="flex flex-col ml-3">
                            <p className="text-xl font-semibold dark:text-white">{localPost.user.name}</p>
                            <p className="text-sm text-gray-600 dark:text-neutral-300">
                                {format(new Date(localPost.created_at), 'dd MMMM, yyyy', { locale: ru })}
                            </p>
                        </div>
                    </div>
                    <div className="mt-2 mb-4 dark:border-neutral-600">
                        <p className="text-gray-800 dark:text-white">{localPost.content}</p>
                    </div>

                    <div className="flex flex-col h-[calc(100vh-240px)] overflow-y-auto">
                        <h2 className="text-lg font-semibold dark:text-white mb-2">Комментарии</h2>
                        <div className="flex flex-col space-y-3">
                            {post.comments.length > 0 ? renderComments(post.comments) : (
                                <p className="text-gray-500">Комментариев пока нет</p>
                            )}
                        </div>
                    </div>

                    <div className="absolute bottom-0 left-0 right-0 w-full bg-white p-4 rounded-b-xl shadow-lg z-50 dark:bg-neutral-800 flex items-center">
                        <input
                            type="text"
                            value={newComment}
                            onChange={onCommentChange}
                            className="flex-grow border border-gray-300 p-2 rounded-full focus:outline-none focus:border-violet-500 dark:bg-neutral-700 dark:text-white dark:border-neutral-600"
                            placeholder="Комментарий..."
                        />
                        <button
                            onClick={onSubmitComment}
                            className="bg-violet-500 text-white px-2.5 py-1.5 w-10 h-10 rounded-full ml-2 hover:bg-violet-600 transition duration-200"
                        >
                            <i className="fa-solid fa-paper-plane"></i>
                        </button>
                    </div>
                </aside>
            </motion.div>
        </AnimatePresence>
    );
}

export default Modal;