import React from 'react';

const NewsCardSkeleton = () => {
    return (
        <div className="bg-white dark:bg-neutral-800 rounded-xl shadow-md overflow-hidden">
            <div className="bg-gray-300 dark:bg-neutral-700 h-48 animate-pulse" />

            <div className="p-5 space-y-4">
                <div className="flex gap-3">
                    <div className="bg-gray-200 dark:bg-neutral-700 h-4 w-24 rounded-full animate-pulse" />
                    <div className="bg-gray-200 dark:bg-neutral-700 h-4 w-20 rounded-full animate-pulse" />
                </div>

                <div className="bg-gray-200 dark:bg-neutral-700 h-6 w-3/4 rounded-lg animate-pulse" />

                <div className="space-y-2">
                    <div className="bg-gray-200 dark:bg-neutral-700 h-4 rounded-lg animate-pulse" />
                    <div className="bg-gray-200 dark:bg-neutral-700 h-4 w-5/6 rounded-lg animate-pulse" />
                    <div className="bg-gray-200 dark:bg-neutral-700 h-4 w-2/3 rounded-lg animate-pulse" />
                </div>

                <div className="bg-gray-200 dark:bg-neutral-700 h-5 w-32 rounded-lg animate-pulse" />
            </div>
        </div>
    );
};

export default NewsCardSkeleton;