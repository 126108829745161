import React, { useEffect, useState } from 'react';
import { Circle, Clock } from 'lucide-react';
import { useOnlineStatus } from '../context/OnlineStatusContext';

const OnlineStatusIndicator = ({ userId, showText = true, size = 'md', className = '' }) => {
    const { getUserStatus, formatLastActivity } = useOnlineStatus();
    const [status, setStatus] = useState({ is_online: false, last_activity: null });

    // Get status and set up a refresh interval
    useEffect(() => {
        // Initial status check
        const userStatus = getUserStatus(userId);
        setStatus(userStatus);

        // Update status every 30 seconds
        const interval = setInterval(() => {
            const freshStatus = getUserStatus(userId);
            // Обновляем только если статус изменился
            if (freshStatus.is_online !== status.is_online ||
                freshStatus.last_activity !== status.last_activity) {
                setStatus(freshStatus);
            }
        }, 5000);

        return () => clearInterval(interval);
    }, [userId, getUserStatus, status]);

    // Определяем размеры на основе пропса size
    const sizeClasses = {
        sm: 'w-2 h-2',
        md: 'w-3 h-3',
        lg: 'w-4 h-4'
    };

    const textSizeClasses = {
        sm: 'text-xs',
        md: 'text-sm',
        lg: 'text-base'
    };

    const dotSize = sizeClasses[size] || sizeClasses.md;
    const textSize = textSizeClasses[size] || textSizeClasses.md;

    // Логирование только в режиме разработки
    // if (process.env.NODE_ENV === 'development') {
    //     console.log(`Rendering status for user ${userId}:`, status);
    // }

    return (
        <div className={`flex items-center space-x-2 ${className}`}>
            {status && status.is_online === true ? (
                <>
                    <Circle className={`${dotSize} text-green-500`} fill="currentColor" />
                    {showText && <span className={`${textSize} text-green-600 dark:text-green-400`}>Онлайн</span>}
                </>
            ) : (
                <>
                    <Clock className={`${dotSize} text-gray-400`} />
                    {showText && (
                        <span className={`${textSize} text-gray-500 dark:text-gray-400`}>
                            {status && status.last_activity ? formatLastActivity(status.last_activity) : 'Оффлайн'}
                        </span>
                    )}
                </>
            )}
        </div>
    );
};

export default OnlineStatusIndicator;
