import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import { throttle } from 'lodash';
import Post from "./Post";
import { ClipLoader } from "react-spinners";
import { motion, AnimatePresence } from 'framer-motion';
import BackLink from "./BackLink";
import { FiArrowUp, FiGrid, FiList, FiFilter } from 'react-icons/fi';
import useWindowSize from "../hooks/useWindowSize";
import BottomNav from "./BottomNav";
import Aside from "./Aside";

function PostList2({
                       externalPosts = [],
                       setExternalPosts,
                       userId,
                       userPreferences = {},
                       groupId = null
                   }) {
    const [internalPosts, setInternalPosts] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const [error, setError] = useState(null);
    const [viewMode, setViewMode] = useState('list');
    const [sortBy, setSortBy] = useState('newest');
    const [showScrollTop, setShowScrollTop] = useState(false);
    const [isInitialLoading, setIsInitialLoading] = useState(true);

    const isControlled = setExternalPosts !== undefined;
    const posts = isControlled ? externalPosts : internalPosts;
    const setPosts = isControlled ? setExternalPosts : setInternalPosts;

    const { width} = useWindowSize();

    const fetchPosts = useCallback(async (page = 1, reset = false) => {
        setIsLoading(true);
        setError(null);
        try {
            const params = {
                page,
                per_page: viewMode === 'grid' ? 9 : 10,
                userId,
                groupId,
                sort: sortBy
            };

            const response = await axios.get(`${process.env.REACT_APP_API_URL}/posts`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
                params
            });

            const { data, meta } = response.data;

            const processedPosts = data.map(post => ({
                ...post,
                postAgeInHours: (Date.now() - new Date(post.created_at).getTime()) / 1000 / 3600
            }));

            if (reset) {
                setPosts(processedPosts);
            } else {
                setPosts(prev => [...prev, ...processedPosts]);
            }

            setTotalPages(meta.last_page);
            setHasMore(meta.current_page < meta.last_page);
            if (isInitialLoading) setIsInitialLoading(false);

        } catch (error) {
            console.error('Ошибка при получении постов:', error);
            setError('Не удалось загрузить посты');
        } finally {
            setIsLoading(false);
        }
    }, [userId, groupId, setPosts, viewMode, sortBy, isInitialLoading]);

    useEffect(() => {
        const handleScroll = throttle(() => {
            if (window.innerHeight + document.documentElement.scrollTop >=
                document.documentElement.offsetHeight - 100 &&
                !isLoading &&
                hasMore) {
                setCurrentPage(prev => prev + 1);
            }
        }, 500);

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [isLoading, hasMore]);

    useEffect(() => {
        if (currentPage > 1) {
            fetchPosts(currentPage);
        }
    }, [currentPage, fetchPosts]);

    useEffect(() => {
        setCurrentPage(1);
        fetchPosts(1, true);
    }, [userId, groupId, fetchPosts]);

    useEffect(() => {
        const checkScroll = () => {
            if (!showScrollTop && window.pageYOffset > 400) {
                setShowScrollTop(true);
            } else if (showScrollTop && window.pageYOffset <= 400) {
                setShowScrollTop(false);
            }
        };
        window.addEventListener('scroll', throttle(checkScroll, 200));
        return () => window.removeEventListener('scroll', checkScroll);
    }, [showScrollTop]);

    const handleSortChange = (e) => {
        setSortBy(e.target.value);
        setCurrentPage(1);
        fetchPosts(1, true);
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    if (isInitialLoading) {
        return (
            <div className="flex items-center justify-center min-h-screen">
                <div className="text-center">
                    <ClipLoader color="#6B46C1" size={75} />
                    <p className="mt-4 text-gray-600 dark:text-gray-300 animate-pulse">
                        Загружаем интересные посты...
                    </p>
                </div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="container mx-auto rounded-lg min-h-screen dark:bg-neutral-900 p-4">
                <p className="text-center text-red-500 dark:text-red-400">{error}</p>
            </div>
        );
    }

    if (posts.length === 0 && !isLoading) {
        return (
            <div className="min-h-screen bg-gradient-to-br from-purple-50 to-blue-50 dark:from-neutral-900 dark:to-neutral-800">
                <div className="container mx-auto px-4 py-8 max-w-7xl">
                    <BackLink />
                    <div className="flex flex-col items-center justify-center min-h-[60vh]">
                        <div className="text-center">
                            <div className="mb-6 text-9xl text-purple-500 dark:text-purple-400">
                                🌀
                            </div>
                            <h3 className="text-2xl font-bold text-gray-800 dark:text-white mb-2">
                                Здесь пока пусто
                            </h3>
                            <p className="text-gray-600 dark:text-gray-300">
                                Будьте первым, кто поделится чем-то интересным!
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="container mx-auto flex flex-col lg:flex-row gap-2 p-2 md:p-2 pt-28">
            {width >= 1024 ? <Aside className="w-1/4"/> : <BottomNav/>}
            <div className="container mx-auto p-4 pt-16">

                {/* Header Section */}
                <BackLink/>
                <div className="flex flex-col md:flex-row justify-between items-start md:items-center mb-8">
                    <motion.h2
                        initial={{opacity: 0, y: 10}}
                        animate={{opacity: 1, y: 0}}
                        className="text-3xl font-extrabold dark:text-white mb-4 md:mb-0"
                    >
                        Лента публикаций
                    </motion.h2>

                    <div className="flex items-center gap-4">
                        <div className="relative group">
                            <FiFilter className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400"/>
                            <select
                                value={sortBy}
                                onChange={handleSortChange}
                                className="pl-10 pr-4 py-2 rounded-lg bg-white dark:bg-neutral-800 border border-gray-200 dark:border-neutral-700 text-gray-700 dark:text-gray-200 focus:outline-none focus:ring-2 focus:ring-purple-500"
                            >
                                <option value="newest">Сначала новые</option>
                                <option value="popular">Популярные</option>
                                <option value="commented">С комментариями</option>
                            </select>
                        </div>


                        <div className="flex gap-2 bg-white dark:bg-neutral-800 p-1 rounded-lg">
                            <button
                                onClick={() => setViewMode('grid')}
                                className={`p-2 rounded-md ${viewMode === 'grid' ? 'bg-purple-100 dark:bg-neutral-700' : ''}`}
                            >
                                <FiGrid className="text-xl text-gray-600 dark:text-gray-300"/>
                            </button>
                            <button
                                onClick={() => setViewMode('list')}
                                className={`p-2 rounded-md ${viewMode === 'list' ? 'bg-purple-100 dark:bg-neutral-700' : ''}`}
                            >
                                <FiList className="text-xl text-gray-600 dark:text-gray-300"/>
                            </button>
                        </div>
                    </div>
                </div>

                {/* Posts Grid */}
                <div
                    className={`grid gap-6 max-w-7xl mx-auto ${viewMode === 'grid' ? 'grid-cols-1 md:grid-cols-2 lg:grid-cols-3' : 'grid-cols-1'}`}>
                    <AnimatePresence>
                        {posts.map((post, index) => (
                            <motion.div
                                key={post.id}
                                initial={{opacity: 0, scale: 0.95}}
                                animate={{opacity: 1, scale: 1}}
                                exit={{opacity: 0, scale: 0.95}}
                                transition={{duration: 0.3}}
                                className={`${viewMode === 'grid' ? 'h-full' : ''}`}
                            >
                                <Post
                                    post={post}
                                    viewMode={viewMode}
                                    className="hover:shadow-lg transition-shadow duration-300"
                                />
                            </motion.div>
                        ))}
                    </AnimatePresence>
                </div>

                {/* Loading and End Indicators */}
                {isLoading && (
                    <div className="flex justify-center p-8">
                        <ClipLoader color="#6B46C1" size={40}/>
                    </div>
                )}

                {!hasMore && !isLoading && (
                    <div className="text-center py-8">
                        <p className="text-gray-500 dark:text-gray-400 inline-flex items-center gap-2">
                            <span className="bg-gray-200 dark:bg-neutral-700 h-px flex-1"></span>
                            <span className="px-4">Вы достигли конца ленты</span>
                            <span className="bg-gray-200 dark:bg-neutral-700 h-px flex-1"></span>
                        </p>
                    </div>
                )}
            </div>

            {/* Scroll to Top Button */}
            <motion.button
                onClick={scrollToTop}
                initial={{opacity: 0, y: 20}}
                animate={showScrollTop ? {opacity: 1, y: 0} : {opacity: 0, y: 20}}
                className="fixed bottom-28 right-4 z-50 p-4 bg-white dark:bg-neutral-800 rounded-full shadow-lg hover:shadow-xl transition-shadow duration-300 border border-gray-200 dark:border-neutral-700"
            >
                <FiArrowUp className="text-xl text-gray-600 dark:text-gray-300"/>
            </motion.button>
        </div>
    );
}

export default PostList2;