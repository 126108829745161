import React, { useState, useEffect } from "react";
import axios from "axios";
import AdminLayout from "./AdminLayout";
import { motion, AnimatePresence } from "framer-motion";
import toast from "react-hot-toast";

const AdminMusic = () => {
    const [tracks, setTracks] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedTrack, setSelectedTrack] = useState(null);
    const token = localStorage.getItem("token");

    useEffect(() => {
        fetchTracks();
    }, []);

    const fetchTracks = () => {
        axios.get(`${process.env.REACT_APP_API_URL}/admin/tracks`, {
            headers: { Authorization: `Bearer ${token}` }
        })
            .then(response => {
                setTracks(response.data);
                setLoading(false);
            })
            .catch(error => {
                console.error("Error fetching tracks:", error);
                setLoading(false);
            });
    };

    const handleDelete = (trackId) => {
        axios.delete(`${process.env.REACT_APP_API_URL}/admin/tracks/${trackId}`, {
            headers: { Authorization: `Bearer ${token}` }
        })
            .then(() => {
                fetchTracks();
                setShowDeleteModal(false);
                toast.success('Трек успешно удален!')
            })
            .catch(console.error, toast.error('Произошла ошибка при удалении трека!'));
    };

    const modalVariants = {
        hidden: {
            opacity: 0,
            scale: 0.8,
            transition: { duration: 0.2 }
        },
        visible: {
            opacity: 1,
            scale: 1,
            transition: {
                type: "spring",
                stiffness: 300,
                damping: 20
            }
        }
    };

    const backdropVariants = {
        hidden: { opacity: 0 },
        visible: { opacity: 1 }
    };

    if (loading) {
        return (
            <div className="flex items-center justify-center h-screen bg-neutral-100 dark:bg-neutral-900">
                <div className="text-purple-500 dark:text-purple-400 text-xl font-semibold">
                    <i className="fas fa-spinner fa-spin mr-2" />
                    Загрузка данных...
                </div>
            </div>
        );
    }

    return (
        <AdminLayout>
            <AnimatePresence>
                {showDeleteModal && (
                    <motion.div
                        className="fixed inset-0 bg-black bg-opacity-50 dark:bg-opacity-80 flex items-center justify-center z-50 backdrop-blur-sm"
                        variants={backdropVariants}
                        initial="hidden"
                        animate="visible"
                        exit="hidden"
                    >
                        <motion.div
                            className="bg-white dark:bg-neutral-800 p-6 rounded-xl w-full max-w-md shadow-2xl"
                            variants={modalVariants}
                        >
                            <div className="flex items-center mb-4">
                                <motion.div
                                    animate={{ rotate: [0, 10, -10, 0] }}
                                    transition={{ repeat: Infinity, duration: 1.5 }}
                                >
                                    <i className="fas fa-exclamation-triangle text-red-500 text-xl mr-3" />
                                </motion.div>
                                <h3 className="text-xl font-bold text-neutral-800 dark:text-neutral-200">
                                    Подтверждение удаления
                                </h3>
                            </div>

                            <motion.p
                                className="text-neutral-600 dark:text-neutral-400 mb-6"
                                initial={{ y: 10, opacity: 0 }}
                                animate={{ y: 0, opacity: 1 }}
                            >
                                Вы уверены, что хотите удалить трек
                                <span className="font-semibold ml-1 text-purple-600 dark:text-purple-400">
                                    {selectedTrack?.title}
                                </span>?
                            </motion.p>

                            <div className="flex justify-end gap-3">
                                <motion.button
                                    whileHover={{ scale: 1.05 }}
                                    whileTap={{ scale: 0.95 }}
                                    onClick={() => setShowDeleteModal(false)}
                                    className="px-5 py-2.5 bg-neutral-100 dark:bg-neutral-700 text-neutral-800 dark:text-neutral-200 rounded-lg hover:bg-neutral-200 dark:hover:bg-neutral-600 transition-colors"
                                >
                                    Отмена
                                </motion.button>
                                <motion.button
                                    whileHover={{ scale: 1.05 }}
                                    whileTap={{ scale: 0.95 }}
                                    onClick={() => handleDelete(selectedTrack?.id)}
                                    className="px-5 py-2.5 bg-red-500 text-white rounded-lg hover:bg-red-600 flex items-center gap-2 transition-colors"
                                >
                                    <i className="fas fa-trash h-5 w-5" />
                                    Удалить
                                </motion.button>
                            </div>
                        </motion.div>
                    </motion.div>
                )}
            </AnimatePresence>
            <motion.div
                initial={{opacity: 0, y: 20}}
                animate={{opacity: 1, y: 0}}
                transition={{duration: 0.3}}
            >
                <div className="flex items-center justify-between mb-8">
                    <div className="flex items-center">
                        <i className="fas fa-music text-purple-600 dark:text-purple-400 text-2xl mr-3"/>
                        <h2 className="text-3xl font-bold text-neutral-800 dark:text-neutral-200">Управление
                            музыкой</h2>
                    </div>
                    <button
                        className="bg-purple-500 hover:bg-purple-600 text-white px-4 py-2 rounded-lg flex items-center"
                        onClick={() => fetchTracks()}
                    >
                        <i className="fas fa-sync-alt mr-2"/>
                        Обновить
                    </button>
                </div>

                <div className="overflow-x-auto rounded-xl border dark:border-neutral-700 shadow-sm">
                    <table className="w-full">
                        <thead className="bg-neutral-50 dark:bg-neutral-700">
                        <tr>
                            {["Название", "Исполнитель", "Загружен", "Действия"].map((header) => (
                                <th
                                    key={header}
                                    className="px-4 py-3 text-left text-sm font-semibold text-neutral-700 dark:text-neutral-300 uppercase tracking-wider"
                                >
                                    {header}
                                </th>
                            ))}
                        </tr>
                        </thead>
                        <tbody className="divide-y divide-neutral-200 dark:divide-neutral-700">
                        {tracks.map((track, index) => (
                            <motion.tr
                                key={track.id}
                                initial={{ opacity: 0, y: 10 }}
                                animate={{ opacity: 1, y: 0 }}
                                exit={{ opacity: 0, y: -10 }}
                                transition={{ duration: 0.3 }}
                                className="hover:bg-neutral-50 dark:hover:bg-neutral-700/30 transition-colors"
                            >
                                <td className="px-4 py-3 text-neutral-800 dark:text-neutral-200 font-medium">
                                    {track.title}
                                </td>
                                <td className="px-4 py-3 text-neutral-700 dark:text-neutral-300">
                                    {track.artist.name}
                                </td>
                                <td className="px-4 py-3 text-neutral-700 dark:text-neutral-300">
                                    {new Date(track.created_at).toLocaleDateString()}
                                </td>
                                <td className="px-4 py-3">
                                    <motion.button
                                        whileHover={{scale: 1.05}}
                                        whileTap={{scale: 0.95}}
                                        onClick={() => {
                                            setSelectedTrack(track);
                                            setShowDeleteModal(true);
                                        }}
                                        className="flex items-center gap-2 px-4 py-2 bg-red-500/10 text-red-600 dark:text-red-400 rounded-lg hover:bg-red-500/20 transition-colors"
                                    >
                                        <i className="fas fa-trash-can h-5 w-5"/>
                                        <span>Удалить</span>
                                    </motion.button>
                                </td>
                            </motion.tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            </motion.div>
        </AdminLayout>
    );
};

export default AdminMusic;