import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import NewsCard from './NewsCard';
import NewsCardSkeleton from "./NewsCardSkeleton";
import { ChevronRight, RefreshCw, AlertCircle, Newspaper } from "lucide-react";
import { motion, AnimatePresence } from 'framer-motion';

const LatestNews = () => {
    const [latestNews, setLatestNews] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(false);
    const navigate = useNavigate();

    const fetchLatestNews = () => {
        setIsLoading(true);
        setError(false);
        axios.get(`${process.env.REACT_APP_API_URL}/news/latest`)
            .then(res => {
                // Добавляем проверку наличия данных
                if (res.data && Object.keys(res.data).length > 0) {
                    setLatestNews(res.data);
                    setError(false);
                } else {
                    setLatestNews(null);
                    setError(false);
                }
            })
            .catch((err) => {
                console.error('Ошибка загрузки:', err);
                setLatestNews(null);
                setError(true);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    useEffect(() => {
        fetchLatestNews();
    }, []);

    const containerVariants = {
        hidden: { opacity: 0 },
        visible: { opacity: 1 },
        exit: { opacity: 0 }
    };

    return (
        <section className="relative">
            <AnimatePresence mode='wait'>
                {isLoading ? (
                    <motion.div
                        key="skeleton"
                        variants={containerVariants}
                        initial="hidden"
                        animate="visible"
                        exit="exit"
                    >
                        <NewsCardSkeleton />
                    </motion.div>
                ) : latestNews ? (
                    <motion.div
                        key="content"
                        variants={containerVariants}
                        initial="hidden"
                        animate="visible"
                        exit="exit"
                        className="group relative cursor-pointer"
                        onClick={() => navigate(`/news/${latestNews.id}`)}
                    >
                        <NewsCard
                            news={latestNews}
                            isFeatured
                            parent={'RightSidebar'}
                        />
                    </motion.div>
                ) : (
                    <motion.div
                        key="empty"
                        variants={containerVariants}
                        initial="hidden"
                        animate="visible"
                        exit="exit"
                        className="text-center py-12 bg-gray-50/50 dark:bg-neutral-900/30 rounded-3xl border-2
                              border-dashed border-gray-200 dark:border-neutral-700"
                    >
                        {error ? (
                            <>
                                <AlertCircle className="mx-auto h-12 w-12 text-red-500 dark:text-red-400 mb-4" />
                                <p className="text-gray-600 dark:text-neutral-300 text-lg font-medium mb-4">
                                    Ошибка загрузки новостей
                                </p>
                                <motion.button
                                    whileHover={{ scale: 1.05 }}
                                    whileTap={{ scale: 0.95 }}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        fetchLatestNews();
                                    }}
                                    className="inline-flex items-center gap-2 px-4 py-2 bg-gray-100 dark:bg-neutral-800
                                     rounded-lg hover:bg-gray-200 dark:hover:bg-neutral-700 transition-colors"
                                >
                                    <RefreshCw className="w-4 h-4" />
                                    Попробовать снова
                                </motion.button>
                            </>
                        ) : (
                            <>
                                <Newspaper className="mx-auto h-12 w-12 text-gray-400 dark:text-neutral-600 mb-4" />
                                <p className="text-gray-600 dark:text-neutral-300 text-lg font-medium">
                                    Актуальных новостей пока нет
                                </p>
                            </>
                        )}
                    </motion.div>
                )}
            </AnimatePresence>

            <div className="mt-8 flex justify-center">
                <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    onClick={() => navigate('/news')}
                    className="group relative inline-flex items-center gap-2 px-5 py-2.5
               text-gray-600 dark:text-gray-300 hover:text-gray-900
               dark:hover:text-gray-100 transition-colors duration-300"
                >
                    <span className="relative after:absolute after:bottom-0 after:left-0 after:w-full
                    after:h-[1px] after:bg-gray-300 dark:after:bg-gray-600
                    after:origin-right after:scale-x-0 after:transition-transform
                    group-hover:after:origin-left group-hover:after:scale-x-100">
                        Все новости
                    </span>
                    <ChevronRight className="w-5 h-5 transition-transform duration-300 group-hover:translate-x-1" />
                    <div className="absolute inset-0 -z-10 rounded-lg bg-gray-100/30
                   dark:bg-white/10 opacity-0 group-hover:opacity-100
                   transition-opacity duration-300" />
                </motion.button>
            </div>
        </section>
    );
};

export default LatestNews;