import React, { useState, useEffect } from 'react';
import axios from 'axios';
import toast from 'react-hot-toast';
import { motion, AnimatePresence } from 'framer-motion';
import { X, Search, Send, Link as LinkIcon, Copy } from 'lucide-react';
import OnlineStatusIndicator from "./OnlineStatusIndicator";

const modalVariants = {
    hidden: { opacity: 0, scale: 0.96, y: 20 },
    visible: {
        opacity: 1,
        scale: 1,
        y: 0,
        transition: {
            type: 'spring',
            stiffness: 300,
            damping: 25,
            when: "beforeChildren"
        }
    },
    exit: {
        opacity: 0,
        scale: 0.98,
        y: -10,
        transition: { duration: 0.2 }
    }
};

const listVariants = {
    visible: {
        transition: {
            staggerChildren: 0.07,
            delayChildren: 0.2
        }
    }
};

const itemVariants = {
    hidden: { opacity: 0, y: 10 },
    visible: {
        opacity: 1,
        y: 0,
        transition: {
            type: 'spring',
            stiffness: 150
        }
    }
};

const SkeletonItem = ({ index }) => (
    <motion.div
        variants={itemVariants}
        custom={index}
        className="flex items-center p-4 rounded-xl bg-neutral-100/50 dark:bg-neutral-800/50"
    >
        <div className="w-12 h-12 rounded-xl bg-neutral-200 dark:bg-neutral-700 animate-pulse" />
        <div className="ml-4 flex-1">
            <div className="h-4 w-3/4 mb-2 bg-neutral-200 dark:bg-neutral-700 rounded animate-pulse" />
            <div className="h-3 w-1/2 bg-neutral-200 dark:bg-neutral-700 rounded animate-pulse" />
        </div>
    </motion.div>
);

function SharePostModal({ post, onClose, onSend }) {
    const [chats, setChats] = useState([]);
    const [filteredChats, setFilteredChats] = useState([]);
    const [selectedChatId, setSelectedChatId] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(true);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchChats = async () => {
            const token = localStorage.getItem('token');
            setLoading(true);
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/chats`, {
                    headers: { Authorization: `Bearer ${token}` },
                });
                setChats(response.data);
                setFilteredChats(response.data);
            } catch (error) {
                console.error('Ошибка при получении чатов', error);
                toast.error('Не удалось загрузить чаты');
            } finally {
                setLoading(false);
            }
        };
        fetchChats();
    }, []);

    const copyPostLink = () => {
        const postUrl = `${window.location.origin}/posts/${post.id}`;
        navigator.clipboard.writeText(postUrl)
            .then(() => toast.success('Ссылка скопирована!'))
            .catch(() => toast.error('Не удалось скопировать ссылку'));
    };

    const handleSendPost = async () => {
        const token = localStorage.getItem('token');
        if (!selectedChatId) {
            toast.error('Пожалуйста, выберите чат.');
            return;
        }

        try {
            await axios.post(
                `${process.env.REACT_APP_API_URL}/posts/${post.id}/send`,
                { chat_id: selectedChatId, post_id: post.id },
                { headers: { Authorization: `Bearer ${token}` } }
            );
            onSend();
            toast.success('Пост успешно отправлен!');
            setIsModalOpen(false);
            onClose();
        } catch (error) {
            console.error('Ошибка при отправке поста', error);
            toast.error('Ошибка при отправке поста.');
        }
    };

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
        setFilteredChats(chats.filter(chat =>
            chat.user1.name.toLowerCase().includes(e.target.value.toLowerCase()) ||
            chat.user2.name.toLowerCase().includes(e.target.value.toLowerCase())
        ));
    };

    const getChatPartner = (chat) => {
        const userId = parseInt(localStorage.getItem('user_id'), 10);
        return chat.user1_id === userId ? chat.user2 : chat.user1;
    };

    const getAvatarUrl = (chat) => {
        const partner = getChatPartner(chat);
        return partner.avatar
            ? `${process.env.REACT_APP_BASE_URL}/storage/${partner.avatar}`
            : `https://ui-avatars.com/api/?name=${partner.name}&background=random`;
    };

    useEffect(() => {
        document.body.style.overflow = isModalOpen ? 'hidden' : 'auto';
        return () => {
            document.body.style.overflow = 'auto';
        };
    });

    return (
        <AnimatePresence>
            {isModalOpen && (
                <motion.div
                    className="fixed inset-0 bg-black/40 backdrop-blur-md flex justify-center items-center z-50 p-4"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                >
                    <motion.div
                        className="bg-white dark:bg-neutral-900 rounded-2xl shadow-2xl max-w-md w-full relative overflow-hidden"
                        variants={modalVariants}
                        initial="hidden"
                        animate="visible"
                        exit="exit"
                    >
                        <div className="p-6 border-b dark:border-neutral-800 flex justify-between items-center">
                            <motion.h2
                                initial={{ y: -5, opacity: 0 }}
                                animate={{ y: 0, opacity: 1 }}
                                className="text-2xl font-bold text-neutral-900 dark:text-white"
                            >
                                Поделиться постом
                            </motion.h2>
                            <motion.button
                                onClick={onClose}
                                whileHover={{ scale: 1.05 }}
                                whileTap={{ scale: 0.95 }}
                                className="p-2 hover:bg-neutral-100 dark:hover:bg-neutral-800 rounded-lg"
                            >
                                <X className="w-5 h-5 text-neutral-500 dark:text-neutral-400" />
                            </motion.button>
                        </div>

                        <div className="p-6">
                            {/* Блок для копирования ссылки */}
                            <motion.div
                                initial={{ opacity: 0, y: -10 }}
                                animate={{ opacity: 1, y: 0 }}
                                className="mb-6 p-4 bg-neutral-50 dark:bg-neutral-800 rounded-xl border border-dashed border-neutral-200 dark:border-neutral-700"
                            >
                                <div className="flex items-center justify-between">
                                    <div className="flex items-center gap-3">
                                        <div className="p-2 bg-white dark:bg-neutral-700 rounded-lg shadow-sm">
                                            <LinkIcon className="w-5 h-5 text-purple-500" />
                                        </div>
                                        <div>
                                            <p className="text-sm font-medium text-neutral-900 dark:text-white">Прямая ссылка на пост</p>
                                            <p className="text-sm text-neutral-500 dark:text-neutral-400 truncate max-w-[200px]">
                                                {`${window.location.origin}/posts/${post.id}`}
                                            </p>
                                        </div>
                                    </div>
                                    <motion.button
                                        onClick={copyPostLink}
                                        whileHover={{ scale: 1.05 }}
                                        whileTap={{ scale: 0.95 }}
                                        className="p-2 hover:bg-neutral-100 dark:hover:bg-neutral-700 rounded-lg"
                                    >
                                        <Copy className="w-5 h-5 text-neutral-500 dark:text-neutral-400" />
                                    </motion.button>
                                </div>
                            </motion.div>

                            <motion.div
                                className="relative mb-6"
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                            >
                                <Search className="absolute left-4 top-4 w-5 h-5 text-neutral-400" />
                                <input
                                    type="text"
                                    value={searchTerm}
                                    onChange={handleSearchChange}
                                    placeholder="Поиск чатов..."
                                    className="w-full pl-12 pr-4 py-3 bg-neutral-50 dark:bg-neutral-800 rounded-xl border-none focus:ring-2 focus:ring-violet-500 focus:bg-white dark:focus:bg-neutral-700 transition-all"
                                />
                            </motion.div>

                            <motion.div
                                className="space-y-3 max-h-96 overflow-y-auto"
                                variants={listVariants}
                                initial="hidden"
                                animate="visible"
                            >
                                {loading ? (
                                    Array.from({ length: 5 }).map((_, index) => (
                                        <SkeletonItem key={index} index={index} />
                                    ))
                                ) : filteredChats.length === 0 ? (
                                    <motion.div
                                        className="p-4 text-center text-neutral-500 dark:text-neutral-400"
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 1 }}
                                    >
                                        Ничего не найдено
                                    </motion.div>
                                ) : (
                                    filteredChats.map((chat, index) => (
                                        <motion.div
                                            key={chat.id}
                                            custom={index}
                                            variants={itemVariants}
                                            className={`group flex items-center p-4 rounded-xl cursor-pointer transition-all 
                                                ${selectedChatId === chat.id
                                                ? 'bg-violet-50/50 dark:bg-violet-900/20 border-2 border-violet-500'
                                                : 'hover:bg-neutral-50 dark:hover:bg-neutral-800 border-2 border-transparent'}`}
                                            onClick={() => setSelectedChatId(chat.id)}
                                        >
                                            <motion.div
                                                className="relative"
                                            >
                                                <img
                                                    src={getAvatarUrl(chat)}
                                                    alt="Avatar"
                                                    className="w-12 h-12 rounded-xl object-cover shadow-sm"
                                                    onError={(e) => {
                                                        e.target.src = `https://ui-avatars.com/api/?name=${getChatPartner(chat).name}`;
                                                    }}
                                                />
                                                <div className="absolute -bottom-1 -right-1 bg-white dark:bg-neutral-900 p-1 rounded-full shadow">
                                                    <motion.div
                                                        className={`w-3 h-3 rounded-full ${selectedChatId === chat.id ? 'bg-violet-500' : 'bg-neutral-300'}`}
                                                        animate={selectedChatId === chat.id ? { scale: [1, 1.2, 1] } : {}}
                                                    />
                                                </div>
                                            </motion.div>
                                            <div className="ml-4">
                                                <h3 className="font-semibold text-neutral-900 dark:text-neutral-100">
                                                    {getChatPartner(chat).name}
                                                </h3>
                                                <div className="flex items-center gap-2 mt-1">
                                                    <OnlineStatusIndicator userId={getChatPartner(chat).id} size="sm" showText={true} />
                                                </div>
                                            </div>
                                        </motion.div>
                                    ))
                                )}
                            </motion.div>
                        </div>

                        <motion.div
                            className="p-4 bg-neutral-50 dark:bg-neutral-800/50 border-t dark:border-neutral-800"
                            initial={{ y: 20, opacity: 0 }}
                            animate={{ y: 0, opacity: 1 }}
                        >
                            <div className="flex gap-3 justify-end">
                                <motion.button
                                    onClick={onClose}
                                    whileHover={{ y: -1 }}
                                    whileTap={{ scale: 0.98 }}
                                    className="px-6 py-2.5 rounded-lg text-neutral-600 dark:text-neutral-300 hover:bg-neutral-200 dark:hover:bg-neutral-700"
                                >
                                    Отмена
                                </motion.button>
                                <motion.button
                                    onClick={handleSendPost}
                                    disabled={!selectedChatId}
                                    className={`px-6 py-2.5 rounded-lg flex items-center gap-2 ${
                                        !selectedChatId
                                            ? 'bg-neutral-100 dark:bg-neutral-700 text-neutral-400 cursor-not-allowed'
                                            : 'bg-violet-500 hover:bg-violet-600 text-white shadow-lg'
                                    }`}
                                    whileHover={!selectedChatId ? {} : { y: -1 }}
                                    whileTap={!selectedChatId ? {} : { scale: 0.98 }}
                                >
                                    <Send className="w-5 h-5" />
                                    Отправить пост
                                </motion.button>
                            </div>
                        </motion.div>
                    </motion.div>
                </motion.div>
            )}
        </AnimatePresence>
    );
}

export default SharePostModal;