import React, { useContext, useEffect, useState, useCallback, useRef } from "react";
import { AuthContext } from "../context/AuthContext";
import { Link } from "react-router-dom";
import axios from "axios";
import Pusher from "pusher-js";
import BottomNav from "./BottomNav";
import useWindowSize from "../hooks/useWindowSize";
import {
    MessageCircle,
    Search,
    Compass,
    Music,
    SlidersHorizontal,
    ClipboardList,
    UserCircle,
    Users2,
    BellRing,
    Grid2x2Plus,
    Home
} from "lucide-react";

const NAV_ITEMS = [
    { path: "/", icon: Home, label: "Главная" },
    { path: "/profile", icon: UserCircle, label: "Профиль" },
    { path: "/messages", icon: MessageCircle, label: "Сообщения" },
    { path: "/posts/all", icon: ClipboardList, label: "Посты" },
    { path: "/friends", icon: Users2, label: "Друзья" },
    { path: "/notifications", icon: BellRing, label: "Уведомления" },
    { path: "/search", icon: Search, label: "Поиск" },
    { path: "/groups", icon: Compass, label: "Группы" },
    { path: "/music", icon: Music, label: "Музыка" },
    { path: "/services", icon: Grid2x2Plus, label: "Сервисы" },
    { path: "/settings", icon: SlidersHorizontal, label: "Настройки" },
    { path: "/reports", icon: ClipboardList, label: "Жалобы" },
];

function Aside() {
    const { isLoggedIn } = useContext(AuthContext);
    const { width } = useWindowSize();
    const userId = parseInt(localStorage.getItem("user_id"), 10);
    
    // Состояния для счетчиков
    const [unreadMessagesCount, setUnreadMessagesCount] = useState(0);
    const [unreadNotificationsCount, setUnreadNotificationsCount] = useState(0);
    
    // Анимация для счетчиков
    const [isCounting, setIsCounting] = useState(false);
    const [isNotifCounting, setIsNotifCounting] = useState(false);
    const prevCountRef = useRef(0);
    const prevNotifCountRef = useRef(0);

    // Стили анимации
    const counterStyle = {
        transform: isCounting ? 'scale(1.3)' : 'scale(1)',
        transition: 'transform 0.3s cubic-bezier(0.4, 0, 0.2, 1)'
    };

    const notifCounterStyle = {
        transform: isNotifCounting ? 'scale(1.3)' : 'scale(1)',
        transition: 'transform 0.3s cubic-bezier(0.4, 0, 0.2, 1)'
    };

    // Метод для получения непрочитанных сообщений
    const fetchUnreadMessages = useCallback(async () => {
        try {
            const token = localStorage.getItem("token");
            if (!token) return;
            
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/messages/unread_count`, {
                headers: { Authorization: `Bearer ${token}` }
            });
            setUnreadMessagesCount(response.data?.unread_count || 0);
        } catch {
            setUnreadMessagesCount(0);
        }
    }, []);

    // Метод для получения непрочитанных уведомлений
    const fetchUnreadNotifications = useCallback(async () => {
        try {
            const token = localStorage.getItem("token");
            if (!token) return;
            
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/notifications/unread_count`, {
                headers: { Authorization: `Bearer ${token}` }
            });
            setUnreadNotificationsCount(response.data?.unread_count || 0);
        } catch {
            setUnreadNotificationsCount(0);
        }
    }, []);

    useEffect(() => {
        if (prevCountRef.current !== unreadMessagesCount) {
            setIsCounting(true);
            const timer = setTimeout(() => setIsCounting(false), 300);
            return () => clearTimeout(timer);
        }
        prevCountRef.current = unreadMessagesCount;
    }, [unreadMessagesCount]);

    useEffect(() => {
        if (prevNotifCountRef.current !== unreadNotificationsCount) {
            setIsNotifCounting(true);
            const timer = setTimeout(() => setIsNotifCounting(false), 300);
            return () => clearTimeout(timer);
        }
        prevNotifCountRef.current = unreadNotificationsCount;
    }, [unreadNotificationsCount]);

    useEffect(() => {
        if (isLoggedIn) {
            fetchUnreadMessages();
            fetchUnreadNotifications();

            const token = localStorage.getItem('token');
            const pusher = new Pusher(process.env.REACT_APP_PUSHER_APP_KEY, {
                cluster: "eu",
                forceTLS: true,
                channelAuthorization: {
                    endpoint: `${process.env.REACT_APP_API_URL}/broadcasting/auth`,
                    transport: "ajax",
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "X-Requested-With": "XMLHttpRequest",
                        "Content-Type": "application/json"
                    }
                }
            });

            const channel = pusher.subscribe(`private-user.${userId}`);
            
            // Обработчики событий
            channel.bind('message.read', fetchUnreadMessages);
            channel.bind('notification.created', fetchUnreadNotifications);
            channel.bind('notification.read', fetchUnreadNotifications);

            const messagesInterval = setInterval(fetchUnreadMessages, 5000);
            const notifInterval = setInterval(fetchUnreadNotifications, 5000);

            return () => {
                channel.unbind_all();
                pusher.unsubscribe(`private-user.${userId}`);
                clearInterval(messagesInterval);
                clearInterval(notifInterval);
            };
        }
    }, [isLoggedIn, fetchUnreadMessages, fetchUnreadNotifications, userId]);

    if (width < 768) {
        return <BottomNav 
            unreadMessagesCount={unreadMessagesCount}
            unreadNotificationsCount={unreadNotificationsCount}
        />;
    }

    return (
        <aside className="max-w-[250px] w-full p-4 sticky pt-16 top-0 h-[calc(100vh-73px)] overflow-y-auto flex flex-col">
            <ul className="space-y-2">
                {NAV_ITEMS.map(({ path, icon: Icon, label }) => (
                    <li key={path} className="group">
                        <Link
                            to={path}
                            className="flex items-center p-2 rounded-lg transition-transform duration-200 hover:scale-105 bg-white dark:bg-neutral-800 group-hover:shadow-md group-hover:bg-gray-50 dark:group-hover:bg-neutral-700"
                        >
                            <Icon className="mr-3 w-6 h-6 text-lg text-gray-600 dark:text-gray-300 group-hover:text-purple-600 dark:group-hover:text-purple-400" />
                            <div className="flex justify-between items-center w-full">
                                <span className="font-medium text-gray-800 dark:text-gray-200 group-hover:text-purple-600 dark:group-hover:text-purple-400">
                                    {label}
                                </span>
                                {path === "/messages" && unreadMessagesCount > 0 && (
                                    <span
                                        className="bg-red-500 text-white text-xs w-5 h-5 flex justify-center items-center rounded-full shadow-sm"
                                        style={counterStyle}
                                    >
                                        {unreadMessagesCount}
                                    </span>
                                )}
                                {path === "/notifications" && unreadNotificationsCount > 0 && (
                                    <span
                                        className="bg-violet-500 text-white text-xs w-5 h-5 flex justify-center items-center rounded-full shadow-sm"
                                        style={notifCounterStyle}
                                    >
                                        {unreadNotificationsCount}
                                    </span>
                                )}
                            </div>
                        </Link>
                    </li>
                ))}
            </ul>
        </aside>
    );
}

export default Aside;