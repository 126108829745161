import React, { useState, useRef, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { motion, AnimatePresence, Reorder } from 'framer-motion';
import BackLink from "./BackLink";
import toast from "react-hot-toast";
import useWindowSize from "../hooks/useWindowSize";
import BottomNav from "./BottomNav";
import { X, Upload, Music, Image, Info, Plus, Save, Trash2, Search, PlusCircle, GripVertical, ChevronLeft, ChevronRight, FileAudio, AlertCircle, XCircle } from "lucide-react";

const UploadAlbum = () => {
    const [albumData, setAlbumData] = useState({
        title: '',
        artist: '',
        artist_id: null,
        artists: [], // Массив выбранных исполнителей
        year: '',
        genre: '',
        album_art: null,
        album_art_preview: null,
        tracks: []
    });
    const [loading, setLoading] = useState(false);
    const [dragOver, setDragOver] = useState(false);
    const [trackDragOver, setTrackDragOver] = useState(false);
    const [trackFieldDragOver, setTrackFieldDragOver] = useState(null); // Индекс поля, над которым происходит перетаскивание
    const [step, setStep] = useState(1); // 1: Album Info, 2: Tracks
    const navigate = useNavigate();
    const { width } = useWindowSize();
    const fileInputRef = useRef(null);
    const trackFileRefs = useRef([]);
    const artistDropdownRef = useRef(null);
    const trackDropAreaRef = useRef(null);
    const formRef = useRef(null); // Ref для формы
    const isMobile = width < 768;
    const [touchStartY, setTouchStartY] = useState(null);
    const [touchedTrackIndex, setTouchedTrackIndex] = useState(null);
    const [showDragInstructions, setShowDragInstructions] = useState(true);
    // Новое состояние для использования жанра альбома для всех треков
    const [useAlbumGenreForTracks, setUseAlbumGenreForTracks] = useState(true);

    // Artist selection state
    const [artists, setArtists] = useState([]);
    const [artistsLoading, setArtistsLoading] = useState(false);
    const [showArtistDropdown, setShowArtistDropdown] = useState(false);
    const [creatingNewArtist, setCreatingNewArtist] = useState(false);

    useEffect(() => {
        const fetchArtists = async () => {
            setArtistsLoading(true);
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/artists`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch artists');
                }

                const data = await response.json();
                setArtists(data.data || []);
            } catch (error) {
                console.error('Error fetching artists:', error);
                toast.error('Не удалось загрузить список исполнителей');
                setArtists([]);
            } finally {
                setArtistsLoading(false);
            }
        };

        fetchArtists();
    }, []);

    // Скрываем инструкции по перетаскиванию после первого успешного перетаскивания
    useEffect(() => {
        const hasSuccessfulDrop = localStorage.getItem('hasSuccessfulDrop');
        if (hasSuccessfulDrop) {
            setShowDragInstructions(false);
        }
    }, []);

    // Новый эффект для обновления жанров треков при изменении жанра альбома
    useEffect(() => {
        if (useAlbumGenreForTracks && albumData.genre) {
            setAlbumData(prev => ({
                ...prev,
                tracks: prev.tracks.map(track => ({
                    ...track,
                    genre: prev.genre
                }))
            }));
        }
    }, [albumData.genre, useAlbumGenreForTracks]);

    // Прокрутка к кнопке отправки при переходе на шаг 2 на мобильных устройствах
    useEffect(() => {
        if (step === 2 && isMobile) {
            // Небольшая задержка для завершения рендеринга
            setTimeout(() => {
                window.scrollTo({
                    top: document.body.scrollHeight - 200,
                    behavior: 'smooth'
                });
            }, 300);
        }
    }, [step, isMobile]);

    const markSuccessfulDrop = () => {
        localStorage.setItem('hasSuccessfulDrop', 'true');
        setShowDragInstructions(false);
    };

    const filteredArtists = (Array.isArray(artists) ?
            artists.filter(artist =>
                artist.name.toLowerCase().includes(albumData.artist.trim().toLowerCase()) &&
                !albumData.artists.some(selectedArtist => selectedArtist.id === artist.id)
            ) : []
    );

    // Handle artist selection
    const handleSelectArtist = (artist) => {
        setAlbumData(prev => {
            // Если это первый исполнитель, устанавливаем его как основного
            const isFirstArtist = prev.artists.length === 0;

            return {
                ...prev,
                artist: isFirstArtist ? artist.name : '',
                artist_id: isFirstArtist ? artist.id : prev.artist_id,
                artists: [...prev.artists, artist]
            };
        });
        setShowArtistDropdown(false);
    };

    // Удаление исполнителя из списка выбранных
    const handleRemoveArtist = (artistId) => {
        setAlbumData(prev => {
            const updatedArtists = prev.artists.filter(artist => artist.id !== artistId);

            // Если удалили основного исполнителя, обновляем artist и artist_id
            const newMainArtist = updatedArtists.length > 0 ? updatedArtists[0] : null;

            return {
                ...prev,
                artist: newMainArtist ? newMainArtist.name : '',
                artist_id: newMainArtist ? newMainArtist.id : null,
                artists: updatedArtists
            };
        });
    };

    useEffect(() => {
        const handleClickOutside = (e) => {
            if (artistDropdownRef.current && !artistDropdownRef.current.contains(e.target)) {
                setShowArtistDropdown(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    // Обработчики для обложки альбома
    const handleAlbumArtChange = (e) => {
        const file = e.target.files[0];
        if (!file) return;

        setAlbumData({
            ...albumData,
            album_art: file,
            album_art_preview: URL.createObjectURL(file)
        });
    };

    const handleDragOver = (e) => {
        e.preventDefault();
        setDragOver(true);
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        setDragOver(false);
    };

    const handleDrop = (e) => {
        e.preventDefault();
        setDragOver(false);

        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            const file = e.dataTransfer.files[0];
            if (file.type.startsWith('image/')) {
                setAlbumData({
                    ...albumData,
                    album_art: file,
                    album_art_preview: URL.createObjectURL(file)
                });
                toast.success('Обложка альбома добавлена');
                markSuccessfulDrop();
            } else {
                toast.error('Пожалуйста, загрузите изображение для обложки');
            }
        }
    };

    // Обработчики для треков
    const handleTrackUpload = (e, index) => {
        const file = e.target.files[0];
        if (!file) return;

        setAlbumData((prev) => {
            const newTracks = [...prev.tracks];
            if (!newTracks[index]) {
                newTracks[index] = {
                    title: '',
                    file: null,
                    // Используем жанр альбома, если включена соответствующая опция
                    genre: useAlbumGenreForTracks ? prev.genre : ''
                };
            }
            // Автоматически устанавливаем имя файла как название трека, если поле пустое
            if (!newTracks[index].title) {
                const fileName = file.name.replace(/\.[^/.]+$/, ""); // Убираем расширение файла
                newTracks[index].title = fileName;
            }
            newTracks[index].file = file;
            return { ...prev, tracks: newTracks };
        });

        toast.success('Аудио файл добавлен');
    };

    // Drag and Drop для аудио файлов в конкретное поле трека
    const handleTrackFieldDragOver = (e, index) => {
        e.preventDefault();
        e.stopPropagation();
        setTrackFieldDragOver(index);
    };

    const handleTrackFieldDragLeave = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setTrackFieldDragOver(null);
    };

    const handleTrackFieldDrop = (e, index) => {
        e.preventDefault();
        e.stopPropagation();
        setTrackFieldDragOver(null);

        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            const file = e.dataTransfer.files[0];
            if (file.type.startsWith('audio/')) {
                setAlbumData((prev) => {
                    const newTracks = [...prev.tracks];
                    if (!newTracks[index]) {
                        newTracks[index] = {
                            title: '',
                            file: null,
                            // Используем жанр альбома, если включена соответствующая опция
                            genre: useAlbumGenreForTracks ? prev.genre : ''
                        };
                    }
                    if (!newTracks[index].title) {
                        const fileName = file.name.replace(/\.[^/.]+$/, "");
                        newTracks[index].title = fileName;
                    }
                    newTracks[index].file = file;
                    return { ...prev, tracks: newTracks };
                });
                toast.success('Аудио файл добавлен в трек');
                markSuccessfulDrop();
            } else {
                toast.error('Пожалуйста, загрузите аудио файл');
            }
        }
    };

    // Drag and Drop для аудио файлов в общую область
    const handleTrackDragOver = (e) => {
        e.preventDefault();
        setTrackDragOver(true);
    };

    const handleTrackDragLeave = (e) => {
        e.preventDefault();
        setTrackDragOver(false);
    };

    // Обработчик для Drag and Drop новых треков в область
    const handleTrackDrop = (e, index) => {
        e.preventDefault();
        e.currentTarget.classList.remove('border-purple-500', 'bg-purple-50');
        e.currentTarget.classList.add('border-gray-300');

        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            const file = e.dataTransfer.files[0];
            if (file.type.startsWith('audio/')) {
                setAlbumData((prev) => {
                    const newTracks = [...prev.tracks];
                    if (!newTracks[index]) {
                        newTracks[index] = {
                            title: '',
                            file: null,
                            // Используем жанр альбома, если включена соответствующая опция
                            genre: useAlbumGenreForTracks ? prev.genre : ''
                        };
                    }
                    if (!newTracks[index].title) {
                        const fileName = file.name.replace(/\.[^/.]+$/, "");
                        newTracks[index].title = fileName;
                    }
                    newTracks[index].file = file;
                    return { ...prev, tracks: newTracks };
                });
                toast.success('Аудио файл добавлен');
            } else {
                toast.error('Пожалуйста, загрузите аудио файл');
            }
        }
    };

    // Обработчики для мобильных устройств
    const handleTouchStart = (e, index) => {
        if (isMobile) {
            setTouchStartY(e.touches[0].clientY);
            setTouchedTrackIndex(index);
        }
    };

    const handleTouchMove = (e) => {
        if (isMobile && touchStartY !== null && touchedTrackIndex !== null) {
            const currentY = e.touches[0].clientY;
            const diff = currentY - touchStartY;

            // Если перемещение достаточно большое, можно начать перетаскивание
            if (Math.abs(diff) > 20) {
                // Здесь можно добавить визуальную индикацию перетаскивания
            }
        }
    };

    const handleTouchEnd = (e, index) => {
        if (isMobile && touchStartY !== null && touchedTrackIndex !== null) {
            const touchEndY = e.changedTouches[0].clientY;
            const diff = touchEndY - touchStartY;

            // Если перемещение достаточно большое, выполняем перемещение трека
            if (Math.abs(diff) > 50) {
                const newIndex = diff > 0
                    ? Math.min(touchedTrackIndex + 1, albumData.tracks.length - 1)
                    : Math.max(touchedTrackIndex - 1, 0);

                if (newIndex !== touchedTrackIndex) {
                    const newTracks = [...albumData.tracks];
                    const movedTrack = newTracks[touchedTrackIndex];
                    newTracks.splice(touchedTrackIndex, 1);
                    newTracks.splice(newIndex, 0, movedTrack);

                    setAlbumData(prev => ({
                        ...prev,
                        tracks: newTracks
                    }));

                    toast.success('Трек перемещен');
                }
            }

            setTouchStartY(null);
            setTouchedTrackIndex(null);
        }
    };

    const handleTrackGenreChange = (e, index) => {
        // Если включено использование жанра альбома, отключаем его при ручном изменении жанра трека
        if (useAlbumGenreForTracks) {
            setUseAlbumGenreForTracks(false);
            toast.info('Режим единого жанра отключен');
        }

        const genre = e.target.value;

        setAlbumData((prev) => {
            const newTracks = [...prev.tracks];
            if (!newTracks[index]) {
                newTracks[index] = { title: '', file: null, genre: '' };
            }
            newTracks[index].genre = genre;
            return { ...prev, tracks: newTracks };
        });
    };

    const handleTrackTitleChange = (e, index) => {
        const title = e.target.value;

        setAlbumData((prev) => {
            const newTracks = [...prev.tracks];
            if (!newTracks[index]) {
                newTracks[index] = {
                    title: '',
                    file: null,
                    // Используем жанр альбома, если включена соответствующая опция
                    genre: useAlbumGenreForTracks ? prev.genre : ''
                };
            }
            newTracks[index].title = title;
            return { ...prev, tracks: newTracks };
        });
    };

    const addTrackField = () => {
        setAlbumData((prev) => ({
            ...prev,
            tracks: [...prev.tracks, {
                title: '',
                file: null,
                // Используем жанр альбома, если включена соответствующая опция
                genre: useAlbumGenreForTracks ? prev.genre : ''
            }]
        }));
    };

    const removeTrackField = (index) => {
        setAlbumData(prev => ({
            ...prev,
            tracks: prev.tracks.filter((_, i) => i !== index)
        }));
        toast.success('Трек удален');
    };

    const reorderTracks = (newOrder) => {
        setAlbumData(prev => ({
            ...prev,
            tracks: newOrder
        }));
    };

    // Проверка валидации перед переходом к следующему шагу
    const validateFirstStep = () => {
        if (!albumData.title || albumData.artists.length === 0 || !albumData.album_art) {
            toast.error('Пожалуйста, заполните обязательные поля');
            return false;
        }
        return true;
    };

    const nextStep = () => {
        if (validateFirstStep()) {
            setStep(2);
        }
    };

    const prevStep = () => {
        setStep(1);
    };

    // Обработчик для переключения режима использования жанра альбома
    const toggleUseAlbumGenre = () => {
        setUseAlbumGenreForTracks(prev => !prev);

        // Если включаем режим, обновляем жанры всех треков
        if (!useAlbumGenreForTracks) {
            setAlbumData(prev => ({
                ...prev,
                tracks: prev.tracks.map(track => ({
                    ...track,
                    genre: prev.genre
                }))
            }));
            toast.success('Жанр альбома применен ко всем трекам');
        } else {
            toast.success('Режим единого жанра отключен');
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log('Обработка отправки формы');

        if (albumData.tracks.length === 0) {
            toast.error('Добавьте хотя бы один трек');
            return;
        }

        const invalidTracks = albumData.tracks.some(track => !track.title || !track.file);
        if (invalidTracks) {
            toast.error('Убедитесь, что все треки имеют название и файл');
            return;
        }

        if (albumData.artists.length === 0) {
            toast.error('Пожалуйста, выберите хотя бы одного исполнителя');
            return;
        }

        setLoading(true);
        toast.loading('Загрузка альбома...');

        const formData = new FormData();
        formData.append('title', albumData.title);
        formData.append('artist_id', albumData.artist_id);

        albumData.artists.forEach((artist) => {
            formData.append('artists[]', artist.id);
        });

        if (albumData.year) formData.append('year', albumData.year);
        if (albumData.genre) formData.append('genre', albumData.genre);
        formData.append('album_art', albumData.album_art);

        albumData.tracks.forEach((track, index) => {
            formData.append(`tracks[${index}][title]`, track.title);
            formData.append(`tracks[${index}][file]`, track.file);
            // Если включен режим использования жанра альбома, используем его
            formData.append(`tracks[${index}][genre]`, useAlbumGenreForTracks ? albumData.genre : (track.genre || ''));
            formData.append(`tracks[${index}][order]`, index + 1);
        });

        try {
            console.log('Отправка запроса на сервер');
            const response = await fetch(`${process.env.REACT_APP_API_URL}/albums`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                    'Accept': 'application/json'
                },
                body: formData
            });

            const responseText = await response.text();
            console.log('Получен ответ от сервера:', responseText);

            try {
                const result = JSON.parse(responseText);

                if (!response.ok) {
                    throw new Error(result.message || 'Ошибка сервера');
                }

                if (result.album && result.album.id) {
                    toast.dismiss();
                    toast.success('Альбом успешно загружен!');
                    navigate(`/albums/${result.album.id}`);
                } else {
                    throw new Error('Неверная структура ответа сервера');
                }
            } catch (parseError) {
                console.error('Ошибка парсинга ответа:', parseError, 'Ответ:', responseText);
                throw new Error('Неверный формат ответа от сервера');
            }

        } catch (error) {
            console.error('Ошибка загрузки:', error);
            toast.dismiss();
            toast.error(error.message || 'Не удалось загрузить альбом');
        } finally {
            setLoading(false);
        }
    };

    const containerVariants = {
        hidden: { opacity: 0, y: 20 },
        visible: {
            opacity: 1,
            y: 0,
            transition: {
                duration: 0.5,
                when: "beforeChildren",
                staggerChildren: 0.1
            }
        }
    };

    const itemVariants = {
        hidden: { opacity: 0, y: 10 },
        visible: { opacity: 1, y: 0 }
    };

    // Анимация для перетаскивания
    const dragIndicatorVariants = {
        initial: { opacity: 0, scale: 0.8 },
        animate: { opacity: 1, scale: 1, transition: { duration: 0.2 } },
        exit: { opacity: 0, scale: 0.8, transition: { duration: 0.2 } }
    };

    const renderArtistField = () => (
        <motion.div variants={itemVariants} className="space-y-4">
            <div className="flex items-center justify-between">
                <label className="block text-sm font-medium text-neutral-700 dark:text-neutral-200 transition-colors">
                    Исполнители <span className="text-red-500">*</span>
                </label>
                {albumData.artists.length > 0 && !creatingNewArtist && (
                    <div className="flex items-center gap-1.5 text-sm text-green-600 dark:text-green-400 animate-fade-in">
                        <svg className="w-4 h-4 flex-shrink-0" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                        </svg>
                        <span>Выбрано: {albumData.artists.length}</span>
                    </div>
                )}
            </div>

            {/* Отображение выбранных исполнителей */}
            {albumData.artists.length > 0 && (
                <div className="flex flex-wrap gap-2 mb-3">
                    {albumData.artists.map(artist => (
                        <div
                            key={artist.id}
                            className="inline-flex items-center gap-1.5 px-3 py-1.5 bg-purple-100 dark:bg-purple-900/30 text-purple-700 dark:text-purple-300 rounded-full text-sm"
                        >
                            <span>{artist.name}</span>
                            <button
                                type="button"
                                onClick={() => handleRemoveArtist(artist.id)}
                                className="text-purple-500 hover:text-purple-700 dark:text-purple-400 dark:hover:text-purple-200 transition-colors"
                            >
                                <XCircle size={16} />
                            </button>
                        </div>
                    ))}
                </div>
            )}

            <div className="relative group">
                <div className="flex items-center rounded-lg transition-shadow duration-300 group-hover:shadow-sm">
                    <input
                        type="text"
                        placeholder="Добавить исполнителя..."
                        value={albumData.artist}
                        onChange={(e) => {
                            setAlbumData({
                                ...albumData,
                                artist: e.target.value
                            });
                            setShowArtistDropdown(true);
                        }}
                        onFocus={() => setShowArtistDropdown(true)}
                        className="block w-full pl-4 pr-11 py-3.5 border-2 border-gray-200 dark:border-neutral-600 rounded-xl bg-white dark:bg-neutral-800 placeholder-gray-400 dark:placeholder-neutral-500 text-gray-900 dark:text-neutral-100 focus:border-purple-500 focus:ring-2 focus:ring-purple-500/30 focus:outline-none transition-all duration-200"
                        required
                        aria-haspopup="listbox"
                        aria-expanded={showArtistDropdown}
                    />
                    <div className="absolute right-4 top-1/2 -translate-y-1/2 text-gray-400 dark:text-neutral-500">
                        <Search size={22} strokeWidth={2} />
                    </div>
                </div>

                {showArtistDropdown && (
                    <motion.div
                        initial={{ opacity: 0, y: -5 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0 }}
                        ref={artistDropdownRef}
                        role="listbox"
                        className="absolute z-20 w-full mt-2 bg-white dark:bg-neutral-800 border-2 border-gray-100 dark:border-neutral-700 rounded-xl shadow-xl backdrop-blur-sm max-h-72 overflow-y-auto"
                    >
                        {artistsLoading ? (
                            <div className="p-5 flex justify-center items-center">
                                <ClipLoader size={26} color="#9333ea" />
                            </div>
                        ) : (
                            <>
                                {filteredArtists.length > 0 ? (
                                    <>
                                        {filteredArtists.map(artist => (
                                            <button
                                                key={artist.id}
                                                type="button"
                                                role="option"
                                                className="w-full px-5 py-3.5 text-left text-sm flex items-center gap-3 hover:bg-purple-50/70 dark:hover:bg-purple-900/30 transition-colors bg-transparent"
                                                onClick={() => handleSelectArtist(artist)}
                                            >
                                                <span className="flex-1 truncate text-gray-900 dark:text-white">{artist.name}</span>
                                                <Plus size={16} className="text-purple-500" />
                                            </button>
                                        ))}
                                        <div className="border-t border-gray-100 dark:border-neutral-700">
                                            <Link
                                                to="/artists/new"
                                                className="w-full px-5 py-3.5 text-sm flex items-center gap-2 text-purple-600 dark:text-purple-400 hover:bg-gray-50/50 dark:hover:bg-neutral-700/50 transition-colors"
                                            >
                                                <PlusCircle size={18} className="flex-shrink-0" />
                                                <span>Создать нового исполнителя</span>
                                            </Link>
                                        </div>
                                    </>
                                ) : (
                                    <div className="p-5 text-center space-y-4">
                                        <div className="text-gray-500 dark:text-neutral-400 text-sm">
                                            Ничего не найдено для "<span className="font-medium">{albumData.artist}</span>"
                                        </div>
                                        <Link
                                            to="/artists/new"
                                            className="inline-flex items-center gap-2 px-5 py-2.5 bg-purple-500 hover:bg-purple-600 text-white text-sm font-medium rounded-lg transition-colors"
                                        >
                                            <Plus size={16} />
                                            <span>Создать нового исполнителя</span>
                                        </Link>
                                    </div>
                                )}
                            </>
                        )}
                    </motion.div>
                )}
            </div>
        </motion.div>
    );

    // Компонент для отображения индикатора перетаскивания
    const DragIndicator = ({ isVisible, text }) => (
        <AnimatePresence>
            {isVisible && (
                <motion.div
                    variants={dragIndicatorVariants}
                    initial="initial"
                    animate="animate"
                    exit="exit"
                    className="absolute inset-0 flex items-center justify-center bg-purple-500/10 dark:bg-purple-900/30 backdrop-blur-[1px] rounded-xl z-10"
                >
                    <div className="bg-white dark:bg-neutral-800 rounded-lg shadow-lg p-3 flex items-center gap-2">
                        <FileAudio className="text-purple-500" size={20} />
                        <span className="text-sm font-medium text-gray-700 dark:text-gray-200">{text}</span>
                    </div>
                </motion.div>
            )}
        </AnimatePresence>
    );


    return (
        <motion.div
            initial="hidden"
            animate="visible"
            variants={containerVariants}
            className="container mx-auto px-4 sm:px-6 pb-36 pt-16 min-h-screen"
        >
            {isMobile && <BottomNav />}
            <div className="mx-auto">
                <div className="flex items-center mb-4">
                    <BackLink className="mr-2" />
                    {isMobile && step === 2 && (
                        <button
                            onClick={prevStep}
                            className="inline-flex items-center text-sm text-purple-600 dark:text-purple-400 hover:underline"
                        >
                            <ChevronLeft size={16} />
                            <span>К информации</span>
                        </button>
                    )}
                </div>

                <h1 className="text-2xl sm:text-3xl font-extrabold mb-6 sm:mb-8 dark:text-white text-center sm:text-left">
                    {step === 1 ? 'Создание нового альбома' : 'Добавление треков'}
                </h1>

                {/* Progress indicator */}
                <div className="flex items-center justify-center mb-8">
                    <div className="flex items-center w-full max-w-xs">
                        <div
                            className={`w-10 h-10 rounded-full flex items-center justify-center ${
                                step === 1
                                    ? 'bg-purple-600 text-white'
                                    : 'bg-purple-600 text-white'
                            }`}
                        >
                            <Info size={18} />
                        </div>
                        <div className={`h-1 flex-1 ${step === 1 ? 'bg-gray-300 dark:bg-neutral-700' : 'bg-purple-600'}`}></div>
                        <div
                            className={`w-10 h-10 rounded-full flex items-center justify-center ${
                                step === 2
                                    ? 'bg-purple-600 text-white'
                                    : 'bg-gray-300 dark:bg-neutral-700 text-gray-500 dark:text-neutral-400'
                            }`}
                        >
                            <Music size={18} />
                        </div>
                    </div>
                </div>

                <form
                    ref={formRef}
                    onSubmit={handleSubmit}
                    className="bg-white dark:bg-neutral-800 rounded-2xl shadow-xl p-4 sm:p-8 space-y-6 max-w-3xl mx-auto"
                    id="albumUploadForm"
                >
                    <AnimatePresence mode="wait" initial={false}>
                        {step === 1 && (
                            <motion.div
                                key="step1"
                                initial={{ opacity: 0, x: -20 }}
                                animate={{ opacity: 1, x: 0 }}
                                exit={{ opacity: 0, x: -20 }}
                                transition={{ duration: 0.3 }}
                                className="space-y-6"
                            >
                                {/* Album Cover */}
                                <motion.div
                                    variants={itemVariants}
                                    className="space-y-3"
                                >
                                    <label className="block text-sm font-medium text-neutral-700 dark:text-neutral-300">
                                        Обложка альбома <span className="text-red-500">*</span>
                                    </label>
                                    <div
                                        className={`border-2 border-dashed rounded-2xl p-4 transition-all duration-300 relative ${
                                            dragOver
                                                ? 'border-purple-500 bg-purple-50 dark:bg-purple-900/20'
                                                : 'border-gray-300 dark:border-neutral-600'
                                        }`}
                                        onDragOver={handleDragOver}
                                        onDragLeave={handleDragLeave}
                                        onDrop={handleDrop}
                                    >
                                        <DragIndicator
                                            isVisible={dragOver}
                                            text="Отпустите, чтобы добавить обложку"
                                        />

                                        {albumData.album_art_preview ? (
                                            <div className="relative">
                                                <motion.img
                                                    src={albumData.album_art_preview}
                                                    alt="Album Cover Preview"
                                                    className="w-full h-64 object-contain rounded-lg"
                                                    initial={{ opacity: 0, scale: 0.9 }}
                                                    animate={{ opacity: 1, scale: 1 }}
                                                    transition={{ duration: 0.3 }}
                                                />
                                                <button
                                                    type="button"
                                                    onClick={() => setAlbumData({...albumData, album_art: null, album_art_preview: null})}
                                                    className="absolute top-2 right-2 bg-red-500 text-white p-1.5 rounded-full hover:bg-red-600 transition-colors shadow-md"
                                                >
                                                    <X size={18} />
                                                </button>
                                            </div>
                                        ) : (
                                            <div
                                                className="flex flex-col items-center justify-center h-64 cursor-pointer"
                                                onClick={() => fileInputRef.current.click()}
                                            >
                                                <Image className="w-16 h-16 text-gray-400 dark:text-gray-500 mb-3" />
                                                <p className="text-sm text-gray-500 dark:text-gray-400 text-center mb-2">
                                                    Перетащите изображение сюда или нажмите для выбора
                                                </p>
                                                <motion.button
                                                    type="button"
                                                    whileHover={{ scale: 1.05 }}
                                                    whileTap={{ scale: 0.95 }}
                                                    className="px-4 py-2 bg-purple-500 text-white rounded-lg hover:bg-purple-600 transition-colors shadow-md"
                                                >
                                                    Выбрать обложку
                                                </motion.button>
                                            </div>
                                        )}
                                        <input
                                            type="file"
                                            ref={fileInputRef}
                                            onChange={handleAlbumArtChange}
                                            accept="image/*"
                                            className="hidden"
                                            required
                                        />
                                    </div>
                                </motion.div>

                                {/* Album Title */}
                                <motion.div variants={itemVariants} className="space-y-3">
                                    <label className="block text-sm font-medium text-neutral-700 dark:text-neutral-300">
                                        Название альбома <span className="text-red-500">*</span>
                                    </label>
                                    <input
                                        type="text"
                                        placeholder="Введите название альбома"
                                        value={albumData.title}
                                        onChange={(e) => setAlbumData({...albumData, title: e.target.value})}
                                        className="block w-full p-3.5 border-2 border-gray-200 rounded-xl focus:ring-2 focus:ring-purple-500 focus:border-purple-500 dark:bg-neutral-700 dark:border-neutral-600 dark:text-white transition-all duration-200"
                                        required
                                    />
                                </motion.div>

                                {renderArtistField()}

                                {/* Album Genre */}
                                <motion.div variants={itemVariants} className="space-y-3">
                                    <label className="block text-sm font-medium text-neutral-700 dark:text-neutral-300">
                                        Жанр альбома
                                    </label>
                                    <input
                                        type="text"
                                        placeholder="Например: Рок, Поп, Хип-хоп (через запятую)"
                                        value={albumData.genre}
                                        onChange={(e) => setAlbumData({...albumData, genre: e.target.value})}
                                        className="block w-full p-3.5 border-2 border-gray-200 rounded-xl focus:ring-2 focus:ring-purple-500 focus:border-purple-500 dark:bg-neutral-700 dark:border-neutral-600 dark:text-white transition-all duration-200"
                                    />
                                </motion.div>

                                {/* Year */}
                                <motion.div variants={itemVariants} className="space-y-3">
                                    <label className="block text-sm font-medium text-neutral-700 dark:text-neutral-300">
                                        Год выпуска
                                        <span className="text-gray-500 text-xs ml-2">(необязательно, если альбом новый)</span>
                                    </label>
                                    <input
                                        type="number"
                                        placeholder="Введите год выпуска"
                                        value={albumData.year}
                                        onChange={(e) => setAlbumData({...albumData, year: e.target.value})}
                                        min="1900"
                                        max={new Date().getFullYear()}
                                        className="block w-full p-3.5 border-2 border-gray-200 rounded-xl focus:ring-2 focus:ring-purple-500 focus:border-purple-500 dark:bg-neutral-700 dark:border-neutral-600 dark:text-white transition-all duration-200"
                                    />
                                </motion.div>

                                <motion.button
                                    variants={itemVariants}
                                    type="button"
                                    onClick={nextStep}
                                    whileHover={{ scale: 1.02 }}
                                    whileTap={{ scale: 0.98 }}
                                    className="w-full bg-gradient-to-r from-purple-600 to-indigo-600 text-white px-6 py-3.5 rounded-xl flex justify-center items-center gap-2 hover:from-purple-700 hover:to-indigo-700 transition-all duration-200 shadow-lg shadow-purple-500/20"
                                >
                                    Далее: Добавить треки
                                    <ChevronRight size={20} />
                                </motion.button>
                            </motion.div>
                        )}

                        {step === 2 && (
                            <motion.div
                                key="step2"
                                initial={{ opacity: 0, x: 20 }}
                                animate={{ opacity: 1, x: 0 }}
                                exit={{ opacity: 0, x: 20 }}
                                transition={{ duration: 0.3 }}
                                className="space-y-6"
                            >
                                <div className="flex flex-col sm:flex-row sm:items-center justify-between mb-4 gap-3">
                                    <h3 className="text-xl font-semibold text-neutral-800 dark:text-white">
                                        Треки альбома
                                    </h3>
                                    <div className="flex gap-2">
                                        <button
                                            type="button"
                                            onClick={addTrackField}
                                            className="px-3 py-2 bg-purple-500/10 text-purple-500 rounded-lg hover:bg-purple-500/20 transition-colors duration-200 flex items-center gap-1"
                                        >
                                            <Plus size={16} />
                                            <span className="hidden sm:inline">Добавить трек</span>
                                            <span className="sm:hidden">Добавить</span>
                                        </button>
                                    </div>
                                </div>

                                {/* Переключатель для использования жанра альбома */}
                                <div className="flex items-center p-3 bg-purple-50 dark:bg-purple-900/20 border border-purple-200 dark:border-purple-800 rounded-lg">
                                    <label className="flex items-center cursor-pointer">
                                        <input
                                            type="checkbox"
                                            checked={useAlbumGenreForTracks}
                                            onChange={toggleUseAlbumGenre}
                                            className="sr-only"
                                        />
                                        <div className={`relative w-10 h-5 transition-colors duration-200 ease-linear rounded-full ${useAlbumGenreForTracks ? 'bg-purple-500' : 'bg-gray-300 dark:bg-neutral-600'}`}>
                                            <div className={`absolute left-0.5 top-0.5 w-4 h-4 transition-transform duration-200 ease-linear transform bg-white rounded-full ${useAlbumGenreForTracks ? 'translate-x-5' : 'translate-x-0'}`}></div>
                                        </div>
                                        <span className="ml-3 text-sm font-medium text-gray-700 dark:text-gray-200">
                                            Использовать жанр альбома для всех треков
                                        </span>
                                    </label>
                                </div>

                                {/* Подсказка о перетаскивании */}
                                <div className="p-3 bg-blue-50 dark:bg-blue-900/20 border border-blue-200 dark:border-blue-800 rounded-lg text-sm text-blue-600 dark:text-blue-400">
                                    <p>{isMobile ? "Нажмите на поле для выбора аудио файла" : "Вы можете перетащить аудио файлы прямо в поле выбора трека для быстрой загрузки."}</p>
                                </div>

                                {albumData.tracks.length === 0 && (
                                    <motion.div
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 1 }}
                                        className="p-8 border-2 border-dashed border-gray-300 dark:border-neutral-600 rounded-xl text-center"
                                    >
                                        <Music className="mx-auto mb-4 text-gray-400 dark:text-gray-500" size={32} />
                                        <p className="text-gray-500 dark:text-gray-400 mb-4">Нет добавленных треков</p>
                                        <motion.button
                                            type="button"
                                            onClick={addTrackField}
                                            whileHover={{ scale: 1.05 }}
                                            whileTap={{ scale: 0.95 }}
                                            className="px-4 py-2 bg-purple-500 text-white rounded-lg hover:bg-purple-600 transition-colors inline-flex items-center gap-2"
                                        >
                                            <Plus size={18} />
                                            Добавить первый трек
                                        </motion.button>
                                    </motion.div>
                                )}

                                <div className="space-y-5">
                                    {albumData.tracks.map((track, index) => (
                                        <motion.div
                                            key={index}
                                            initial={{opacity: 0, y: 10}}
                                            animate={{opacity: 1, y: 0}}
                                            exit={{opacity: 0, y: -10}}
                                            transition={{duration: 0.3}}
                                            className="p-4 border border-gray-200 dark:border-neutral-700 rounded-xl bg-gray-50 dark:bg-neutral-800 relative group"
                                        >
                                            <div className="flex items-start gap-4">
                                                <div className="flex-shrink-0 w-8 h-8 rounded-full bg-purple-100 dark:bg-purple-900/30 flex items-center justify-center text-purple-600 dark:text-purple-400 font-semibold">
                                                    {index + 1}
                                                </div>
                                                <div className="flex-grow space-y-3">
                                                    <input
                                                        type="text"
                                                        placeholder="Название трека"
                                                        value={track.title}
                                                        onChange={(e) => handleTrackTitleChange(e, index)}
                                                        className="block w-full p-3 border border-gray-300 rounded-xl focus:ring-2 focus:ring-purple-500 focus:border-transparent dark:bg-neutral-700 dark:border-neutral-600 dark:text-white transition-all duration-200"
                                                        required
                                                    />
                                                    <div className="flex flex-col sm:flex-row gap-3">
                                                        <div className="flex-grow">
                                                            <div className="relative">
                                                                <div
                                                                    onClick={() => trackFileRefs.current[index].click()}
                                                                    onDragOver={(e) => handleTrackDragOver(e, index)}
                                                                    onDragLeave={handleTrackDragLeave}
                                                                    onDrop={(e) => handleTrackDrop(e, index)}
                                                                    className="flex items-center p-3 border border-gray-300 dark:border-neutral-600 dark:bg-neutral-700 rounded-xl cursor-pointer transition-all duration-200"
                                                                >
                                                                    {track.file ? (
                                                                        <>
                                                                            <span className="text-green-600 dark:text-green-400 mr-2">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                                                                    <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
                                                                                    <polyline points="22 4 12 14.01 9 11.01"></polyline>
                                                                                </svg>
                                                                            </span>
                                                                            <span
                                                                                className="truncate dark:text-white max-w-[200px] sm:max-w-[300px]">{track.file.name}</span>
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            <Upload className="text-gray-400 dark:text-gray-500 mr-2" size={20} />
                                                                            <span className="text-gray-500 dark:text-gray-400">
                                                                                {isMobile ? "Выберите аудио файл" : "Выберите или перетащите аудио файл"}
                                                                            </span>
                                                                        </>
                                                                    )}
                                                                </div>
                                                                <input
                                                                    type="file"
                                                                    accept="audio/*"
                                                                    ref={el => trackFileRefs.current[index] = el}
                                                                    onChange={(e) => handleTrackUpload(e, index)}
                                                                    className="hidden"
                                                                    required
                                                                />
                                                            </div>
                                                        </div>
                                                        {/* Поле жанра трека отображается только если отключен режим использования жанра альбома */}
                                                        {!useAlbumGenreForTracks && (
                                                            <input
                                                                type="text"
                                                                placeholder="Жанр трека (через запятую)"
                                                                value={track.genre || ''}
                                                                onChange={(e) => handleTrackGenreChange(e, index)}
                                                                className="block w-full sm:w-1/3 p-3 border border-gray-300 rounded-xl focus:ring-2 focus:ring-purple-500 focus:border-transparent dark:bg-neutral-700 dark:border-neutral-600 dark:text-white transition-all duration-200"
                                                            />
                                                        )}
                                                    </div>
                                                </div>
                                                <motion.button
                                                    type="button"
                                                    onClick={() => removeTrackField(index)}
                                                    whileHover={{ scale: 1.1 }}
                                                    whileTap={{ scale: 0.9 }}
                                                    className="flex-shrink-0 text-red-500 hover:text-red-700 p-2 rounded-full hover:bg-red-100 dark:hover:bg-red-900/30 transition-colors"
                                                    aria-label="Удалить трек"
                                                >
                                                    <Trash2 size={18} />
                                                </motion.button>
                                            </div>
                                        </motion.div>
                                    ))}
                                </div>

                                {/* Кнопки навигации для десктопа и мобильных устройств */}
                                <div className="flex flex-col sm:flex-row justify-between gap-3 pt-4">
                                    {!isMobile && (
                                        <motion.button
                                            type="button"
                                            onClick={prevStep}
                                            whileHover={{ scale: 1.02 }}
                                            whileTap={{ scale: 0.98 }}
                                            className="px-6 py-3 bg-gray-200 dark:bg-neutral-700 text-gray-700 dark:text-white rounded-xl flex items-center justify-center gap-2 hover:bg-gray-300 dark:hover:bg-neutral-600 transition-colors"
                                        >
                                            <ChevronLeft size={20} />
                                            Назад
                                        </motion.button>
                                    )}

                                    {/* Кнопка отправки для всех устройств (включая мобильные) */}
                                    <motion.button
                                        onClick={handleSubmit}
                                        type="submit"
                                        disabled={loading}
                                        whileHover={{ scale: 1.02 }}
                                        whileTap={{ scale: 0.98 }}
                                        className={`px-6 py-3.5 bg-gradient-to-r from-purple-600 to-indigo-600 text-white rounded-xl flex items-center justify-center gap-2 hover:from-purple-700 hover:to-indigo-700 transition-colors shadow-lg shadow-purple-500/20 ${isMobile ? 'w-full' : ''}`}
                                    >
                                        {loading ? (
                                            <ClipLoader size={20} color="#fff" />
                                        ) : (
                                            <>
                                                <Save size={20} />
                                                Загрузить альбом
                                            </>
                                        )}
                                    </motion.button>
                                </div>
                            </motion.div>
                        )}
                    </AnimatePresence>
                </form>
            </div>
        </motion.div>
    );
};

export default UploadAlbum;
