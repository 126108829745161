import React, { useEffect, useState } from 'react';
import axios from 'axios';
import toast from 'react-hot-toast';
import { motion, AnimatePresence } from 'framer-motion';
import useWindowSize from "../hooks/useWindowSize";

const notifyError = () => toast.error('Произошла ошибка при изменении поста!');
const notifySuccess = () => toast.success('Пост успешно обновлен!');

function EditPostModal({ post, onClose, onSave }) {
    const { width } = useWindowSize();
    const [content, setContent] = useState(post.content);
    const [swipeStartY, setSwipeStartY] = useState(0);
    const [swipeOffsetY, setSwipeOffsetY] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        document.body.classList.add('no-scroll');
        return () => {
            document.body.classList.remove('no-scroll');
        };
    }, []);

    const handleSave = async () => {
        if (!content.trim()) {
            toast.error('Текст поста не может быть пустым');
            return;
        }

        setIsLoading(true);
        try {
            const response = await axios.put(`${process.env.REACT_APP_API_URL}/posts/${post.id}`, {
                content: content
            }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });

            notifySuccess();
            if (typeof onSave === 'function') {
                onSave(response.data);
            }
            onClose();
        } catch (error) {
            notifyError();
            console.error('There was an error saving the post!', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleTouchStart = (e) => {
        setSwipeStartY(e.touches[0].clientY);
        setSwipeOffsetY(0);
    };

    const handleTouchMove = (e) => {
        const currentY = e.touches[0].clientY;
        const offsetY = currentY - swipeStartY;
        if (offsetY > 0) {
            setSwipeOffsetY(offsetY);
        }
    };

    const handleTouchEnd = () => {
        if (swipeOffsetY > 150) {
            onClose();
        } else {
            setSwipeOffsetY(0);
        }
    };

    const overlayVariants = {
        visible: { opacity: 1 },
        hidden: { opacity: 0, transition: { duration: 0.4 } }
    };

    const mobileModalVariants = {
        open: { y: swipeOffsetY, opacity: 1 },
        closed: { y: '100%', opacity: 0, transition: { type: 'tween', duration: 0.4, ease: [0.32, 0.72, 0, 1] } }
    };

    const desktopModalVariants = {
        open: { scale: 1, opacity: 1, transition: { type: 'spring', stiffness: 300, damping: 30 } },
        closed: { scale: 0.95, opacity: 0, transition: { type: 'spring', stiffness: 400, damping: 40, duration: 0.4 } }
    };

    return (
        <AnimatePresence>
            <motion.div
                className="fixed inset-0 bg-black/70 backdrop-blur-md z-40"
                initial="hidden"
                animate="visible"
                exit="hidden"
                variants={overlayVariants}
                onClick={onClose}
            />

            {width < 768 ? (
                <motion.div
                    key="mobile-modal"
                    className="fixed bottom-0 left-0 w-full h-auto bg-white shadow-xl rounded-t-3xl dark:bg-neutral-900 z-50"
                    initial="closed"
                    animate="open"
                    exit="closed"
                    variants={mobileModalVariants}
                    style={{ y: swipeOffsetY }}
                    transition={{ type: 'spring', stiffness: 350, damping: 35 }}
                    onTouchStart={handleTouchStart}
                    onTouchMove={handleTouchMove}
                    onTouchEnd={handleTouchEnd}
                >
                    <div className="relative p-6">
                        <div className="w-16 h-1.5 bg-gray-300 dark:bg-neutral-700 rounded-full mx-auto mb-6"></div>

                        <div className="flex items-center justify-between mb-5">
                            <h2 className="text-xl font-bold dark:text-white">Редактировать пост</h2>
                            <button
                                onClick={onClose}
                                className="w-9 h-9 rounded-full flex items-center justify-center hover:bg-gray-100 dark:hover:bg-neutral-800 transition-colors"
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" className="text-gray-500 dark:text-gray-400">
                                    <line x1="18" y1="6" x2="6" y2="18"></line>
                                    <line x1="6" y1="6" x2="18" y2="18"></line>
                                </svg>
                            </button>
                        </div>

                        <div className="bg-gray-50 dark:bg-neutral-800 p-3 rounded-2xl border border-gray-100 dark:border-neutral-700 mb-5">
                            <textarea
                                value={content}
                                onChange={(e) => setContent(e.target.value)}
                                className="w-full bg-transparent p-2 text-lg resize-none focus:outline-none dark:text-white placeholder-gray-400 dark:placeholder-gray-500"
                                rows="6"
                                placeholder="Поделитесь своими мыслями..."
                            />
                        </div>

                        <div className="flex space-x-3">
                            <button
                                onClick={onClose}
                                className="w-full py-3 text-base font-medium text-gray-700 dark:text-gray-300 bg-gray-100 dark:bg-neutral-800 rounded-xl hover:bg-gray-200 dark:hover:bg-neutral-700 transition-colors"
                            >
                                Отмена
                            </button>
                            <button
                                onClick={handleSave}
                                disabled={isLoading}
                                className={`w-full py-3 text-base font-medium text-white bg-violet-600 rounded-xl ${isLoading ? 'opacity-70' : 'hover:bg-violet-700'} transition-colors relative overflow-hidden`}
                            >
                                {isLoading ? (
                                    <span className="flex items-center justify-center">
                                        <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                        </svg>
                                        Сохранение...
                                    </span>
                                ) : "Сохранить"}
                            </button>
                        </div>
                    </div>
                </motion.div>
            ) : (
                <motion.div
                    key="desktop-modal"
                    className="fixed inset-0 flex items-center justify-center z-50"
                    initial="closed"
                    animate="open"
                    exit="closed"
                    variants={desktopModalVariants}
                    onClick={(e) => e.stopPropagation()}
                >
                    <motion.div
                        className="bg-white p-8 rounded-2xl shadow-2xl w-full max-w-xl dark:bg-neutral-900 border border-gray-100 dark:border-neutral-800"
                        variants={desktopModalVariants}
                    >
                        <div className="flex items-center justify-between mb-6">
                            <h2 className="text-2xl font-bold dark:text-white">Редактировать пост</h2>
                            <button
                                onClick={onClose}
                                className="w-10 h-10 rounded-full flex items-center justify-center hover:bg-gray-100 dark:hover:bg-neutral-800 transition-colors"
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" className="text-gray-500 dark:text-gray-400">
                                    <line x1="18" y1="6" x2="6" y2="18"></line>
                                    <line x1="6" y1="6" x2="18" y2="18"></line>
                                </svg>
                            </button>
                        </div>

                        <div className="bg-gray-50 dark:bg-neutral-800 p-4 rounded-2xl border border-gray-100 dark:border-neutral-700 mb-6">
                            <textarea
                                value={content}
                                onChange={(e) => setContent(e.target.value)}
                                className="w-full bg-transparent p-2 text-lg resize-none focus:outline-none dark:text-white placeholder-gray-400 dark:placeholder-gray-500"
                                rows="8"
                                placeholder="Поделитесь своими мыслями..."
                            />
                        </div>

                        <div className="flex space-x-4">
                            <button
                                onClick={onClose}
                                className="w-full py-3.5 text-base font-medium text-gray-700 dark:text-gray-300 bg-gray-100 dark:bg-neutral-800 rounded-xl hover:bg-gray-200 dark:hover:bg-neutral-700 transition-colors"
                            >
                                Отмена
                            </button>
                            <button
                                onClick={handleSave}
                                disabled={isLoading}
                                className={`w-full py-3.5 text-base font-medium text-white bg-violet-600 rounded-xl ${isLoading ? 'opacity-70' : 'hover:bg-violet-700'} transition-colors`}
                            >
                                {isLoading ? (
                                    <span className="flex items-center justify-center">
                                        <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                        </svg>
                                        Сохранение...
                                    </span>
                                ) : "Сохранить"}
                            </button>
                        </div>
                    </motion.div>
                </motion.div>
            )}
        </AnimatePresence>
    );
}

export default EditPostModal;