import React, { useEffect, useState } from "react";
import BackLink from "../BackLink";
import { ClipLoader } from "react-spinners";
import { motion, AnimatePresence } from "framer-motion";
import { FiChevronDown, FiChevronUp, FiSearch, FiNavigation, FiSunrise, FiSunset } from "react-icons/fi";

export default function WeatherDetails() {
    const [city, setCity] = useState("");
    const [weather, setWeather] = useState(null);
    const [forecast, setForecast] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [expandedDay, setExpandedDay] = useState(null);

    const API_KEY = "f271c589dc595252feddf5cae33f1718";

    const fetchWeatherData = async (cityName) => {
        setLoading(true);
        setError(null);
        try {
            const response = await fetch(`https://api.openweathermap.org/data/2.5/weather?q=${cityName}&units=metric&appid=${API_KEY}&lang=ru`);
            if (!response.ok) throw new Error("Не удалось найти город");
            const data = await response.json();
            setWeather(data);
            fetchForecastData(data.coord.lat, data.coord.lon);
        } catch (err) {
            setError(err.message);
            setWeather(null);
        } finally {
            setLoading(false);
        }
    };

    const fetchForecastData = async (latitude, longitude) => {
        try {
            const response = await fetch(`https://api.openweathermap.org/data/2.5/forecast?lat=${latitude}&lon=${longitude}&units=metric&appid=${API_KEY}&lang=ru`);
            if (!response.ok) throw new Error("Не удалось загрузить прогноз");
            const data = await response.json();
            const dailyForecast = data.list.filter((item) => item.dt_txt.includes("12:00:00"));
            setForecast(dailyForecast);
        } catch (err) {
            console.error("Ошибка при загрузке прогноза:", err);
        }
    };

    const fetchCityByGeolocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    fetchWeatherByGeolocation(latitude, longitude);
                },
                () => setError("Геолокация не разрешена или недоступна")
            );
        } else {
            setError("Геолокация не поддерживается браузером");
        }
    };

    const fetchWeatherByGeolocation = async (latitude, longitude) => {
        setLoading(true);
        try {
            const response = await fetch(`https://api.openweathermap.org/data/2.5/weather?lat=${latitude}&lon=${longitude}&units=metric&appid=${API_KEY}&lang=ru`);
            if (!response.ok) throw new Error("Не удалось определить город по геопозиции");
            const data = await response.json();
            setCity(data.name);
            setWeather(data);
            fetchForecastData(latitude, longitude);
        } catch (err) {
            setError("Ошибка при получении данных по геопозиции");
            setWeather(null);
        } finally {
            setLoading(false);
        }
    };

    const formatTime = (timestamp) => {
        const date = new Date(timestamp * 1000);
        return date.toLocaleTimeString("ru-RU", { hour: "2-digit", minute: "2-digit" });
    };

    const formatDate = (timestamp) => {
        const date = new Date(timestamp * 1000);
        return date.toLocaleDateString("ru-RU", { weekday: "long", day: "numeric", month: "long" });
    };

    useEffect(() => {
        fetchCityByGeolocation();
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (city) fetchWeatherData(city);
    };

    const toggleDayDetails = (day) => {
        setExpandedDay((prev) => (prev === day.dt ? null : day.dt));
    };

    const getMinMaxTemp = (forecastList) => {
        return forecastList.reduce((acc, item) => {
            if (item.main.temp_min < acc.min) acc.min = item.main.temp_min;
            if (item.main.temp_max > acc.max) acc.max = item.main.temp_max;
            return acc;
        }, { min: Infinity, max: -Infinity });
    };

    return (
        <div className="min-h-screen bg-gradient-to-br from-sky-100 to-purple-50 dark:from-slate-900 dark:to-slate-800 transition-all duration-500">
            <div className="container mx-auto px-4 pt-16">
                <BackLink />
                <motion.h1
                    initial={{ opacity: 0, y: -20 }}
                    animate={{ opacity: 1, y: 0 }}
                    className="text-5xl font-black text-center bg-gradient-to-r from-purple-600 to-sky-500 bg-clip-text text-transparent mb-8"
                >
                    Погода
                </motion.h1>

                {/* Форма поиска */}
                <form
                    onSubmit={handleSubmit}
                    className="flex flex-col items-center mb-12 space-y-4 group"
                >
                    <motion.div
                        className="relative w-full max-w-xl"
                        whileHover={{ scale: 1.02 }}
                    >
                        <input
                            type="text"
                            placeholder="Начните вводить город..."
                            value={city}
                            onChange={(e) => setCity(e.target.value)}
                            className="w-full px-6 py-4 rounded-2xl bg-white/80 dark:bg-slate-800/80 backdrop-blur-sm text-xl shadow-xl border-2 border-transparent focus:border-purple-500 focus:ring-2 focus:ring-purple-200 focus:outline-none transition-all dark:text-white"
                        />
                        <button
                            type="submit"
                            className="absolute right-3 top-3 p-2 bg-purple-600 hover:bg-purple-700 rounded-xl text-white transition-all shadow-md"
                        >
                            <FiSearch size={24} />
                        </button>
                    </motion.div>

                    <button
                        type="button"
                        onClick={fetchCityByGeolocation}
                        className="flex items-center text-sm text-purple-600 dark:text-purple-400 hover:text-purple-700 transition-colors group"
                    >
                        <FiNavigation className="mr-2 transition-transform group-hover:rotate-12" />
                        Использовать мое местоположение
                    </button>
                </form>

                {/* Индикатор загрузки */}
                {loading && (
                    <div className="flex flex-col items-center gap-4">
                        <ClipLoader color="#7c3aed" size={60} speedMultiplier={0.8} />
                        <span className="text-purple-600 animate-pulse">Идет поиск данных...</span>
                    </div>
                )}

                <AnimatePresence>
                    {/* Блок ошибок */}
                    {error && (
                        <motion.div
                            className="p-4 mb-8 bg-red-100/90 dark:bg-red-900/30 backdrop-blur-sm rounded-xl border-2 border-red-500 text-red-700 dark:text-red-300 flex items-center gap-3"
                            initial={{ opacity: 0, scale: 0.9 }}
                            animate={{ opacity: 1, scale: 1 }}
                            exit={{ opacity: 0, scale: 0.9 }}
                        >
                            <div className="text-2xl">⚠️</div>
                            <div>{error}</div>
                        </motion.div>
                    )}

                    {/* Основная информация о погоде */}
                    {weather && (
                        <motion.div
                            className="relative bg-white/80 dark:bg-slate-800/80 backdrop-blur-lg rounded-2xl shadow-2xl p-8 mb-12 overflow-hidden"
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                        >
                            <div className="absolute inset-0 bg-gradient-to-r from-purple-500/10 to-sky-500/10" />

                            <div className="relative z-10 flex flex-col md:flex-row items-center justify-between">
                                <div className="text-center md:text-left">
                                    <h2 className="text-4xl font-bold mb-2 text-slate-800 dark:text-white">
                                        {weather.name}
                                    </h2>
                                    <p className="text-2xl text-slate-600 dark:text-slate-300 mb-4 capitalize">
                                        {weather.weather[0].description}
                                    </p>
                                    <div className="text-6xl font-black bg-gradient-to-r from-purple-600 to-sky-500 bg-clip-text text-transparent">
                                        {Math.round(weather.main.temp)}°C
                                    </div>
                                </div>

                                <div className="mt-6 md:mt-0">
                                    <img
                                        src={`https://openweathermap.org/img/wn/${weather.weather[0].icon}@4x.png`}
                                        className="w-48 h-48 drop-shadow-lg animate-float"
                                        alt="Иконка погоды"
                                    />
                                </div>
                            </div>

                            {/* Дополнительные параметры */}
                            <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mt-8">
                                {[
                                    { icon: '💧', label: 'Влажность', value: `${weather.main.humidity}%` },
                                    { icon: '🌬️', label: 'Ветер', value: `${weather.wind.speed} м/с` },
                                    { icon: '🌡️', label: 'Ощущается', value: `${Math.round(weather.main.feels_like)}°C` },
                                    { icon: '📏', label: 'Давление', value: `${weather.main.pressure} гПа` },
                                ].map((item, index) => (
                                    <motion.div
                                        key={index}
                                        className="p-4 bg-white/50 dark:bg-slate-700/50 rounded-xl backdrop-blur-sm hover:shadow-md transition-all"
                                        whileHover={{ scale: 1.05 }}
                                    >
                                        <div className="text-2xl mb-2">{item.icon}</div>
                                        <div className="text-sm text-slate-500 dark:text-slate-300">{item.label}</div>
                                        <div className="text-xl font-semibold dark:text-white">{item.value}</div>
                                    </motion.div>
                                ))}
                            </div>

                            {/* Восход и закат */}
                            <div className="grid md:grid-cols-2 gap-6 mt-8">
                                <div className="p-4 bg-purple-50/50 dark:bg-slate-700/50 rounded-xl flex items-center gap-4">
                                    <FiSunrise className="text-3xl text-purple-600 dark:text-purple-400" />
                                    <div>
                                        <div className="text-sm text-slate-500 dark:text-slate-300">Восход солнца</div>
                                        <div className="text-2xl font-bold dark:text-white">{formatTime(weather.sys.sunrise)}</div>
                                    </div>
                                </div>
                                <div className="p-4 bg-sky-50/50 dark:bg-slate-700/50 rounded-xl flex items-center gap-4">
                                    <FiSunset className="text-3xl text-sky-600 dark:text-sky-400" />
                                    <div>
                                        <div className="text-sm text-slate-500 dark:text-slate-300">Закат солнца</div>
                                        <div className="text-2xl font-bold dark:text-white">{formatTime(weather.sys.sunset)}</div>
                                    </div>
                                </div>
                            </div>
                        </motion.div>
                    )}

                    {/* Прогноз на 5 дней */}
                    {forecast.length > 0 && (
                        <motion.div
                            className="mb-12"
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                        >
                            <h3 className="text-2xl font-bold mb-6 text-slate-800 dark:text-white">
                                Прогноз на 5 дней
                            </h3>

                            <div className="flex flex-col gap-4">
                                {forecast.map((day) => {
                                    const { min, max } = getMinMaxTemp(forecast);
                                    return (
                                        <motion.div
                                            key={day.dt}
                                            className="bg-white/80 dark:bg-slate-800/80 backdrop-blur-sm rounded-xl p-4 cursor-pointer hover:shadow-lg transition-shadow relative group"
                                            onClick={() => toggleDayDetails(day)}
                                            whileHover={{ scale: 1.01 }}
                                        >
                                            <div className="flex items-center justify-between">
                                                <div className="flex items-center gap-4">
                                                    <img
                                                        src={`https://openweathermap.org/img/wn/${day.weather[0].icon}@2x.png`}
                                                        className="w-16 h-16 animate-pulse-slow"
                                                        alt="Иконка погоды"
                                                    />
                                                    <div>
                                                        <div className="font-semibold text-slate-800 dark:text-white">
                                                            {formatDate(day.dt)}
                                                        </div>
                                                        <div className="text-sm text-slate-500 dark:text-slate-300 capitalize">
                                                            {day.weather[0].description}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="flex items-center gap-6">
                                                    <div className="text-right">
                                                        <div className="text-2xl font-bold text-slate-800 dark:text-white">
                                                            {Math.round(day.main.temp)}°C
                                                        </div>
                                                        <div className="text-sm text-slate-500 dark:text-slate-400">
                                                            мин {Math.round(min)}° / макс {Math.round(max)}°
                                                        </div>
                                                    </div>
                                                    <div className="text-purple-500">
                                                        {expandedDay === day.dt ? <FiChevronUp /> : <FiChevronDown />}
                                                    </div>
                                                </div>
                                            </div>

                                            <AnimatePresence>
                                                {expandedDay === day.dt && (
                                                    <motion.div
                                                        className="mt-4 pt-4 border-t border-slate-200 dark:border-slate-700"
                                                        initial={{ opacity: 0, height: 0 }}
                                                        animate={{ opacity: 1, height: 'auto' }}
                                                        exit={{ opacity: 0, height: 0 }}
                                                    >
                                                        <div className="grid grid-cols-2 gap-4 text-sm">
                                                            <DetailItem label="Влажность" value={`${day.main.humidity}%`} />
                                                            <DetailItem label="Скорость ветра" value={`${day.wind.speed} м/с`} />
                                                            <DetailItem label="Давление" value={`${day.main.pressure} гПа`} />
                                                            <DetailItem label="Ощущается как" value={`${Math.round(day.main.feels_like)}°C`} />
                                                            <DetailItem label="Облачность" value={`${day.clouds.all}%`} />
                                                            <DetailItem label="Видимость" value={`${day.visibility/1000} км`} />
                                                        </div>
                                                    </motion.div>
                                                )}
                                            </AnimatePresence>
                                        </motion.div>
                                    );
                                })}
                            </div>
                        </motion.div>
                    )}
                </AnimatePresence>
            </div>
        </div>
    );
}

const DetailItem = ({ label, value }) => (
    <div className="flex justify-between items-center p-2 bg-white/50 dark:bg-slate-700/50 rounded-lg hover:bg-white/70 transition-colors">
        <span className="text-slate-500 dark:text-slate-300">{label}</span>
        <span className="font-medium text-slate-800 dark:text-white">{value}</span>
    </div>
);