import React, { useState, useEffect, useContext, useRef } from 'react';
import Aside from './Aside';
import PostList from './PostList';
import PostModal from './PostModal';
import CreatePost from './CreatePost';
import useWindowSize from '../hooks/useWindowSize';
import BottomNav from './BottomNav';
import axios from 'axios';
import { AuthContext } from '../context/AuthContext';
import { motion, AnimatePresence } from 'framer-motion';
import RightSidebar from './RightSidebar';
import { PenLine, Newspaper, Home as HomeIcon, Plus, TrendingUp, Filter } from 'lucide-react';
import NewsCard from "./NewsCard";

function Home() {
    const { isLoggedIn, user } = useContext(AuthContext);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [posts, setPosts] = useState([]);
    const [news, setNews] = useState([]);
    const [showCreateButton, setShowCreateButton] = useState(true);
    const { width } = useWindowSize();
    const [userData, setUserData] = useState(user);
    const [activeTab, setActiveTab] = useState('posts');
    const [isFilterOpen, setIsFilterOpen] = useState(false);
    const [newsData, setNewsData] = useState({
        items: [],
        meta: {}
    });

    const prevScrollY = useRef(0);
    const [isHeaderVisible, setIsHeaderVisible] = useState(true);
    const throttleTimeout = useRef(null);

    useEffect(() => {
        const fetchUserData = async () => {
            if (isLoggedIn && !userData) {
                const token = localStorage.getItem('token');
                try {
                    const response = await axios.get(`${process.env.REACT_APP_API_URL}/profile`, {
                        headers: { Authorization: `Bearer ${token}` },
                    });
                    setUserData(response.data);
                } catch (error) {
                    console.error('Ошибка загрузки данных пользователя', error);
                }
            }
        };
        fetchUserData();
    }, [isLoggedIn, userData]);

    useEffect(() => {
        const fetchNews = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/news`);
                setNews(response.data.data || []);

                console.log('News data structure:', {
                    received: response.data,
                    used: response.data.data
                });
            } catch (error) {
                console.error('Error fetching news:', error);
                setNews([]);
            }
        }
        fetchNews();
    }, [])


    useEffect(() => {
        document.body.style.overflow = isModalOpen ? 'hidden' : 'auto';
        return () => document.body.style.overflow = 'auto';
    }, [isModalOpen]);

    useEffect(() => {
        const handleScroll = () => {
            if (throttleTimeout.current) return;

            throttleTimeout.current = setTimeout(() => {
                const currentScrollY = window.scrollY;
                const scrollingDown = currentScrollY > prevScrollY.current;
                const scrollThreshold = 50;
                const scrollDifference = Math.abs(currentScrollY - prevScrollY.current);

                if (currentScrollY > 200) {
                    setShowCreateButton(!scrollingDown);
                } else {
                    setShowCreateButton(true);
                }

                if (currentScrollY < 10) {
                    setIsHeaderVisible(true);
                } else if (scrollDifference > scrollThreshold) {
                    setIsHeaderVisible(!scrollingDown);
                }

                prevScrollY.current = currentScrollY;
                throttleTimeout.current = null;
            }, 100);
        };

        window.addEventListener('scroll', handleScroll, { passive: true });
        return () => {
            window.removeEventListener('scroll', handleScroll);
            if (throttleTimeout.current) {
                clearTimeout(throttleTimeout.current);
            }
        };
    }, []);

    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);
    const addPost = (newPost) => setPosts([newPost, ...posts]);
    const toggleFilter = () => setIsFilterOpen(!isFilterOpen);

    const tabs = [
        { id: 'posts', label: 'Посты', icon: <PenLine size={16} /> },
        { id: 'news', label: 'Новости', icon: <Newspaper size={16} /> },
        { id: 'trending', label: 'Популярное', icon: <TrendingUp size={16} /> }
    ];

    const renderFloatingButton = () => (
        <motion.button
            onClick={openModal}
            className={`fixed bottom-20 right-6 flex items-center justify-center bg-gradient-to-r from-violet-500 to-purple-600 hover:from-violet-600 hover:to-purple-700 text-white font-semibold rounded-full shadow-lg transition-all duration-300 z-30
                ${width < 768 ? 'w-14 h-14' : 'w-14 h-14'}`}
            initial={{ scale: 0 }}
            animate={{ scale: showCreateButton ? 1 : 0 }}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.95 }}
            transition={{ duration: 0.3 }}
        >
            <Plus className="w-6 h-6" />
        </motion.button>
    );

    const headerVariants = {
        visible: {
            y: 0,
            opacity: 1,
            scale: 1,
            backdropFilter: 'blur(12px)',
            boxShadow: '0 10px 30px -10px rgba(0,0,0,0.1)',
            transition: {
                type: "spring",
                stiffness: 200,
                damping: 25,
                mass: 0.5
            }
        },
        hidden: {
            y: -100,
            opacity: 0,
            scale: 0.98,
            backdropFilter: 'blur(0px)',
            boxShadow: '0 4px 6px -1px rgba(0,0,0,0.02)',
            transition: {
                type: "spring",
                stiffness: 300,
                damping: 20
            }
        }
    };

    return (
        <div className="container mx-auto flex flex-col lg:flex-row gap-2 p-2 md:p-2 pt-16">
            {width >= 1024 ? <Aside /> : <BottomNav />}

            <main className="w-full lg:w-2/5 xl:w-1/2 dark:bg-neutral-900">
            <motion.header
    className={`sticky top-16 mb-2 bg-white/80 dark:bg-neutral-800/90 backdrop-blur-md border border-white/10 p-2 rounded-xl shadow-glass z-50 mt-16 ${width < 768 ? 'mt-0' : ''} }`}
    initial="hidden"
    animate={isHeaderVisible ? "visible" : "hidden"}
    variants={headerVariants}
    style={{
        WebkitBackdropFilter: 'blur(12px)',
        willChange: 'transform, opacity, backdrop-filter'
    }}
>
    <div className="flex items-center justify-between">
        {/* Левая часть - Логотип и название */}
        <div className="flex items-center gap-2">
            <motion.div
                whileTap={{ scale: 0.95 }}
                className="p-1"
            >
                <HomeIcon className="w-6 h-6 text-violet-600 dark:text-violet-400"/>
            </motion.div>
            <h2 className="text-xl font-bold text-gray-900 dark:text-white">
                Главная
            </h2>
        </div>

        {/* Правая часть - Кнопки */}
        <div className="flex items-center gap-2">
            <motion.button
                whileTap={{ scale: 0.95 }}
                onClick={toggleFilter}
                className="p-2 rounded-lg bg-white/50 dark:bg-neutral-800/50 shadow-sm"
                aria-label="Filter posts"
            >
                <Filter className="w-5 h-5 text-gray-600 dark:text-gray-300"/>
            </motion.button>

            <motion.button
                whileTap={{ scale: 0.95 }}
                onClick={openModal}
                className="flex items-center gap-1 px-3 py-2 rounded-lg bg-violet-500 shadow-sm"
            >
                <PenLine className="w-4 h-4 text-white"/>
                <span className="text-white text-sm font-medium">
                    Пост
                </span>
            </motion.button>
        </div>
    </div>

    {/* Табы */}
    <div className="mt-3 flex border-b border-gray-200/50 dark:border-neutral-800 overflow-x-auto">
        {tabs.map((tab) => (
            <motion.button
                key={tab.id}
                onClick={() => setActiveTab(tab.id)}
                className={`flex items-center gap-1 px-3 py-2 text-xs font-medium relative min-w-max
                    ${activeTab === tab.id
                        ? 'text-violet-600 dark:text-violet-400'
                        : 'text-gray-600 dark:text-gray-400'
                    }`}
            >
                {React.cloneElement(tab.icon, {className: "w-4 h-4"})}
                {tab.label}
                {activeTab === tab.id && (
                    <motion.div
                        layoutId="activeTabIndicator"
                        className="absolute bottom-0 left-0 w-full h-[2px] bg-violet-500 dark:bg-violet-400"
                        transition={{ duration: 0.2 }}
                    />
                )}
            </motion.button>
        ))}
    </div>
</motion.header>
                <AnimatePresence>
                    {isFilterOpen && (
                        <motion.div
                            initial={{opacity: 0, height: 0}}
                            animate={{opacity: 1, height: 'auto'}}
                            exit={{opacity: 0, height: 0}}
                            transition={{duration: 0.3}}
                            className="bg-white dark:bg-neutral-800 rounded-xl p-4 mb-4 shadow-md"
                        >
                            <h3 className="font-medium text-gray-800 dark:text-gray-200 mb-3">Фильтры</h3>
                            <div className="flex flex-wrap gap-2">
                                <button
                                    className="px-3 py-1.5 bg-violet-100 dark:bg-violet-900/30 text-violet-600 dark:text-violet-300 rounded-full text-sm font-medium">Все
                                    посты
                                </button>
                                <button
                                    className="px-3 py-1.5 bg-gray-100 dark:bg-neutral-700 text-gray-600 dark:text-gray-300 rounded-full text-sm font-medium hover:bg-violet-100 dark:hover:bg-violet-900/30 transition-colors">Мои
                                    подписки
                                </button>
                                <button
                                    className="px-3 py-1.5 bg-gray-100 dark:bg-neutral-700 text-gray-600 dark:text-gray-300 rounded-full text-sm font-medium hover:bg-violet-100 dark:hover:bg-violet-900/30 transition-colors">Популярное
                                </button>
                                <button
                                    className="px-3 py-1.5 bg-gray-100 dark:bg-neutral-700 text-gray-600 dark:text-gray-300 rounded-full text-sm font-medium hover:bg-violet-100 dark:hover:bg-violet-900/30 transition-colors">Новое
                                </button>
                            </div>
                        </motion.div>
                    )}
                </AnimatePresence>

                <AnimatePresence mode="wait">
                    <motion.div
                        key={activeTab}
                        initial={{opacity: 0, y: 10}}
                        animate={{opacity: 1, y: 0}}
                        exit={{opacity: 0, y: -10}}
                        transition={{duration: 0.3}}
                    >
                        {activeTab === 'posts' && <PostList posts={posts} setPosts={setPosts}/>}
                        {activeTab === 'news' && (
                            Array.isArray(news) ? (
                                news.length > 0 ? (
                                    <div className="space-y-6 last:pb-28">
                                        {news.map(item => (
                                            <NewsCard
                                                key={item.id}
                                                news={item}
                                                isFeatured={false}
                                                parent="Home"
                                            />
                                        ))}
                                    </div>
                                ) : (
                                    <div className="text-center py-8 text-gray-500">
                                        Новостей пока нет
                                    </div>
                                )
                            ) : (
                                <div className="text-red-500 p-4">
                                    Ошибка загрузки новостей. Получен некорректный формат данных.
                                </div>
                            )
                        )}
                        {activeTab === 'trending' && (
                            <div className="bg-white dark:bg-neutral-800 rounded-xl p-6 text-center">
                                <TrendingUp className="w-12 h-12 mx-auto mb-3 text-gray-400 dark:text-gray-500"/>
                                <h3 className="text-lg font-medium text-gray-800 dark:text-gray-200">Популярное скоро
                                    появится</h3>
                                <p className="text-gray-500 dark:text-gray-400 mt-1">Мы работаем над этим разделом</p>
                            </div>
                        )}
                    </motion.div>
                </AnimatePresence>

                {renderFloatingButton()}
            </main>

            {width >= 1024 && <RightSidebar/>}

            <AnimatePresence>
                {isModalOpen && (
                    <motion.div
                        initial={{opacity: 0}}
                        animate={{opacity: 1}}
                        exit={{opacity: 0}}
                        transition={{duration: 0.2}}
                    >
                        <PostModal closeModal={closeModal}>
                            <CreatePost addPost={addPost} closeModal={closeModal}/>
                        </PostModal>
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
}

export default Home;
